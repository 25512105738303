import React, { useContext } from "react";
import { withStyles, Divider } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import Carousel from "../../carousel/Carousel";
import { openLink } from "../../util/utilNative";
import IconVideo from "../../../asset/img/ico_video.png";
import MoreIcon from "../../../asset/img/ic_list_arrow_nor.png";

const styles = (theme) => ({
  root: {
    position: 'relative',
    letterSpacing: "-0.5px",
    margin: '6px 0px',
    zIndex: 11
  },
  carousel: {
    width: '100%',
    height: '144px'
  },
  listitem: {
    width: "298px",
    padding: "0px 8px 0px 0px",
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    width: '290px',
    height: '100%',
    flex: 'auto',
    display: 'flex',
    marginRight: '8px',
    background: '#fff',
    border: '1px solid #d7d7d7',
    borderRadius: '12px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  indexBoxContainer: {
    height: "5.625em", // '90px',
    display: 'flex',
    flexDirection: 'column',
    width: '280px',
    padding: "12px 20px"
  },
  indexBox: {
    fontSize: theme.font.size18,
    fontFamily: "LGSmHaTSB",
    textAlign: "start",
    color: theme.color.accent,
  },
  info: {
    height:'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  infoTitleBox: {
    width: '100%',
    flexGrow: 1
  },
  infoTitle: {
    height: "2.8125em", //"45px",
    fontSize: theme.font.size15, // "15px",
    color: theme.color.text,
    fontFamily: 'LGSmHaTSB',
    textAlign: "start",
    overflow: "hidden"
  },
  videoIcon: {
    width: "14px",
    verticalAlign: "middle",
    margin: "0 0 0 3px"
  },
  infoDescription: {
    fontSize: theme.font.size13,
    color: theme.color.text,
    textAlign: 'start',
    padding: '10px',
    flexGrow: 1
  },
  more: {
    height: '40px',
    fontSize: theme.font.size14,
    color: theme.color.text,
    fontFamily: "LGSmHaTSB",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 20px",
    background: "#fafafa",
    borderRadius: "0px 0px 12px 12px",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)"
    },
    outline: "0 none",
    border: "0px"
  },
  moreImage: {
    width: "24px",
    height: "24px"
  }
});

const CLCard = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const context = useContext(DataContext);
  const MAX_SUMMARY_STR_LENGNTH = 90;

  const handleClick = item => {
    if (ref.clickAllowed()){
      console.log(item.url);
      openLink(context, item.type, item.url, item.event_action);
    }
  };
  const Card = ({ item, index }) => {
    let summaryStr = item.summary
    if (summaryStr.length > MAX_SUMMARY_STR_LENGNTH){
      summaryStr = summaryStr.substring(0,MAX_SUMMARY_STR_LENGNTH-3)+"...";
    }
    return (
      <div className={classes.card} >
        <div className={classes.cardContainer} onClick={() => handleClick(item)} aria-label={item.title}>
          <div className={classes.indexBoxContainer}>
            <div className={classes.indexBox}>
              {"Q"+(index + 1)}
            </div>
            <div className={classes.infoTitleBox}>
              <div className={classes.infoTitle}>{item.title}{(item.video_yn === 'Y') && <img className={classes.videoIcon} src={IconVideo} alt={"IconVideo"} />}</div>
            </div>
          </div>
          <div className={classes.info}>
            <div className={classes.infoDescription}>{summaryStr}</div>
            <Divider orientation={"horizontal"} component={"div"} disabled/>
            <button className={classes.more} >
              자세히 보기
              <img src={MoreIcon} className={classes.moreImage} alt={""}/>
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    (data&& 'list_items' in data)&&data.list_items.map((item, index) => (
      <div key={index} className={classes.listitem}>
        <Card key={index} item={item} index={index} />
      </div>
    ))
  )
});

function CL(props) {
  const { classes, data } = props;

  return (
    <div className={classes.root}>
      {/* Card Carousel */}
      <Carousel className={classes.carousel} component={withStyles(styles)(CLCard)} data={data} />
    </div>
  );
}
export default withStyles(styles)(CL);
