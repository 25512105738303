import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import NewTickets from '../chat/NewTickets';
import ChatTab from '../chat/ChatTab';

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  topLayout: {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  bottomLayout: {
    flexGrow: 1,
    width: "100%",
    display:"flex",
  },
  leftLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px"
  },
  rightLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px"
  }
});

function Chat(props) {
  const { classes } = props;
  const [chatTab, setChatTab] = useState(0);
  const [chatTicket, setChatTicket] = useState();
  const [transferredTicket, setTransferredTicket] = useState();

  const handleChange = (type) => {
    setChatTab(type);
    setChatTicket(null);
  }

  const handleTicketClick = ticket => {
    setChatTicket(ticket);
  }

  const addNewTicket = ticket => {
    setTransferredTicket(ticket)
  }


  const [argusKey, setArgusKey] = useState("");
  const tempSetArgus = (key) =>{
    console.log("set__argus__key:",key)
    setArgusKey(key);
  }

  return (
    <div className={classes.root}>
      <div className={classes.topLayout}>
          <NewTickets onChange={handleChange} onTicketClick={handleTicketClick} addNewTicket={addNewTicket} argus={tempSetArgus}/>
      </div>
      <div className={classes.bottomLayout}>
          <ChatTab type={chatTab} ticket={chatTicket} transferredTicket={transferredTicket} argusKey={argusKey}/>
      </div>
    </div>
  );
}
export default withStyles(styles)(Chat);