import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  title: {
    display: 'flex',
    width: '100%',
    fontSize: "50px",
    fontFamily: 'LGSmHaT',
    fontWeight: 'bold', 
    justifyContent: 'center'
 }
});

function Error(props) {
  const { classes } = props;
  return (
    <>
      <div className={classes.title}>
        No Permission
      </div> 
    </>
  );
}
export default withStyles(styles)(Error);