import React, { useEffect, useState } from 'react';
import { RBACIsAccessbile } from '../data/Loader';
import { useUser } from '../data/User'
import {
  Typography,
  Collapse,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import { 
  ExpandLess,
  ExpandMore,
  Chat,
  Visibility,
  PieChart,
  Dvr,
  Bookmark,
  Explore,
  Person,
  People,
  Equalizer,
  Message,
  AddComment,
  Settings
} from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    height: "-webkit-fill-available",
    backgroundColor: "#f9f9f9"
  },
  list: {
    padding: "0px"
  },
  listItem: {
    color: "#262626"
  },
  selected: {
    borderRight: `5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  menuIcon: {
    width: "20px",
    height: "20px",
    margin: 0,
    padding: 0
  },
  menuIconSelected: {
    color: theme.palette.primary.main
  }
});

// 일반 탭 메뉴
const NormalMenu = withStyles(styles)((props) => {
  const { classes, menu, select } = props;
  return (
    <List className={classes.list}>
      <ListItem
        className={select === menu.link ? classes.selected : classes.listItem}
        fullwidth={"true"}
        aria-label={menu.label}
        component={Link}
        to={menu.link}>
        <ListItemIcon className={select === menu.link ? classes.menuIconSelected: ""}>{menu.icon}</ListItemIcon>
        <ListItemText><Typography variant={"caption"}>{menu.label}</Typography></ListItemText>
      </ListItem>
      <Divider/>
    </List>
  );
});

// 하위 메뉴를 포함하는 메뉴
const SubMenu = withStyles(styles)((props) => {
  const { classes, menu, select } = props;
  const [open, setOpen] = useState(false);

  // 하위 메뉴를 열거나 닫음
  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <List className={classes.list}>
      <ListItem 
        fullwidth={"true"}
        aria-label={menu.label}
        onClick={handleClick}>
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText><Typography variant={"caption"}>{menu.label}</Typography></ListItemText>
          {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItem>
      <Collapse in={open} timeout={"auto"} unmountOnExit>
        {menu.subMenus.map((subMenu, index) => (
          <ListItem key={`sub-${index}`}
            className={select === subMenu.link ? classes.selected : classes.listItem}
            component={Link}
            to={subMenu.link}>
            <ListItemIcon className={[classes.subMenuIcon, select === subMenu.link ? classes.menuIconSelected : ""].join(" ")}>{subMenu.icon}</ListItemIcon>
            <ListItemText><Typography variant={"caption"}>{subMenu.label}</Typography></ListItemText>
          </ListItem>
        ))}
      </Collapse>
      <Divider/>
    </List>
  );
});

const MenuBar = (props) => {
  const { classes } = props;
  const [pathname, setPathname] = useState(window.location.pathname);
  const [accInfo, setAccInfo] = useState({});
  const { me } = useUser();
  const history = useHistory();

  // 메뉴는 추후 서버에서 받아올 수도 있으나, 현재는 Front-end가 관리함
  let menus = [
    {
      label: "상담하기",
      icon: <Chat className={classes.menuIcon} />,
      link: "/chat",
      accessible: accInfo['menu_counsel'],
    },
    {
      label: "모니터링",
      icon: <Visibility className={classes.menuIcon} />,
      link: "/monitor",
      accessible: accInfo['menu_monitoring'],
      subMenus: [{
        label: "CS톡 모니터링",
        icon: <Equalizer classes={classes.menuIcon} />,
        link: "/monitor/cs"
      }, {
        label: "전체 로그인/배분 현황",
        icon: <People classes={classes.menuIcon} />,
        link: "/monitor/allocate"
      }]
    },
    /*
    {
      label: "상담관리",
      icon: <Dvr className={classes.menuIcon} />,
      link: "/history",
    },
    {
      label: "통계지표",
      icon: <PieChart className={classes.menuIcon} />,
      link: "/graph",
    },
    {
      label: "지식관리",
      icon: <Bookmark className={classes.menuIcon} />,
      link: "/knowledge",
    },
    */
    {
      label: "상담운영 설정",
      icon: <Explore className={classes.menuIcon} />,
      link: "/management",
      accessible: accInfo['menu_branch_setting'],
      subMenus: [{
        label: "카테고리 관리",
        icon: <Typography variant={"caption"}>Category</Typography>,
        link: "/management/category"
      }, {
        label: "상담사 관리",
        icon: <Typography variant={"caption"}>User</Typography>,
        link: "/management/counselor"
      }, 
      // {
      //   label: "업무 배정",
      //   icon: <Typography variant={"caption"}>Work</Typography>,
      //   link: "/management/work"
      // }, {
      //   label: "휴일 관리",
      //   icon: <Typography variant={"caption"}>Holiday</Typography>,
      //   link: "/management/holiday"
      // }, {
      //   label: "설문 관리",
      //   icon: <Typography variant={"caption"}>Survey</Typography>,
      //   link: "/management/survey"
      // }, {
      //   label: "시스템 템플릿 관리",
      //   icon: <Typography variant={"caption"}>Template</Typography>,
      //   link: "/management/template"
      // },
      {
        label: "공지사항 관리",
        icon: <Typography variant={"caption"}>Notice</Typography>,
        link: "/management/notice"
      }, 
      {
        label: "휴일/업무시간 관리",
        icon: <Typography variant={"caption"}>Holiday</Typography>,
        link: "/management/holiday"
      }, 
      {
        label: "기타 관리",
        icon: <Typography variant={"caption"}>Others</Typography>,
        link: "/management/others"
      }, 
      // {
      //   label: "관심고객 관리",
      //   icon: <Typography variant={"caption"}>Favorite</Typography>,
      //   link: "/management/favorite"
      // }, {
      //   label: "금칙어 관리",
      //   icon: <Typography variant={"caption"}>Prohibit</Typography>,
      //   link: "/management/prohibit"
      // }
    ]
    },
    {
      label: "개인환경 설정",
      icon: <Person className={classes.menuIcon} />,
      link: "/setting",
      accessible: accInfo['menu_account_setting'],
      subMenus: [{
        label: "매크로 설정",
        icon: <AddComment classes={classes.menuIcon} />,
        link: "/setting/macro"
      }, {
        label: "개인정보 설정",
        icon: <Settings classes={classes.menuIcon} />,
        link: "/setting/personal"
      // }, {
      //   label: "톡 추천 메시지 관리",
      //   icon: <Message classes={classes.menuIcon} />,
      //   link: "/setting/recommend"
      }]
    },
  ];

  if (me && me.currentBranch && me.currentBranch==='system') {
    menus = [
      {
        label: "시스템세팅",
        icon: <Settings className={classes.menuIcon} />,
        link: "/system/settings",
        accessible: accInfo['menu_system_setting'],
      },
    ]

  }

  // Path Name 을 통해서 현재 선택된 메뉴를 표시
  useEffect(() => {
    const unlisten = history.listen((location) => {
      console.log("[MenuBar] - The current pathname is", location.pathname);
      setPathname(location.pathname);
    });

    return function cleanup() {
      unlisten();
    }

    
  }, []);

  useEffect(() => {
    let retvalue = ['viewId', 'viewName'];
    let viewIdArray = [
      "menu_counsel",
      "menu_account_setting",
      "menu_branch_setting",
      "menu_monitoring",
      "menu_system_setting"
    ]
    
    if (me.counselorId) {
      let branchArray = [
        me.currentBranch
      ]
      RBACIsAccessbile(retvalue, branchArray, viewIdArray)
        .then(RBACViewList => {
          let accObj = RBACViewList.reduce((newObj, viewInfo) => {
            newObj[viewInfo.viewId] = true
            return newObj;
          }, {})
          console.log(accObj);
          setAccInfo(accObj);
          // menus.map(curMenu=>{
          //   curMenu.accessible = accObj[curMenu.viewId];
          // })
        })
        .catch(error => {
          console.error(error)
        })
    }
    else {
      setAccInfo({});
    }
  }, [me]);

  return (
    <div className={classes.root}>
      {menus.reduce((newArray, menu) => {
        if (menu.accessible) {
          if (menu.subMenus) {
            newArray.push(<SubMenu menu={menu} key={menu.link} select={pathname} />)
          } else {
            newArray.push(<NormalMenu menu={menu} key={menu.link} select={pathname} />)
          }
        }
        return newArray;
      }, [])}
    </div>
  )
}
export default withStyles(styles)(MenuBar);