import React from 'react';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import Circular from '../highcharts/Circular';
import Column from '../highcharts/Column';

import {
  ArrowRight,
  HeadsetMic,
  Alarm,
  HourglassEmpty
} from '@material-ui/icons';

const styles = (theme) => ({
  root: {

  },
  container: {
    padding: "12px"
  },
  graphTitle: {
    display: "flex",
    alignItems: "center"
  },
  graphText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #e6e6e6",
    margin: 0,
    padding: 0
  }
});

function Dashboard(props) {
  const { classes, value, index } = props;

  // 연동 전 테스트 데이터를 임시로 사용한다.
  const testCircularData = [{
    series: [{name: "처리율", y: 89}, {name: "미처리율", y: 11}],
    title: "처리율"
  }, {
    series: [{name: "목표시간내 수락률"}, {name: "No Data", y: 100}],
    title: "목표시간내 수락률"
  }, {
    series: [{name: "목표시간내 응답률", y: 89}, {name: "미응답률", y: 11}],
    title: "목표시간내 응답률"
  }, {
    series: [{name: "SPOT 지표", y: 89}, {name: "실패", y: 11}],
    title: "SPOT 지표"
  }];

  const testColumnData = [{
    series: [{name: "최근 8 ~ 14 일", data: [123, 234, 345, 456, 567]}, {name: "최근 0 ~ 7 일", data: [111, 222, 444, 333, 123]}],
    category: ["금", "월", "화", "수", "목"],
    title: "주간 동향 ( 처리 )"
  }, {
    series: [{name: "시간 동향 ( 처리 )", data: [65, 105, 70, 6, 52, 76, 77, 43, 24]}],
    category: ["9시", "10시", "11시", "12시", "13시", "14시", "15시", "16시", "17시"],
    title: "시간 동향 ( 처리 )"
  }];

  const testTimeData = [{
    data: "00:06:10",
    title: "상담시간",
    icon: <HeadsetMic/>
  }, {
    data: "00:00:18",
    title: "응답시간",
    icon: <Alarm/>
  }, {
    data: "00:00:16",
    title: "대기시간",
    icon: <HourglassEmpty/>
  }]


  return (value === index && (
    <div className={classes.root}>
      <Grid container className={classes.container} spacing={1}>
        {testCircularData.map((data, index) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={`monitor_panel_dashboard_graph_${index}`}>
            <div className={classes.graphTitle}>
              <ArrowRight color="primary"/>
              <Typography variant={"subtitle2"}>{data.title}</Typography>
            </div>
            <Circular series={[{ data: data.series, innerSize: "75%" }]} width={240} height={240} />
          </Grid>
        ))}
      </Grid>
      <Grid container className={classes.container} spacing={1}>
        {testColumnData.map((data, index) => (
          <Grid item xs={10} sm={10} md={5} lg={5} key={`monitor_panel_dashboard_graph_${index}`}>
            <div className={classes.graphTitle}>
              <ArrowRight color="primary"/>
              <Typography variant={"subtitle2"}>{data.title}</Typography>
            </div>
            <Column series={data.series} categories={data.category} height={240} />
          </Grid>
        ))}
        <Grid item xs={10} sm={10} md={5} lg={2} key={`monitor_panel_dashboard_graph_${index}`}>
            <div className={classes.graphTitle}>
              <ArrowRight color="primary"/>
              <Typography variant={"subtitle2"}>평균 처리 지표</Typography>
            </div>
            <List className={classes.graphText}>
              {testTimeData.map((data, index) => (
                <>
                  {index !== 0 && <Divider orientation={"horizontal"}/>}
                  <ListItem dense>  
                    <ListItemIcon>{data.icon}</ListItemIcon>
                    <ListItemText>
                      <div><Typography variant={"subtitle2"}>{data.title}</Typography></div>
                      <Typography variant={"subtitle1"}>{data.data}</Typography>
                    </ListItemText>
                  </ListItem>
                </>
              ))}
            </List>
          </Grid>
      </Grid>
    </div>
  ));
}
export default withStyles(styles)(Dashboard);