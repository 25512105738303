import React, { useState, useEffect} from 'react';
import { withStyles, Grid, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { getConversation, getTicketHistoryList, getTicket, getMessages, QueryChatHistoryInfo, getTicketRecord } from '../../data/Loader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUser } from '../../data/User';
import Message from "../Message/Message";
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination,
  Collapse, Box, CircularProgress, IconButton} from '@material-ui/core';


const styles = (theme) => ({
  root:{
    // display:"flex",
    background:"#fff",
    height: "calc(100% - 60px)",
    padding:"10px",
    fontSize:"0.8rem"
  },
  accordionTitle:{
    background: "#ebebeb",
    // borderRadius: "3px",
    padding: "10px 16px",
    paddingRight:"48px",
    color: "#726f6f",
  },
  row:{
    display:"flex",
    textAlign:"center",
    width:"100%",
  },
  num:{
    width:"10%",
    borderRight:"1px solid #726f6f"
  },
  date:{
    width:"70%",
    // borderRight:"1px solid #726f6f"
  },
  status:{
    width:"20%"
  },
  accordionContent:{
    // display:"flex",
    textAlign:"center",
    width:"100%",
  },
  info:{
    // width:"30%",
    // paddingRight:"10px",
    // borderRight:"2px solid #cacaca",
    // borderRadius:"3px",
  },
  chatHistory:{
    // width:"70%",
    // border:"1px solid ",
    // borderRadius:"3px",
    height:"350px",
    overflowY:"auto",
    // borderRight: "1px solid #e0e0e0",
    // borderLeft: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
    background: "#f5f5f5",
  },
  infoTable:{
    width:"100%",
    fontSize:"11.5px",
    borderTop :"1px solid #e0e0e0",
    // borderLeft :"1px solid #e0e0e0",
    // borderRight:"1px solid #e0e0e0",
  },
  infoTd:{
    width:"50%", 
    padding:'5px',
    fontSize:"12px",
    border:"none",
  },
  errorMsgBox:{
    background: "#eeeeee",
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    wordBreak: "break-all",
    boxShadow: "1px 1px 5px #b9b9b9",
    color: "#949494",
  },
  noMsgBox:{
    display:"flex",
    height:"100%",
    width:"100%",
    justifyContent:"center",
    alignItems:"center",
    color: "#949494",
  },
  accordionSummaryContent:{
    margin: "8px 0px",
  },
  expandIcon:{
    padding:"0px"
  },
  historyTableContainer:{
    padding:"5px",
    maxHeight:"700px",
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #ececec",
    marginTop:"5px"
  },
  table:{
    background: "#ffffff",
    borderTop:"1px solid #e0e0e0"
  },
  td100:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"1em"
  },
  td120:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"1em"
  },
  td11:{
    height:"32px",
    padding:"0px 10px",
    fontSize:"1em",
    width: "220px",
  },
  cellCollapse:{
    width: "80px"
  },
  cellnum:{
    width:"80px",
  },
  loadingCol:{
    height: "220px",
    color: "#7c7c7c",
    border: "none",
    textAlign:"center",
    fontSize:"0.8rem" 
  },

});

const Row = withStyles(styles)((props)=>{
  const { classes, row , num} = props;
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [counselorResult, setCounselorResult] = useState("");

  const ticketId = row && row.ticketId;
  const convId = row && row.convId

  useEffect(()=>{
    ticketId && getRecordInfo(ticketId)
    }, [ticketId]
  );
  const getRecordInfo = async (ticketId) =>{
    try{
      let result = await getTicketRecord(ticketId);
      console.log("[getRecordInfo] Query Result", result)
      const record = result.data.getTicketRecord;
      record && setCounselorResult(record.counselorResult);
    }
    catch (err){
      console.log("[getRecordInfo] Query Error", err)
    }
  }
  const getInfo = () =>{
    setLoading(true)
    QueryChatHistoryInfo(["getTicket", "getConversation", "getMessages"], {
      ticketId: ticketId,
      convId: convId,
      created: null,
      nextCursor: null, 
      orderAsc: true, 
      resultLimit: null
    }).then((result) => {
        console.log("[get_livechat_history_info] Query Result", result.data);
        const ticketInfo = result.data.getTicket;
        const convInfo = result.data.getConversation;
        const messages = result.data.getMessages.info.filter(message => {
          const contentJson = JSON.parse(message.contentJson);
          return contentJson.template_type !== "typing";
        });
        let historyData = {
          ticket: ticketInfo,
          conversation: convInfo,
          messageList: messages
        }
      // console.log("[getTicketHistory] Query Result", historyData);
      setInfo(historyData);
    }).catch((e) => {
        console.log("[get_livechat_history_info] Query Error", e);
    }).finally(() => {
      setLoading(false)
    });

  }

  const handleChange = ()=>{
    setOpen(!open)
    console.log(info)
    if (!info){
      getInfo();
    }
  }

  const statusNameList = {
    status_new : "NEW",
    status_assigned : "티켓 할당",
    status_chat : "상담중",
    status_transfer : "티켓 전달중",
    status_followup : "후처리 대기",
    status_closed : "상담 종료"
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleChange}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {num}
        </TableCell>
        <TableCell component="th" scope="row">
        {row.ticketId}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {counselorResult==="complete"?"처리":"미처리"}
        </TableCell>
        <TableCell><div className={classes.date}>{moment(row.created * 1000).format("YYYY-MM-DD HH:mm:ss")}</div></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ border: 0, paddingBottom: 0, paddingTop: 0}} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                {loading && <TableRow><TableCell colSpan={3} className={classes.loadingCol}><CircularProgress/></TableCell></TableRow>}
                {info && (
                  <div className={classes.accordionContent}>
                    <div className={classes.info}>
                      <Table className={classes.infoTable} size="small" aria-label="">
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.td100} component="th" scope="row">티켓ID</TableCell>
                            <TableCell className={classes.td11} colSpan={3}>{info.ticket && info.ticket.ticketId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.td100} component="th" scope="row">카테고리</TableCell>
                            <TableCell className={classes.td11} colSpan={3}>{info.ticket && info.ticket.categoryId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.td100} component="th" scope="row">상담사</TableCell>
                            <TableCell className={classes.td11} colSpan={3}>{info.ticket && info.ticket.counselorId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.td100} component="th" scope="row">티켓상태</TableCell>
                            <TableCell className={classes.td11}>{info.ticket && statusNameList[info.ticket.statusId]}</TableCell>
                            <TableCell className={classes.td100} component="th" scope="row">종료일자</TableCell>
                            <TableCell className={classes.td11}>{info.conversation && moment(info.conversation.ended*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.td100} component="th" scope="row">상담요약:</TableCell>
                            <TableCell className={classes.td11} colSpan={3}></TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                    <div className={classes.chatHistory}>
                      {info.messageList&&info.messageList.length?
                        (info.messageList.map((m,i)=>{
                          let str = m.contentJson;
                          str = str.replace(/'/g, '"')
                          try{
                            let jsn = JSON.parse(str);
                            return(
                              <Message key={`message-${i}`} data={jsn} />
                            )
                          }catch(e){
                            return(
                              <div className="wrapper right" key={`key-chat-message-${i}`}>
                                <div className = {classes.errorMsgBox}> 
                                  <ErrorOutline className="errorIcon"></ErrorOutline>
                                  <div>해당 메시지를 불러올 수 없습니다.</div>
                                </div>
                              </div>
                            )
                          }
                        })
                        ):(
                            <div className = {classes.noMsgBox}> 
                              <ErrorOutline className="errorIcon"></ErrorOutline>
                            <div> 메시지가 없습니다. </div>
                          </div>
                        )}
                    </div>
                  </div>)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

const CollapsibleTable = withStyles(styles)((props)=>{
  const {history, classes, getInfo, pageInfo, loadStatus, ticketId} = props;
  // const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    loadData(0, parseInt(event.target.value, 10));
  };

  const loadData = (currPage, currRowsPerPage) => {

    console.log(">>current page:", currPage)
    console.log(">>current limit:", currRowsPerPage)
    const total_row_count = pageInfo.totalCount;
    
    //해당 페이지에서 보여져야 하는 item 시작/끝 index
    const start_index = currPage*currRowsPerPage;
    const end_index = Math.min((currPage+1)*currRowsPerPage-1, total_row_count-1); 
    console.log("[page start index]", start_index);
    console.log("[page end index]", end_index);

    //실제 loaded item index:
    const loaded_start_index = 0
    const loaded_end_index = history.length-1;
    console.log("[already loaded start index]", loaded_start_index);
    console.log("[already loaded end index]", loaded_end_index);

    if (loaded_end_index<end_index){
      const limit = end_index - loaded_end_index;
      getInfo(pageInfo && pageInfo.endCursor, limit, true);
      console.log("[limit]", limit);
    }

  }

  useEffect(()=>{setPage(0); setRowsPerPage(5)},[ticketId]);
  return (
    <>
    <TableContainer className={classes.historyTableContainer}>
      <Table 
        stickyHeader
        size={"small"}
        className={classes.table}
        aria-label="Service History"
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellCollapse}/>
            <TableCell align="left" className={classes.cellnum}>번호</TableCell>
            <TableCell align="left">제목</TableCell>
            <TableCell align="left">처리상태</TableCell>
            <TableCell align="left">접수일자</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadStatus=="loading" && <TableRow><TableCell colSpan={5} className={classes.loadingCol}><CircularProgress/></TableCell></TableRow>}
          {history.slice(page*rowsPerPage, (page+1)*rowsPerPage).map((row, index) => (
            <Row key={row.ticketId} row={row} num={page*rowsPerPage +index+1}/>
          ))}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
          </TableRow>
        </TableFooter> */}
      </Table>
    </TableContainer>
    <TablePagination
          colSpan={5}
          rowsPerPageOptions={[5, 10]}
          // count={history.length}
          count={pageInfo.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>
  )
});

function LiveChatHistory(props) {
  const { classes, value, index, data } = props;
  const [history, setHistory] = useState([]);
  const ticket = data;
  const ticketId = ticket.ticketId;
  const [nextInfo, setNextInfo] = useState([]);
  const [loadStatus, setLoadStatus] = useState("init");

  // const [expanded, setExpanded] = React.useState(false);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const getInfo = async (nextCursor, limit, more=false) => {
    try {
      setLoadStatus("loading")
      let result = await getTicketHistoryList(ticketId, nextCursor, null, limit);
      console.log("[getTicketHistoryList] Query Result", result);
      setLoadStatus("result")
      const historyData = result.data && result.data.getTicketHistoryList.info;
      const pageInfo = result.data && result.data.getTicketHistoryList.pageinfo; //endCursor, hasNextPage
      setNextInfo(pageInfo);
      more? setHistory( prev =>{ return [...prev, ...historyData] }) :setHistory(historyData)
    }
    catch (err) {
      console.log("[getTicketHistoryList] Query Error", err);
      throw (err);
    }
  }

  useEffect(() => {
    getInfo(null, 5);
  }, [ticketId]);


  return (
    <CollapsibleTable history={history} pageInfo={nextInfo} getInfo={getInfo} loadStatus={loadStatus} ticketId={ticketId}>
    </CollapsibleTable>


  );
}
export default withStyles(styles)(LiveChatHistory);