import React from "react";
import FolderIcon from "@material-ui/icons/Folder";
import { Person } from "@material-ui/icons";

export const TreeIcon = (props) => {
  switch (props.fileType) {
    case "user":
      return <Person color={"primary"} />;
    case "category":
      return <FolderIcon color={"success"} />;
    default:
      return null;
  }
};
