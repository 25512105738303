import { MuiThemeProvider } from '@material-ui/core';
import { CssBaseline } from '@material-ui/core';
import './App.css';
import Amplify from 'aws-amplify';
import RouteMap from './component/page/RouteMap';
import Test from './component/page/test';
import theme from './component/theme/theme';
import LiveChatAwsExport from "./asset/aws/livechat-aws-exports";
import { UserProvider } from './component/data/User';
import Font from './component/font/Font';
import loadJs from "load-js"
import { ToastProvider} from 'react-toast-notifications';

// Amplify.configure({Auth: {
//   region: LiveChatAwsExport.aws_cognito_region,
//   userPoolId: LiveChatAwsExport.aws_user_pools_id,
//   userPoolWebClientId: LiveChatAwsExport.aws_user_pools_web_client_id
// }});

Amplify.configure(LiveChatAwsExport);

function App() {
	// 동적으로 네이버 맵 로드
	loadJs(
		"https://" + "openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=iveo33q885&submodules=geocoder"
	);

  return (
    <MuiThemeProvider theme={theme}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}
        placement="top-right">
        <CssBaseline/>
        <Font/>
        <UserProvider>
          {/* <Test /> */}
          <RouteMap/>
        </UserProvider>
      </ToastProvider>
    </MuiThemeProvider>
  );
}

export default App;
