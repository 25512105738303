import React, {useContext, createContext, useEffect, useState} from 'react';
import { withStyles, Typography, Divider, Grid } from '@material-ui/core';

import Carousel from "../../carousel/Carousel";
import SG from "../Message/SG"
import TX from "../Message/TX"
import CT from "../Message/CT"
import ProductCard from "../Message/ProductCard"
import {Card, Button, TextField, FormControl, Select, MenuItem, InputLabel} from '@material-ui/core';

import img1 from '../../../asset/img/test1.jpg';
import img2 from '../../../asset/img/test2.jpg';
import img3 from '../../../asset/img/test3.jpg';
import img4 from '../../../asset/img/test4.jpg';
import img5 from '../../../asset/img/test5.jpg';
import { mutationSendMessage, getProductInfo, searchProduct} from '../../data/Loader';
import {mkTLStr,mkProductCardStr} from '../../data/DataConvert'
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const productCompareURL = 'https://www.lge.co.kr/compare-products?chatbot=y&modelId=';

const styles = (theme) => ({
  root: {
    height: "-webkit-fill-available",
    fontFamily: 'LGSmHaT',
    color:'#8e8e8e',
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "5px",
  },
  doSearchButton:{
    width:"25%",
    margin:"5px",
    boxShadow:"none",
    background:"#0a8297",
  },
  conditionAddButton:{
    width:"25%",
    margin:"5px",
    boxShadow:"none",
    border: "1px solid #0a8297",
    color: "#0A8297",
    backgroundColor: "#f5f5f5",
    fontWeight: 600,
    padding: "0px 19px",
    fontSize: "0.8rem",
    height: "31px",
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  }, 
  title:{
    fontWeight:'600',
    color:'#7b7a7a',
    paddingLeft:'15px',
    paddingBottom:'5px',
  },
  td:{
    background:'none',
    border:'none',
    padding:'3px 24px 3px 16px',
    color:'#7b7a7a'
  },
  searchInput:{
    display:'inline'
  },
  contentSearchTextField: {
    width: "75%",
    margin: "5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  },
  contentSearchTextField2: {
    width: "75%",
    margin: "5px",
    '& label':{
      fontSize:"0.8rem",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
      transform: "translate(15px, 10px) scale(1)",
    },
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.8rem"
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      "paddingTop":"8px",
      "paddingBottom":"8px",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },
  
  value:{

  },
  refreshIcon:{
    float:'right',
  },
  carousel:{
    color:'yellow'
  },
  carouselDiv:{
    height:'100px',
  },
  gscsBtn:{
    backgroundColor:'#bf134a',
    width:'100px',
  },
  inputSearchDiv:{
    minWidth:"100%",
    display:"flex",
  },
  selectDiv:{
    minWidth:"100%",
    display:"flex",
  },
  subConditionDiv:{
    background: "#f5f5f5",
    margin: "10px 5px 10px 5px",
    // paddingTop: "10px"
  },
  subCondition:{
    // minWidth:"100%",
    display:"flex",
    // border:"1px solid #ececec",
    // margin:"5px"

  },
  selectFormControl:{
    padding:0,
    width: "50%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  },
  selectFormControl2:{
    textAlign:"center",
    height:"33px",
    padding:0,
    width: "50%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.8rem"
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      "padding":"8px 32px 8px 14px"
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
      transform: "translate(15px, 10px) scale(1)",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },
  menuItem:{
    lineHeight:"1.1",
    fontSize: "0.84rem",
  },
  selectLabel2:{
    fontSize: "0.8rem"
  },
  submitBtn:{
    display:'inline',
    alignSelf:'center',
    textAlign:'center',
    margin:"15px",
  },
  submit:{
    backgroundColor:'#0a8297',
    width:'100px',
    margin:"5px",
  },
  submitCancel:{
    backgroundColor:'#a9a8a8',
    width:'100px',
  },
  searchBtnDiv:{
    display:"flex",
    justifyContent:"flex-end"
  },
  carousels:{
    minHeight: "400px",
  },
  carouselMsg:{
    border: "1px solid #c4c4c4",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    height: "400px",
    margin: "5px",
  },
  alert:{
    margin:"-5px 5px 5px 5px",
  },
  alertBox:{
    marginTop:"10px"
  }
});

function ProductSearch(props) {
  const { classes, data } = props;
  const counselorId = data && data.counselorId;
  const convId = data && data.convId

  const [productObj, setProductObj] = React.useState({});
  const [productGroupList, setProductGroupList] = React.useState([]);
  const [productList, setProductList] = React.useState([]);

  const [productGroupSelect, setProductGroupSelect] = React.useState("");
  const [productSelect, setProductSelect] = React.useState("");
  const [searchType, setSearchType] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  const [conditionType, setConditionType] = React.useState("");
  const [conditionValue, setConditionValue] = React.useState("");
  const [conditionUnitList, setConditionUnitList] = React.useState([]);

  const [conditionUnit, setConditionUnit] = React.useState("");
  const [conditionSign, setConditionSign] = React.useState("");

  const [addedCondition, setAddedCondition] = React.useState({});

  const [productInfoData, setProductInfoData] = React.useState([]);
  const [productCardSelected, setProductCardSelected] = React.useState([]);
  const [productInfoStatus, setProductInfoStatus] = useState("init");

  const [searchError, setSearchError] = useState("");
  const [resultError, setResultError] = useState("");

  const [linkSendDisabled, setLinkSendDisabled] = useState(true);
  const [sendDisabled, setSendDisabled] = useState(true);

  const convertCondName = (conditionCode)=>{
    switch(conditionCode){
      case 'screen_size':
        return "스크린사이즈" 
      case 'capacity_weight':
        return "무게" 
      case 'capacity_area':
        return "평형" 
      case 'capacity_volume':
        return "용량" 
    }
    return "INVALID_TYPE"
  }

  const convertSignName = (signCode)=>{
    switch(signCode){
      case 'eq':
        return "=" 
      case 'gt':
        return ">" 
      case 'lt':
        return "<" 
      case 'ge':
        return ">=" 
      case 'le':
        return "<=" 
    }
    return "INVALID_SIGN_TYPE"
  }

  const convertUnitName = (unitCode)=>{
    switch(unitCode){
      case 'centi-meter':
        return "cm" 
      case 'inch':
        return "inch" 
      case 'kilo-gram':
        return "kg" 
      case 'square-meter':
        return "m2" 
      case 'pyeong':
        return "평" 
      case 'liter':
        return "liter" 
    }
    return "INVALID_UNIT_TYPE"
  }

  const conditionTypeList = [
    {code:'screen_size',name:"스크린사이즈"}, 
    {code:'capacity_weight',name:"무게"}, 
    {code:'capacity_area',name:"평형"},
    {code:'capacity_volume',name:"용량"}
  ] ;

  const conditionSignList = [
    {code:'eq', name:'='},
    {code:'gt', name:'>'},
    {code:'lt', name:'<'},
    {code:'ge', name:'>='},
    {code:'le', name:'<='},
  ]

  const makeText = (type, value, unit, sign) => {
    return `${convertCondName(type)} ${convertSignName(sign)} ${value} ${convertUnitName(unit)} `
  }

  const deleteCondition = (index) => {
    console.log(`deleteCondition is called for index - ${index}`)
    setAddedCondition(prev => {
      if (prev && 'list_items' in prev){
        let newArray = prev.list_items;
        newArray.splice(index,1);
        console.log(newArray)
        return { ...prev, list_items: newArray };
      };
      return prev;
    });
    console.log("addedCondition", addedCondition);
  };

  const makeProductInfoData = (productInfoList) => {
    let newData = {
      items : productInfoList.map(item=>{
        let product_spec = item.product_spec.map(specInfo=>{
          let convertedSpec = specInfo.replaceAll(/, */ig, ',').replaceAll('=', '":"').replaceAll(',','","').replaceAll('{','{"').replaceAll('}','"}');
          return JSON.parse(convertedSpec)
        })
        return {
          link : {
            product_image_mobile_url: item.product_mobile_url,
	          product_image_url: item.product_image_url,
	          product_link_url: item.product_link_url,
	          product_name: item.product_name,
            product_id: item.product_id,
	          product_title: item.product_title,
	          type: "external"
          },
          product_spec,
          product_price: {
            product_master_price: item.product_master_price,
            product_obs_price: item.product_obs_price
          },
          horizontal_buttons: [
            {
              label: "더 많은 제품보기",
              parameters: {
                action: "external",
                enable: "Y",
                mobile_url: item.product_group_link_url,
                url : item.product_group_link_url
              },
              type: "action_frontend"
            }
          ],
          buttons: [
            {
              label: "구매전문가 상담",
              parameters: {
                action: "speech",
                enable: "Y",
                mobile_url: item.bestshop_counsel_mobile_link_url,
                url: item.bestshop_counsel_pc_link_url
              },
              type: "speech"
            },
            {
              label: "상세보기" ,
              parameters: {
                action: "external",
                enable: "Y",
                mobile_url:item.product_shop_mobile_link_url,
                url: item.product_shop_pc_link_url
              },
              type: "action_frontend"
            }
          ]
        }
      }),
    }
    return newData;
  }

  const handleSearch = () => {
    console.log(`${productGroupSelect}-${productSelect}-${searchType}-${searchText}`)

    if(!productGroupSelect){
      console.log("no group select");
      setSearchError("제품 그룹을 선택해 주세요.")
      return;
    }
    setSearchError("")
    if ('list_items' in addedCondition) {
      addedCondition.list_items.map(item => {
        console.log(item)
      })
    }
    const retvalue = [
      "product_kind",
      "product_id",
      "product_code",
      "product_title",
      "product_copy",
      "product_name",
      "product_model_name",
      "product_image_url",
      "product_image_mobile_url",
      "product_sales_count",
      "product_ship_date",
      "product_new_flag",
      "product_hit_flag",
      "product_group_link_url",
      "product_link_url",
      "product_shop_pc_link_url",
      "product_shop_mobile_link_url",
      "bestshop_counsel_pc_link_url",
      "bestshop_counsel_mobile_link_url",
      "product_shop_counseling",
      "product_master_price",
      "product_obs_price",
      "product_price",
      "product_review_cnt",
      "product_social_review_cnt",
      "event_count",
      "bs_event_count",
      "product_search_condition_value",
      "product_spec",
      "product_story_chatbot"
    ]
    let defaultLimit = 30;
    let conditionList = null;
    if ('list_items' in addedCondition) {
      conditionList = addedCondition.list_items.map(item => {
        let newItem = {
          unit: item.unit,
          comparison: convertSignName(item.sign),
          type: item.type,
          value: item.value
        }
        return JSON.stringify(newItem);
      })
    }

    setProductInfoStatus("loading");
    searchProduct(retvalue, productSelect?productSelect:productGroupSelect, searchType, defaultLimit, conditionList, searchText)
    .then(productDetailList=>{
      const convertedProductDetail = makeProductInfoData(productDetailList);
      console.log("[product detail list]", convertedProductDetail);
      setProductInfoData(convertedProductDetail)
      setProductCardSelected(convertedProductDetail.items.map(_=>{return false}));
      setProductInfoStatus("result");
    })
    .catch(error => {
      console.error(error)
      setProductInfoStatus("error");
    });
  }

  const handleAddCondition = () => {
    console.log("click add condition", addedCondition)

    if(!(conditionType && conditionValue && conditionUnit && conditionSign)){
      setSearchError("조건이 맞지 않습니다.")
      return;
    }
    setSearchError("")
    let newCondition = {
      type: conditionType,
      value: conditionValue,
      unit: conditionUnit,
      sign: conditionSign,
      title : makeText(conditionType, conditionValue, conditionUnit, conditionSign),
      deleteFunc : deleteCondition
    }

    let prevCondition = [];
    if ('list_items' in addedCondition){
      prevCondition = addedCondition.list_items
    }
    
    let newAddedCondition = [...prevCondition, newCondition ];

    setAddedCondition({
      type: "CT",
      list_items: newAddedCondition,
    })
   
    setConditionType("");
    setConditionValue("");
    setConditionUnitList([]);
    setConditionUnit("");
    setConditionSign("");
  }

  const getCompareModelLinkURL = () => {
    let productIdArray = [];
    for (let index in productCardSelected) {
      let selected = productCardSelected[index];
      if (selected) {
        productIdArray.push(productInfoData.items[index].link.product_id);
      }
    }

    let url = ""
    if (productIdArray.length>=2 || productIdArray.length<=3){
      url = `https://www.lge.co.kr/compare-products?chatbot=y&modelId=${productIdArray.join(',')}`
    }
    else {
      console.log(`selected card is ${productIdArray.length} : only 2~3 cards can be compared`);
    }
    return url;
  }
  const handleSendLink = () => {
    // var contentJson = mkTLStr(counselorId,"false","test_text", "test_label", "text speec", "inapp", "www.naver.com");
    let linkURL = getCompareModelLinkURL();
    if (linkURL) {
      var contentJson = mkTLStr(counselorId, false, "모델 비교 링크를 통해 모델간의 차이점을 확인하실 수 있습니다",
        "모델 비교하기", "모델 비교하기", "external", linkURL);

      mutationSendMessage(contentJson, convId, counselorId)
        .then(result => {
          console.log("[Send Message Link] Result", result);
        })
        .catch(error => {
          console.log("[Send Message Link] Error", error);
        })
    }
    else {
      //todo - show alert for unavailable makeing compare url 
    }
  }

  
  const handleSendCard = () => {
    let dataToSend = [];
    for (let index in productCardSelected){
      let selected = productCardSelected[index];
      if (selected){
        dataToSend.push(productInfoData.items[index]);
      }
    }
    mutationSendMessage(mkProductCardStr(dataToSend), convId, counselorId)
      .then(result => {
        console.log("[Send Message Carousel] Result", result);
      })
      .catch(error => {
        console.log("[Send Message Carousel] Error", error);
      })

  }
  const handleProductGroupChange = (event) => {
    const productGroupCode = event.target.value
    console.log(`productGroupInfo : ${productGroupCode}`)
    setSearchError("")
    setProductGroupSelect(productGroupCode);
    let tempProductList = []
    for (let productCode in productObj[productGroupCode]){
      let productInfo = productObj[productGroupCode][productCode]
      tempProductList.push({
        name: productInfo.productName,
        code: productInfo.productCode
      })
    }
    console.log('tempProductList');
    console.log(tempProductList)
    setProductList(tempProductList);
  }

  const handleProductChange = (event) => {
    const productCode = event.target.value
    console.log(`productInfo : ${productCode}`)
    setProductSelect(productCode);
  }

  const handleSearchTypeChange = (event) => {
    const searchType = event.target.value
    console.log(`searchType : ${searchType}`)
    setSearchType(searchType)
  }

  const handleConditionTypeChange = (event) => {
    const conditionType = event.target.value
    console.log(`conditionType : ${conditionType}`)
    setConditionType(conditionType);
    let conditionUnitList = []
    switch(conditionType){
      case 'screen_size': 
        conditionUnitList = [
          {type:'centi-meter',name:convertUnitName('centi-meter')},
          {type:'inch',name:convertUnitName('inch')},
        ]
        break;
      case 'capacity_weight': 
        conditionUnitList = [
          {type:'kilo-gram',name:convertUnitName('kilo-gram')}
        ]
        break;
      case 'capacity_area': 
        conditionUnitList = [
          {type:'square-meter',name:convertUnitName('square-meter')},
          {type:'pyeong',name:convertUnitName('pyeong')}
        ]
        break;
      case 'capacity_volume': 
        conditionUnitList = [
          {type:'liter',name:convertUnitName('liter')}
        ]
        break;
    }
    setConditionUnitList(conditionUnitList);
  }

  const handleSearchText = (event) => {
    // event.preventDefault();
    setSearchText(event.target.value);
  };

  const handleConditionValue = (event) => {
    // event.preventDefault();
    setConditionValue(event.target.value);
  };

  const handleConditionUnit = (event) => {
    setConditionUnit(event.target.value);
  }
  
  const handleConditionSign = (event) => {
    setConditionSign(event.target.value)
  }

  const handleProductCardSelected = (index) => {
    setProductCardSelected(prev => {
      prev[index] = !prev[index];
      return {...prev};
    })

    let selected_num = Object.keys(productCardSelected).filter((key)=>productCardSelected[key]).length;
    
    if (selected_num<2 || selected_num>3){
      setLinkSendDisabled(true);
    }
    else {
      setLinkSendDisabled(false);
    }

    if (selected_num<=0){
      setSendDisabled(true);
    }
    else {
      setSendDisabled(false);
    }
  }


  useEffect(()=>{
    getProductInfo([
      'productGroupCode',
      'productGroupName',
      'productCode',
      'productName',
      'is2depth'
    ])
    .then(productList=>{
      console.log(productList);
      let productObj = {}
      let tempGroupList = []
      let tempProductList = []
      productList.map(productInfo=>{
        if (!(productInfo.productGroupCode in productObj)){
          productObj[productInfo.productGroupCode] = {};
          tempGroupList.push({
            code: productInfo.productGroupCode,
            name: productInfo.productGroupName,
          })
        }
        productObj[productInfo.productGroupCode][productInfo.productCode] = productInfo
      })
      // let productGroupList = Object.keys(productObj)
      console.log(tempGroupList)
      setProductGroupList(tempGroupList);
      setProductList(tempProductList);
      setProductObj(productObj);
      
    //////////// temp/////////////
   const tempProdGroup = "CT50000131"; //에어컨
   const tempProd = "CT50000134"; //시스템에어컨

   setProductGroupSelect(tempProdGroup);
   console.log(productObj[tempProdGroup])
   let plist  = []
   for (let productCode in productObj[tempProdGroup]){
     let productInfo = productObj[tempProdGroup][productCode]
     plist.push({
       name: productInfo.productName,
       code: productInfo.productCode
     })
   }
   console.log('tempProductList::', plist);
   setProductList(plist);
   setProductSelect(tempProd);
   ///////////////////////////////
    })
    .catch(error => {
      console.error(error)
    });
  },[]);

  return (
    <div className={classes.root}>
      {/* <Button variant="contained" size="small" color="secondary" className={classes.gscsBtn}>GSCS</Button> */}
      {/* <form noValidate autoComplete="off">
        <div className={classes.inputSearchDiv}>
        </div>
      </form> */}
      <div className={classes.selectDiv}>
        {/* <ProductSelect/> */}
        {/* <SymptomSelect/> */}

        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
          <InputLabel id="product-group-select-label" classes={{root:classes.selectLabel}}>제품그룹</InputLabel>
          <Select
            size='small'
            labelId="product-group-select-label"
            id="product-group-select"
            value={productGroupSelect}
            onChange={handleProductGroupChange}
            label="제품그룹"
          >
            {
              productGroupList.map(productGroupInfo=>{
                return <MenuItem key={productGroupInfo.code} value={productGroupInfo.code} className={classes.menuItem}>{productGroupInfo.name}({productGroupInfo.code})</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
          <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품</InputLabel>
          <Select
            size='small'
            labelId="product-select-label"
            id="product-select"
            value={productSelect}
            onChange={handleProductChange}
            label="제품"
          >
            { productList&&productList.length
              ?  productList.map(productInfo=>{
                  return <MenuItem key={productInfo.code} value={productInfo.code} className={classes.menuItem}>{productInfo.name}({productInfo.code})</MenuItem>
                })
              :<MenuItem value="" className={classes.menuItem}><em>제품그룹을 선택해 주세요.</em></MenuItem>
            }
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
          <InputLabel id="search-type-select-label" classes={{root:classes.selectLabel}}>검색타입</InputLabel>
          <Select
            size='small'
            labelId="search-type-select-label"
            id="search-type-select"
            value={searchType}
            onChange={handleSearchTypeChange}
            label="검색타입"
          >
            <MenuItem key={0} value={"rec"} className={classes.menuItem}>추천순</MenuItem>
            <MenuItem key={1} value={"best"} className={classes.menuItem}>판매순</MenuItem>
            <MenuItem key={2} value={"new"} className={classes.menuItem}>신제품</MenuItem>
            <MenuItem key={3} value={"petname"} className={classes.menuItem}>펫네임</MenuItem>
            <MenuItem key={4} value={"model"} className={classes.menuItem}>모델명</MenuItem>
            <MenuItem key={5} value={"product"} className={classes.menuItem}>제품ID</MenuItem>
            <MenuItem key={6} value={"ret"} className={classes.menuItem}>렌탈</MenuItem>
          </Select>
        </FormControl>
        <TextField
            value={searchText}
            onChange={(event) => handleSearchText(event)}
            id="search-text-field"
            label="검색어"
            size="small"
            className={classes.contentSearchTextField}
            variant="outlined"
          />

      </div>
       
      <div className={classes.subConditionDiv}>
        <div className={classes.subCondition}>
          <FormControl variant="outlined" className={classes.selectFormControl2} margin='dense' size="small">
            <InputLabel id="condition-type-select-label" classes={{root:classes.selectLabel2}}>검색조건</InputLabel>
            <Select
              size='small'
              labelId="condition-type-select-label"
              id="condition-type-select"
              value={conditionType}
              onChange={handleConditionTypeChange}
              label="검색조건"
            >
              {
                conditionTypeList.map(conditionType=>{
                  return <MenuItem key={conditionType.code} value={conditionType.code} className={classes.menuItem}>{conditionType.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl2} margin='dense' size="small">
            <InputLabel id="condition-unit-select-label" classes={{root:classes.selectLabel2}}>단위</InputLabel>
            <Select
              size='small'
              labelId="condition-unit-select-label"
              id="condition-unit-select"
              value={conditionUnit}
              onChange={handleConditionUnit}
              label="단위"
            >
              {
                conditionUnitList.map(conditionUnit=>{
                  return <MenuItem key={conditionUnit.type} value={conditionUnit.type} className={classes.menuItem}>{conditionUnit.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl2} margin='dense' size="small">
            <InputLabel id="condition-sign-select-label" classes={{root:classes.selectLabel2}}>기호</InputLabel>
            <Select
              size='small'
              labelId="condition-sign-select-label"
              id="condition-sign-select"
              value={conditionSign}
              onChange={handleConditionSign}
              label="기호"
            >
              {
                conditionSignList.map(condSign=>{
                  return <MenuItem key={condSign.code} value={condSign.code}>{condSign.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <TextField
              value={conditionValue}
              onChange={(event) => handleConditionValue(event)}
              id="search-text-field"
              label="조건값"
              size="small"
              className={classes.contentSearchTextField2}
              variant="outlined"
            />
          <Button onClick={handleAddCondition} variant="contained" size="small" color="primary" className={classes.conditionAddButton}>조건추가</Button>
        </div>
        <div className={(addedCondition&&'list_items' in addedCondition)&& classes.carouselDiv}>
            {
              ((addedCondition&&'list_items' in addedCondition)&&<CT data={addedCondition}/>)
            }
        </div>
      </div>
      {searchError.length>0 && <Alert severity="error" className={classes.alert}>{searchError}</Alert>}

      <div className={classes.searchBtnDiv}>
        <Button onClick={handleSearch} variant="contained" size="small" color="primary" className={classes.doSearchButton}>모델검색</Button>
      </div>
      
      
      <div className={classes.carousels} >
        {productInfoStatus==="loading" && <div className={classes.carouselMsg}><CircularProgress/></div>}
        {productInfoStatus==="init" && <div className={classes.carouselMsg}> 제품을 검색해 주세요. </div>}
        {
          productInfoStatus==="result" && (
            (productInfoData &&'items' in productInfoData && productInfoData.items.length>0)
            ? <ProductCard 
              data={productInfoData} 
              selectable={true} 
              selectCardFunc={handleProductCardSelected} 
              selectCardArray={productCardSelected}/>
            : <div className={classes.carouselMsg}> 검색 결과가 없습니다. </div>
          )
        }
        </div>
        <div className={classes.alertBox}>
          {resultError.length>0 && <Alert severity="error" className={classes.alert}>{resultError}</Alert>}
        </div>
        <div className={classes.submitBtn}>
          <Button disabled={linkSendDisabled} onClick={handleSendLink} variant="contained" size="small" color="primary" className={classes.submit}>비교Link전송</Button>
          <Button disabled={sendDisabled} onClick={handleSendCard} variant="contained" size="small" color="primary" className={classes.submit}>Card전송</Button>
        </div>
    </div>
  );
}
export default withStyles(styles)(ProductSearch);