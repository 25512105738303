import React, { useContext, Fragment, useState } from "react";
import {
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Button,
  Checkbox,
  Grid
} from "@material-ui/core";
import Carousel from "../../carousel/Carousel";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";
import IconMore from "../../../asset/img/btn_more_nor.png";
import IconMorePressed from "../../../asset/img/btn_more_sel.png";
import IconMoreItem from "../../../asset/img/btn_more_slide.png";

const styles = (theme) => ({
  root: {
    position: "relative",
    letterSpacing: "-0.5px",
    marginTop: "20px",
    zIndex: 10,
  },
  carousel: {
    width: "100%",
    height: "144px",
  },
  listitem: {
    width: "288px",
    padding: "0px 8px 0px 0px",
  },
  card: {
    width: "280px",
    flex: "auto",
    background: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  moreItemList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "288px",
    padding: "0px 8px 0px 0px",
  },
  moreItemCard: {
    width: "140px",
    background: "transparent",
    border: "0px",
    borderRadius: "6px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  moreItemText: {
    fontSize: theme.font.size13,
    fontFamily: "LGSmHaTSB",
    color: theme.color.text,
    textAlign: "center",
    margin: "15px 0px",
    lineHeight: "24px"
  },
  thumbnail: {
    height: "150px",
    padding: "15px 20px",
  },
  thumbnailImage: {
    width: "auto",
    height: "132px",
  },
  moreItemThumbnailImage: {
    width: "60px",
    height: "60px",
    backgroundImage: `url(${IconMore})`,
    backgroundSize: "60px",
    "&:active": {
      backgroundImage: `url(${IconMorePressed})`
    }
  }, 
  info: {
    flexGrow: 1,
    minHeight: "155px",
  },
  infoTitle: {
    fontSize: theme.font.size15, // "15px",
    fontFamily: "LGSmHaTSB",
    color: theme.color.text,
    textAlign: "center",
  },
  infoDescription: {
    fontSize: theme.font.size12,
    fontFamily: "LGSmHaTL",
    color: "#7f7f7f",
    textAlign: "center",
  },
  table: {
    width: "-webkit-fill-available",
    margin: "12px 20px",
    padding: "0px",
    lineHeight: "18px",
  },
  tableRow: {
    borderBottom: `1px solid ${theme.color.list_divider}`
  },
  label: {
    width: "90px",
    maxWidth: "90px",
    height: "36px",
    fontSize: theme.font.size13,
    fontFamily: "LGSmHaT",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  value: {
    fontSize: theme.font.size13,
    fontFamily: "LGSmHaT",
    color: theme.color.text,
    textAlign: "start",
    padding: "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: '0',
  },
  horizontalDivider: {
    width: "100%",
    height: "1px",
    backgroundColor: theme.color.list_divider
  },
  external: {
    display: "flex",
    height: "45px",
    borderRadius: "0px 0px 12px 12px",
    background: "#fafafa"
  },
  externalHorizontal: {
    display: "flex",
    height: "45px",
    background: "#f7f0f0",
    flexDirection: "column"
  },
  endBorderRadius: {
    borderRadius: "0 0 12px 12px",
  },
  externalButton: {
    color: theme.color.accent,
    fontFamily: "LGSmHaTSB",
    fontSize: theme.font.size14,
    flex: 1,
    border: "none",
    borderRadius: "0px 0px 12px 12px",
    padding: 0,
    background: "#fafafa",
    "&:active": {
      background: "rgba(38, 38, 38, 0.1)"
    }
  },
  externalButtonLeft: {
    borderRadius: "0px 0px 0px 12px"
  },
  externalButtonRight: {
    borderRadius: "0px 0px 12px 0px"
  },
  horizontalButton: {
    width: "100%",
    height: "45px",
    textAlign: "center",
    color: "#707070",
    fontSize: theme.font.size14,
    fontWeight: "bold",
    padding: "0px",
    backgroundColor: "#f7f7f7",
  },
  disabledButton: {
    color: "rgba(38, 38, 38, 0.4)",
  },
  externalDivider: {
    height: "20px",
    alignSelf: "center",
    backgroundColor: theme.color.list_divider
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonIcon: {
    width: "20px",
    height: "20px",
    marginRight: "4px",
  },
  horizontalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 18px",
  },
  horizontalButtonLabel: {
    display: "flex",
    flexDirection: "row",
  },
  priceRow: {
    height: "100%",
    display: "flex",
    alignItems: "baseline",
  },
  priceObs: {
    fontWeight: "bold",
    fontSize: theme.font.size16,
    color: theme.color.message.text,
    marginStart: "8px",
    wordBreak: "breakWord",
  },
  priceMaster: {
    textDecoration: "line-through",
    fontSize: theme.font.size14,
    color: theme.color.message.date,
    marginStart: "8px",
  },
  selectedCard: {
    border: "1px solid #0a8297",
    position: "relative"
  },
  selectedCheck: {
    position: "absolute",
    top: "4px",
    right: "4px",
    zIndex: 12,
    color: "#0a8297",
    background: "#fff",
    width: "30px",
    height: "30px"
  }
});

const Card = React.forwardRef((props, ref) => {
  // console.log(props)
  const { classes, data, selectable, selectCardFunc, selectCardArray } = props;
  const context = useContext(DataContext);
  const featureHorizontalButtons = false;
  const featureMoreItems = true;

  const handleClick = (url, action, event) => {
    if (!ref.clickAllowed()) {
      return;
    }
    openLink(context, action, url, event);
  };

  const handleSelect = (event, index) => {
    selectCardFunc(index);
  }

  let cardList = data.items && Array.isArray(data.items) && data.items.map((item, key) => (
    <div key={`cardList${key}`} className={classes.listitem}>
      <div className={[classes.card, ((selectable && selectCardArray) && selectCardArray[key])?classes.selectedCard:""].join(" ")}>
        {(selectable && selectCardArray)&& <Checkbox color="primary" checked={selectCardArray[key]} className={classes.selectedCheck} onChange={e => handleSelect(e, key)}/>}
        
        <div
          className={classes.thumbnail}
          onClick={() => handleClick(item.link["product_link_url"], item.link["type"])}
        >
          <img
            className={classes.thumbnailImage}
            src={item.link["product_image_url"]}
            alt={`${item.link["product_title"]}, 상세 바로가기`}
          />
        </div>
        <div className={classes.info}>
          {/* HTML TAG가 들어간 경우 처리 */}
          <div className={classes.infoTitle} dangerouslySetInnerHTML={{__html: item.link["product_title"]}} />
          <div className={classes.infoDescription} dangerouslySetInnerHTML={{__html: item.link["product_name"]}} />
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              {item.product_spec.map((spec, key) => (
                <TableRow className={classes.tableRow} key={`product_spec${key}`}>
                  <TableCell className={classes.label} align="left" dangerouslySetInnerHTML={{__html:spec.label}} />
                  <TableCell className={classes.value} align="right" dangerouslySetInnerHTML={{__html:spec.value}} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <div></div> */}
          {item.product_price &&
            item.product_price.product_master_price !== "0" && (
              <div className={classes.table} size="small">
                <Grid
                  container
                  className={classes.priceRow}
                  key={`product_price${key}`}
                >
                  <Grid
                    item
                    className={classes.priceObs}
                    dangerouslySetInnerHTML={{
                      __html: item.product_price.product_obs_price + "원",
                    }}
                    aria-label={`할인가: ${item.product_price.product_obs_price}원`}
                    role="text"
                  />
                  <Grid
                    item
                    className={classes.priceMaster}
                    dangerouslySetInnerHTML={{
                      __html: item.product_price.product_master_price + "원",
                    }}
                    aria-label={`정상가: ${item.product_price.product_master_price}원`}
                    role="text"
                  />
                </Grid>
              </div>
            )}
        </div>
        <Divider orientation={"horizontal"} className={classes.horizontalDivider} component={"div"} disabled/>
        <div
          className={[
            classes.external,
            (featureHorizontalButtons===false||!("horizontal_buttons" in item)) && classes.endBorderRadius,
          ].join(" ")}
        >
          {item.buttons.map((button, index) => (
            <Fragment key={`buttons${index}`}>
              <Divider
                className={classes.externalDivider}
                orientation="vertical"
                hidden={index === 0}
                component={"div"} 
                disabled
              />
              <Button
                className={[
                  classes.externalButton,
                  button.parameters.enable === "N" && classes.disabledButton,
                  (index === 0 && item.buttons.length > 1) ? classes.externalButtonLeft : "",
                  (index === item.buttons.length - 1 && item.buttons.length > 1) ? classes.externalButtonRight : ""
                ].join(" ")}
                disabled={
                  context.conversationEnd || button.parameters.enable === "N"
                }
                disableRipple
                onClick={() =>
                  handleClick(
                    context.userAgent.deviceType === "mobile"
                      ? button.parameters.mobile_url
                      : button.parameters.url,
                    button.parameters.action,
                    button.event_action
                  )
                }
                aria-label={`${button.label}, ${item.link["product_title"]}`}
              >
                <div className={classes.buttonContainer}>
                  {/* <img
                    className={classes.buttonIcon}
                    src={
                      button.label === context.string.chat.bestshopCounsel
                        ? button.parameters.enable === "N"
                          ? IconChatDisabled
                          : IconChat
                        : button.parameters.enable === "N"
                        ? IconStoreDisabled
                        : IconStore
                    }
                    alt={
                      button.label === context.string.chat.bestshopCounsel
                        ? "IconChat"
                        : "IconStore"
                    }
                  /> */}
                  <div>{button.label}</div>
                </div>
              </Button>
            </Fragment>
          ))}
        </div>
        {featureHorizontalButtons===true && item.horizontal_buttons && (
          <div
            className={[classes.externalHorizontal, classes.endBorderRadius].join(" ")}
          >
            {item.horizontal_buttons.map((button, index) => (
              <Fragment key={`horizontal_buttons${index}`}>
                <Divider component={"div"} disabled />
                <Button
                  className={[
                    classes.horizontalButton,
                    classes.endBorderRadius,
                    button.parameters.enable === "N" && classes.disabledButton,
                  ].join(" ")}
                  disabled={
                    context.conversationEnd || button.parameters.enable === "N"
                  }
                  disableRipple
                  onClick={() =>
                    handleClick(
                      context.userAgent.deviceType === "mobile"
                        ? button.parameters.mobile_url
                        : button.parameters.url,
                      button.parameters.action
                    )
                  }
                >
                  <div className={classes.horizontalButtonContainer}>
                    <div className={classes.horizontalButtonLabel}>
                      {button.label === context.string.chat.moreProducts && (
                        <img
                          className={classes.buttonIcon}
                          src={IconMore}
                          alt={"IconMore"}
                        />
                      )}
                      <div>{button.label}</div>
                    </div>
                    <div>></div>
                  </div>
                </Button>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  ));
  if (featureMoreItems && data.more_items && data.more_items.enable==='Y'){
    let moreitem_button = (
      <div className={classes.moreItemList}>
        <div className={classes.moreItemCard} onClick={() => handleClick(data.more_items.url,data.more_items.type)}>
          <div className={classes.moreItemThumbnailImage} />
          <div className={classes.moreItemText}>
            {context.string.chat.moreItemHomepage.split("\n").map((content, index) => (
              <Fragment key={`moreItem${index}`}>
                <span>{content}</span>
                <br />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    )
    cardList.push(moreitem_button);
  }
  return cardList;

});

function ProductCard(props) {
  const { classes, data, selectable, selectCardFunc, selectCardArray } = props;
  
  return (
    <>
      <div className={classes.root}>
        <Carousel
          className={classes.carousel}
          component={withStyles(styles)(Card)}
          data={data}
          selectable={selectable}
          selectCardFunc={selectCardFunc}
          selectCardArray={selectCardArray}
        />
      </div>
    </>
  );
}
export default withStyles(styles)(ProductCard);
