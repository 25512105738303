import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  withStyles
} from "@material-ui/core";
import {
  Menu
} from "@material-ui/icons";
import clsx from 'clsx';
import Status from "./Status";
import MenuBar from "./MenuBar";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1,
  },
  tabsContainer: {
    width: "100%",
    height: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "transparent",
    position: "absolute",
    top: "48px"
  },
  children: {
    height: "-webkit-fill-available",
    width: `calc(100% - 51px)`,
    padding: "16px",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  childrenOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerPaper: {
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: "51px",
  },
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
  },
  emptyToolbar: {
    minHeight: "48px"
  }
});

const NavBar = (props) => {
  const { classes, children } = props;
  const [open, setOpen] = useState(false);

  // Drawer를 열고 닫음
  const handleDrawer = () => {
    setOpen(!open);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.title}>
            LG LiveChat
          </Typography>
          <Status/>
        </Toolbar>
      </AppBar>
      <div className={classes.tabsContainer}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}>
          <Toolbar classes={{root: classes.emptyToolbar}} />
          <MenuBar drawerWidth={drawerWidth}/>
        </Drawer>
        <div className={[classes.children, open ? classes.childrenOpen : ""].join(" ")}>{children}</div>
      </div>
    </div>
  );
};
export default withStyles(styles)(NavBar);
