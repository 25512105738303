import React, { useState, useRef } from "react";
import { Typography, Button, withStyles, TextField } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: "600",
  },
  form: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonOK: {
    marginStart: "10px",
  },
});

function ChangePasswordForm(props) {
  const { classes, onLogin, onChangePassword, error } = props;
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordNewConfirm, setPasswordNewConfirm] = useState("");
  const refs = [useRef(null), useRef(null), useRef(null)];

  // 아이디
  const handleChangeID = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };

  // 비멀번호
  const handleChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  // 새로운 비밀번호
  const handleChangeNewPassword = (e) => {
    e.preventDefault();
    setPasswordNew(e.target.value);
  };

  // 새로운 비밀번호 확인
  const handleChangeNewPasswordConfirm = (e) => {
    e.preventDefault();
    setPasswordNewConfirm(e.target.value);
  };

  // 다음 Input 이동
  const handleNext = (e, next) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refs[next].current.focus();
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary" className={classes.title}>
        CHANGE PASSWORD
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete="true"
        onSubmit={(e) => {
          e.preventDefault();
          onChangePassword(id, password, passwordNew, passwordNewConfirm);
        }}
      >
        <div>
          <TextField
            type={"text"}
            value={id}
            onChange={handleChangeID}
            label={"ID"}
            error={error && typeof error === "string" && error.length > 0}
            required
            margin={"dense"}
            fullWidth
            autoFocus
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => handleNext(e, 0)}
          />
          <TextField
            type={"password"}
            value={password}
            onChange={handleChangePassword}
            label={"PASSWORD"}
            error={error && typeof error === "string" && error.length > 0}
            required
            margin={"dense"}
            fullWidth
            inputRef={refs[0]}
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => handleNext(e, 1)}
          />
          <TextField
            type={"password"}
            value={passwordNew}
            onChange={handleChangeNewPassword}
            label={"NEW PASSWORD"}
            error={error && typeof error === "string" && error.length > 0}
            required
            margin={"dense"}
            fullWidth
            inputRef={refs[1]}
            InputLabelProps={{ shrink: true }}
            onKeyPress={(e) => handleNext(e, 2)}
          />
          <TextField
            type={"password"}
            value={passwordNewConfirm}
            onChange={handleChangeNewPasswordConfirm}
            label={"CONFIRM PASSWORD"}
            error={error && typeof error === "string" && error.length > 0}
            helperText={error}
            required
            margin={"dense"}
            fullWidth
            inputRef={refs[2]}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onLogin}
          >
            LOG IN
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonOK}
            type={"submit"}
          >
            OK
          </Button>
        </div>
      </form>
    </div>
  );
}
export default withStyles(styles)(ChangePasswordForm);
