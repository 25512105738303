import { DialogActions } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, withStyles, Button, DialogContentText,
  Table, TableHead, TableRow, TableCell, TableBody, Typography,TableContainer,
  MenuItem, FormControl,RadioGroup,FormControlLabel, Radio, Select, 
  CircularProgress,  TextField,
} from '@material-ui/core';
import{Autocomplete} from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getSymptomCodes, getSymptomCodeSubs, AIMLSearchModel, AIMLSummarizeConversation, getSymptomProducts, mutationSendNoti ,
  getSymptomProductGroups, mutationUpdateTicketRecord, mutationCloseTicket,
} from '../data/Loader';
import { useUser } from "../data/User";

const styles = (theme) => ({
  
  menuItem: {
    fontSize:"1.0em"
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #ececec",
    marginTop:"5px"
  },
  td100:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td120:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td11:{
    height:"32px",
    padding:"0px 10px",
    fontSize:"0.9em",
    width: "220px",
  },
 
  summarySelectFormControl:{
    '& .MuiInputBase-root':{
      "borderRadius": "2px",
      "margin": "2px 5px 2px 0px",
      "height": "30px",
      "minWidth": "150px",
      "fontSize": "1.1em"
    },
    '& .MuiSelect-root':{
      "paddingTop":"0px",
      "paddingBottom":"0px",
    },
  },
  summaryAutocomplete:{
    '& .MuiInputBase-root':{
      "borderRadius": "2px",
      "margin": "2px 5px 2px 0px",
      "height": "30px",
      "minWidth": "250px",
      "fontSize": "1.1em",
      "padding":"0px 55px 0px 5px!important"

    },
    '& .MuiSelect-root':{
      "paddingTop":"0px",
      "paddingBottom":"0px",
    },
    '& .MuiInputBase-input':{
      "padding":"0px 5px !important",
      "fontSize":"0.9em"
    }
  },
  autoCompleteOptions:{
    fontSize:"0.8em"
  },
  summaryMenuItem:{
    fontSize:"1em"
  },
  summaryRadio:{
    marginLeft:"0px",
    marginRight:"5px",
    '& .MuiButtonBase-root':{
      padding:"3px 0px 3px 3px"
    },
    '& .MuiTypography-root':{
      fontSize:"1em",
    }
  },
  summaryTextfield:{
    width:"150px",
    verticalAlign:"middle",
    '& .MuiInputBase-root':{
      height: "30px",
      borderRadius: "3px",
      margin: "2px -2px 2px 0px",
      fontSize:"1.0em"
    },
    '& .MuiInputBase-root:focus':{
      border:"1px solid red"
    },
    '& .MuiInputBase-input':{
      padding: "0px 10px",
    },
  },
  summaryButton:{
    padding: "0px",
    height: "30px",
    background: "#0A8297",
    color: "#ffffff",
    minWidth: "50px",
    marginLeft: "-1px",
    borderRadius: "0px 3px 3px 0px",
    "&:hover":{
      background: "#6ea3ad",
      color: "#fff"
    }
  },
  commentRow:{
    display:"flex"
  },
  aiProgress:{
    color:"#b3dce3",
    width:"50px",
    height:"50px",
  },
  aiBtn:{
    width:"80px",
    color:"#fff",
    background:"#0a8297",
    margin:"5px 0px 0px 5px",
    fontSize:"0.9em",
    "&:hover": {
      background: "#075B69",
      color: "#fff"
    },
  },
  popTitle:{
    fontSize: "1.0em",
    padding: "10px 20px",
    background: "#f5f5f5",
    marginBottom: "15px",
    borderBottom: "1px solid #ececec",
  },
  popDialogContent:{
    padding: "5px 20px",
    display:"flex",
    minHeight:"350px",
    "& p":{
      fontSize:"1.0em",
      fontWeight: 600,
    }
  },
  textCount:{
    textAlign: "right",
    fontSize: "0.75em",
    marginRight:"80px",
  },
  popRow: {
    // width:"33.333%"
    minWidth: "150px",
    marginRight: "1.0rem",
  },
  popProdInfo: {
    display: "flex",
  },
  commentTextarea: {
    borderRadius: "0px",
    marginTop: "5px",
    width: "100%",
    "&:focus": {
      border: "1px solid #b6b6b6",
      outline: "none",
    },
    border: "1px solid #d7d7d7",
    fontFamily: "inherit",
    padding: "5px",
    fontSize: "1.0em",
    minHeight: "5.0rem"
  },
  dialogPaper: {
    borderRadius: "0px",
  },
  dialogWidth: { maxWidth: "100%" },
  popLabel: {
    fontSize: "1.0rem",
    fontWeight: "600",
    color: "#0a8297",
  },
});


const ChatRecordDialog = props => {
  const { classes, ticket, open, handleClose, handleOpen, closeTicket} = props;
  const { me } = useUser();


  const branch = ticket && ticket.branch;
  const categoryId = ticket && ticket.categoryId;
  const convId = ticket && ticket.convId;
  const counselorId = ticket && ticket.counselorId;
  const created = ticket && ticket.created;
  const customerId = ticket && ticket.customerId;
  const description = ticket && ticket.description;
  const statusId = ticket && ticket.statusId;
  const ticketId = ticket && ticket.ticketId;
  const [status, setStatus] = React.useState(statusId);
  
  

  const chatStr = ticket && ticket.chatInfo;
  const deviceStr = ticket && ticket.deviceInfo;
  let chatJson = {};
  let deviceJson = {};
  try{
   chatJson = chatStr ? JSON.parse(chatStr):{};
   deviceJson =deviceStr? JSON.parse(deviceStr):{};
  }catch(e){
     console.log("parse error ", e)
  }

  // summary
  const [productGroup, setProductGroup] = useState('');
  const [productGroupName, setProductGroupName] = useState('');
  const [product, setProduct] = useState('');
  const [productName, setProductName] = useState('');
  const [symptom, setSymptom] = useState(null);
  // const [symptomName, setSymptomName] = useState('');
  const [subSymptom, setSubSymptom] = useState('');
  const [subSymptomName, setSubSymptomName] = useState('');
  const [model, setModel] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  const [modelSearchResults, setModelSearchResults] = useState([]);
  
  // summary > select list
  const [listProductGroup, setListProductGroup] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listSymptom, setListSymptom] = useState([]);
  const [listSubSymptom, setListSubSymptom] = useState([]);

  const [comment, setComment] = React.useState("");
  const [costFree, setCostFree] =  React.useState(true);
  const [counselResult, setCounselResult] =  React.useState();

  const [seachLoading, setSearchLoding] = React.useState(false);
  const [commentLen, setCommentLen] = useState(0);
    


  useEffect(()=>{
    getSymptomProductGroups(['productGroupCode','productGroupName',])
    .then(prodGroupList=>{
      console.log("[prodGroupList]",prodGroupList);
      setListProductGroup(prodGroupList.map(prodGroupInfo=>{
        return {
          name:`${prodGroupInfo.productGroupName}`,
          code:`${prodGroupInfo.productGroupCode}`
        }
      }));

      //////////// temp/////////////
      const tempProdGroup = "AC";
      const tempProd = "PAC";
      const tempSymp = { name:chatJson.symptom_name, code:chatJson.symptom_code, productName: "가정용_에어컨",};
      const tempSubSymp = chatJson.sub_symptom_code;

      console.log(">>>>>>>>", tempProdGroup, tempProd, tempSymp, tempSubSymp, );
      setProductGroup(tempProdGroup);
      setProduct(tempProd);
      setSymptom(tempSymp);

      //product select list
      getSymptomProducts(['productCode','productName',], "", tempProdGroup)
      .then(prodList=>{
        console.log("productList:", prodList);
        setListProduct(prodList.map(prodInfo=>{
          return {
            name: prodInfo.productName,
            code: prodInfo.productCode
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });

      //symptom list
      getSymptomCodes(['productCode','productName','symptomCode','symptomName',],"",tempProdGroup,tempProd)
      .then(symptomList=>{
        console.log("[symptom list]:",symptomList);
        setListSymptom(symptomList.map(sympInfo=>{
          return {
            name: sympInfo.symptomName,
            code: sympInfo.symptomCode,
            productName: sympInfo.productName,
          } 
        }))
      })
      .catch(error => {
        console.error(error)
      });
    
      //sub symptom list
      getSymptomCodeSubs(['productGroupCode','productGroupName','productCode','productName','symptomCode','symptomName','subSymptomCode','subSymptomName'],
      "",tempProdGroup, tempProd, tempSymp.code)
      .then(subSympList=>{
        console.log("[subSympList]", subSympList);
        setListSubSymptom(subSympList.map(subSympInfo=>{
          return {
            name: subSympInfo.subSymptomName,
            code: subSympInfo.subSymptomCode,
            productName:subSympInfo.productName,
            symptomName:subSympInfo.symptomName
          }
        }));
        setSubSymptom(tempSubSymp);

      })
      .catch(error => {
        console.error(error)
      });

      setModel(deviceJson.sales_model)
      //////////////////////////////////////////////////////////////////////////////////////////////////  

      
    })
    .catch(error => {
      console.error(error)
    });
  },[]);

  const handleCommentChange = (e) => {
    e.preventDefault();
    // const text = e.target.value.length > 260 ? e.target.value.substring(0, 260) : e.target.value;
    setComment(e.target.value);
    setCommentLen(e.target.value.length)
  };


  const fnMutationCloseTicket = ticketId => {
    mutationCloseTicket(ticketId)
      .then(result => {
        console.log("[Close Ticket] Result", result);
        closeTicket();
      })
      .catch(error => {
        console.log("[Close Ticket] Error", error);
      })
  }


  const fnMutationSendNoti = (message, notiType, ticketId) => {

    var contentJson = JSON.stringify({
      message: message,
      details:{ticketId:ticketId },
    })
    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });

  }

  function submitHistory() {

    console.log(">>>>>>", comment, ticketId, costFree, counselResult, productGroup, productGroupName, product, productName,  symptom?symptom.code:"", symptom?symptom.name:"", subSymptom, subSymptomName, model);

    mutationUpdateTicketRecord(comment, ticketId, costFree=="1"?true:false, counselResult, product, productGroup, productGroupName, productName, subSymptom, symptom?symptom.code:"", symptom?symptom.name:"", subSymptomName, model, )
      .then((result) => {
        console.log("[update ticket log] Result", result);
        fnMutationCloseTicket(ticketId);
        fnMutationSendNoti("상담이 종료되었습니다.", "info", ticketId );
        handleClose();
        //close dialog
      })
      .catch((error) => {
        console.log("[update ticket log] Error", error);
      });
  }


  
  const [aiLoad, setAiLoad] = useState(false);
  const [aiErrMsg, setAiErrMsg] = useState("");

  const [aiOpen, setAiOpen] = React.useState(false);
  const handleClickAiOpen = () => {
    setAiOpen(true);
  };
  const handleAiClose = () => {
    setAiOpen(false);
  };

  const handleAIComment = () => {
    setAiLoad(true)
    AIMLSummarizeConversation(ticketId)
    .then((result)=>{
      console.log("[AIMLSummarizeConversation] Result", result);
      result && result.data && result.data.AIMLSummarizeConversation&& setComment(result.data.AIMLSummarizeConversation);
    })
    .catch((error) => {
      console.log("[AIMLSummarizeConversation] Error", error.errors);
      setAiErrMsg(" AI 자동 요약을 할 수 없습니다.")
      handleClickAiOpen()
    })
    .finally(()=>{
      setAiLoad(false)
    })
  }


  
  //summary
  const handleProductGroupChange = (event, child) => {
    console.log(`${event.target.value} is CLICKED!`)
    
    const pgroup = event.target.value
    setProductGroup(pgroup)
    setProductGroupName(child.props.name)

    setProduct("");
    setListProduct([]);
    setSymptom(null);
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);

    getSymptomProducts(['productCode','productName',], "", pgroup)
    .then(prodList=>{
      console.log(prodList);
      setListProduct(prodList.map(prodInfo=>{
        return {
          name: prodInfo.productName,
          code: prodInfo.productCode
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };

  const handleProductChange = (event, child) => {
    const p = event.target.value;
    setProduct(p);
    setProductName(child.props.name)

    setSymptom(null);
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomCodes(['productCode','productName','symptomCode','symptomName',],"",productGroup, p)
    .then(symptomList=>{
      console.log(symptomList);
      setListSymptom(symptomList.map(sympInfo=>{
        return {
          // name: `${sympInfo.symptomName}(${sympInfo.productName})`,
          name: sympInfo.symptomName,
          code: sympInfo.symptomCode,
          productName: sympInfo.productName,
        } 
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleSymptomChange = (_, value) => {
    console.log("symptom change:", value);
    setSymptom(value);
    setSubSymptom("");
    setListSubSymptom([]);
    if (value){
      getSymptomCodeSubs(['productGroupCode','productGroupName','productCode','productName','symptomCode','symptomName','subSymptomCode','subSymptomName'],"",productGroup,product,value.code)
      .then(subSympList=>{
        console.log(subSympList);
        setListSubSymptom(subSympList.map(subSympInfo=>{
          return {
            name: subSympInfo.subSymptomName,
            code: subSympInfo.subSymptomCode,
            productName:subSympInfo.productName,
            symptomName:subSympInfo.symptomName
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });
    }
  };
  const handleSubSymptomChange = (event, child) => {
    setSubSymptom(event.target.value);
    console.log(child)
    setSubSymptomName(child.props.name)
  };

  const handleModelSearchChange = (e, newVal) =>{
    setModel(newVal)
  }
  const handleModelSearchInputChange = (e, newInputValue) =>{
    setSearchLoding(true)
    setModelSearch(newInputValue);

    if(newInputValue && newInputValue.length>2){
      AIMLSearchModel(newInputValue.toUpperCase())
      .then((result) => {
        console.log(">>>searchresult = ", result);
        setModelSearchResults(result.data.AIMLSearchModel)
      })
      .catch((e) =>{
        console.log("[get transfer info] Query Error", e)
      })
      .finally(() => {
        setSearchLoding(false);
      });
    }else{
      setSearchLoding(false);
    }
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialogPaper,
          paperWidthSm: classes.dialogWidth,
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.popTitle} disableTypography={true}>상담 요약</DialogTitle>
        <DialogContent classes={{root:classes.popDialogContent}}>
          <div>
            <Typography>상담 내용</Typography>
            <div className={classes.commentRow}>
              <textarea
                className={classes.commentTextarea}
                onChange={handleCommentChange}
                value={comment}
                placeholder="상담요약을 입력하세요."
              ></textarea>
              <Button className={classes.aiBtn} onClick={handleAIComment}>
                {aiLoad ?<CircularProgress className={classes.aiProgress}/>   : <span>AI 자동<br/>요약</span>}
              </Button>
            </div>
            <div className={classes.textCount}>{commentLen}/1000</div>
            <Typography>제품 정보</Typography>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell classes={{root:classes.td100}}>제품그룹</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                    <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                      <Select
                        id="demo-simple-select"
                        value={productGroup}
                        onChange={handleProductGroupChange}
                      >
                    {listProductGroup.map(productGroupInfo=>{
                      return <MenuItem key={productGroupInfo.code} value={productGroupInfo.code} className={classes.summaryMenuItem} name={productGroupInfo.name}>
                              {productGroupInfo.name}({productGroupInfo.code})
                              </MenuItem>
                    })}
                      </Select>
                    </FormControl>
                    </TableCell>
                    <TableCell classes={{root:classes.td100}}>제품명</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                          <Select
                            value={product}
                            onChange={handleProductChange}
                          >
                          {listProduct && listProduct.length 
                            ?
                            listProduct.map(productInfo=>{
                              return <MenuItem key={productInfo.code} value={productInfo.code} className={classes.summaryMenuItem} name={productInfo.name}>
                                {productInfo.name}({productInfo.code})
                                </MenuItem>
                              })
                            : <MenuItem value="" className={classes.summaryMenuItem}><em>제품 그룹을 선택해 주세요.</em></MenuItem>
                          }
                          </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td100}}>모델명</TableCell>
                    <TableCell classes={{root:classes.td11}} colSpan={3}>
                    <Autocomplete
                        className={classes.summaryAutocomplete}
                        classes={{popper:classes.autocompletePopper}}
                        options={modelSearchResults}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        loading={seachLoading}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span className={classes.autoCompleteOptions}>{option}</span>
                          </React.Fragment>
                        )}
                        value={model}
                        inputValue = {modelSearch}
                        onInputChange = {handleModelSearchInputChange}
                        onChange={handleModelSearchChange}
                      />
                      {/* <TextField variant="outlined" className={classes.summaryTextfield}/>  
                      <Button color="primary" autoFocus type="button" className={classes.summaryButton}>검색</Button> */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  </TableRow>
                  <TableRow>
                    {/* <TableCell classes={{root:classes.td100}}>접수증상</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                          <Select
                          value={symptom}
                          onChange={handleSymptomChange}
                          >
                          { listSymptom && listSymptom.length
                            ?
                              listSymptom.map(symptomInfo=>{
                                return <MenuItem key={symptomInfo.code} value={symptomInfo.code} className={classes.summaryMenuItem}>{symptomInfo.name}</MenuItem>
                              })
                            : <MenuItem value="" className={classes.summaryMenuItem}><em>제품을 선택해 주세요.</em></MenuItem>
                          }
                          </Select>
                      </FormControl>
                    </TableCell> */}
                    <TableCell classes={{root:classes.td100}}>증상검색</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <Autocomplete
                        className={classes.summaryAutocomplete}
                        classes={{popper:classes.autocompletePopper}}
                        options={listSymptom}
                        getOptionLabel={(option) => `${option.name}(${option.productName})`}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span className={classes.autoCompleteOptions}>{option.name}({option.productName})</span>
                          </React.Fragment>
                        )}
                        value={symptom}
                        onChange={handleSymptomChange}
                      />
                      {/* <TextField variant="outlined" className={classes.summaryTextfield}/>  
                      <Button color="primary" autoFocus type="button" className={classes.summaryButton}>검색</Button> */}
                    </TableCell>
                    <TableCell classes={{root:classes.td100}}>세부증상</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                          <Select
                          value={subSymptom}
                          onChange={handleSubSymptomChange}
                          >
                          { listSubSymptom && listSubSymptom.length?
                            listSubSymptom.map(subSymp=>{
                              return <MenuItem key={subSymp.code} value={subSymp.code} className={classes.menuItem} name={subSymp.name}>{subSymp.name}({subSymp.productName},{subSymp.symptomName})</MenuItem>
                            })
                            :<MenuItem value="" className={classes.menuItem}><em>고장증상을 선택해 주세요.</em></MenuItem>
                          }

                          </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell classes={{root:classes.td100}}>상담유형</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                        <Select>
                          <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                        <Select>
                          <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.summarySelectFormControl}>
                        <Select>
                          <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell classes={{root:classes.td100}}>유/무상</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <FormControl component="fieldset">
                        <RadioGroup row aria-label="costFree" name="costFree" onChange={ e => { setCostFree(e.target.value)}} value={costFree}>
                          <FormControlLabel value="0" control={<Radio color="primary" />} label="유상" className={classes.summaryRadio}/>
                          <FormControlLabel value="1" control={<Radio color="primary" />} label="무상" className={classes.summaryRadio}/>
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell classes={{root:classes.td100}}>처리결과</TableCell>
                    <TableCell classes={{root:classes.td11}}>
                      <FormControl component="fieldset">
                        <RadioGroup row aria-label="counselResult" name="counselResult" onChange={ e =>{ setCounselResult(e.target.value)}} value={counselResult}>
                          <FormControlLabel value="incomplete" control={<Radio color="primary" />} label="미완료" className={classes.summaryRadio}/>
                          <FormControlLabel value="complete" control={<Radio color="primary" />} label="완료" className={classes.summaryRadio}/>
                          <FormControlLabel value="etc" control={<Radio color="primary" />} label="기타" className={classes.summaryRadio}/>
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </div>


          <Dialog
            open={aiOpen}
            onClose={handleAiClose}
          >
            <DialogContent>
              <DialogContentText>
                {aiErrMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAiClose} color="primary" autoFocus>
                확인
              </Button>
            </DialogActions>
          </Dialog>


        </DialogContent>
        <DialogActions>
          <Button onClick={submitHistory} color="primary" autoFocus type="submit">
            입력
          </Button>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default withStyles(styles)(ChatRecordDialog);