import { Dialog, DialogTitle, DialogContent, InputBase, withStyles, Button, 
  Table, TableHead, TableRow, TableCell, TableBody, Typography,
IconButton, Divider, Paper, AppBar, Tabs, Tab,
Container,Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { ArrowDropDown, Search, ArrowRight, Message, Group, Person, Home, Folder, Assignment, Remove, People} from '@material-ui/icons';
import * as MuiIcons from '@material-ui/icons';
import { TreeItem, TreeView, Alert} from '@material-ui/lab';
import EnhancedTable from './EnhancedTable';
import { useUser } from "../data/User";
import {getAssignableCounselor, getCategoryMap, mutationSendNoti, mutationTicketTransferRequest,
} from "../data/Loader";

const styles = (theme) => ({
  root: {
    width: "800px",
    maxWidth:"800px",
    borderRadius:"0px"
  },
  search:{
    padding: '2px 4px',
    display: 'flex',
    boxShadow: "none",
    width: "100%",
    height: "35px",
    border:"1px solid #ececec",
    borderRadius:"0px",
    background:"#f5f5f5"
  },
  search2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#ededed",
    fontSize:"1.0em",
    width:"100%",
    borderRadius: "2px",
    height:"30px",
    fontSize:"0.9em",
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    border:"1px solid grey",
    width: '100%',
  },
  folder: {
    width: "100%",
    padding: "3px"
  },

  box:{
    bgcolor: '#ffffff', height: '60vh' 
  },
  dialogTitle:{
    fontSize: "1.0em",
    padding: "10px 20px",
    background: "#f5f5f5",
    marginBottom: "15px",
    borderBottom: "1px solid #ececec",
  },
  dialogContent:{
    padding: "0px",
    display:"flex",
    height:"500px",
  },
  userTree:{
    width: "100%",
    padding: "0px",
    height: "100%",
    // marginBottom:"10px",
    borderRight: "1px solid #ececec",
    boxSizing:"border-box"
  },
  userPanel:{
    width: "100%",
    padding: "10px",
    height: "100%",
  },

  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: "1.0em"
  },
  iconButton: {
    // padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchCol:{

  },
  searchTablePaper:{
    width: "100%",
    height: "200px",
    borderRadius:"0px",
    boxShadow: "none",
    overflowY:"scroll",
    border: "1px solid #ececec"
  },
  searchRow:{
  },
  searchTh:{
    padding: "2px",
    fontSize: "0.85em",
    textAlign: "center",
    background: "#f5f5f5",
    fontWeight: "600",
  },
  searchCol:{
    padding: "2px",
    fontSize: "0.85em",
    textAlign: "center",
  },
  noData:{
    textAlign: "center",
    height:"150px",
    border:"none",
    color:"#7f7f7f"
  },
  confirmBtn:{
    margin: "5px",
    padding: "2px 0px",
  },
  cancelBtn:{
    margin: "5px",
    padding: "2px 0px",
  },
  textarea:{
    width: "100%",
    height: "100px",
    marginRight: "10px",
    border: "1px #e8e8e8 solid",
    borderRadius: "2px",
    "&:focus" :{
      border: "2px #e8e8e8 solid",
      outline: "none"
    },
    fontFamily: "inherit"
  },
  msgPaper:{
    width: "100%",
    boxShadow: "none",
    height: "200px",
  },
  msgIcon:{
    fontSize: "1.3em",
    marginRight: "3px",
  },
  msgTitle:{
    padding: "5px",
    fontSize: "0.88em",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  },
  btns:{
    display:"flex",
    justifyContent:"center",
    padding:"10px",
    marginTop: "5px",
  },


  treeItemRoot: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      // backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      // color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      // backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  icon:{
    fontSize: "1em",
    marginRight: "3px",
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  tabPanelRoot:{
    padding:"0px",
    '& .MuiBox-root':{
      padding: '3px',
    }
  },
  treeViewRoot:{
    // border:"1px solid green",
    margin: "0px",
  },
  tabs:{
    border: "none",
    minHeight:'20px',
    // width: "100%",
    fontSize: "1.0em",
    padding: "0px",
    background: "#ffffff",
    color: "#000",
    fontWeight: "400",
    boxShadow: "none",
  },
  tab:{
    // border: "1px solid #ececec",
    // background: "#ececec",
    minHeight: '20px',
    minWidth: "60px",
    boxShadow: "none",
  },
  appBar:{
    boxShadow:'none',
    backgroundColor:'transparent',
  },
  tabSelected:{
    color:"#fff",
    background: theme.palette.primary.main, //"#bf134a",
    fontWeight: 500,
    boxShadow:'none',
  },
   indicator:{
    background:"none",
  },
  alert:{
    height: "2.0rem",
  },
  alertMessage:{
    padding:"0px",
    fontSize:"0.9em"
  },
  alertIcon:{
    padding:"0px",
    fontSize:"1.3em"
  }


});
const StyledTreeItem = withStyles(styles)((props) => {
  const { classes, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        // root: classes.treeItemRoot,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
});


const ConvertDialog = props => {
  const { classes, onClose, ticket, index, updateStatus} = props;
  const { me } = useUser();
  const [value, setValue] = useState(0);
  const [categoryData, setCategoryData] = useState({});
  const [assignable, setAssignable] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [targetId, setTargetId] = useState("");
  const [message, setMessage] = useState("");
  const [categoryExpanded, setCategoryExpanded] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  var categories = [];
  function getAllCategories(nodes){
      Object.keys(nodes).forEach((key)=>{
        categories.push(nodes[key].categoryInfo.categoryId)
        if(nodes[key].path && Object.keys(nodes[key].path).length!=0)
          getAllCategories(nodes[key].path) 
      });
    return categories;
  }

  function makeCounselorData(cns){ // -> {categoryId: [ ]}
    var json = {}
    cns.forEach((c)=>{
      c.category.forEach(cat=>{
        if(cat.categoryId in json ) 
          json[cat.categoryId].push(c)
        else 
          json[cat.categoryId] = [c]
      })
    })
    return json;
  }
  
  var newJson = {};
  function makeData(nodes, cns){ //nodes:categorymap(node), cns:counselors(leaf)

    Object.keys(nodes).forEach((key)=>{
      var node = nodes[key];
      var categoryId = node.categoryInfo.categoryId;
        if (node.path && Object.keys(node.path).length==0){
          if (categoryId in cns){
            var temp = {};
            cns[categoryId].forEach((item)=>{temp[item.counselorId] = {"path":{}, "categoryInfo":{"categoryId":item.counselorId, "leaf":true, "info":item, "parentId":categoryId, "name":item.counselorId}}})
            node.path = temp
          }
        }else{
          if (node.path.categoryInfo)
            node.path.categoryInfo["parentId"] = categoryId;
          makeData(node.path, cns);
        }
    });

    return nodes;
  }

  const getCategory = () => {
    getCategoryMap(me.currentBranch)
    .then((result) => {
      console.log("[get category] Query Result", result.data);
      var data = JSON.parse(result.data.getCategoryMap )
      const all_cat = getAllCategories(data)
      getAssignableCounselor(me.currentBranch,  all_cat)
      .then((result) => {
        console.log("[getAssignableCounselor] Query Result", result.data);
        var assignable_arr = result.data.getAssignableCounselor;
        var assignable_json = makeCounselorData(assignable_arr);
        var newData = makeData(data, assignable_json);
        setCategoryData(newData);
        setAssignable(assignable_arr);
      })
      .catch((e) => {
        console.log("[getAssignableCounselor] Query Error", e);
      })
    })
    .catch((e) => {
      console.log("[get category] Query Error", e);
    })
  }

  useEffect(() => {
    getCategory();
  }, [me]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

 
  const MaterialIcon = ({ icon, props }) => {
  
    // somehow resolve this icon
    // let resolvedIcon = // ...
    return React.createElement(icon, props)
    };
  

      
  const findNodeById = (nodes, id) => {
    if (nodes=={}) return ;

    let result
    Object.keys(nodes).forEach((key) => {
      if (id === nodes[key].categoryInfo.categoryId)
        result =  nodes[key]
      else
        result = result || findNodeById(nodes[key].path, id)
    });
    
    return result
    
  }

  const handleData = (node)=>{
    let leaf = node.categoryInfo.leaf;
    if( leaf ){
      setTableData([node && node.categoryInfo &&node.categoryInfo.info])
      
      let expanded = []
      let nodeId = node.categoryInfo && node.categoryInfo.parentId;
      let pNode = findNodeById(categoryData, nodeId);

      while (pNode != undefined ){
        expanded.push(pNode && pNode.categoryInfo && pNode.categoryInfo.categoryId)
        pNode = findNodeById(categoryData, pNode.categoryInfo.parentId)
      }

      setCategoryExpanded(expanded)
    }
  }
  const MyTreeItem = withStyles(styles)((props) => {
    const {classes, nodeKey, node,  handleClick, handleData} = props;
    function treeItemClick(e){
      // e.preventDefault();
      handleData(node)
    }

    return (
    <TreeItem key={nodeKey} 
      nodeId={node.categoryInfo.categoryId} 
      handleData={handleData}
      onLabelClick={treeItemClick}
      label={
        <div className={classes.labelRoot}>
          {node.categoryInfo.leaf
            ? <MaterialIcon icon={Person} classes={{root:classes.icon}}/>
            : <MaterialIcon icon={Folder} classes={{root:classes.icon}}/>
          }
          <Typography variant="body2" className={classes.labelText}>
            {node.categoryInfo.name}
          </Typography>
        </div>
      }
      classes={{
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        label: classes.label,
      }}
    >
      {renderTree(node.path)}
    </TreeItem>
    )
  });


  const renderTree = (nodes) =>{  
    // console.log("[node]", nodes)
    if ( !nodes || Object.keys(nodes).length===0)
      return null;
    
    return(<>
      {Object.keys(nodes).map((key) => (
        <MyTreeItem 
          key={key} 
          nodeKey = {key}
          node = {nodes[key]}
          // nodeId={nodes[key].categoryInfo.id} 
          handleData={handleData} 
          // label={
          //   <div className={classes.labelRoot}>
          //     {nodes[key].categoryInfo.leaf
          //       ? <MaterialIcon icon={Person} classes={{root:classes.icon}}/>
          //       : <MaterialIcon icon={Folder} classes={{root:classes.icon}}/>
          //     }
          //     <Typography variant="body2" className={classes.labelText}>
          //       {nodes[key].categoryInfo.name}
          //     </Typography>
          //   </div>
          // }
        >
          {/* {renderTree(nodes[key].path)} */}
        </MyTreeItem>
      ))}
      </>
  );
    };
  
  const handleClickRow = (target) => {
    if (target==="init")
     setAlertOpen(false)
    else{
      setAlertOpen(target==undefined)
      setTargetId(target)
    }
  }

  const handleTextareaChange = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  }

  const fnMutationSendNoti = (message, notiType, ticketId) => {

    var contentJson = JSON.stringify({
      message: message,
      details:{ticketId:ticketId },
    })

    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);


      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });

  }


  const handleSubmit = () => {
    //  targetId
    //  message
    if(!targetId){
      setAlertOpen(true);
      return;
    }

    mutationTicketTransferRequest(targetId, ticket.ticketId, message, ticket.branch, ticket.branch, targetId)
      .then((result) => {
        console.log("[RequestTicketTransfer] Result", result);
        fnMutationSendNoti("티켓 전달을 완료 하였습니다.", "info", ticket.ticketId );
        updateStatus(index, "from");
        onClose()
      })
      .catch((error) => {
        fnMutationSendNoti("티켓 전달에 실패하였습니다.", "info", ticket.ticketId );
        console.log("[RequestTicketTransfer] Error", error);
      });
  }

  const inputSearch = (e) => {
    const search = e.target.value
    if (search==""){
      setTableData([])
      return;
    } 

    const result = assignable.filter((a)=>{
      let find;
      Object.keys(a).forEach((key)=>{
        if (["name", "phone", "mobilephone", "email"].includes(key)){
          if ( a[key] && (a[key].includes(search))){
              find = true;
            }
        }
      });
        return find;
    })    
    setTableData(result)
  }

  return (
    <Dialog {...props} classes={{paper: classes.root}}>
      <DialogTitle classes={{root:classes.dialogTitle}} disableTypography={true}>
        상담사 전환
      </DialogTitle>
      <DialogContent classes={{root:classes.dialogContent}}>
        <Grid container className={classes.userTree} xs={12} sm={4} md={4} >
        <div className={classes.folder}>
          <AppBar position="static" classes={{root:classes.appBar}}>
            <Tabs value={value} onChange={handleChange} aria-label="" classes={{root: classes.tabs,indicator: classes.indicator,}}>
              <Tab label="사용자 그룹" {...a11yProps(0)} 
              className={classes.tab}
              classes={{selected:classes.tabSelected }} 
              />
              <Tab label="사용자 권한" {...a11yProps(1)} 
              className={classes.tab}
              classes={{selected:classes.tabSelected }} 
              />
            </Tabs>
          </AppBar>
          <div role="tabpanel" value={value} index={0} hidden={value !== 0}>
              <TreeView
                className={classes.treeViewRoot}
                defaultExpanded={categoryExpanded} 
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                {renderTree(categoryData)}
              </TreeView>
          </div>
          <div role="tabpanel" value={value} index={1} hidden={value !== 1}>
              <TreeView
                className={classes.treeViewRoot}
                defaultExpanded={categoryExpanded} 
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                {renderTree(categoryData)}
              </TreeView>
          </div>
        </div>
        </Grid>
        <Grid container className={classes.userPanel} xs={12} sm={8} md={8} >
          <Paper className={classes.search} elevation={1}>
            <InputBase className={classes.input} placeholder="Search" onChange={inputSearch}/>
            <IconButton className={classes.iconButton} aria-label="Search">
              <Search/>
            </IconButton>
          </Paper>
          <Paper className={classes.searchTablePaper}>
            <EnhancedTable data={tableData} handleClickRow={handleClickRow}></EnhancedTable>
          </Paper>
          <Paper className={classes.msgPaper}>
            <div className={classes.msgTitle}><Message classes={{root:classes.msgIcon}}></Message>전달 메시지</div>
            <textarea className={classes.textarea} onChange={handleTextareaChange}></textarea>
            {alertOpen && <Alert severity="error" classes={{root:classes.alert,message:classes.alertMessage, icon:classes.alertIcon}}>상담사를 선택 해 주세요</Alert>}
            <div className={classes.btns}>
            <Button variant="contained" size="small" color="primary" classes={{ root: classes.confirmBtn}}
             onClick={handleSubmit}>
              확인
            </Button>
            <Button variant="outlined" size="small" color="primary" className={classes.cancelBtn}
              onClick={onClose}>
              취소
            </Button>
            </div>
          </Paper>
            {/* <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            <div className={classes.searchIcon}>
              <SearchOutlined />
            </div> */}
          {/* </div>  */}
        </Grid>
      {/*<Container fixed>
        <Box className = {classes.box}>
           <div className={classes.search}>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            <div className={classes.searchIcon}>
              <SearchOutlined />
            </div>
          </div> 
        </Box>
      </Container>*/}
        
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(ConvertDialog);