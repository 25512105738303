import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { MUTATION, QUERY, SUBSCRIBE } from "./Scheme";

const graphQLQuery = (graphqlOp, queryType) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOp)
      .then((result) => {
        let retvalue = null;
        if (result.data && result.data[queryType]) {
          retvalue = result.data[queryType];
        }
        resolve(retvalue);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTicketsNotAssigned = (branch, categoryId) => {
  return API.graphql(
    graphqlOperation(QUERY.getTicketsNotAssigned, {
      branch: branch,
      categoryId: categoryId,
    })
  );
};

const getStatusList = (branch) => {
  return API.graphql(graphqlOperation(QUERY.getStatusList, { branch: branch }));
};

const getMe = () => {
  const operation = `query Query {
    ${QUERY.whoami}
  }`;
  return API.graphql(graphqlOperation(operation));
};

const getBranchPhrase = (branch, phraseType) => {
  const operation = `query Query($branch: String!, $phraseType: String!) {
    ${QUERY.getBranchPhrase}
  }`;
  return API.graphql(graphqlOperation(operation, { branch, phraseType }));
};

const getBranchPhraseAll = (branch) => {
  const operation = `query Query($branch: String!) {
    ${QUERY.getBranchPhraseAll}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const getBranchMacro = (branch) => {
  const operation = `query Query($branch: String!) {
    ${QUERY.getBranchMacro}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const getCounselorStatus = (counselorId) => {
  const operation = `query Query($counselorId: String!) {
    ${QUERY.getCounselorStatus}
  }`;
  return API.graphql(graphqlOperation(operation, { counselorId: counselorId }));
};

const getTicket = (ticketId) => {
  const operation = `query Query($ticketId: String!) {
    ${QUERY.getTicket}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const getTicketRecord = (ticketId) => {
  const operation = `query Query($ticketId: String!) {
    ${QUERY.getTicketRecord}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const getTicketsMine = (
  branch,
  statusId,
  nextCursor,
  orderAsc,
  resultLimit
) => {
  const operation = `query Query($branch:String, $statusId:[String], $nextCursor: String, $orderAsc: Boolean, $resultLimit: Int) {
    ${QUERY.getTicketsMine}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      statusId: statusId,
      nextCursor: nextCursor,
      orderAsc: orderAsc,
      resultLimit: resultLimit,
    })
  );
};

const getNotis = (recipientId) => {
  const operation = `query Query($recipientId: String!) {
    ${QUERY.getNotis}
  }`;
  return API.graphql(graphqlOperation(operation, { recipientId: recipientId }));
};

const getNotisByBranch = (branch, notiType) => {
  const operation = `query Query($branch: String!, $notiType: String) {
    ${QUERY.getNotisByBranch}
  }`;
  return API.graphql(graphqlOperation(operation, { branch:branch, notiType:notiType }));
};

const getCounselorMacro = (counselorId) => {
  const operation = `query Query($counselorId: String!) {
    ${QUERY.getCounselorMacro}
  }`;
  return API.graphql(graphqlOperation(operation, { counselorId: counselorId }));
};

const getCategoryMap = (branch) => {
  const operation = `query Query($branch: String!) {
    ${QUERY.getCategoryMap}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const getCategoryList = (branch) =>{
  const operation = `query Query($branch: String!) {
    ${QUERY.getCategoryList}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
}

const getAssignableCounselor = (branch, categoryId) => {
  const operation = `query Query($branch: String!, $categoryId:[String]) {
    ${QUERY.getAssignableCounselor}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, categoryId: categoryId })
  );
};

const getCounselors = (branch) => {
  const operation = `query Query($branch: String!){
    ${QUERY.getCounselors}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const getCounselor = (counselorId, branch) => {
  const operation = `query Query($counselorId: String!, $branch: String){
    ${QUERY.getCounselor}
  }`;
  return API.graphql(graphqlOperation(operation, { counselorId:counselorId, branch: branch }));
};

const getRoleInfo = (branch) => {
  const operation = `query Query($branch: String!){
    ${QUERY.getRoleInfo}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const getCustomer = (customerId) => {
  const operation = `query Query($customerId: String!) {
    ${QUERY.getCustomer}
  }`;
  return API.graphql(graphqlOperation(operation, { customerId: customerId }));
};

const getTransferInfo = (
  transferStatus,
  counselorIdFrom,
  counselorIdTo,
  requestId,
  ticketId,
  branchFrom,
  branchTo
) => {
  const operation = `query Query($transferStatus: [String], $counselorIdFrom: String, $counselorIdTo: String, $requestId: String,  $ticketId: String, $branchFrom:String, $branchTo:String) {
    ${QUERY.getTransferInfo}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      transferStatus: transferStatus,
      counselorIdFrom: counselorIdFrom,
      counselorIdTo: counselorIdTo,
      requestId: requestId,
      ticketId: ticketId,
      branchFrom: branchFrom,
      branchTo: branchTo,
    })
  );
};

const getSymptomCodeSubs = (
  returnValueArray,
  countryCode,
  productGroupCode,
  productCode,
  symptomCode
) => {
  const queryType = "getSymptomCodeSubs";
  const operation = `
    query Query($countryCode: String, $productCode: String, $productGroupCode: String, $symptomCode: String) {
      ${queryType} (countryCode: $countryCode, productCode: $productCode, productGroupCode: $productGroupCode, symptomCode: $symptomCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    countryCode,
    productCode,
    productGroupCode,
    symptomCode,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const getSymptomCodes = (
  returnValueArray,
  countryCode,
  productGroupCode,
  productCode
) => {
  const queryType = "getSymptomCodes";
  const operation = `
    query Query($countryCode: String, $productCode: String, $productGroupCode: String) {
      ${queryType} (countryCode: $countryCode, productCode: $productCode, productGroupCode: $productGroupCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    countryCode,
    productCode,
    productGroupCode,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const getSymptomProducts = (
  returnValueArray,
  countryCode,
  productGroupCode
) => {
  const queryType = "getSymptomProducts";
  const operation = `
    query Query($countryCode: String, $productGroupCode: String) {
      ${queryType} (countryCode: $countryCode, productGroupCode: $productGroupCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    countryCode,
    productGroupCode,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const getSymptomProductGroups = (returnValueArray, countryCode) => {
  const queryType = "getSymptomProductGroups";
  const operation = `
    query Query($countryCode: String) {
      ${queryType} (countryCode: $countryCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, { countryCode });
  return graphQLQuery(graphqlOp, queryType);
};

const getSymptomInfo = (
  returnValueArray,
  countryCode,
  productGroupCode,
  productCode,
  symptomCode,
  subSymptomCode
) => {
  console.log(
    `[getSymptomInfo] countryCode: '${countryCode}', productGroupCode: '${productGroupCode}', productCode: '${productCode}', symptomCode: '${symptomCode}', subSymptomCode: '${subSymptomCode}'`
  );
  const queryType = "getSymptomInfo";
  const operation = `
    query Query($countryCode: String, $productCode: String, $productGroupCode: String, $symptomCode: String, $subSymptomCode: String) {
      ${queryType} (countryCode: $countryCode, productCode: $productCode, productGroupCode: $productGroupCode, symptomCode: $symptomCode, subSymptomCode: $subSymptomCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    countryCode,
    productCode,
    productGroupCode,
    symptomCode,
    subSymptomCode,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const getSymptomSummaries = (
  returnValueArray,
  countryCode,
  productCode,
  symptomCode,
  subSymptomCode
) => {
  console.log(
    `[getSymptomSummaries] countryCode: '${countryCode}', productCode: '${productCode}', symptomCode: '${symptomCode}', subSymptomCode: '${subSymptomCode}'`
  );
  const queryType = "getSymptomSummaries";
  const operation = `
    query Query($countryCode: String, $productCode: String!, $symptomCode: String!, $subSymptomCode: String!) {
      ${queryType} (countryCode: $countryCode, productCode: $productCode, symptomCode: $symptomCode, subSymptomCode: $subSymptomCode) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    countryCode,
    productCode,
    symptomCode,
    subSymptomCode,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const getProductInfo = (returnValueArray) => {
  const queryType = "getProductInfo";
  const operation = `
    query Query {
      ${queryType}  {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation);
  return graphQLQuery(graphqlOp, queryType);
};

const searchProduct = (
  returnValueArray,
  productCode,
  searchType,
  limit,
  searchCondition,
  searchText
) => {
  const queryType = "searchProduct";
  const operation = `
    query Query ($productCode: String, $searchType: String, $limit: Int, $searchCondition: [String], $searchText: String){
      ${queryType} (productCode: $productCode, searchType: $searchType, limit: $limit, searchCondition: $searchCondition, searchText: $searchText) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, {
    productCode,
    searchType,
    limit,
    searchCondition,
    searchText,
  });
  return graphQLQuery(graphqlOp, queryType);
};

const RBACIsAccessbile = (returnValueArray, branch, viewId, counselorId) => {
  const queryType = "RBACIsAccessible";
  const operation = `
    query Query ($branch:[String!]!, $viewId:[String!]!, $counselorId:String){
      ${queryType} (branch: $branch, viewId: $viewId, counselorId: $counselorId) {
        ${returnValueArray.join(" ")}
      }
    }
  `;
  let graphqlOp = graphqlOperation(operation, { branch, viewId, counselorId });
  return graphQLQuery(graphqlOp, queryType);
};

const AIMLSearchModel = (searchModel) => {
  const operation = ` query Query ($searchModel:String!) {
      ${QUERY.getSearchModel}
    }`;
  return API.graphql(graphqlOperation(operation, { searchModel: searchModel }));
};

const AIMLSummarizeConversation = (ticketId) => {
  const operation = ` query Query ($ticketId:String!){
    ${QUERY.getAIMLSummarizeConversation}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const AIMLSearchSymptomSummaries = (searchTxt) => {
  const operation = ` query Query ($searchTxt:String!){
    ${QUERY.getAIMLSearchSymptomSummaries}
  }`;
  return API.graphql(graphqlOperation(operation, { searchTxt: searchTxt }));
}

const searchHolidayInfo = (fromDate, toDate, updatedBy) => {
  const operation = ` query Query ($fromDate:Int!, $toDate:Int!, $updatedBy:String!){
    ${QUERY.searchHolidayInfo}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      fromDate: fromDate,
      toDate: toDate,
      updatedBy: updatedBy,
    })
  );
};

const getBranchSetting = (branch, settingType) => {
  const operation = ` query Query ($branch:String!, $settingType:String){
    ${QUERY.getBranchSetting}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, settingType: settingType })
  );
};

/***
 * MUTATION
 */
const mutationAssignTicketToCounselor = (counselorId, ticketId) => {
  const operation = `mutation Mutation($counselorId: String!, $ticketId: String!) {
    ${MUTATION.assignTicketToCounselor}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      counselorId: counselorId,
      ticketId: ticketId,
    })
  );
};

const mutationSendMessage = (contentJson, convId, senderId) => {
  const operation = `mutation Mutation($contentJson: String!, $convId: String!, $senderId: String!) {
    ${MUTATION.sendMessage}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      contentJson: contentJson,
      convId: convId,
      senderId: senderId,
    })
  );
};

const mutationStartConversation = (ticketId) => {
  const operation = `mutation Mutation($ticketId: String!) {
    ${MUTATION.startConversation}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const mutationEndConversation = (convId) => {
  const operation = `mutation Mutation($convId: String!) {
    ${MUTATION.endConversation}
  }`;
  return API.graphql(graphqlOperation(operation, { convId: convId }));
};

const mutationCloseTicket = (ticketId) => {
  const operation = `mutation Mutation($ticketId: String!) {
    ${MUTATION.closeTicket}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const mutationUpdateCounselorStatus = (counselorId, status) => {
  const operation = `mutation Mutation($counselorId: String!, $status: String!) {
    ${MUTATION.updateCounselorStatus}
  }`;
  return API.graphql(
    graphqlOperation(operation, { counselorId: counselorId, status: status })
  );
};

const mutationUpdateCounselor = (
  counselorId,
  currentBranch,
  department,
  maxCounselNum,
  mobilephone,
  phone
) => {
  let operation = `mutation Mutation(`;

  counselorId && (operation = operation + `$counselorId: String!, `);
  currentBranch && (operation = operation + `$currentBranch: String, `);
  department && (operation = operation + `$department: String, `);
  maxCounselNum && (operation = operation + `$maxCounselNum:Int, `);
  mobilephone && (operation = operation + `$mobilephone: String, `);
  phone && (operation = operation + `$phone: String`);

  operation = operation + `) {updateCounselor(`;

  counselorId && (operation = operation + `counselorId: $counselorId, `);
  currentBranch && (operation = operation + `currentBranch: $currentBranch, `);
  department && (operation = operation + `department: $department, `);
  maxCounselNum && (operation = operation + `maxCounselNum: $maxCounselNum, `);
  mobilephone && (operation = operation + `mobilephone: $mobilephone, `);
  phone && (operation = operation + `phone: $phone`);
  operation =
    operation +
    `) {
      availCounselNum
      branch
      category {
        branch
        categoryId
      }
      cognitoId
      counselorId
      created
      currentBranch
      email
      maxCounselNum
      mobilephone
      name
      phone
      role {
        branch
        roleName
      }
      currentCounselNum
      department
    }
  }`;
  console.log("[operation]:", operation);
  return API.graphql(
    graphqlOperation(operation, {
      counselorId: counselorId,
      currentBranch: currentBranch,
      department: department,
      maxCounselNum: maxCounselNum,
      mobilephone: mobilephone,
      phone: phone,
    })
  );
};

const mutationCounselorBranchAdd = (branch, counselorId) => {
  const operation = `mutation Mutation($branch:String!, $counselorId: String!) {
    ${MUTATION.counselorBranchAdd}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch:branch, counselorId: counselorId})
  );
};
const mutationCounselorCategoryAdd = (branch, counselorId, targetCategoryId) => {
  const operation = `mutation Mutation($branch:String!, $counselorId: String!, $targetCategoryId: String!) {
    ${MUTATION.counselorCategoryAdd}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch:branch, counselorId: counselorId, targetCategoryId:targetCategoryId})
  );
};

const mutationCounselorCategoryDelete = (branch, counselorId, targetCategoryId) => {
  const operation = `mutation Mutation($branch:String!, $counselorId: String!, $targetCategoryId: String!) {
    ${MUTATION.counselorCategoryDelete}
  }`;
  console.log(branch, counselorId, targetCategoryId)
  console.log(operation)
  return API.graphql(
    graphqlOperation(operation, { branch:branch, counselorId: counselorId, targetCategoryId:targetCategoryId})
  );
};

const mutationSendNoti = (contentJson, notiType, recipientId, branch, TTLinSec) => {
  const operation = `mutation Mutation($contentJson: String!, $notiType: String!, $recipientId: String!, $branch:String!, $TTLinSec:Int) {
      ${MUTATION.sendNoti}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      contentJson: contentJson,
      notiType: notiType,
      recipientId: recipientId,
      TTLinSec:TTLinSec
    })
  );
};

const mutationCounselorMacro = (macroId, action) => {
  const operation = `mutation Mutation($macroId: ID!, $action: String) {
      ${MUTATION.updateCounselorMacro}
  }`;
  return API.graphql(
    graphqlOperation(operation, { macroId: macroId, action: action })
  );
};

const mutationTicketTransferRequest = (
  counselorIdTo,
  ticketId,
  comment,
  branchFrom,
  branchTo
) => {
  const operation = `mutation Mutation($counselorIdTo:String!, $ticketId:String!, $comment:String, $branchFrom:String, $branchTo:String) {
      ${MUTATION.ticketTransferRequest}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      counselorIdTo: counselorIdTo,
      ticketId: ticketId,
      comment: comment,
      branchFrom: branchFrom,
      branchTo: branchTo,
    })
  );
};

const mutationTicketTransferAccept = (feedback, requestId) => {
  const operation = `mutation Mutation($feedback:String, $requestId:String) {
      ${MUTATION.ticketTransferAccept}
  }`;
  return API.graphql(
    graphqlOperation(operation, { feedback: feedback, requestId: requestId })
  );
};

const mutationTicketTransferReject = (feedback, requestId) => {
  const operation = `mutation Mutation($feedback:String, $requestId:String!) {
      ${MUTATION.ticketTransferReject}
  }`;
  return API.graphql(
    graphqlOperation(operation, { feedback: feedback, requestId: requestId })
  );
};

const mutationTicketTransferComplete = (requestId) => {
  const operation = `mutation Mutation($requestId:String!) {
      ${MUTATION.ticketTransferComplete}
  }`;
  return API.graphql(graphqlOperation(operation, { requestId: requestId }));
};

const mutationCategoryAdd = (branch, categoryName, parentCategoryId = "") => {
  const operation = `mutation Mutation($branch:String!, $categoryName: String!, $parentCategoryId: String!) {
    ${MUTATION.categoryAdd}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      categoryName: categoryName,
      parentCategoryId: parentCategoryId,
    })
  );
};

const mutationCategoryDelete = (targetCategoryId) => {
  const operation = `mutation Mutation($targetCategoryId:String!) {
    ${MUTATION.categoryDelete}
  }`;
  return API.graphql(
    graphqlOperation(operation, { targetCategoryId: targetCategoryId })
  );
};
const mutationTicketTransferCancel = (requestId) => {
  const operation = `mutation Mutation($requestId:String!) {
      ${MUTATION.ticketTransferCancel}
  }`;
  return API.graphql(graphqlOperation(operation, { requestId: requestId }));
};
const mutationCategoryUpdate = (targetCategoryId, parentId, categoryName) => {
  const operation = `mutation Mutation($targetCategoryId:String!, $parentId: String, $categoryName: String) {
      ${MUTATION.categoryUpdate}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      targetCategoryId: targetCategoryId,
      parentId: parentId,
      categoryName: categoryName,
    })
  );
};

const mutationCounselorRoleAdd = (branch, counselorId, roleName) => {
  const operation = `mutation Mutation($branch:String!, $counselorId: String!, $roleName: [String!]!) {
    ${MUTATION.counselorRoleAdd}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      counselorId: counselorId,
      roleName: roleName,
    })
  );
};
const mutationCounselorRoleDelete = (branch, counselorId, roleName) => {
  const operation = `mutation Mutation($branch:String!, $counselorId: String!, $roleName: [String!]!) {
    ${MUTATION.counselorRoleDelete}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      counselorId: counselorId,
      roleName: roleName,
    })
  );
};

const mutationCounselorDelete = (counselorId) => {
  const operation = `mutation Mutation($counselorId: String!) {
    ${MUTATION.counselorDelete}
  }`;
  return API.graphql(graphqlOperation(operation, { counselorId: counselorId }));
};

const mutationAddHoliday = (branch, date, dateName) => {
  const operation = `mutation Mutation($branch:String!, $date: Int!, $dateName: String!) {
    ${MUTATION.addHolidayInfo}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      date: date,
      dateName: dateName,
    })
  );
};

const mutationDeleteHoliday = (branch, date) => {
  const operation = `mutation Mutation($branch:String, $date: Int!) {
    ${MUTATION.deleteHolidayInfo}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, date: date })
  );
};

const mutationSyncHolidayInfo = (year) => {
  const operation = `mutation Mutation($year:Int!) {
    ${MUTATION.syncHolidayInfo}
  }`;
  return API.graphql(graphqlOperation(operation, { year: year }));
};

const mutationUpdateBranchPhrase = (branch, phrase, type, description) => {
  const operation = `mutation Mutation($branch:String!, $phrase:String!, $type:String!, $description:String){
    ${MUTATION.updateBranchPhrase}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      branch: branch,
      phrase: phrase,
      type: type,
      description: description,
    })
  );
};

const mutationUpdateBranchMacro = (action, branch, description, key) => {
  const operation = `mutation Mutation($action:String, $branch:String!, $description:String, $key:String!){
    ${MUTATION.updateBranchMacro}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      action: action,
      branch: branch,
      description: description,
      key: key,
    })
  );
};

const mutationUpdateBranchSetting = (branch, dataJson, settingType) => {
  const operation = ` mutation Mutation ($branch:String, $dataJson:String, $settingType:String){
    ${MUTATION.updateBranchSetting}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, dataJson:dataJson, settingType: settingType })
  );
};

const mutationCreateCounselor = (branch, categoryId,  counselorId, department, name, roleName,  email, mobilephone) => {
  const operation = ` mutation Mutation ($branch:String!, $categoryId:[String]!, $counselorId:String!, $department:String, $name:String!
    $roleName:[String]!, $email:String!, $mobilephone:String){
    ${MUTATION.createCounselor}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, categoryId:categoryId, counselorId:counselorId, department:department, name:name,
    roleName:roleName, email:email, mobilephone:mobilephone})
  );
};
/**
 * SUBSCRIBE
 */
const subscribeStatusUpdated = (branch, counselorId) => {
  const operation = `subscription Subscribe($branch: String, $counselorId: String) {
    ${SUBSCRIBE.counselorStatusUpdated}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, counselorId: counselorId })
  );
};

const mutationUpdateTicketRecord = (
  counselorComment,
  ticketId,
  costFree,
  counselResult,
  productCode,
  productGroupCode,
  productGroupName,
  productName,
  subSymptomCode,
  symptomCode,
  symptomName,
  subSymptomName,
  modelName
) => {
  const operation = `mutation Mutation($counselorComment: String!, $ticketId: String!, $costFree: Boolean, $counselResult: String, 
    $productCode: String, $productGroupCode: String, $productGroupName: String, $productName: String, 
    $subSymptomCode: String, $symptomCode: String, $symptomName: String, $subSymptomName: String, $modelName: String) {
    ${MUTATION.updateTicketRecord}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      counselorComment: counselorComment,
      ticketId: ticketId,
      costFree: costFree,
      counselResult: counselResult,
      productCode: productCode,
      productGroupCode: productGroupCode,
      productGroupName: productGroupName,
      productName: productName,
      subSymptomCode: subSymptomCode,
      symptomCode: symptomCode,
      symptomName: symptomName,
      subSymptomName: subSymptomName,
      modelName: modelName,
    })
  );
};

const subscribeNewTickets = (branch) => {
  const operation = `subscription Subscribe($branch: String!) {
    ${SUBSCRIBE.newTicket}
  }`;
  return API.graphql(graphqlOperation(operation, { branch: branch }));
};

const subscribeNewMessages = (convId) => {
  const operation = `subscription Subscribe($convId: String!) {
    ${SUBSCRIBE.newMessage}
  }`;
  return API.graphql(graphqlOperation(operation, { convId: convId }));
};

const subscribeTicketUpdated = (ticketId) => {
  const operation = `subscription Subscribe($ticketId: String) {
    ${SUBSCRIBE.ticketUpdated}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const subscribeMyTicketUpdated = (counselorId, statusId) => {
  const operation = `subscription Subscribe($counselorId: String, $statusId:String) {
    ${SUBSCRIBE.myTicketUpdated}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      counselorId: counselorId,
      statusId: statusId,
    })
  );
};

const subscribeTicketStatusUpdated = (statusId) => {
  const operation = `subscription Subscribe($statusId: String) {
    ${SUBSCRIBE.ticketStatusUpdated}
  }`;
  return API.graphql(graphqlOperation(operation, { statusId: statusId }));
};

const subscribeCloseTicket = (branch, categoryId) => {
  const operation = `subscription Subscribe($branch: String, $categoryId:String) {
    ${SUBSCRIBE.closeTicket}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, categoryId: categoryId })
  );
};

const subscribeNewNoti = (branch, recipientId) => {
  const operation = `subscription Subscribe($branch: String, $recipientId:String) {
    ${SUBSCRIBE.newNoti}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, recipientId: recipientId })
  );
};


const subscribeNewNotibyBranch = (branch, notiType) => {
  console.log("subscribeNewNotibyBranch")
  const operation = `subscription Subscribe($branch: String, $notiType:String) {
    ${SUBSCRIBE.newNotibyBranch}
  }`;
  return API.graphql(
    graphqlOperation(operation, { branch: branch, notiType: notiType })
  );
};

const subscribeTicketTransferTo = (counselorIdTo) => {
  const operation = `subscription Subscribe($counselorIdTo: String) {
    ${SUBSCRIBE.ticketTransferTo}
  }`;
  return API.graphql(
    graphqlOperation(operation, { counselorIdTo: counselorIdTo })
  );
};

const subscribeTicketTransfer = (ticketId) => {
  const operation = `subscription Subscribe($ticketId:String) {
    ${SUBSCRIBE.ticketTransfer}
  }`;
  return API.graphql(graphqlOperation(operation, { ticketId: ticketId }));
};

const getConversation = (convId) => {
  const operation = `query Query($convId: String) {
    ${QUERY.getConversation}
  }`;
  return API.graphql(graphqlOperation(operation, { convId }));
};

const getMessages = (convId, created, nextCursor, orderAsc, resultLimit) => {
  const operation = `query Query(
      $convId: String!, 
      $created: Int, 
      $nextCursor: String,
      $orderAsc: Boolean,
      $resultLimit: Int
    ) {
    ${QUERY.getMessages}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      convId,
      created,
      nextCursor,
      orderAsc,
      resultLimit,
    })
  );
};

const getTicketHistoryList = (ticketId, nextCursor, orderAsc, resultLimit) => {
  const operation = `query Query(
    $ticketId: String!,
    $nextCursor: String,
    $orderAsc: Boolean,
    $resultLimit: Int
    ) {
    ${QUERY.getTicketHistoryList}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      ticketId,
      nextCursor,
      orderAsc,
      resultLimit,
    })
  );
};

const extGetGeocode = (
  address,
  apiType,
  countryCode,
  isPostalCode,
  language
) => {
  const operation = `query Query($address: String!, $apiType: String!, $countryCode: String, $isPostalCode: Boolean, $language: String!) {
    ${QUERY.extGetGeocode}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      address,
      apiType,
      countryCode,
      isPostalCode,
      language,
    })
  );
};

const extSearchCSCenterOrBestshop = (
  searchType,
  countryCode,
  latitude,
  longitude,
  limit
) => {
  const operation = `query Query(
		$searchType: String!,
		$countryCode: String!,
		$latitude: Float!,
		$longitude: Float!,
		$limit: Int
	) {
    ${QUERY.extSearchCSCenterOrBestshop}
  }`;
  return API.graphql(
    graphqlOperation(operation, {
      searchType,
      countryCode,
      latitude,
      longitude,
      limit,
    })
  );
};

const Query = (queries, params) => {
  const schemes = queries
    .flatMap((query) => (QUERY[query] ? [QUERY[query]] : []))
    .join("\n");
  const operation = `query Query($branch: String!, $nextCursor: String, $orderAsc: Boolean, $resultLimit: Int, $transferStatus: [String], $counselorIdFrom: String, $counselorIdTo: String, $requestId: String, $ticketId: String, $branchFrom: String, $branchTo: String, $statusId:[String]) {
    ${schemes}
  }`;
  return API.graphql(graphqlOperation(operation, params));
};

const QueryChatHistoryInfo = (queries, params) => {
  const schemes = queries
    .flatMap((query) => (QUERY[query] ? [QUERY[query]] : []))
    .join("\n");
  const operation = `query Query($ticketId:String!, $convId:String!, $created: Int, $nextCursor: String, $orderAsc: Boolean, $resultLimit: Int) {
    ${schemes}
  }`;
  return API.graphql(graphqlOperation(operation, params));
};

const QueryTickets = (queries, params) => {
  const schemes = queries
    .flatMap((query) => (QUERY[query] ? [QUERY[query]] : []))
    .join("\n");
  const operation = `query Query($branch: String, $nextCursor: String, $orderAsc: Boolean, $resultLimit: Int) {
    ${schemes}
  }`;
  return API.graphql(graphqlOperation(operation, params));
};

const Subscribe = (targets, params) => {
  const schemes = targets
    .flatMap((target) => (SUBSCRIBE[target] ? [SUBSCRIBE[target]] : []))
    .join("\n");
  const operation = `subscription Subscribe($branch: String, $ticketId: String, $categoryId: String) {
    ${schemes}
  }`;
  return API.graphql(graphqlOperation(operation, params));
};

export {
  Query,
  QueryTickets,
  QueryChatHistoryInfo,
  getTicketsNotAssigned,
  getStatusList,
  getMe,
  getBranchPhrase,
  getBranchPhraseAll,
  getBranchMacro,
  getCounselorStatus,
  getTicket,
  getTicketRecord,
  getNotis,
  getNotisByBranch,
  getCounselorMacro,
  getCategoryMap,
  getCategoryList,
  getAssignableCounselor,
  getCounselors,
  getCounselor,
  getRoleInfo,
  getCustomer,
  getTransferInfo,
  getSymptomCodeSubs,
  getSymptomCodes,
  getSymptomProducts,
  getSymptomProductGroups,
  getSymptomInfo,
  getSymptomSummaries,
  getProductInfo,
  searchProduct,
  RBACIsAccessbile,
  AIMLSearchModel,
  AIMLSummarizeConversation,
  AIMLSearchSymptomSummaries,
  searchHolidayInfo,
  getBranchSetting,
  mutationAssignTicketToCounselor,
  mutationSendMessage,
  mutationStartConversation,
  mutationEndConversation,
  mutationCloseTicket,
  mutationUpdateCounselorStatus,
  mutationUpdateTicketRecord,
  mutationSendNoti,
  mutationCounselorMacro,
  mutationTicketTransferRequest,
  mutationTicketTransferAccept,
  mutationTicketTransferReject,
  mutationTicketTransferComplete,
  mutationCategoryAdd,
  mutationCategoryDelete,
  mutationTicketTransferCancel,
  mutationUpdateCounselor,
  mutationCounselorBranchAdd,
  mutationCounselorCategoryAdd,
  mutationCounselorCategoryDelete,
  mutationCategoryUpdate,
  mutationCounselorRoleAdd,
  mutationCounselorRoleDelete,
  mutationCounselorDelete,
  mutationAddHoliday,
  mutationDeleteHoliday,
  mutationSyncHolidayInfo,
  mutationUpdateBranchPhrase,
  mutationUpdateBranchMacro,
  mutationUpdateBranchSetting,
  mutationCreateCounselor,
  subscribeNewTickets,
  subscribeNewMessages,
  subscribeTicketUpdated,
  subscribeMyTicketUpdated,
  subscribeTicketStatusUpdated,
  subscribeCloseTicket,
  subscribeStatusUpdated,
  subscribeNewNoti,
  subscribeNewNotibyBranch,
  subscribeTicketTransfer,
  subscribeTicketTransferTo,
  getTicketsMine,
  getConversation,
  getMessages,
  getTicketHistoryList,
  extGetGeocode,
  extSearchCSCenterOrBestshop,
};
