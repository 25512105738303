import { Dialog, DialogTitle, DialogContent, InputBase, withStyles, Button, 
  Table, TableHead, TableRow, TableCell, TableBody, Typography, MenuItem,
IconButton, Divider, Paper, FormControl, InputLabel, Select, TextField,
Container,Box, Grid } from '@material-ui/core';
import React from 'react';
import { ArrowDropDown, Search, ArrowRight, Message, Group, Person, Home, Folder, Assessment} from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';

const styles = (theme) => ({
  root: {
    width: "800px",
    maxWidth:"800px",
    borderRadius:"0px"
  },
  search:{
    padding: '2px 4px',
    display: 'flex',
    boxShadow: "none",
    width: "100%",
    height: "35px",
    border:"1px solid #ececec",
    borderRadius:"0px",
    background:"#f5f5f5"
  },
  search1: {
    padding: '5px',
    display: 'flex',
    boxShadow: "none",
    width: "100%",
    height: "65px",
    // border:"1px solid #ececec",
    borderRadius:"0px",
    // background:"#f5f5f5"
  },
  formControl:{
    // border: "1px solid black",
    width: "200px",
    marginRight:"15px",
    
  },
  selectRoot:{
    // border: "1px solid red"
    width: "100%",
    fontSize: "0.8em",

  },
  select:{
    fontSize: "0.8em",
    // color: "red"
    // border: "1px solid blue",
    // padding: "5px",
    // margine: "0px",
  },
  selectMenu:{fontSize: "0.8em",},
  menuItemRoot:{
    fontSize: "0.9em",
  },
  inputRoot: {
    color: 'inherit',
  },
  folder: {
    display: "flex",
    width: "100%",
    padding: "10px"
  },

  box:{
    bgcolor: '#ffffff', height: '60vh' 
  },
  dialogTitle:{
    fontSize: "1.0em",
    padding: "10px 20px",
    background: "#f5f5f5",
    marginBottom: "15px",
    borderBottom: "1px solid #ececec",
  },
  dialogContent:{
    padding: "0px",
    display:"flex",
    height:"500px",
  },
  userTree:{
    width: "100%",
    padding: "0px",
    height: "100%",
    borderRight: "1px solid #ececec",
    boxSizing:"border-box"
  },
  userPanel:{
    width: "100%",
    padding: "10px",
    height: "100%",
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: "1.0em"
  },
  iconButton: {
    // padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchCol:{

  },
  searchTablePaper:{
    width: "100%",
    height: "200px",
    borderRadius:"0px",
    boxShadow: "none",
    overflowY:"scroll",
    border: "1px solid #ececec"

  },
  searchRow:{

  },
  searchTh:{
    padding: "2px",
    fontSize: "0.85em",
    textAlign: "center",
    background: "#f5f5f5",
    fontWeight: "600",
  },
  searchCol:{
    padding: "2px",
    fontSize: "0.85em",
    // borderLeft: " 1px solid #ececec",
    textAlign: "center",
  },
  noData:{
    textAlign: "center",
    height:"150px",
    border:"none",
    color:"#7f7f7f"
  },
  confirmBtn:{
    margin: "5px",
    padding: "2px 0px",
  },
  cancelBtn:{
    margin: "5px",
    padding: "2px 0px",
  },
  textarea:{
    width: "100%",
    height: "100px",
    marginRight: "10px",
    border: "1px #e8e8e8 solid",
    borderRadius: "2px",
  },
  msgPaper:{
    width: "100%",
    boxShadow: "none",
    height: "200px",
    // border: "1px solid #ececec"
  },
  msgIcon:{
    fontSize: "1.3em",
    marginRight: "3px",
  },
  msgTitle:{
    padding: "5px",
    fontSize: "0.88em",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  },
  btns:{
    display:"flex",
    justifyContent:"center",
    padding:"10px",
    marginTop: "5px",
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },



});
const StyledTreeItem = withStyles(styles)((props) => {
  const { classes, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        // root: classes.treeItemRoot,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        // group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
});
const ConvertDialogDomain = props => {
  const { classes } = props;
  return (
    <Dialog {...props} classes={{paper: classes.root}}>
      <DialogTitle classes={{root:classes.dialogTitle}} disableTypography={true}>
        도메인 이관
      </DialogTitle>
      <DialogContent classes={{root:classes.dialogContent}}>
        <Grid container className={classes.userTree} xs={12} sm={4} md={4} >
        <div className={classes.folder}>
        <TreeView
          className={classes.root}
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDown />}
          defaultExpandIcon={<ArrowRight />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <StyledTreeItem nodeId="1" labelText="Root" labelIcon={Home}>
            <StyledTreeItem nodeId="2" labelText="LG전자 마케팅1" labelIcon={Folder} color="#1a73e8" bgColor="#e8f0fe">
              <StyledTreeItem nodeId="3" labelText="제품 구매상담1-1" labelIcon={Folder} color="#1a73e8" bgColor="#e8f0fe">
                <StyledTreeItem nodeId="4" labelText="제품 구매상담1-1-1" labelIcon={Folder} color="#1a73e8" bgColor="#e8f0fe"/>
              </StyledTreeItem>
              <StyledTreeItem nodeId="5" labelText="제품 구매상담1-2" labelIcon={Folder} color="#1a73e8" bgColor="#e8f0fe"/>
            </StyledTreeItem>
          </StyledTreeItem>
        </TreeView>
        </div>
        </Grid>
        <Grid container className={classes.userPanel} xs={12} sm={8} md={8} >
          <Paper className={classes.search1} elevation={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">상담사</InputLabel>
              <Select classes={{root:classes.selectRoot, select:classes.select}}>
                <MenuItem value={10} classes={{root:classes.menuItemRoot}}>카테고리 내</MenuItem>
                <MenuItem value={20} classes={{root:classes.menuItemRoot}}>도메인 전체</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">이관가능상태</InputLabel>
                <Select classes={{root:classes.selectRoot, select:classes.select, filled:classes.selectFilled, selectMenu:classes.selectMenu}}>
                  <MenuItem value={10} classes={{root:classes.menuItemRoot}}>전체</MenuItem>
                  <MenuItem value={20} classes={{root:classes.menuItemRoot}}>이관 가능</MenuItem>
                  <MenuItem value={30} classes={{root:classes.menuItemRoot}}>이관 불가</MenuItem>
                </Select>
            </FormControl>
          </Paper>
          <Paper className={classes.search1} elevation={1}>
            <FormControl className={classes.formControl}>
              <TextField id="standard-basic" label="이름" />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField id="standard-basic" label="아이디" />
            </FormControl>
            <Button variant="contained" size="small" color="primary" classes={{ root: classes.confirmBtn}}>
              검색
            </Button>
          </Paper>
          <Paper className={classes.searchTablePaper}>
            <Table className={classes.searchTable}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{root:classes.searchTh}}>권한</TableCell>
                  <TableCell classes={{root:classes.searchTh}}>아이디</TableCell>
                  <TableCell classes={{root:classes.searchTh}}>이름</TableCell>
                  <TableCell classes={{root:classes.searchTh}}>배분상태</TableCell>
                  <TableCell classes={{root:classes.searchTh}}>연결수(현재/최대)</TableCell>
                  <TableCell classes={{root:classes.searchTh}}>도메인</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell classes={{root:classes.searchCol}}>상담사</TableCell>
                  <TableCell classes={{root:classes.searchCol}}>why.lee</TableCell>
                  <TableCell classes={{root:classes.searchCol}}>이유민</TableCell>
                  <TableCell classes={{root:classes.searchCol}}>배분</TableCell>
                  <TableCell classes={{root:classes.searchCol}}>1/5</TableCell>
                  <TableCell classes={{root:classes.searchCol}}>CS</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell classes={{root:classes.noData}} colSpan={6}>데이터가 없습니다.</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </Paper>
          <Paper className={classes.msgPaper}>
            <div className={classes.msgTitle}><Message classes={{root:classes.msgIcon}}></Message>이관 메시지</div>
            <textarea className={classes.textarea}></textarea>
            <div className={classes.btns}>
            <Button variant="contained" size="small" color="primary" classes={{ root: classes.confirmBtn}}>
              확인
            </Button>
            <Button variant="outlined" size="small" color="primary" className={classes.cancelBtn}>
              취소
            </Button>
            </div>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(ConvertDialogDomain);