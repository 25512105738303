import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import {TablePagination, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, FormControlLabel, Switch, withStyles, Chip, Avatar} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

const tableStyles = (theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      backgroundColor: "rgb(255, 255, 255)",  
      color: "rgb(33, 43, 54)", 
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",  
      backgroundImage: "none",  
      overflow: "hidden", 
      position: "relative", 
      boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px", 
      borderRadius: "16px", 
      zIndex: "0",  
    },
    table: {
      minWidth: 500,
      // border: '1px solid #e0e0e0'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableCell:{
      fontSize:"0.9em"
    },
    rowStyle:{
      '& .MuiTableCell-root':{
        lineHeight:"2.8",
        fontSize: '1.0em',
        padding: '3px',
      },
      '& .MuiButtonBase-root':{
        padding: '0px'
      },
      '& .MuiTableCell-head':{
          fontWeight: '600'
      }
    },
    tableHead:{
      background : "#f5f5f5"
    },
    chip:{
      fontSize:"11px",
      borderRadius:"10px",
    },
    notiType:{
      fontSize:"11px",
      marginRight:"2px",
      // color:"red",
      fontWeight:"600",
      borderRadius:"10px",
    },
    branch:{
      fontSize:"11px",
      borderRadius:"10px",
      fontWeight:"600",
    },
    pagination:{
      border:"1px solid #e0e0e0",
      borderTop:"none"
    }
});

const useToolbarStyles = (theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  });

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




function CommonTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead classes={{root:classes.tableHead}}>
      <TableRow classes={{root:classes.rowStyle}}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells && headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CommonTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = withStyles(useToolbarStyles)((props) => {
  const {classes, numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
});

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


function NoticeTable(props){
  const {classes, data, headCells, rows, selectRow, checkRow, checkSelect} = props; 
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(()=>{
    console.log("??data:::", data);
    setSelected([]);
    // handleClickRow("init");
  }, [data]);

  // check box click
  const checkClick = (event, val, row) =>{
    console.log(row)
    // console.log("check click....")
    // event.preventDefault()

    
    const checkedIndex = checked.indexOf(row.id);
    if (checkedIndex === -1) {
        checkRow(row)
        setChecked([row.id])
        checkSelect && setSelected([row.id])
    
    }
    else{
        checkRow()
        setChecked([])
        checkSelect && setSelected([])
    }
    
  }
  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    if (selectedIndex === -1) {
    //     setSelected([row.id])
        selectRow(row)
    }
    else{
        selectRow()
    //     setSelected([])
    }    
  };

  const cellClick = (event, row) => {
    if (checkSelect){
      checkClick(null, null, row)
    }else{
      selectRow(row)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isChecked = (name) => checked.indexOf(name) !== -1;


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  return (
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <CommonTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort} 
              rowCount={rows.length}
              headCells = {headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isItemChecked = isChecked(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
               
                  const cells = null;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={checkSelect && isItemSelected}
                      className={classes.rowStyle}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemChecked}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange = { (e, val)=>{checkClick(e,val, row)}}
                        />
                      </TableCell>
                        {
                          Object.keys(row).filter(a=>a!=="id" && a!=="content").map(
                            key => (
                            <TableCell align="left" key={`table_cell_${key}`}
                             onClick = {e=>cellClick(e, row)}
                             >
                                {key==="branch" || key==="notiType" ? 
                                    <Chip className={classes[key]} size="small" label={row[key].toUpperCase()}/>
                                    :row[key]}
                            </TableCell>)
                            )
                        }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      //  <FormControlLabel
      //   control={<Switch checked={dense} onChange={handleChangeDense} />}
      //   label="Dense padding" 
      // />
    // </div>
  );
}

export default withStyles(tableStyles)(NoticeTable); 