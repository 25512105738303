import React, {useContext, createContext, useEffect, useState} from 'react';
import { withStyles, Typography, Divider, Grid } from '@material-ui/core';
import CL from "../Message/CL";

import Carousel from "../../carousel/Carousel";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Card, Button, TextField, FormControl, Select, MenuItem, InputLabel, 
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';

import img1 from '../../../asset/img/test1.jpg';
import img2 from '../../../asset/img/test2.jpg';
import img3 from '../../../asset/img/test3.jpg';
import img4 from '../../../asset/img/test4.jpg';
import img5 from '../../../asset/img/test5.jpg';
import { 
  mutationSendMessage, 
  getSymptomCodeSubs, 
  getSymptomCodes, 
  getSymptomProductGroups, 
  getSymptomProducts,
  getSymptomInfo,
  getSymptomSummaries,
  AIMLSearchSymptomSummaries,
} from '../../data/Loader';
import {mkTLStr,mkCLStr} from '../../data/DataConvert'
import { CircularProgress } from '@material-ui/core';


const styles = (theme) => ({
  root: {
    height: "-webkit-fill-available",
    fontFamily: 'LGSmHaT',
    color:'#8e8e8e',
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "5px"
  },
  title:{
    fontWeight:'600',
    color:'#7b7a7a',
    paddingLeft:'15px',
    paddingBottom:'5px',
  },
  table: {
    // minHeight:'300px'
  },
  td:{
    background:'none',
    border:'none',
    padding:'3px 24px 3px 16px',
    color:'#7b7a7a'
  },
  searchInput:{
    display:'inline'
  },
  
  value:{

  },
  refreshIcon:{
    float:'right',
  },
  carousel:{
    color:'yellow'
  },
  carouselDiv:{
    height:'300px',
  },
  gscsBtn:{
    backgroundColor:'#bf134a',
    width:'100px',
  },
  inputSearchDiv:{
    minWidth:"100%",
    display:"flex",
  },
  contentSearchTextField:{
    width: '75%',
    margin:'5px',
  },
  contentSearchBtn:{
    width:"25%",
    margin:"5px",
    boxShadow:"none",
    background:"#0a8297",
  },
  selectDiv:{
    minWidth:"100%",
    display:"flex",
  },
  selectFormControl:{
    padding:0,
    width: "50%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  },
  menuItem:{
    lineHeight:"1.1",
    fontSize: "0.84rem",
  },
  submitBtn:{
    display:'inline',
    alignSelf:'center',
    textAlign:'center',
    margin:"30px",
  },
  submit:{
    backgroundColor:'#0a8297',
    width:'100px',
  },
  submitCancel:{
    backgroundColor:'#a9a8a8',
    width:'100px',
  },
  tableContainer: {
    // maxHeight: "400px",
    // minHeight: "100%",
    height: "300px",
    background: "#f9f9f9",
    margin: "10px 5px",
    border: "1px solid #c4c4c4",
    width:"auto",
    overflowY:"auto"
  },
  th: {
    // padding:'5px 10px',
    lineHeight: "1.0rem",
    fontWeight: "500",
    height:"30px",
    paddingLeft:"10px",
    // backgroundColor: "red"
  },
  td: {
    // minWidth: "100px",
    width: "100px",
    padding: "5px 10px",
    lineHeight: "1.0rem",
    height:"30px",
    fontSize:"0.8rem" 
  },
  tableButton: {
    "&:hover": {
      backgroundColor: "#ece6e6",
    },
  },
  loadingCol:{
    height: "250px",
    color: "#7c7c7c",
    border: "none",
    textAlign:"center",
    fontSize:"0.8rem" 
  },
  symptomSummary :{
    height: "245px",
  },
  noSymptomInfo: {
    border: "1px solid #c4c4c4",
    margin: "5px",
    height: '240px', 
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    "& p":{
      fontSize:"0.8rem"
    }
  },
  searchText:{
    padding:0,
    width: "80%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      borderRadius: "0px",
      padding:"0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  }


});

function SymptomSearch(props) {
  const { classes, data } = props;
  const counselorId = data && data.counselorId;
  const convId = data && data.convId
  const [productGroup, setProductGroup] = React.useState('');
  const [product, setProduct] = React.useState('');
  const [symptom, setSymptom] = React.useState('');
  const [subSymptom, setSubSymptom] = React.useState('');

  const [listProductGroup, setListProductGroup] = React.useState([]);
  const [listProduct, setListProduct] = React.useState([]);
  const [listSymptom, setListSymptom] = React.useState([]);
  const [listSubSymptom, setListSubSymptom] = React.useState([]);

  const [symptomSearchResult, setSymptomSearchResult] = React.useState([]);
  const [symptomSearchResultStatus, setSymptomSearchResultStatus] = useState("init");
  const [symptomSummary, setSymptomSummary] = React.useState(null);
  const [symptomSummaryStatus, setSymptomSummaryStatus] = React.useState("noresult");

  const [searchText, setSearchText] = React.useState("");

  const [simpleAlertOpen, setSimpleAlertOpen] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");

  const chatStr = data && data.chatInfo;
  const deviceStr = data && data.deviceInfo;
  let chatJson = {};
  let deviceJson = {};
  try{
   chatJson = chatStr ? JSON.parse(chatStr):{};
   deviceJson =deviceStr? JSON.parse(deviceStr):{};
  }catch(e){
     console.log("parse error ", e)
  }



  const returnValueSymptomFull = [
    'productGroupCode',
    'productGroupName',
    'productCode',
    'productName',
    'symptomCode',
    'symptomName',
    'subSymptomCode',
    'subSymptomName',
  ]
  // getSymptomCodeSubs(returnValueSubSymptom)
  // .then(result=>{
  //   console.log(result);
  //   let subSympList = result.data.getSymptomCodeSubs;
  //   setListSubSymptom(subSympList.map(subSympList=>{
  //     return {
  //       name:`${subSympList.subSymptomName}(${subSympList.productName},${subSympList.symptomName})`,
  //       code:`${subSympList.productCode}-${subSympList.symptomCode}-${subSympList.subSymptomCode}`
  //     }
  //   }))
  // })
  // .catch(error => {
  //   console.error(error)
  // });

  const returnValueSymptom = [
    'productCode',
    'productName',
    'symptomCode',
    'symptomName',
  ]
  // getSymptomCodes(returnValueSymptom)
  // .then(result=>{
  //   setListSymptom(result.map(sympList=>{
  //     return `${sympList.symptomName}(${sympList.productName})`
  //   }))
  //   console.log(result);
  // })
  // .catch(error => {
  //   console.error(error)
  // });

  const returnValueProduct = [
    'productCode',
    'productName',
  ]
  // getSymptomProducts(returnValueProduct)
  // .then(result=>{
  //   setListProduct(result.map(prodList=>{
  //     return `${prodList.productName} (${prodList.productCode})`
  //   }))
  //   console.log(result);
  // })
  // .catch(error => {
  //   console.error(error)
  // });
  
  const returnValueProductGroup = [
    'productGroupCode',
    'productGroupName',
  ]
  
  const handleSendCard = () => {
    if (symptomSummary){
      mutationSendMessage(mkCLStr(symptomSummary), convId, counselorId)
      .then(result => {
        console.log("[Send Message Carousel] Result", result);
      })
      .catch(error => {
        console.log("[Send Message Carousel] Error", error);
      })
    }
  }
  const handleProductGroupChange = (event) => {
    console.log(`${event.target.value} is CLICKED!`)
    const productGroup = event.target.value
    setProductGroup(productGroup);
    setProduct("");
    setListProduct([]);
    setSymptom("");
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomProducts(returnValueProduct, "", productGroup)
    .then(prodList=>{
      console.log(prodList);
      setListProduct(prodList.map(prodInfo=>{
        return {
          name:`${prodInfo.productName} (${prodInfo.productCode})`,
          code: prodInfo.productCode
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleProductChange = (event) => {
    const product = event.target.value
    console.log(`product : ${product}`)
    setProduct(product);
    setSymptom("");
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomCodes(returnValueSymptom,"",productGroup,product)
    .then(symptomList=>{
      console.log(symptomList);
      setListSymptom(symptomList.map(sympInfo=>{
        return {
          name: `${sympInfo.symptomName}(${sympInfo.productName})`,
          code: `${sympInfo.symptomCode}`
        } 
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleSymptomChange = (event) => {
    const symptomCode = event.target.value;
    setSymptom(symptomCode);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomCodeSubs(returnValueSymptomFull,"",productGroup,product,symptomCode)
    .then(subSympList=>{
      console.log(subSympList);
      setListSubSymptom(subSympList.map(subSympInfo=>{
        return {
          name:`${subSympInfo.subSymptomName}(${subSympInfo.productName},${subSympInfo.symptomName})`,
          code:`${subSympInfo.subSymptomCode}`
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleSubSymptomChange = (event) => {
    setSubSymptom(event.target.value);
  };

  const handleSearch = () => {
    setSymptomSearchResultStatus("loading")
    
    getSymptomInfo(returnValueSymptomFull, "", productGroup, product, symptom, subSymptom)
    .then(symptomList=>{
      console.log(symptomList)
      if ( !symptomList || symptomList.length==0) 
        setSymptomSearchResultStatus("noresult")
      else{
        setSymptomSearchResult(symptomList);
        setSymptomSearchResultStatus("result")
      }
    })
    .catch(error=>{
      console.error(error)
      setSymptomSearchResultStatus("error")
    });
    
  }

  const handleSearchText = () => {
    if (searchText==""){
      setSimpleAlertMsg("검색어를 입력하여 주세요.");
      setSimpleAlertOpen(true);
      return;
    }
    setSymptomSearchResultStatus("loading")
    
    AIMLSearchSymptomSummaries(searchText)
    .then(result=>{
      console.log("[AIMLSearchSymptomSummaries] Result:", result)
      
      const symptomList = result.data.AIMLSearchSymptomSummaries;

      if ( !symptomList || symptomList.length==0) 
        setSymptomSearchResultStatus("noresult")
      else{
        setSymptomSearchResult(symptomList);
        setSymptomSearchResultStatus("result")
      }
    })
    .catch(error=>{
      console.error("[AIMLSearchSymptomSummaries] Error:", error)
      setSymptomSearchResultStatus("error")
    });
    
  }

  let clickSymptom = (sympInfo)=>{
    setSymptomSummaryStatus("loading");
    console.log(`Clicked! ${sympInfo}`);
    let retvalue = [
      "symptomBranch",
      "contentId",
      "productCode",
      "productName",
      "productGroupName",
      "productGroupCode",
      "symptomName",
      "symptomCode",
      "subSymptomName",
      "subSymptomCode",
      "contentTitle",
      "videoYn",
      "updatedDate",
      "contentSummaryChatbot"
    ]
    getSymptomSummaries(retvalue, "", sympInfo.productCode, sympInfo.symptomCode, sympInfo.subSymptomCode)
    .then(result=>{
      if(!result || result.length==0)
        setSymptomSummaryStatus("noresult");  
      else {
        setSymptomSummaryStatus("result");
      }
      setSymptomSummary(result.map(symptomSum=>{
        return {
          title: symptomSum.contentTitle,
          summary: symptomSum.contentSummaryChatbot,
          video_yn: symptomSum.videoYn,
          url : `https://gscs.lge.com/gscs/collpack/contents/readChatbotContents.do?corporation=LGEKR&contentsId=${symptomSum.contentId}`,
          type : 'external'
        }
      }))
    })
    .catch(error=>{
      console.error(error)
    });
  }


  const resultMsg = { init:"고장 증상을 검색해 주세요.", noresult:"검색 결과가 없습니다.", error:"결과를 조회하는데 문제가 발생하였습니다."};

  useEffect(()=>{
    getSymptomProductGroups(returnValueProductGroup)
    .then(prodGroupList=>{
      console.log(prodGroupList);
      setListProductGroup(prodGroupList.map(prodGroupInfo=>{
        return {
          name:`${prodGroupInfo.productGroupName} (${prodGroupInfo.productGroupCode})`,
          code:`${prodGroupInfo.productGroupCode}`
        }
      }))

      //////////// temp/////////////
      const tempProdGroup = "AC";
      const tempProd = "PAC";
      const tempSymp = chatJson.symptom_code;
      const tempSubSymp = chatJson.sub_symptom_code;

      setProductGroup(tempProdGroup);
      setProduct(tempProd);
      setSymptom(tempSymp);
      setSubSymptom(tempSubSymp);

      //product select list
      getSymptomProducts(returnValueProduct, "", tempProdGroup)
      .then(prodList=>{
        console.log("productList:", prodList);
        setListProduct(prodList.map(prodInfo=>{
          return {
            name:`${prodInfo.productName} (${prodInfo.productCode})`,
            code: prodInfo.productCode
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });

      //symptom list
      getSymptomCodes(returnValueSymptom,"",tempProdGroup,tempProd)
      .then(symptomList=>{
        console.log(symptomList);
        setListSymptom(symptomList.map(sympInfo=>{
          return {
            name: `${sympInfo.symptomName}(${sympInfo.productName})`,
            code: `${sympInfo.symptomCode}`
          } 
        }))
      })
      .catch(error => {
        console.error(error)
      });
    
      //sub symptom list
      getSymptomCodeSubs(returnValueSymptomFull,"", tempProdGroup, tempProd, tempSymp)
      .then(subSympList=>{
        console.log(subSympList);
        setListSubSymptom(subSympList.map(subSympInfo=>{
          return {
            name:`${subSympInfo.subSymptomName}(${subSympInfo.productName},${subSympInfo.symptomName})`,
            code:`${subSympInfo.subSymptomCode}`
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });
      //////////////////////////////////////////////////////////////////////////////////////////////////  
    })
    .catch(error => {
      console.error(error)
    });
  },[]);
  return (
    <div className={classes.root}>
      {/* <Button variant="contained" size="small" color="secondary" className={classes.gscsBtn}>GSCS</Button> */}
      <form noValidate autoComplete="off">
      <div className={classes.selectDiv}>
        {/* <ProductGroupSelect/> */}
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
          <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품그룹</InputLabel>
          <Select
            size='small'
            labelId="product-group-select-label"
            id="product-group-select"
            value={productGroup}
            onChange={handleProductGroupChange}
            label="제품그룹"
            classes={{root:classes.selectRoot}}
          >
          {
            listProductGroup.map(productGroupInfo=>{
              return <MenuItem key={productGroupInfo.code} value={productGroupInfo.code} className={classes.menuItem}>{productGroupInfo.name}</MenuItem>
            })
          }
          </Select>
        </FormControl>
        {/* <ProductSelect/> */}
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
          <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품</InputLabel>
          <Select
            size='small'
            labelId="product-select-label"
            id="product-select"
            value={product}
            onChange={handleProductChange}
            label="제품"
          >
            {listProduct && listProduct.length 
              ?
               listProduct.map(productInfo=>{
                return <MenuItem key={productInfo.code} value={productInfo.code} className={classes.menuItem}>{productInfo.name}</MenuItem>
                })
              : <MenuItem value="" className={classes.menuItem}><em>제품 그룹을 선택해 주세요.</em></MenuItem>
            }
          </Select>
        </FormControl>
        {/* <SymptomSelect/> */}
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
          <InputLabel id="symptom-select-label">고장증상</InputLabel>
          <Select
              labelId="symptom-select-label"
              id="symptom-select"
              value={symptom}
              onChange={handleSymptomChange}
              label="고장증상"
            >
            { listSymptom && listSymptom.length
              ?
                listSymptom.map(symptomInfo=>{
                  return <MenuItem key={symptomInfo.code} value={symptomInfo.code} className={classes.menuItem}>{symptomInfo.name}</MenuItem>
                })
              : <MenuItem value="" className={classes.menuItem}><em>제품을 선택해 주세요.</em></MenuItem>
            }
          </Select>
        </FormControl>
        {/* <SubSymptomSelect/> */}
        <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
          <InputLabel id="sub-symptom-select-label">상세 고장증상</InputLabel>
            <Select
              labelId="sub-symptom-select-label"
              id="sub-symptom-select"
              value={subSymptom}
              onChange={handleSubSymptomChange}
              label="상세 고장증상"
            >
            
            { listSubSymptom && listSubSymptom.length?
              listSubSymptom.map(subSymp=>{
                return <MenuItem key={subSymp.code} value={subSymp.code} className={classes.menuItem}>{subSymp.name}</MenuItem>
              })
              :<MenuItem value="" className={classes.menuItem}><em>고장증상을 선택해 주세요.</em></MenuItem>
            }

            </Select>
        </FormControl>
        <Button onClick={handleSearch} variant="contained" size="small" color="primary" className={classes.contentSearchBtn}>검색</Button>
      </div>
      <div className={classes.selectDiv}>
        <TextField id="searchText" className={classes.searchText} value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} variant="outlined" margin="dense"/>
        <Button onClick={handleSearchText} variant="contained" size="small" color="primary" className={classes.contentSearchBtn}>전체 검색</Button>
      </div>
      </form>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
          className={classes.table}
          aria-label="Service History"
        >
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.th }}>제품그룹</TableCell>
              <TableCell classes={{ root: classes.th }}>제품</TableCell>
              <TableCell classes={{ root: classes.th }}>증상</TableCell>
              <TableCell classes={{ root: classes.th }}>증상Code</TableCell>
              <TableCell classes={{ root: classes.th }}>상세증상</TableCell>
              <TableCell classes={{ root: classes.th }}>상세증상Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(symptomSearchResultStatus in resultMsg) && 
              <TableRow><TableCell colSpan={6} className={classes.loadingCol}>{resultMsg[symptomSearchResultStatus]}</TableCell></TableRow>}
            {symptomSearchResultStatus === 'loading' && 
              <TableRow><TableCell colSpan={6} className={classes.loadingCol}><CircularProgress/></TableCell></TableRow>}
            {symptomSearchResultStatus === 'result' && symptomSearchResult &&
              symptomSearchResult.map(sympInfo => (
              <TableRow className={classes.tableButton} onClick={()=>clickSymptom(sympInfo)}>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.productGroupName}({sympInfo.productGroupCode})
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.productName}({sympInfo.productCode})
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.symptomName}
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.symptomCode}
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.subSymptomName}
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {sympInfo.subSymptomCode}
                </TableCell>
              </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <div className={classes.symptomSummary}>

        {symptomSummaryStatus==="loading" &&
          <div className={classes.noSymptomInfo}><CircularProgress/></div>}

        {symptomSummaryStatus==="result" && symptomSummary && symptomSummary.length &&
            <CL data={{list_items:symptomSummary}}/>}

        {symptomSummaryStatus==="noresult" &&
            <div className={classes.noSymptomInfo}><Typography>증상 정보가 없습니다.</Typography></div>
        }
      </div>
      

      <div className={classes.submitBtn}>
        <Button disabled={!(symptomSummary&&symptomSummary.length>0)} onClick={handleSendCard} variant="contained" size="small" color="primary" className={classes.submit}>Card전송</Button>
      </div>

        {/* Simple Alert  */}
        <Dialog
          open={simpleAlertOpen}
          onClose={()=>setSimpleAlertOpen(false)}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {simpleAlertMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setSimpleAlertOpen(false)} autoFocus>확인</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
export default withStyles(styles)(SymptomSearch);