import React, { useEffect, useState } from 'react';
import { useUser } from '../../data/User';
import { Table, TableBody, TableRow, TableCell, TableContainer, withStyles,
        Radio, FormControlLabel, FormControl, Select, MenuItem, Button, TextField, RadioGroup,
        Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, 
} from '@material-ui/core';
import { getTicketRecord, 
  getSymptomProductGroups,
  getSymptomProducts,
  getSymptomCodes,
  getSymptomCodeSubs,
  AIMLSearchModel,
  mutationUpdateTicketRecord,
  mutationSendNoti,
  AIMLSummarizeConversation, } from '../../data/Loader';
  import{Autocomplete} from "@material-ui/lab";
  import { CircularProgress } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px",
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #cccccc",
  },
  summarySelectFormContol:{
    '& .MuiInputBase-root':{
      "borderRadius": "2px",
      "margin": "2px 5px 2px 0px",
      "height": "30px",
      "fontSize": "1.1em"
    },
    '& .MuiSelect-root':{
      "paddingTop":"0px",
      "paddingBottom":"0px",
    },
  },
  summaryMenuItem:{
    fontSize:"1em"
  },
  summaryRadio:{
    marginLeft:"0px",
    marginRight:"5px",
    '& .MuiButtonBase-root':{
      padding:"3px 0px 3px 3px"
    },
    '& .MuiTypography-root':{
      fontSize:"1em",
    }
  },
  summaryTextfield:{
    width:"150px",
    verticalAlign:"middle",
    '& .MuiInputBase-root':{
      height: "30px",
      borderRadius: "3px",
      margin: "2px -2px 2px 0px",
      fontSize:"1.0em"
    },
    '& .MuiInputBase-root:focus':{
      border:"1px solid red"
    },
    '& .MuiInputBase-input':{
      padding: "0px 10px",
    },
  },
  summaryButton:{
    padding: "0px",
    height: "30px",
    background: "#0A8297",
    color: "#ffffff",
    minWidth: "50px",
    marginLeft: "-1px",
    borderRadius: "0px 3px 3px 0px",
    "&:hover":{
      background: "#6ea3ad",
      color: "#fff"
    },
  },
  td1:{
    height:"30px",
    padding:"0px 10px",
    width:"150px",
    backgroundColor:"#ededed",
    fontSize:"0.9em"
  },
  td100:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td120:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td11:{
    height:"32px",
    padding:"0px 10px",
    fontSize:"0.9em",
    width: "220px",
  },
  commentTextarea: {
    borderRadius: "0px",
    margin: "10px 5px 10px 0px",
    width: "100%",
    "&:focus": {
      border: "1px solid #b6b6b6",
      outline: "none",
    },
    border: "1px solid #d7d7d7",
    fontFamily: "inherit",
    padding: "5px",
    fontSize: "1.0em",
    minHeight: "5.0rem"
  },
  updateDiv:{
    textAlign:"right"
  },
  updateBtn:{
    width:"150px",
    height:"33px",
    color:"#fff",
    background:"#0a8297",
    margin:"10px 0px",
    "&:hover": {
      background: "#075B69",
      color: "#fff"
    },
  },
  aiBtn:{
    width:"80px",
    color:"#fff",
    background:"#0a8297",
    margin:"10px 0px 10px 5px",
    fontSize:"0.9em",
    "&:hover": {
      background: "#075B69",
      color: "#fff"
    },
  },
  summaryAutocomplete:{
    '& .MuiInputBase-root':{
      "borderRadius": "2px",
      "margin": "2px 5px 2px 0px",
      "height": "30px",
      "minWidth": "250px",
      "fontSize": "1.1em",
      "padding":"0px 55px 0px 5px!important"

    },
    '& .MuiSelect-root':{
      "paddingTop":"0px",
      "paddingBottom":"0px",
    },
    '& .MuiInputBase-input':{
      "padding":"0px 5px !important",
      "fontSize":"0.9em"
    }
  },
  autoCompleteOptions:{
    fontSize:"0.8em"
  },
  loadingCol:{
    height: "220px",
    color: "#7c7c7c",
    border: "none",
    textAlign:"center",
    fontSize:"0.8rem" 
  },
  commentRow:{
    display:"flex"
  },
  aiProgress:{
    color:"#b3dce3",
    width:"50px",
    height:"50px",
  }
});

function ChatSummary(props) {
  const { classes, data } = props;
  const { me } = useUser();
  const ticket = data;
  const ticketId = ticket && ticket.ticketId;
  const counselorId = ticket && ticket.counselorId;

  
  // summary
  const [productGroup, setProductGroup] = useState('');
  const [productGroupName, setProductGroupName] = useState('');
  const [product, setProduct] = useState('');
  const [productName, setProductName] = useState('');
  const [symptom, setSymptom] = useState(null);
  const [subSymptom, setSubSymptom] = useState('');
  const [subSymptomName, setSubSymptomName] = useState('');
  const [costFree, setCostFree] = useState(null)
  const [counselResult, setCounselResult] = useState('')
  const [counselorComment, setCounselorComment] = useState('');
  
  // summary > select list
  const [listProductGroup, setListProductGroup] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listSymptom, setListSymptom] = useState([]);
  const [listSubSymptom, setListSubSymptom] = useState([]);

  const [model, setModel] = useState('')
  const [modelSearch, setModelSearch] = useState('');
  const [modelSearchResults, setModelSearchResults] = useState([]);
  const [seachLoading, setSearchLoding] = useState(false);

  const [loading, setLoading] = useState(false);
  const [aiErrMsg, setAiErrMsg] = useState("");
  
  const handleProductGroupChange = (event, child) => {
    console.log(`${event.target.value} is CLICKED!`)
    const productGroup = event.target.value
    setProductGroup(productGroup);
    setProductGroupName(child.props.name)
    setProduct("");
    setProductName("");
    setListProduct([]);
    setSymptom(null);
    setListSymptom([]);
    setSubSymptom("");
    setSubSymptomName("");
    setListSubSymptom([]);
    getSymptomProducts(['productCode','productName',], "", productGroup)
    .then(prodList=>{
      console.log("[prodList]", prodList);
      setListProduct(prodList.map(prodInfo=>{
        return {
          name: prodInfo.productName,
          code: prodInfo.productCode
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleProductChange = (event, child) => {
    const product = event.target.value
    console.log(`product : ${product}`)
    setProduct(product);
    setProductName(child.props.name)
    setSymptom(null);
    setListSymptom([]);
    setSubSymptom("");
    setSubSymptomName("");
    setListSubSymptom([]);
    getSymptomCodes(['productCode','productName','symptomCode','symptomName',],"",productGroup,product)
    .then(symptomList=>{
      console.log(symptomList);
      setListSymptom(symptomList.map(sympInfo=>{
        return {
          name: sympInfo.symptomName,
          code: sympInfo.symptomCode,
          productName: sympInfo.productName,
        } 
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleSymptomChange = (_, value) => {
    if(value){
      setSymptom(value);
      setSubSymptom("");
      setListSubSymptom([]);
      getSymptomCodeSubs(['productGroupCode','productGroupName','productCode','productName','symptomCode','symptomName','subSymptomCode','subSymptomName'],"",productGroup,product,value.code)
      .then(subSympList=>{
        console.log("[subSympList]", subSympList);
        setListSubSymptom(subSympList.map(subSympInfo=>{
          return {
            name: subSympInfo.subSymptomName,
            code: subSympInfo.subSymptomCode,
            productName:subSympInfo.productName,
            symptomName:subSympInfo.symptomName
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });
    };
  }
  const handleModelSearchChange = (e, newVal) =>{
    setModel(newVal)
  }
  const handleModelSearchInputChange = (e, newInputValue) =>{
    setSearchLoding(true)
    setModelSearch(newInputValue);

    if(newInputValue && newInputValue.length>2){
      AIMLSearchModel(newInputValue.toUpperCase())
      .then((result) => {
        console.log(">>>searchresult = ", result);
        setModelSearchResults(result.data.AIMLSearchModel)
      })
      .catch((e) =>{
        console.log("[get transfer info] Query Error", e)
      })
      .finally(() => {
        setSearchLoding(false);
      });
    }else{
      setSearchLoding(false);
    }
  }
  const fnMutationSendNoti = (message, notiType, ticketId) => {

    var contentJson = JSON.stringify({
      message: message,
      details:{ticketId:ticketId },
    })
    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });

  }
  const handleUpdate = () => {
    setLoading(true)
    console.log("update")
    console.log("counselorComment::::",counselorComment)
    console.log(" productGroupCode::::",productGroup)
    console.log(" productGroupName::::",productGroupName)
    console.log(" productCode::::",product)
    console.log(" productCodeName::::",productName)
    console.log(" symptomCode::::",symptom)
    console.log(" subSymptomCode::::",subSymptom)
    console.log(" subSymptomName::::",subSymptomName)
    console.log(" costFree::::",costFree)
    console.log(" counselResult::::",counselResult)
    console.log(" modelName", model)

    mutationUpdateTicketRecord(counselorComment, ticketId, costFree=="1"?true:costFree===null?null:false, counselResult, product, productGroup, 
    productGroupName, productName, subSymptom, symptom?symptom.code:"", symptom?symptom.name:"", subSymptomName, model, )
    .then((result) => {
      console.log("[update ticket log] Result", result);
      fnMutationSendNoti(  "상담 내용이 저장 되었습니다.", "info", ticketId );
    })
    .catch((error) => {
      console.log("[update ticket log] Error", error);
    })
    .finally(()=>{setLoading(false)});


  }
  const handleSubSymptomChange = (event, child) => {
    setSubSymptom(event.target.value);
    setSubSymptomName(child.props.name)
  };
  const handleCommentChange = (e) => {
    e.preventDefault();
    // const text = e.target.value.length > 260 ? e.target.value.substring(0, 260) : e.target.value;
    setCounselorComment(e.target.value);
  };
  const handleAIComment = () => {
    setAiLoad(true)
    AIMLSummarizeConversation(ticketId)
    .then((result)=>{
      console.log("[AIMLSummarizeConversation] Result", result);
      result && result.data && result.data.AIMLSummarizeConversation&& setCounselorComment(result.data.AIMLSummarizeConversation);
    })
    .catch((error) => {
      console.log("[AIMLSummarizeConversation] Error", error.errors);
      setAiErrMsg(" AI 자동 요약을 할 수 없습니다.")
      handleClickAiOpen()
    })
    .finally(()=>{
      setAiLoad(false)
    })
  }
  const fnGetTicketRecord = () => {
    setLoading(true)

    getTicketRecord(ticketId)
    .then((result) => {
      console.log("[get_ticket_log] query result : ", result.data)
      const record = result.data.getTicketRecord;
      const counselorComment = record.counselorComment;
      const productGroupCode = record.productGroupCode;
      const productCode = record.productCode;
      const productGroupName = record.productGroupName;
      const productName = record.productName;
      const symptomCode1 = record.symptomCode;
      const symptomName = record.symptomName;
      const subSymptomCode1 = record.subSymptomCode;
      const subSymptomName = record.subSymptomName;
      const costFree = record.costFree;
      const counselResult  = record.counselResult ;
      const modelName = record.modelName;

      console.log(" counselorComment::::",counselorComment)
      console.log(" productGroupCode::::",productGroupCode)
      console.log(" productCode::::",productCode)
      console.log(" symptomCode::::",symptomCode1, symptomName)
      console.log(" subSymptomCode::::",subSymptomCode1)
      console.log(" costFree::::",costFree)
      console.log(" counselResult::::",counselResult)
      console.log(" modelName::::",modelName)

      setModel(modelName);
      setProductGroup(productGroupCode);
      setProductGroupName(productGroupName);
      setCounselorComment(counselorComment);
      setCostFree(costFree);
      setCounselResult(counselResult);

      productGroupCode && productGroupCode.length && productCode && getSymptomProducts(['productCode','productName',], "", productGroupCode)
        .then(prodList=>{
          console.log("[prodList]", prodList);
          setListProduct(prodList.map(prodInfo=>{
            return {
              name: prodInfo.productName,
              code: prodInfo.productCode
            }
          }));
          setProduct(productCode);
          setProductName(productName);
          getSymptomCodes(['productCode','productName','symptomCode','symptomName',],"",productGroup, productCode)
            .then(symptomList=>{
              console.log("[symptomList]", symptomList);
              setListSymptom(symptomList.map(sympInfo=>{
                return {
                  name: sympInfo.symptomName,
                  code: sympInfo.symptomCode,
                  productName: sympInfo.productName,
                } 
            }));
            setSymptom({name: symptomName, code: symptomCode1, productName: productName}); 
            if (symptomCode1){
              getSymptomCodeSubs(['productGroupCode','productGroupName','productCode','productName','symptomCode','symptomName','subSymptomCode','subSymptomName'],"",productGroup,product,symptomCode1)
                .then(subSympList=>{
                  console.log("[subSympList]", subSympList);
                  setListSubSymptom(subSympList.map(subSympInfo=>{
                    return {
                      name: subSympInfo.subSymptomName,
                      code: subSympInfo.subSymptomCode,
                      productName:subSympInfo.productName,
                      symptomName:subSympInfo.symptomName
                    }
                  }))
                  setSubSymptom(subSymptomCode1);
                  setSubSymptomName(subSymptomName);
                })
                .catch(error => {
                  console.error(error)
                })
                .finally(()=>{
                  setLoading(false)
                })
          }
        })
        .catch(error => {
          console.error(error);
          setModel('');
          setProductGroup('');
          setProductGroupName('');
          setCounselorComment('');
          setCostFree('');
          setCounselResult('');
          setProduct('');
          setProductName('');
          setSymptom(null);
          setSubSymptom('');
          setSubSymptomName('');
        })
        .finally(()=>{
          setLoading(false)
        })

      })
    })
    .catch((e) => {
      console.log("[get_ticket_log] query error: ", e);
    })
    .finally(()=>{
      setLoading(false)
    })
  }
  
  useEffect(() => {
    fnGetTicketRecord();
  }, [ticket])

  useEffect(()=>{
    getSymptomProductGroups(['productGroupCode','productGroupName',])
    .then(prodGroupList=>{
      console.log("[prodGroupList]", prodGroupList);
      setListProductGroup(prodGroupList.map(prodGroupInfo=>{
        return {
          name:`${prodGroupInfo.productGroupName}`,
          code:`${prodGroupInfo.productGroupCode}`
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  },[]);

  const [aiLoad, setAiLoad] = useState(false);

  const [aiOpen, setAiOpen] = React.useState(false);
  const handleClickAiOpen = () => {
    setAiOpen(true);
  };
  const handleAiClose = () => {
    setAiOpen(false);
  };

  return (
    <div className={classes.root}>
      
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableBody className={classes.tbody}>
            {loading?
             <TableRow><TableCell colSpan={4} className={classes.loadingCol}><CircularProgress/></TableCell></TableRow>
             :<>
            <TableRow>
              <TableCell classes={{root:classes.td100}}>제품그룹</TableCell>
              <TableCell classes={{root:classes.td11}}>
              <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                <Select
                  id="demo-simple-select"
                  value={productGroup}
                  onChange={handleProductGroupChange}
                >
                  {listProductGroup.map(productGroupInfo=>{
                    return <MenuItem key={productGroupInfo.code} value={productGroupInfo.code} className={classes.summaryMenuItem} name={productGroupInfo.name}>
                        {productGroupInfo.name}({productGroupInfo.code})
                        </MenuItem>
                    })}
                </Select>
              </FormControl>
              </TableCell>
              <TableCell classes={{root:classes.td100}}>제품명</TableCell>
              <TableCell classes={{root:classes.td11}}>
                <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                    <Select
                      value={product}
                      onChange={handleProductChange}
                    >
                    {listProduct && listProduct.length 
                      ?
                      listProduct.map(productInfo=>{
                        return <MenuItem key={productInfo.code} value={productInfo.code} className={classes.summaryMenuItem} name={productInfo.name}>
                          {productInfo.name}({productInfo.code})
                          </MenuItem>
                        })
                      : <MenuItem value="" className={classes.summaryMenuItem}><em>제품 그룹을 선택해 주세요.</em></MenuItem>
                    }
                    </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{root:classes.td100}}>모델명</TableCell>
              <TableCell classes={{root:classes.td11}} colSpan={3}>
                <Autocomplete
                  className={classes.summaryAutocomplete}
                  classes={{popper:classes.autocompletePopper}}
                  options={modelSearchResults}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  loading={seachLoading}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span className={classes.autoCompleteOptions}>{option}</span>
                    </React.Fragment>
                  )}
                  value={model}
                  inputValue = {modelSearch}
                  onInputChange = {handleModelSearchInputChange}
                  onChange={handleModelSearchChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
            </TableRow>
            <TableRow>
              <TableCell classes={{root:classes.td100}}>증상검색</TableCell>
              <TableCell classes={{root:classes.td11}}>
                <Autocomplete
                  className={classes.summaryAutocomplete}
                  classes={{popper:classes.autocompletePopper}}
                  options={listSymptom}
                  getOptionLabel={(option) => option.name && `${option.name}(${option.productName})`}
                  // getOptionLabel={(option) => `${option.code}`}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span className={classes.autoCompleteOptions}>{option.name}({option.productName})</span>
                      {/* <span className={classes.autoCompleteOptions}>{option.code}</span> */}
                    </React.Fragment>
                  )}
                  value={symptom}
                  onChange={handleSymptomChange}
                />
              </TableCell>
              <TableCell classes={{root:classes.td100}}>세부증상</TableCell>
              <TableCell classes={{root:classes.td11}}>
                <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                    <Select
                    value={subSymptom}
                    onChange={handleSubSymptomChange}
                    >
                    { listSubSymptom && listSubSymptom.length?
                      listSubSymptom.map(subSymp=>{
                        return <MenuItem key={subSymp.code} value={subSymp.code} className={classes.menuItem} name={subSymp.name}>{subSymp.name}</MenuItem>
                      })
                      :<MenuItem value="" className={classes.menuItem}><em>고장증상을 선택해 주세요.</em></MenuItem>
                    }

                    </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell classes={{root:classes.td100}}>상담유형</TableCell>
              <TableCell colSpan={3} classes={{root:classes.td11}}>
                <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                  <Select>
                    <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                  <Select>
                    <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.summarySelectFormContol}>
                  <Select>
                    <MenuItem value="" className={classes.menuItem}>선택</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell classes={{root:classes.td100}}>유/무상</TableCell>
              <TableCell classes={{root:classes.td11}}>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="costFree" name="costFree" 
                  onChange={ e => { 
                    setCostFree(e.target.value); 
                    }} 
                  value={costFree?"1":costFree===null?undefined:"0"}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="유상" className={classes.summaryRadio}/>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="무상" className={classes.summaryRadio}/>
                  </RadioGroup>
                </FormControl>
              </TableCell>
              <TableCell classes={{root:classes.td100}}>처리결과</TableCell>
              <TableCell classes={{root:classes.td11}}>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="counselResult" name="counselResult" onChange={ e =>{ setCounselResult(e.target.value)}} value={counselResult}>
                    <FormControlLabel value="incomplete" control={<Radio color="primary" />} label="미완료" className={classes.summaryRadio}/>
                    <FormControlLabel value="complete" control={<Radio color="primary" />} label="완료" className={classes.summaryRadio}/>
                    <FormControlLabel value="etc" control={<Radio color="primary" />} label="기타" className={classes.summaryRadio}/>
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{root:classes.td100}}>상담내용</TableCell>
              <TableCell colSpan={3} classes={{root:classes.td11}}>
                <div className={classes.commentRow}>
                  <textarea className={classes.commentTextarea}
                  onChange={handleCommentChange} placeholder="상담요약을 입력하세요."
                  value={counselorComment}/>
                  <Button className={classes.aiBtn} onClick={handleAIComment}>
                    {aiLoad ?<CircularProgress className={classes.aiProgress}/>   : <span>AI 자동<br/>요약</span>}
                  </Button>
                </div>
                <Dialog
                  open={aiOpen}
                  onClose={handleAiClose}
                >
                  <DialogContent>
                    <DialogContentText>
                      {aiErrMsg}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleAiClose} color="primary" autoFocus>
                      확인
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableCell>
            </TableRow>
            </>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.updateDiv}>
        <Button className={classes.updateBtn} onClick={handleUpdate}> 수정 </Button>
      </div>
    </div>
  );
}
export default withStyles(styles)(ChatSummary);