import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Message from "./Message/Message";
import { useUser } from "../data/User";
import ConvertDialog from "./ConvertDialog";
import {
  Table, TableBody, TableRow, TableCell, TableContainer, Box,
  withStyles, Button, ButtonGroup, Checkbox, FormControlLabel, FormLabel, TextField,
  Radio, RadioGroup, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
  Typography, Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener, Select, FormControl
} from "@material-ui/core";
import{Autocomplete} from "@material-ui/lab";
import {
  getMessages,
  getTicket,
  getBranchPhrase,
  mutationSendMessage,
  subscribeNewMessages,
  subscribeTicketUpdated,
  mutationStartConversation,
  mutationEndConversation,
  mutationCloseTicket,
  mutationUpdateTicketRecord,
  subscribeNewNoti,
  subscribeTicketTransfer,
  mutationSendNoti,
  getCounselorMacro,
  getBranchMacro,
  getTransferInfo,
  mutationTicketTransferComplete,
  mutationTicketTransferCancel,
  getSymptomProductGroups,
  getSymptomProducts,
  getSymptomCodes,
  getSymptomCodeSubs,
  AIMLSearchModel,
  AIMLSummarizeConversation,
} from "../data/Loader";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { mkTXStr, mkTLStr, makeTXMessage, mkTypingStr } from "../data/DataConvert";
import ConvertDialogDomain from "./ConvertDialogDomain";
import { CircularProgress } from "@material-ui/core";
import ChatRecordDialog from "./ChatRecordDialog";

const MACKEY_ALT = 'alt'
const MACKEY_CTRL = 'ctrl'

const styles = (theme) => ({
  tabPanel: {
    height: "100%",
    marginTop: "0px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "17px",
    width: "100%"
  },
  chatContent: {
    overflowY: "auto",
    height: "393px",  
    background: "#ffffff",
    marginBottom: "10px",
    boxShadow: "1px 1px 5px #888",
    flexGrow: 1
  },
  chatContentDim: {
    overflowY: "auto",
    height: "393px",
    background: "#ffffff",
    marginBottom: "10px",
    boxShadow: "1px 1px 5px #888",
    opacity: 0.4,
    flexGrow: 1
  },
  form: {
    position: "relative"
  },
  macroList: {
    background: "#ffffff",
    maxHeight: "120px",
    width: "-webkit-fill-available",
    bottom: "80px",
    borderRadius: "8px 8px 0px 0px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    position: "absolute",
    margin: "8px",
    zIndex: 12,
    border: "1px dashed #0a8297"
  },
  macro: {
    margin: "4px",
    padding: "4px 8px",
    borderRadius: "8px",
    "&:hover": {
      background: theme.color.accent,
      color: "#fff"
    }
  },
  macroKey: {
    // color: "#0a8297"
    fontWeight: "bold"
  },

  inputChat: {
    width: "100%",
    height: "70px",
    display: "flex",
    boxSizing: "border-box",
    marginBottom: "15px",
  },
  chatTextarea: {
    width: "90%",
    marginRight: "10px",
    border: "0px #e8e8e8 solid",
    borderRadius: "2px",
    fontFamily: "inherit",
    boxShadow: "1px 1px 5px #888",
    "&:focus": {
      border: "1px #e8e8e8 solid",
      outline: "none",
    },
  },
  chatTextareaDim: {
    width: "90%",
    marginRight: "10px",
    background: "##f4f0f0",
    fontFamily: "inherit",
  },
  chatSendBtn: {
    width: "10%",
    background: theme.palette.primary.main, //'#bf134a',
    border: "0px",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px #888",
  },
  chatBtnGrp: {
    background: "#ffffff",
    border: "1px",
    borderRadius: "2px",
    margin: "3px",
    padding: "5px",
    boxShadow: "1px 1px 5px #888",
  },
  btnGroup1: {
    marginRight: "20px",
  },
  btnGroup2: {
    float: "right",
  },
  check: {
    color: "#616161",
  },
  startConv: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  errorMsgBox: {
    background: "#eeeeee",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    wordBreak: "break-all",
    // boxShadow: "1px 1px 5px #b9b9b9",
    color: "#949494",
    margin: "10px",
    padding: "7px",
    borderRadius: "10px"
  },
  messageDateLeft: {
    paddingLeft: "5px",
    fontSize: "0.7em",
    color: "#a29d9d",
    verticalAlign: "bottom",
  },
  messageDateRight: {
    paddingRight: "5px",
    fontSize: "0.7em",
    color: "#a29d9d",
    verticalAlign: "bottom",
  },
  alertText: {
    padding: "0px 24px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  groups: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "10px"
  },
  convertBtnGroup:{
  },
  convertBtn:{
    borderRight:"none !important",
    paddingRight: "0px",
  },
  convertInfoBtn:{
    background: "#017605",
    color: "#ffffff",
    borderRadius:"4px",
    "&:hover": {
      background: "#89bd8a",
      color: "#fff"
    },
    marginLeft: "10px"
  },
  convertBtnOption:{
    borderRadius:"4px 0px 0px 4px"
  },
  menuItem: {
    fontSize:"1.0em"
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #ececec",
    marginTop:"5px"
  },
  td100:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td120:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td11:{
    height:"32px",
    padding:"0px 10px",
    fontSize:"0.9em",
    width: "220px",
  },
  popper:{
    zIndex:1,
  },
});

const Input = withStyles(styles)(props => {
  const { classes, status, convId, counselorId, me} = props;
  const [input, setInput] = useState("");
  const [myTyping, setMyTyping] = useState(false);
  const [macroOpen, setMacroOpen] = useState(null);
  const [counselorMacro, setCounselorMacro] = useState([]);
  const [branchMacro, setBranchMacro] = useState([]);

  useEffect(() => {
    getMyMacro();
    loadBranchMacro();
  }, []);

  useEffect(() => {
    // console.log("myTyping:", myTyping, "input:", input);

    const resetTimer = () => {
      if (!window.livechat) window.livechat = {};
      if (window.livechat.myTypingTimer) clearTimeout(window.livechat.myTypingTimer);
      window.livechat.myTypingTimer = setTimeout(() => {
        console.log("myTypingTimer timer end");
        setMyTyping(false);
      }, 5000);
    };

    if (!myTyping && input && input.length > 0) {
      var contentJson = mkTypingStr();
      mutationSendMessage(contentJson, convId, counselorId)
      .then((result) => {
        console.log("[Send Typing] Result", result);
      })
      .catch((error) => {
        console.log("[Send Typing] Error", error);
      });
      setMyTyping(true);
      resetTimer();
    }
  }, [input]);

  const chatTextSubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    // e.preventDefault();
    const text =
      e.target.value.length > 260
        ? e.target.value.substring(0, 260)
        : e.target.value;
    setInput(text);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSend();
    }
    if (e.altKey || e.ctrlKey){
      if (macroOpen === null){
        let macroKey = e.altKey?MACKEY_ALT:MACKEY_CTRL;
        setMacroOpen(macroKey);
      }
      else {
        let macroArray = (macroOpen === MACKEY_ALT)?counselorMacro:branchMacro;
        const targetMacro = macroArray.find(macro => String.fromCharCode(e.keyCode).toLowerCase() === macro.key.split("+")[1]);
        if (targetMacro){
          setInput(JSON.parse(targetMacro.action).text);
          handleSend();
        }
      }
    }
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
    setMacroOpen(null);
  }

  const handleSend = () => {
    if (inputValidCheck(input)) {
      var contentJson = mkTXStr(counselorId, false, input);
      mutationSendMessage(contentJson, convId, counselorId)
        .then((result) => {
          console.log("[Send Message] Result", result);
          setInput("");
        })
        .catch((error) => {
          console.log("[Send Message] Error", error);
        });
    }
  };
  
  function inputValidCheck(inputStr) {
    let result = true;
    if (inputStr.trim().length <= 0) {
      result = false;
    }
    return result;
  }

  const getMyMacro = () => {
    getCounselorMacro(counselorId)
    .then((result) => {
      console.log("[get_counselorMacro] Query Result", result.data);
      setCounselorMacro(result.data.getCounselorMacro);
    })
    .catch((e) => {
      console.log("[get_counselorMacro] Query Error", e);
    })
  }
  const loadBranchMacro = () => {
    getBranchMacro(me.currentBranch)
    .then((result) => {
      console.log("[getBranchMacro] Query Result", result.data);
      setBranchMacro(result.data.getBranchMacro);
    })
    .catch((e) => {
      console.log("[getBranchMacro] Query Error", e);
    })
  }
  
  const handleClick = text => {
    setInput(text);
    setMacroOpen(null);
  }

  const macroArray = {
    [MACKEY_ALT]:counselorMacro,
    [MACKEY_CTRL]:branchMacro
  }  
  return (
    <form className={classes.form} onSubmit={chatTextSubmit}>
      {macroOpen  && 
        <div className={classes.macroList}>
          {macroArray[macroOpen] && macroArray[macroOpen].map(macro => 
            <div onClick={() => handleClick(JSON.parse(macro.action).text)} className={classes.macro}><span className={classes.macroKey}>{macro.key}</span> : {JSON.parse(macro.action).text}</div>  
          )}
        </div>
      }
      <div className={classes.inputChat}>
        <textarea
          className={status === 'status_followup' || status === 'status_closed' || (status === 'status_transfer' && counselorId!==me.counselorId)? classes.chatTextareaDim : classes.chatTextarea}
          disabled={status === 'status_followup' || status === 'status_closed' || (status === 'status_transfer' && counselorId!==me.counselorId) ? "disabled" : ""}
          placeholder="상담 내용을 입력해 주세요."
          value={input}
          onChange={handleChange}
          onKeyDown={handleEnter}
          onKeyUp={handleKeyUp}
        />
        <button
          className={classes.chatSendBtn}
          onClick={handleSend}
          type="submit"
          disabled={status === 'status_followup' || status === 'status_closed' || (status === 'status_transfer' && counselorId!==me.counselorId) ? "disabled" : ""}
        >
          보내기
        </button>
      </div>
    </form>
  )
});

function LiveChat(props) {
  const { classes, value, index, data, closeTicket, onMessage, updateStatus} = props;
  const ticket = data;
  const [message, setMessage] = useState({});
  const { me } = useUser();

  const branch = ticket && ticket.branch;
  const categoryId = ticket && ticket.categoryId;
  const convId = ticket && ticket.convId;
  const counselorId = ticket && ticket.counselorId;
  const created = ticket && ticket.created;
  const customerId = ticket && ticket.customerId;
  const description = ticket && ticket.description;
  const statusId = ticket && ticket.statusId;
  const ticketId = ticket && ticket.ticketId;
  const [status, setStatus] = React.useState(statusId);
  
  
  // console.log("LIVECHAT:", ticketId, status);
  const [convertDialogOpen, setConvertDialogOpen] = useState(false);
  
  const [convertDialogDomainOpen, setConvertDialogDomainOpen] = useState(false);
  const [transferInfo, setTransferInfo] = useState({transferStatus:"initial"});

  // const [chatRecordDialogOpen, setChatRecordDialogOpen] = useState(false);
  

  // 상태 리스트와 티켓 정보를 Query 한다.
  const getInfo = () => {
    if (!me.name) return;

    getMessages(convId, null, null, true, null)
      .then((result) => {
        console.log("[get_messages] Query Result", result.data);
        if (result.data) {
          const noneTypingMessage = result.data.getMessages.info.filter(message => {
            const contentJson = JSON.parse(message.contentJson);
            return contentJson.template_type !== "typing";
          });

          if(status==="status_transfer")  {
            const test = {
              id:"system",
              type:"info",
              template_type:"tx",
              mine:false, 
              text:" 티켓을 전달 중입니다...",
              date: "",
              loading: false,
              online: true
            }
            noneTypingMessage.push({contentJson:JSON.stringify(test)})
          }

          setMessage({ ...result.data.getMessages, info: noneTypingMessage});
        } 
      })
      .catch((e) => {
        console.log("[get_messages] Query Error", e);
      });
  };

  const markTransfer = () => {
    const transfer_message = {
      id:"system",
              type:"info",
              template_type:"tx",
              mine:false, 
              text:" 티켓을 전달 중입니다...",
              date: "",
              loading: false,
              online: true
    }
    setMessage( prev => ( prev ? 
                          {...prev, info:[ ...prev.info, {contentJson:JSON.stringify(transfer_message)}]}
                          : {info:[{contentJson:JSON.stringify(transfer_message)}]}
    )
    
    );
  }

  const unmarkTransfer = () => {
    setMessage((prev) => {
      prev.info = prev.info.filter(conversation => {
        const contentJson = JSON.parse(conversation.contentJson);
        return contentJson.type !== "info";
      });
      return { ...prev };
    });
  }
  const getTicketStatus = () => {

    getTicket(ticketId)
    .then((result) => {
      console.log("[get_ticket] Query Result", result.data);
      const orgStatus = result.data.getTicket.statusId;
      setStatus(orgStatus)

      if (orgStatus=="status_transfer" || orgStatus=="status_chat"){
        getTransferInfo("", "", "", "", ticketId)
        .then((result) => {
          console.log("[get transfer info] Query Result", result.data);

          if(result.data && result.data.getTransferInfo && result.data.getTransferInfo.length){
            const transfer = result.data.getTransferInfo[0]
            setTransferInfo(transfer);
            if(transfer.transferStatus==="transfer_reject"){
              updateStatus(index, "cancel");
            }else if (transfer.transferStatus==="transfer_complete"){
              updateStatus(index, "to");
            }
          }
        })
        .catch((e) =>{
          console.log("[get transfer info] Query Error", e)
        });
      }
    })
    .catch((e) => {
      console.log("[get_ticket] Query Error", e);
    });
  };

  const transferStatusList = {
    transfer_new: "티켓 전달 정보",
    transfer_accept : "(티켓 수락) 티켓 전달 완료 하기",
    transfer_reject : "티켓 거절",
    transfer_complete : "티켓 전달 완료"
  }
  const transferStatusNameList = {
    transfer_new: "티켓 전달",
    transfer_accept : "상담사 수락",
    transfer_reject : "상담사 거절",
    transfer_complete : "티켓 전달 완료"
  }
  

  const fnMutationCloseTicket = ticketId => {
    mutationCloseTicket(ticketId)
      .then(result => {
        console.log("[Close Ticket] Result", result);
        closeTicket();
      })
      .catch(error => {
        console.log("[Close Ticket] Error", error);
      })
  }

  useEffect(() => {
    getInfo();
  }, [ticket]);


  const [typing, setTyping] = useState(false);

  const setSubNewMsg = () => {

    const resetTimer = () => {
      if (!window.livechat) window.livechat = {};
      if (window.livechat.typingTimer) clearTimeout(window.livechat.typingTimer);
      window.livechat.typingTimer = setTimeout(() => {
        console.log("typing timer end");
        setMessage(prev => {
          prev.info = prev.info.filter(conversation => {
            const contentJson = JSON.parse(conversation.contentJson);
            return contentJson.template_type !== "typing";
          });
          return { ...prev };
        });
        setTyping(false);
      }, 5000);
    };

    const subscription = subscribeNewMessages(convId).subscribe({
      next: (result) => {
        console.log("[new message] Subscribe message Result", result);

        const contentJson = JSON.parse(result.value.data.newMessage.contentJson);
        if (contentJson.template_type.toLowerCase() === "typing") {
          if (result.value.data.newMessage.senderId !== me.counselorId) {
            if (!typing) {
              setMessage((prev) => {
                return {
                  ...prev,
                  info: [...prev.info, result.value.data.newMessage],
                };
              });      
              resetTimer();
              setTyping(true);
            }
          }
        } else {
          setMessage((prev) => {
            prev.info = prev.info.filter(conversation => {
              const contentJson = JSON.parse(conversation.contentJson);
              return contentJson.template_type !== "typing";
            });

            return {
              ...prev,
              info: [...prev.info, result.value.data.newMessage],
            };
          });
          // 내 메세지가 아닌 경우만 전달
          if (result.value.data.newMessage.senderId !== me.counselorId) onMessage(index);
          setTyping(false);
        }
      },
      error: (error) => {
        console.log("[initialize] Subscribe message Error", error);
      },
    });
    return subscription;
  }

  const setSubTicketStatus = () => {
    const subscription = subscribeTicketUpdated(ticketId)
    .subscribe({
      next: result => {
        console.log("[subscribeTicketUpdated] Subscribe Result", result);
        const statusId = result.value.data.ticketUpdated.statusId
        setStatus(statusId)
      },
      error: error => {
        console.log("[subscribeTicketUpdated] Subscribe Error", error);
      }
    });
    return subscription;
  }

  useEffect(() => {
    getTicketStatus();
    const subscription = setSubNewMsg();
    const ticketSubscription = setSubTicketStatus();
    const transferSubscription = setTicketTransferSubscription();

    // View 다시 생성할 경우 구독 취소
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if(ticketSubscription) {
        ticketSubscription.unsubscribe();
      }
      if (transferSubscription) {
        transferSubscription.unsubscribe();
      }
    };
  }, [ticket]);

  const setTicketTransferSubscription = () => {
    const subscription = subscribeTicketTransfer(ticket.ticketId)
    .subscribe({
      next:result =>{
          const transferInfo = result.value.data.ticketTransfer;
          console.log("[subscribeTicketTransfer] Subscribe Result", transferInfo);
          setTransferInfo(transferInfo);

          //transfer update  후에 status가 변경 됨
          getTicket(ticketId)
          .then((result) => {
            console.log("[get_ticket] Query Result", result.data);
            const orgStatus = result.data.getTicket.statusId;
            setStatus(orgStatus)
          })



          if (transferInfo.counselorIdFrom===me.counselorId){ //내가 보낸 티켓
            if (transferInfo.transferStatus==="transfer_new"){
              markTransfer();
            }

            if (transferInfo.transferStatus==="transfer_cancel"){
              unmarkTransfer();
            }

            if (transferInfo.transferStatus==="transfer_accept"){
              fnMutationSendNoti( transferInfo.counselorIdTo + "상담사가 티켓을 수락하였습니다.", "info", ticketId );
            }
            if (transferInfo.transferStatus==="transfer_reject"){
              updateStatus(index, "cancel");
              fnMutationSendNoti( transferInfo.counselorIdTo + "상담사가 티켓을 거절하였습니다.", "info", ticketId );
            }
          }
          // ticket not assigned (type=0)
          // if(transferInfo.transferStatus==="transfer_complete"){
          //   getInfo() 
          // }

          //ticket transfer tab: (type===0? getTicketsMine:setData{})
          if (["transfer_reject", "transfer_complete"].includes(transferInfo.transferStatus)  && ticketId === transferInfo.ticketId){
            closeTicket();
          }
      }
    });
    return subscription;
  }


  const fnMutationSendNoti = (message, notiType, ticketId) => {

    var contentJson = JSON.stringify({
      message: message,
      details:{ticketId:ticketId },
    })
    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });

  }

  const clickEndConversation = () => {
    getBranchPhrase(me.currentBranch, 'END_CONV')
      .then((result) => {
        console.log("[getBranchPhrase] Result", result);
        let endConvPhrase = result.data.getBranchPhrase?result.data.getBranchPhrase:"상담원과의 채팅 상담이 종료되었습니다.";
        const senderId = 'system';
        let contentJson = mkTXStr(senderId, false, endConvPhrase);
        mutationSendMessage(contentJson, convId, senderId)
          .then((result) => {
            console.log("[Send Message] Result", result);
          })
          .catch((error) => {
            console.log("[Send Message] Error", error);
          })
      })
      .catch((error) => {
        console.log("[getBranchPhrase] Error", error);
      })
      .finally(()=>{
        mutationEndConversation(convId)
          .then((result) => {
            console.log("[End Conversation] Result", result);
          })
          .catch((error) => {
            console.log("[End Conversation] Error", error);
          });
      });
  };
  const endConvByCloseTicket = () => {
    clickEndConversation();
    handleAlertClose();
  };

  const clickEndConvRequest = () => {
    var input =
      "궁금하신 사항은 해결되셨나요? 추가 문의가 없으시면 상담 종료 버튼을 눌러 상담을 종료해 주세요.";
    var contentJson = mkTLStr(
      counselorId,
      false,
      input,
      "상담 종료",
      "상담 종료",
      "endconv",
      ticketId
    );
    mutationSendMessage(contentJson, convId, counselorId)
      .then((result) => {
        console.log("[Send Message] Result", result);
      })
      .catch((error) => {
        console.log("[Send Message] Error", error);
      });
  };
  const clickCloseTicket = () => {
    console.log(status);
    if (status === "status_chat") {
      alertMsg(["상담이 종료되지 않았습니다.", "상담을 종료하시겠습니까?"]);
    } else if (status==="status_followup"){
      handleOpen();
    } else{
      fnMutationCloseTicket(ticketId);
      fnMutationSendNoti("상담이 종료되었습니다.", "info", ticketId );
    }
    // if(statusId==="status_followup"){

    // }

    //popup

  
  };

  //layer popup
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //simaple alert
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([]);
  const alertMsg = (msg) => {
    setMsg(msg);
    setAlertOpen(true);
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  

  


  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleConvertDialog = (open) => {
    setConvertDialogOpen(open);
  };

  const handleConvertDomainDialog = (open) => {
    setConvertDialogDomainOpen(open);
  };
  useEffect(scrollToBottom, [message]);


  const [buttonOpen, setButtonOpen] = React.useState(false);
  const [transferInfoModalopen, setTransferInfoModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setButtonOpen((prevOpen) => !prevOpen);
  };

  const options = ['전달', '이관'];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setButtonOpen(false);

    if (index==0){
      handleConvertDialog(true)
    }else{
      handleConvertDomainDialog(true)
    }

  };

  const handleBtnClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setButtonOpen(false);
  };

  const transferStatusBtnClick = ()  =>{
    setTransferInfoModalOpen(true);
  }

  const handleTransferCancel = () =>{
    console.log(transferInfo.requestId)
    mutationTicketTransferCancel(transferInfo.requestId)
    .then((result) => {
      console.log("[transfer cancel] Result", result);
      fnMutationSendNoti("티켓 전달 요청이 취소되었습니다.", "info", ticketId );
      updateStatus(index,"cancel")
    })
    .catch((error) => {
      console.log("[transfer cancel] Error", error);
      fnMutationSendNoti("티켓 전달 요청 취소가 실패했습니다.", "info", ticketId );
    })
  }

  const handleTransferInfoModalClose = () => {
    setTransferInfoModalOpen(false);
  }

  const handleConfirm = () => {
    handleTransferInfoModalClose();
    closeTicket();
  }

  const handleTransferComplete = () => {
    mutationTicketTransferComplete(transferInfo.requestId)
    .then((result) => {
      console.log("[transfer complete] Result", result);
      fnMutationSendNoti("상담사에게 티켓 전달을 완료하였습니다.", "info", ticketId );
    })
    .catch((error) => {
      console.log("[transfer complete] Error", error);
    })
  }

  return (
    <Box classes={{ root: classes.tabPanel }}>
      <div className={status === 'status_followup' || status === 'status_closed' || (status === 'status_transfer' && counselorId!==me.counselorId) ? classes.chatContentDim : classes.chatContent}>
        <div>
          {message &&
            message.info &&
            message.info.map((m, i) => {
              let str = m.contentJson;
              str = str.replace(/'/g, '"');
              try {
                let jsn = JSON.parse(str);
                return (
                  <Message key={`message-${i}`} data={jsn} />
                );
              } catch (e) {
                return (
                  <div className="wrapper right" key={`key-chat-message-${i}`}>
                    <div className={classes.errorMsgBox}>
                      <ErrorOutline className="errorIcon"></ErrorOutline>
                      <div>해당 메시지를 불러올 수 없습니다.</div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <div ref={messagesEndRef} />
      </div>
      
      <Input status={status} convId={convId} counselorId={counselorId} me={me}/>
      {status === "status_transfer" || ["transfer_new", "transfer_accept"].includes(transferInfo.transferStatus)
        ?
        counselorId === me.counselorId 
          ? 
          <div className={classes.groups}>
            <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            size="small"
            className={classes.btnGroup2}
          >
            {transferInfo.transferStatus==="transfer_new" && <Button onClick={handleTransferCancel} >티켓 전달 취소</Button>}
            <Button onClick={transferStatusBtnClick} >{ transferInfo &&transferStatusList[transferInfo.transferStatus]}</Button>
          </ButtonGroup>
          </div> 
          : ""
        : //status in (chat, followup, closed)
        <div className={classes.groups}>
          {/* 전달요청 | 상담종료요청 | 상담종료 buttongroup */}
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            size="small"
            className={classes.btnGroup2}
          >
            {/* 전달요청 > 전달/이관 button */}
            {status==="status_chat" &&
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" classes={{root:classes.convertBtnGroup}}>
              <Button className={classes.convertBtnOption}
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >전달요청
                <ArrowDropDown />
              </Button>
            </ButtonGroup>}
            {/* <Button onClick={clickEndConversation}>상담종료(임시)</Button> */}
            {status==="status_chat" && <Button onClick={clickEndConvRequest}>상담종료요청</Button>}
            {status!=="status_closed" && <Button onClick={clickCloseTicket}>상담종료</Button>}
          </ButtonGroup>

          {/* 전달 요청> 전달/이관 poper */}
          <Popper
          open={buttonOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleBtnClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem className={classes.menuItem}
                          key={option}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

        </div>
      }
        
        
      {/* 전달 팝업 */}
      <ConvertDialog
        open={convertDialogOpen}
        onClose={() => handleConvertDialog(false)}
        ticket = {ticket}
        updateStatus = {updateStatus}
        index = {index}
      />

      {/* 이관 팝업 */}
      <ConvertDialogDomain
        open={convertDialogDomainOpen}
        onClose={() => handleConvertDomainDialog(false)}/>

      {/* simple message alert */}
      <Dialog open={alertOpen} onClose={handleAlertClose}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.alertText}>
            {msg.map((m, i) => (
              <>
                <br />
                {m}
              </>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            취소
          </Button>
          <Button onClick={endConvByCloseTicket} color="primary" type="submit" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

     {/* 상담 record dialog */}
     <ChatRecordDialog ticket={ticket}
     closeTicket={closeTicket}
     handleClose={handleClose}
     open={open}
      />

      {/* 티켓 전달 정보 */}
      <Dialog
        open={transferInfoModalopen}
        onClose={handleTransferInfoModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          티켓 전달 정보
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>조직 (From)</TableCell>
                    <TableCell classes={{root:classes.td11}}>{transferInfo && transferInfo.branchFrom}</TableCell>
                    <TableCell classes={{root:classes.td120}}>조직 (To)</TableCell>
                    <TableCell classes={{root:classes.td11}}>{transferInfo && transferInfo.branchTo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>요청 메시지</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{transferInfo && transferInfo.comment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>받은 메시지</TableCell>
                    <TableCell  colSpan={3} classes={{root:classes.td11}}>{transferInfo && transferInfo.feedback}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>요청 상담사</TableCell>
                    <TableCell classes={{root:classes.td11}}>{transferInfo && transferInfo.counselorIdFrom}</TableCell>
                    <TableCell classes={{root:classes.td120}}>대상 상담사</TableCell>
                    <TableCell classes={{root:classes.td11}}>{transferInfo && transferInfo.counselorIdTo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>요청ID</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{transferInfo && transferInfo.requestId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120}}>티켓ID</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{transferInfo && transferInfo.ticketId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td120 }}>전달 상태</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{transferInfo && transferStatusNameList[transferInfo.transferStatus]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
      </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {transferInfo && transferInfo.transferStatus=="transfer_accept"?
          <Button onClick={handleTransferComplete} autoFocus>티켓 전달 완료 하기</Button>
          :
          <Button onClick={handleConfirm} autoFocus>확인</Button>
          }
          {/* <Button onClick={handleReject} autoFocus>거절</Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default withStyles(styles)(LiveChat);
