import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {

  }
});

function Management(props) {
  const { classes } = props;
  return (
    <>
      <div className={classes.root}>
        상담운영 페이지 입니다.
      </div> 
    </>
  );
}
export default withStyles(styles)(Management);