import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Typography, withStyles } from '@material-ui/core';
import Dashboard from '../monitor/Dashboard';

const styles = (theme) => ({
  root: {

  },
  title: {
    fontWeight: "bold",
    height: "45px"
  },
  appBar:{
    boxShadow:'none',
    backgroundColor:'#ffffff'
  },
  tabs:{
    minHeight:'35px'
  },
  tab:{
    padding:'5px 10px',
    minWidth:'100px',
    border: '1px solid #ffffff',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: '#ece6e6',
    fontSize: '0.75rem',
    color:'#000000',
    minHeight:'35px',
  },
  tabSelected:{
    color: theme.palette.primary.main
  },
  indicator: {
    background: theme.palette.primary.main
  }
});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function Monitor_CS(props) {
  const { classes } = props;
  const [select, setSelect] = useState(0);
  const tabs = [{
    title: "대시보드",
    index: 0
  }, {
    title: "진행중인 현황",
    index: 1
  }, {
    title: "카테고리별 현황",
    index: 2
  }, {
    title: "상담사별 현황",
    index: 3
  }, {
    title: "로그인/배분 현황",
    index: 4
  }];

  const handleChange = (_, value) => {
    setSelect(value);
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color={"primary"}>CS 톡 모니터링</Typography>
      <AppBar position="static" classes={{root:classes.appBar}}>
        <Tabs
          value={select}
          onChange={handleChange}
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}>
          {tabs.map((tab, index) => (
            <Tab
              key={`monitor_cs_tab_${tab.title}`}
              label={tab.title}
              index={index}
              classes={{root: classes.tab, selected:classes.tabSelected }} 
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, i) => (
        <Dashboard key={`monitor_cs_tabpanel_${i}`} index={i} value={select} />
      ))}
    </div> 
  );
}
export default withStyles(styles)(Monitor_CS);