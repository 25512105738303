import React, { useEffect, useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  withStyles,
  Table,TableBody,TableCell,TableContainer,TableHead,TableRow,
  CircularProgress, Button, 
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import moment from "moment";

import {
  getTicket,
  mutationAssignTicketToCounselor,
  mutationSendMessage,
  mutationSendNoti,
  mutationStartConversation,
  mutationTicketTransferAccept,
  mutationTicketTransferReject,
  mutationTicketTransferComplete,
  Query,
  QueryTickets,
  subscribeTicketStatusUpdated,
  subscribeNewTickets,
  subscribeTicketTransfer,
  subscribeTicketTransferTo,
  subscribeTicketUpdated,
  getTicketsMine,
  getBranchPhrase,
} from "../data/Loader";
import { useUser } from "../data/User";
import { mkTXStr } from "../data/DataConvert";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    fontFamily: "LGSmHaT",
    display: "flex",
    flexDirection: "column",
    color: "#8e8e8e",
    boxSizing: "border-box",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#ffffff",
  },
  tabSelected: {
    color: "red",
  },
  table: {
    // width: "",
    // minHeight:'100%',
    background: "#ffffff",
  },
  th: {
    // padding:'5px 10px',
    lineHeight: "1.0rem",
    fontWeight: "500",
    // backgroundColor: "red"
  },
  td: {
    // minWidth: "100px",
    width: "100px",
    padding: "5px 10px",
    lineHeight: "1.0rem",
  },
  title: {
    paddingLeft: "25px",
    height: "24px",
  },
  arrow: {
    position: "absolute",
  },
  tabs: {
    minHeight: "35px",
    // height:'30px'
  },
  tab: {
    padding: "5px 10px",
    minWidth: "100px",
    border: "1px solid #ffffff",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ece6e6",
    fontSize: "0.75rem",
    color: "#000000",
    minHeight: "35px",
  },
  panelContainer: {
    backgroundColor: "#ece6e6",
    padding: "10px",
    height: "200px",
    minHeight: "200px",
    borderRadius: "0px 10px 10px 10px",
    boxSizing: "border-box",
  },
  tabPanel: {
    height: "100%",
  },

  tableButton: {
    "&:hover": {
      backgroundColor: "#ece6e6",
    },
  },
  blink: {
    background: "#0a8297",
    color: "#fff",
    animationName: '$blinker',
    animationDuration: "1s",
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    opacity: 1
  },
  '@keyframes blinker': {
    '0%': { background: "#0a8297" },
    '50%': { background: "#ffffff" },
    '100%': { background: "#0a8297" }
  },
  '@keyframes newblinker': {
    "0%": { color: theme.palette.primary.main },
    "50%": { color: "#fff" },
    "100%": { color: theme.palette.primary.main },
  },
  newTicket: {
    color: theme.palette.primary.main,
    animationName: "$newblinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  indicator: {
    background: theme.palette.primary.main,
  },
  loading: {
    textAlign: "center"
  },
  loadingText: {
    marginLeft: "8px",
    color: theme.palette.primary.main,
  },
  textarea:{
    width: "100%",
    border: "1px #d9d8d8 solid",
    margin: "10px 0px",
    fontFamily: "inherit",
    "&:focus": {
      border: "1px #9f9f9f solid",
      outline: "none",
    },
    height: "5.0em",
  },
  dialogText:{
    fontSize: "1.0em",
    color: "#404040" 
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #ececec",
    height: "100%",
    overflowY: "auto",
  },
  td1:{
    height:"30px",
    padding:"0px 10px",
    width:"120px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td11:{
    height:"30px",
    padding:"0px 10px",
    fontSize:"0.9em",
    width: "150px",
  },
  td2:{
    fontSize:"0.9em",
    height:"300px"
  },
  td3:{
    fontSize:"0.9em",
    height:"30px",
    padding:"0px 10px",
    backgroundColor:"#ededed",
    borderBottom:"none",
  },
  td33:{
    fontSize:"0.9em",
    height:"30px",
    padding:"0px 10px",
    borderBottom:"none",
  },
});

const Ticket = withStyles(styles)((props) => {
  const { classes, data, status, categoryMap, handleClick, type, reload } = props;
  const [now, setNow] = useState(moment());
  const [category, setCategory] = useState(categoryMap[data.categoryId] && categoryMap[data.categoryId].name);
  const [ticket, setTicket] = useState(data);

  // console.log("ticket", ticket, "categoryMap", categoryMap, "currentCategory", currentCategory);

  useEffect(() => {
    // 1초 Tick 마다 대기 시간을 갱신한다.
    const timer = setInterval(() => {
      setNow(moment());
    }, 1000);

    const ticketSubscription = setSubscription();

    return () => {
      clearInterval(timer);
      if (ticketSubscription) ticketSubscription.unsubscribe();
    };
  }, []);

  const setSubscription = () => {
    const subscription = subscribeTicketUpdated(ticket.ticketId)
    .subscribe({
      next: result => {
        console.log("[Ticket subscribeTicketUpdated] Subscribe Result", result);
        const ticketUpdated = result.value.data.ticketUpdated;
        setTicket(ticketUpdated);
        setCategory(categoryMap[ticketUpdated.categoryId] && categoryMap[ticketUpdated.categoryId].name);
        reload()
      }, 
      catch: error => {
        console.log("[Ticket subscribeTicketUpdated] Subscribe Error", error);
    }});
    return subscription;
  }

  // Status List 에서 해당 아이디를 찾아 상태 이름을 반환한다.
  const getStatusName = (statusId) => {
    const target = status.find((item) => item.statusId === statusId);
    if (target) {
      return target.name;
    } else {
      return "Invalid";
    }
  };

  const click = () => {
    handleClick(ticket);
  };

  return (
    <TableRow className={classes.tableButton} onClick={click}>
      <TableCell
        classes={{
          root: [
            classes.td,
            ticket.statusId === "status_new" && classes.newTicket,
          ].join(" "),
        }}
      >
        {getStatusName(ticket.statusId).toUpperCase()}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>{category ? category : "기타"}</TableCell>
      <TableCell classes={{ root: classes.td }}>
        {moment(ticket.created * 1000).format("YYYY-MM-DD HH:mm")}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {moment
          .utc(now.diff(moment(ticket.created * 1000), "seconds") * 1000)
          .format("HH:mm:ss")}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>기타</TableCell>
      {type==="getTicketsAll" &&<TableCell classes={{ root: classes.td }}>{ticket.counselorId}</TableCell>}
      <TableCell classes={{ root: classes.td }}>
        {ticket.customerName}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.customerEmail}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.customerMobile}
      </TableCell>
      
    </TableRow>
  );
});

const TabPanel = withStyles(styles)((props) => {
  const { classes, value, index, data, me, reload, type, onTicketClick, onNextPage, loading, argus, tabClick } =
    props;

  const status = data && data.getStatusList;
  const tickets = data && data[type] && data[type].info;
  const categoryMap = data && Object.keys(data).length>0 && data.getCategoryList.reduce((newObj,categoryInfo)=>{
    newObj[categoryInfo.categoryId] = categoryInfo;
    return newObj
  },{});

  const scrollRef = useRef(null);

  const handleClick = (ticket) => {
    console.log("[NewTickets] handleClick - ticket", ticket, "me", me);

    if (type === "getTicketsNotAssigned") {
      mutationAssignTicketToCounselor(me.counselorId, ticket.ticketId)
        .then((result) => {
          console.log("[NewTicket] Ticket Click Result", result);

          mutationStartConversation(ticket.ticketId)
            .then(result => {
              let convId = result.data.startConversation.convId;
              getBranchPhrase(me.currentBranch, 'START_CONV')
                .then((result) => {
                  let startStr = result.data.getBranchPhrase?result.data.getBranchPhrase:"언제나 고객님과 함께하는 LG전자서비스 입니다. \n산업안전보건법에 따라 상담사 보호를 위한 조치가 시행되고 있습니다. 서로를 존중하는 말로 고객 응대 상담사를 보호해 주세요.";
                  const senderId = 'system';
                  let contentJson = mkTXStr(senderId, false, startStr);
                  mutationSendMessage(contentJson, convId, senderId)
                    .then((result) => {
                      console.log("[Send Message] Result", result);
                    })
                    .catch((error) => {
                      console.log("[Send Message] Error", error);
                    });
                })
                .catch((error) => {
                  console.log("[getBranchPhrase] Error", error);
                })
              console.log("[Start Conversation] Result", result);
              reload();
            })
            .catch(error => {
              console.log("[Start Conversation] Error", error);
            })
        })
        .catch((error) => {
          console.log("[NewTicket] Ticket Click Error", error);
        });
    } else if (type === "getTicketsAll" || type==="getTicketsMine") {
      onTicketClick(ticket);
    }
  };

  const table = tickets;

  const handleScroll = e => {
    e.preventDefault();
    if (e.target.scrollTop +  e.target.clientHeight >= e.target.scrollHeight && (type === "getTicketsAll" || type==="getTicketsMine")&& data[type].pageinfo.hasNextPage) {
      onNextPage(data[type].pageinfo.endCursor, type)
    }
  }

  const [argusKeyVisible, setArgusKeyVisible] = useState(false);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      index={index}
      onClick={e=>{tabClick &&tabClick(e);}}
    >
      {value === index && (
        <TableContainer className={classes.tableContainer} onScroll={handleScroll}> 
          <Table
            stickyHeader
            size={"small"}
            className={classes.table}
            aria-label="Service History"
          >
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.th }}>상태</TableCell>
                <TableCell classes={{ root: classes.th }}>제품</TableCell>
                <TableCell classes={{ root: classes.th }}>접수일자</TableCell>
                <TableCell classes={{ root: classes.th }}>대기시간</TableCell>
                <TableCell classes={{ root: classes.th }}>접수채널</TableCell>
                {type==='getTicketsAll' && <TableCell classes={{ root: classes.th }}>상담사</TableCell>}
                <TableCell classes={{ root: classes.th }}>고객명</TableCell>
                <TableCell classes={{ root: classes.th }}>이메일</TableCell>
                <TableCell classes={{ root: classes.th }}>
                  <span
                   onClick={()=>{  //argus 임시코드
                    setArgusKeyVisible(!argusKeyVisible);
                    navigator.clipboard.writeText("0159e088-3be4-1395-92b3-acf10853868a")
                    .then(() => {
                      let handle = window.open("http://jcloud.lge.com/apps/argus-ext/qa-example.html");
                    })
                    }
                  }
                  >전화번호</span>
                    {argusKeyVisible && <input type="text" 
                                          onChange={e=>{argus(e.target.value);console.log(e.target.value);}} 
                                          onKeyDown={e=>{(e.key==="Enter") && setArgusKeyVisible(false);}}
                                          />}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table &&
                table.map((ticket, i) => (
                  <Ticket
                    key={`ticekt_${ticket.ticketId}_${index}`}
                    index={i}
                    data={ticket}
                    status={status}
                    categoryMap={categoryMap}
                    handleClick={handleClick}
                    type={type}
                    reload={reload}
                  />
                ))}
                <div ref={scrollRef}/>
                {loading && 
                  <TableRow>
                    <TableCell colSpan={8} className={classes.loading}>
                      <CircularProgress size={"1rem"}/>
                      <span className={classes.loadingText}>Loading</span>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
});

const TransferTicket = withStyles(styles)((props) => {
  const { classes, ticket, status, handleClick } = props;
  const [now, setNow] = useState(moment());
  

  // useEffect(() => {
  //   // 1초 Tick 마다 대기 시간을 갱신한다.
  //   const timer = setInterval(() => {
  //     setNow(moment());
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // Status List 에서 해당 아이디를 찾아 상태 이름을 반환한다.
  const getStatusName = (statusId) => {
    const target = status.find((item) => item.statusId === statusId);
    if (target) {
      return target.name;
    } else {
      return "Invalid";
    }
  };

  const click = () => {
    handleClick(ticket);
  };

  const transferStatusNameList = {
    transfer_new: "티켓 전달",
    transfer_accept : "상담사 수락",
    transfer_reject : "상담사 거절",
    transfer_complete : "티켓 전달 완료"
  }


  return (
    <TableRow className={classes.tableButton} onClick={click}>
      <TableCell classes={{ root: classes.td }}>
        {ticket.branchFrom}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.branchTo}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.counselorIdFrom}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.counselorIdTo}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
       {transferStatusNameList[ticket.transferStatus]}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {ticket.comment}
      </TableCell>
      {/* <TableCell classes={{ root: classes.td }}>
        {moment(ticket.created * 1000).format("YYYY-MM-DD HH:mm")}
      </TableCell>
      <TableCell classes={{ root: classes.td }}>
        {moment
          .utc(now.diff(moment(ticket.created * 1000), "seconds") * 1000)
          .format("HH:mm:ss")}
      </TableCell> */}
    </TableRow>
  );
});

const TransferTabPanel = withStyles(styles)((props) => {
  const { classes, value, index, data, me, type, onTicketClick, onNextPage, loading, modalOpenStatus, modalOpen, tabClick } =
    props;

  const status = data && data.getStatusList;
  const tickets = data && data[type] ;

  const scrollRef = useRef(null);

  const handleClick = (ticket) => {
    console.log("[NewTickets] handleClick - ticket", ticket, "me", me);
    setTinfo(ticket)
    // setRequestId(ticket.requestId)
    handleClickOpen()
    
    getTicket(ticket.ticketId)
    .then((result) => {
      console.log("[get_ticket] Query Result", result.data.getTicket);
      onTicketClick(result.data.getTicket)

    })
    .catch((e) => {
      console.log("[get_ticket] Query Error", e);
    });
  };

  const table = tickets;

  const handleScroll = e => {
    e.preventDefault();
    if (e.target.scrollTop +  e.target.clientHeight >= e.target.scrollHeight && type === "getTicketsAll" && data[type].pageinfo.hasNextPage) {
      onNextPage(data[type].pageinfo.endCursor, type)
    }
  }


  const [open, setOpen] = useState(false);
  const [tInfo, setTinfo] = useState();
  const [feedback, setFeedback] = useState("");
  // const [requestId, setRequestId] = useState("");

  const handleClickOpen = () => {
    // console.log("handle click open")
    // setOpen(true);
    modalOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    modalOpen(false);
  };

  const fnMutationSendNoti = (message, notiType, ticketId) => {

    var contentJson = JSON.stringify({
      message: message,
      details:{ticketId:ticketId },
    })

    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });

  }

  const handleOk = () => {
    mutationTicketTransferAccept(feedback, tInfo.requestId)
    .then((result) => {
      console.log("[ticket transfer accept] Result", result);
      fnMutationSendNoti("티켓을 수락하였습니다.", "info", tInfo.ticketId );
    })
    .catch((error) => {
      console.log("ticket transfer accept] Error", error);
      fnMutationSendNoti("티켓 수락을 실패하였습니다.", "info", tInfo.ticketId );
    });

    handleClose();

  };
  const handleReject = () => {
    mutationTicketTransferReject(feedback, tInfo.requestId)
    .then((result) => {
      console.log("[ticket transfer reject] Result", result);
      fnMutationSendNoti("티켓을 거절하였습니다.", "info", tInfo.ticketId );
    })
    .catch((error) => {
      console.log("[ticket transfer reject] Error", error);
      fnMutationSendNoti("티켓 거절을 실패하였습니다.", "info", tInfo.ticketId );
    });
    handleClose();
  };

  const handleTextareaChange = (e) => {
    setFeedback(e.target.value);
  };
 
  const transferStatusNameList = {
    transfer_new: "티켓 전달",
    transfer_accept : "상담사 수락",
    transfer_reject : "상담사 거절",
    transfer_complete : "티켓 전달 완료"
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      index={index}
      onClick={e=>tabClick(e)}
    >
      <Dialog
        // open={open}
        open = {modalOpenStatus}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          상담사 전환 요청
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
          
          <TableContainer className={classes.tableContainer}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>조직 (From)</TableCell>
                    <TableCell classes={{root:classes.td11}}>{tInfo && tInfo.branchFrom}</TableCell>
                    <TableCell classes={{root:classes.td1}}>조직 (To)</TableCell>
                    <TableCell classes={{root:classes.td11}}>{tInfo && tInfo.branchTo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>요청 메시지</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{tInfo && tInfo.comment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>요청 상담사</TableCell>
                    <TableCell classes={{root:classes.td11}}>{tInfo && tInfo.counselorIdFrom}</TableCell>
                    <TableCell classes={{root:classes.td1}}>대상 상담사</TableCell>
                    <TableCell classes={{root:classes.td11}}>{tInfo && tInfo.counselorIdTo}</TableCell>
                  </TableRow>
                  {tInfo && tInfo.transferStatus!=="transfer_new" &&
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>Feedback</TableCell>
                    <TableCell  colSpan={3} classes={{root:classes.td11}}>{tInfo && tInfo.feedback}</TableCell>
                  </TableRow>
                  }
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>요청ID</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{tInfo && tInfo.requestId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>티켓ID</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}>{tInfo && tInfo.ticketId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root:classes.td1}}>전달 상태</TableCell>
                    <TableCell colSpan={3} classes={{root:classes.td11}}> {transferStatusNameList[tInfo && tInfo.transferStatus]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          </TableContainer>

          {tInfo && tInfo.transferStatus==="transfer_new"&& 
            <>
            <br/>
            전달 메시지: <textarea className={classes.textarea} onChange={handleTextareaChange} autofocus/>
            </>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {tInfo && tInfo.transferStatus === "transfer_new" 
            ? <>
              <Button onClick={handleOk} autoFocus>수락</Button>
              <Button onClick={handleReject} >거절</Button>
              </>
            : <Button onClick={handleClose} >확인</Button>}
        </DialogActions>
      </Dialog>

      {value === index && (
        <TableContainer className={classes.tableContainer} onScroll={handleScroll}>
          <Table
            stickyHeader
            size={"small"}
            className={classes.table}
            aria-label="Service History"
          >
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.th }}>조직 (From)</TableCell>
                <TableCell classes={{ root: classes.th }}>조직 (To)</TableCell>
                <TableCell classes={{ root: classes.th }}>상담사 (From)</TableCell>
                <TableCell classes={{ root: classes.th }}>상담사 (To)</TableCell>
                <TableCell classes={{ root: classes.th }}>티켓상태</TableCell>
                <TableCell classes={{ root: classes.th }}>전달내용</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table &&
                table.map((ticket, i) => (
                  <TransferTicket
                    key={`${ticket.ticketId}_${index}`}
                    index={i}
                    ticket={ticket}
                    status={status}
                    handleClick={handleClick}
                  />
                ))}
                <div ref={scrollRef}/>
                {loading && 
                  <TableRow>
                    <TableCell colSpan={8} className={classes.loading}>
                      <CircularProgress size={"1rem"}/>
                      <span className={classes.loadingText}>Loading</span>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
});


function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function NewTickets(props) {
  const { classes, onChange, onTicketClick, addNewTicket, argus } = props;
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const convertStatusName = {
    assigned: "할당됨",
    in_chat: "상담중",
    closed: "상담종료",
    followup: "후속조치",
    new: "New",
    in_transfer: "전달중"
  }

  let blink_states = [];
  const [blink, setBlink] = useState(blink_states);

  // modal open
  const [open, setOpen] = useState(false);
  const modalOpen  = (modalOpenStatus) =>{
    setOpen(modalOpenStatus);
  }

  const { me } = useUser();

  const [loading, setLoading] = useState(true);
  // 상태 리스트와 티켓 정보를 Query 한다.
  const getInfo = () => {
    if (!me.name) return;

    Query(["getStatusList", "getTicketsNotAssigned", "getTicketsAll", "getTransferInfo", "getCategoryList", "getTicketsMine"], {
      branch: me.currentBranch,
      nextCursor:null,
      orderAsc:false,
      resultLimit: 10, // 50
      transferStatus:  ["transfer_new", "transfer_accept"],
      counselorIdFrom: "",
      counselorIdTo: me.counselorId,
      requestId: "",
      ticketId: "",
      branchFrom: "",
      branchTo: me.currentBranch,
    })
      .then((result) => {
        
        result.data.getStatusList = result.data.getStatusList.map(statusInfo => {
          return {
            statusId: statusInfo.statusId,
            name: (statusInfo.name in convertStatusName) ? convertStatusName[statusInfo.name] : "Invalid"
          }
        });
        
        
        console.log("[get_tickets_not_Assigned] Query Result", result.data);
        setData(result.data);
      })
      .catch((e) => {
        console.log("[get_tickets_not_Assigned] Query Error", e);
      });

  };

  const setSubscription = () => {
    // 새로운 티켓 정보를 Subscribe 한다.
    console.log("setSubscription", me);
    let branch = me.currentBranch
    const subscription = subscribeNewTickets(branch).subscribe({ 
      next: async (result) => {
        console.log("[initialize] Subscribe Result", result);
        setData((prev) => {
          const newData = {
            ...prev,
            getTicketsNotAssigned:{
              info: [
                ...prev.getTicketsNotAssigned.info,
                result.value.data.newTicket
              ],
              pageinfo: prev.getTicketsNotAssigned.pageinfo
            }
          };
          console.log(newData);
          return newData;
        });
      },
      error: (error) => {
        console.log("[initialize] Subscribe Error", error);
      },
    });
    return subscription;
  };
  
  const setTicketTransferSubscription = () => {
    const subscription = subscribeTicketTransferTo(me.counselorId)
    .subscribe({
      next:result =>{
          console.log("[subscribeTicketTransferTo] Subscribe Result", result);
          const status = result.value.data.ticketTransfer.transferStatus;
          if(status == "transfer_new"){
            setData((prev) => {
              const newData = {
                ...prev,
                getTransferInfo:[
                    ...prev.getTransferInfo,
                    result.value.data.ticketTransfer
                ]
              };
              return newData;
            });
            setBlink(prev => {
              prev[3] = true;
              return [ ...prev ];
            });
          }else {//if(status=="transfer_complete" || status=="transfer_reject"){
            getInfo();
            modalOpen(false);
            
            //to상담사가 accept한 후 from상담사가 complete한 경우 livechat 탭 생성
            if(status=="transfer_complete"){
              //add chat tab....
              getTicket(result.value.data.ticketTransfer.ticketId)
              .then((result) => {
                console.log("[get_ticket] Query Result", result.data.getTicket);
                addNewTicket(result.data.getTicket);
                
              setBlink(prev => {
                prev[0] = true;
                return [ ...prev ];
              });

              })
              .catch((e) => {
                console.log("[get_ticket] Query Error", e);
              });
            }

          }
  }
    });
    return subscription;
  }

  useEffect(() => {
    getInfo();
    const subscription = setSubscription();
    const transferSubscription = setTicketTransferSubscription();

    // View 다시 생성할 경우 구독 취소
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }

      if (transferSubscription) {
        transferSubscription.unsubscribe();
      }
    };
  }, [me]);

  const handleTabClick = (e) => {
    let index = e.currentTarget.getAttribute("index");
    setBlink(prev => {
      prev[index] = false;
      return [ ...prev ];
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleNextPage = (cursor, type) => {

    setLoading(true);


    if (type==="getTicketsAll"){
      QueryTickets(["getTicketsAll"], {
        branch: me.currentBranch,
        nextCursor:cursor,
        orderAsc:false,
        resultLimit: 10 // 50
      })
        .then((result) => {
          console.log("[NewTickets] handleNextPage Query Result", result.data);
            setData((prev) => {
              const newData = {
                ...prev,
                getTicketsAll:{
                  info: [
                    ...prev.getTicketsAll.info,
                    ...result.data.getTicketsAll.info
                  ],
                  pageinfo: result.data.getTicketsAll.pageinfo
                }
              };
              return newData;
            });
            setLoading(false);
        })
        .catch((e) => {
          console.log("[NewTickets] handleNextPage Query Error", e);
            setLoading(false);
        });
    }else if (type==="getTicketsMine"){
      const status_arr = ["status_assigned", "status_new", "status_chat", "status_transfer", "status_followup", "status_closed"];
      getTicketsMine(me.currentBranch, status_arr, cursor, false, 10)
        .then((result)=>{
          console.log("[getTicketsMine] handlerNextPage Query Result", result.data);
          setData((prev) => {
            const newData = {
              ...prev,
              getTicketsMine:{
                info: [
                  ...prev.getTicketsMine.info,
                  ...result.data.getTicketsMine.info
                ],
                pageinfo: result.data.getTicketsMine.pageinfo
              }
            };
            return newData;
          });
          setLoading(false);
        })
        .catch(e=>{
          console.log("[getTicketsMine] Query Error", e);
          setLoading(false);
        });
    }

  }

  const tab_list = ["미분배 티켓", "나의 티켓", "전체 티켓", "전달 티켓"].map((item, i) => (
    <Tab
      // classes={{ root: classes.tab, selected: classes.tabSelected }}
      classes={{root: [classes.tab, blink[i] ? classes.blink : ""].join(" "), selected:classes.tabSelected, wrapper:classes.muiTab}}
      onClick={handleTabClick}
      label={item}
      key={`tab-ticket-key-${i}`}
      {...a11yProps(i)}
      index={i}
    />
  ));

  if (!me.name) return null;
  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          value={value}
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
          onChange={handleChange}
        >
          {tab_list}
        </Tabs>
      </AppBar>
      <div className={classes.panelContainer}>
        <TabPanel
          key="panel-key-0"
          index={0}
          value={value}
          data={data}
          me={me}
          reload={getInfo}
          type={"getTicketsNotAssigned"}
          argus={argus}
          tabClick={(e)=>{handleTabClick(e)}}
        ></TabPanel>
        <TabPanel
          key="panel-key-1"
          index={1}
          value={value}
          data={data}
          me={me}
          reload={getInfo}
          type={"getTicketsMine"}
          onTicketClick={onTicketClick}
          onNextPage={handleNextPage}
          loading={loading}
          argus={argus}
        ></TabPanel>
        <TabPanel
          key="panel-key-2"
          index={2}
          value={value}
          data={data}
          me={me}
          reload={getInfo}
          type={"getTicketsAll"}
          onTicketClick={onTicketClick}
          onNextPage={handleNextPage}
          loading={loading}
          argus={argus}
        ></TabPanel>
        <TransferTabPanel
          key="panel-key-3"
          index={3}
          value={value}
          data={data}
          reload={getInfo}
          type={"getTransferInfo"}
          onTicketClick={onTicketClick}
          me={me}
          modalOpenStatus = {open}
          modalOpen = {modalOpen}
          tabClick={(e)=>{handleTabClick(e)}}
        ></TransferTabPanel>
      </div>
    </div>
  );
}

export default withStyles(styles)(NewTickets);
