import { createContext, useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  getCounselorStatus,
  getMe,
  subscribeStatusUpdated,
  mutationUpdateCounselorStatus,
} from "./Loader";
import { CookiesProvider, useCookies } from "react-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useIdleTimer } from "react-idle-timer";

const UserContext = createContext();
const MeContext = createContext();
const StatusContext = createContext();

const UserProvider = (props) => {
  const { children } = props;
  const [userContext, setUserContext] = useState(UserContext);
  const [meContext, setMeContext] = useState(MeContext);
  const [statusContext, setStatusContext] = useState(StatusContext);
  var statusSubscription = null;

  const [cookies, setCookie, removeCookie] = useCookies([]);
  const history = useHistory();

  useEffect(() => {
    handleSSO();

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("[User.Provider] Login SUCCESS", user);
        setUserContext(user);
        handleGetMe();
        handleGetStatus(user.username);
      })
      .catch((error) => {
        console.log("[User.Provider] Login ERROR", error);
        handleLogout(
          () => {},
          () => {}
        );
      });

    return () => {
      console.log("[User.Provider] Initialize");
      setUserContext({});

      if (statusSubscription) {
        statusSubscription.unsubscribe();
      }
    };
  }, []);

  const handleSSO = () => {
    const id = cookies["dv_pica_sso_id"];
    const pw = cookies["dv_pica_emp_no"];

    console.log("cookies", id, pw);
    if (id && pw) {
      handleLogin(id, pw, () => {
        history.push("/chat");
      });
    } else {
      // window.location.href = 'https://dv-proxy-sso.lge.com/?redirect-url=https://livechat-st.lge.com';
    }
  };

  const handleGetMe = () => {
    getMe()
      .then((result) => {
        console.log("[User.Provider] Me Result", result);
        setMeContext(result.data.whoami);
      })
      .catch((error) => {
        console.log("[User.Provider] Me Error", error);
      });
  };

  const handleGetStatus = (username) => {
    getCounselorStatus(username)
      .then((result) => {
        console.log("[User.Provider] Status Result", result);
        setStatusContext(result.data.getCounselorStatus);
        statusSubscription = handleStatusUpdated(
          result.data.getCounselorStatus.branch,
          result.data.getCounselorStatus.counselorId
        );
      })
      .catch((error) => {
        console.log("[User.Provider] Status Error", error);
      });
  };

  const handleStatusUpdated = (branch, counselorId) => {
    console.log("[User.Provider] Status Updated Init", branch, counselorId);

    const subscription = subscribeStatusUpdated(branch, counselorId).subscribe({
      next: (result) => {
        console.log("[User.Provider] Status Updated Result", result);
        setStatusContext(result.value.data.counselorStatusUpdated);
      },
      error: (error) => {
        console.log("[User.Provider] Status Updated Error", error);
      },
    });
    return subscription;
  };

  const handleUpdateCounselorStatus = (status, force = false) => {
    console.log(
      "[User.Provider] Update Cousleor Status",
      meContext.counselorId,
      status
    );
    setStatusForced(force && status !== "status#online");

    mutationUpdateCounselorStatus(meContext.counselorId, status)
      .then((result) => {
        console.log("[User.Provider] Update Cousleor Status Result", result);
      })
      .catch((error) => {
        console.log("[User.Provider] Update Cousleor Status Error", error);
      });
  };

  const handleLogin = async (id, password, success = null, failure = null) => {
    // console.log("[handleLogin]", "id", id, "password", password);

    try {
      const user = await Auth.signIn(id, password);
      console.log("[handleLogin] SUCCESS", user);

      // 첫 로그인인 경우 비밀번호를 반드시 변경
      if (
        user.challengeName &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        if (success) success("change_password");
      }
      // 로그인 성공한 경우 '상담하기' 페이지 이동
      else {
        setUserContext(user);
        handleGetMe();
        handleGetStatus(user.username);
        if (success) success("chat");
      }
    } catch (error) {
      console.log("[handleLogin] Error", error);
      if (failure) failure(error);
    }
  };

  const handleChangePassword = (
    id,
    password,
    passwordNew,
    passwordNewConfirm,
    success,
    failure
  ) => {
    console.log(
      "[handleChangePassword]",
      id,
      password,
      passwordNew,
      passwordNewConfirm
    );

    // 로그인하고 비밀번호 변경하는 경우
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, password, passwordNew)
          .then((user) => {
            console.log(
              "[handleChangePassword] changePassword - SUCCESS",
              user
            );
            success("chat");
          })
          .catch((error) => {
            console.log("[handleChangePassword] changePassword - ERROR", error);
            failure();
          });
      })
      .catch((error) => {
        console.log(
          "[handleChangePassword] currentAuthenticatedUser - ERROR",
          error
        );
        // 로그인하지 않고 비밀번호 변경하는 경우
        Auth.signIn(id, password).then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, passwordNew)
              .then((user) => {
                console.log(
                  "[handleChangePassword] changePassword - SUCCESS",
                  user
                );
                success("chat");
              })
              .catch((error) => {
                console.log(
                  "[handleChangePassword] changePassword - ERROR",
                  error
                );
                failure();
              });
          } else {
            console.log("[handleChangePassword] changePassword - ERROR", user);
            failure();
          }
        });
      });
  };

  const handleLogout = (success, failure) => {
    // Proxy SSO 쿠키 삭제
    removeCookie("dv_pica_sso_id", { path: "/", domain: ".lge.com" });
    removeCookie("dv_pica_emp_no", { path: "/", domain: ".lge.com" });
    removeCookie("SMSESSION", { path: "/", domain: ".lge.com" });

    try {
      Auth.signOut();
      success();
      setUserContext({});
      setMeContext({});
    } catch (e) {
      failure(e);
    }
  };

  const onIdle = () => {
    console.log("onIdle", statusContext);
    if (!statusForced) {
      handleUpdateCounselorStatus("status#away");
    }
  };

  const onActive = (event) => {
    console.log("onActive", "status#online");
    if (!statusForced) {
      handleUpdateCounselorStatus("status#online");
    }
  };
  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: 10 * 1000 });
  const [statusForced, setStatusForced] = useState(false);

  return (
    <CookiesProvider>
      <UserContext.Provider
        value={{
          user: userContext,
          me: meContext,
          status: statusContext,
          login: handleLogin,
          changePassword: handleChangePassword,
          logout: handleLogout,
          updateCounselorStatus: handleUpdateCounselorStatus,
        }}
      >
        {children}
      </UserContext.Provider>
    </CookiesProvider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserContext.");
  }
  return context;
};

export { UserProvider, useUser };
