import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A8297", //"#bf134a"
    }
  },
  typography: {
    fontFamily: 
      '"LG"',
    button: {
      textTransform: 'none'
    }
  },
  font: {
    size10: ".625em",     // "10px"
    size11: ".6875em",    // "11px"
    size12: ".75em",      // "12px"
    size13: ".8125em",    // "13px"
    size13_5: ".84375em", // "13.5px"
    size14: ".875em",     // "14px"
    size15: ".9375em",    // "15px"
    size16: "1em",        // "16px"
    size17: "1.0625em",   // "17px"
    size18: "1.125em",    // "18px"
    font50: "3.125em",    // "50px"
  },
  color: {
    message: {
      background: '#b5b5b5',
      color: '#262626',
      date: "#7f7f7f"
    },
    accent: "#0a8297",
    select: {
      color: '#262626',
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          margin: 0,
          padding: 0
        },
        "html, body, #root": {
          height: "100%"
        },
        ul: {
          listStyle: "none"
        }
      }
    },
    MuiSvgIcon: {
      root: { verticalAlign: "middle" }
    }
  }
});

export default theme;