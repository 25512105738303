import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LeftTail from "../../../asset/img/bubble_point1.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "lottie-react";

const styles = (theme) => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
    whiteSpace: "pre-wrap",
    zIndex: 10,
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    padding: "9px 20px",
    maxWidth: "70%",
    zIndex: 10,
    // wordWrap: 'break-word',
    wordBreak: "break-all",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontFamily: "LGSmHaTL",
    "text-size-adjust": "auto",
    fontSize: theme.font.size15, // "15px",
  },
  leftTail: {
    zIndex: 11,
    margin: "6px -21px 0px 11px",
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "contain",
    width: "21px",
    height: "12px",
  },
  messageLoading: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Typing(props) {
  const { classes } = props;
  return (
    <div className={[classes.root, classes.leftContainer].join(" ")}>
      <div className={classes.leftTail} />
      <div className={[classes.message, classes.leftMessage].join(" ")}>
        <Lottie
          {...lottieTypingOptions}
          className={classes.messageLoading}
          width={50}
          height={22}
        />
      </div>
    </div>
  );
}
export default withStyles(styles)(Typing);
