import React, { useEffect, useState } from "react";
import { useUser } from "../data/User";
import { withStyles, Typography } from '@material-ui/core';
import { TreeItem, TreeView, Alert} from '@material-ui/lab';
import { ArrowDropDown, Search, ArrowRight, Message, Group, Person, Home, Folder, Assignment, Remove, People} from '@material-ui/icons';
import {getAssignableCounselor, getCategoryMap, mutationSendNoti, mutationTicketTransferRequest,
} from "../data/Loader";


const styles = (theme) => ({
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  icon:{
    fontSize: "1em",
    marginRight: "3px",
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
});

const MaterialIcon = ({ icon, props }) => {
  
  // somehow resolve this icon
  // let resolvedIcon = // ...
  return React.createElement(icon, props)
  };

      
  const findNodeById = (nodes, id) => {
    if (nodes=={}) return ;

    let result
    Object.keys(nodes).forEach((key) => {
      if (id === nodes[key].categoryInfo.categoryId)
        result =  nodes[key]
      else
        result = result || findNodeById(nodes[key].path, id)
    });
    
    return result
    
  }

const CounselorCategory = (props) => {
  const { classes , containCounselors, clickItem} = props;
  const { me } = useUser();

  const [categoryData, setCategoryData] = useState({});
  const [categoryExpanded, setCategoryExpanded] = useState([]);
  const [assignable, setAssignable] = useState([]);

  var categories = [];
  function getAllCategories(nodes){
      Object.keys(nodes).forEach((key)=>{
        categories.push(nodes[key].categoryInfo.categoryId)
        if(nodes[key].path && Object.keys(nodes[key].path).length!=0)
          getAllCategories(nodes[key].path) 
      });
    return categories;
  }

  function makeCounselorData(cns){ // -> {categoryId: [ ]}
    var json = {}
    cns.forEach((c)=>{
      c.category.forEach(cat=>{
        if(cat.categoryId in json ) 
          json[cat.categoryId].push(c)
        else 
          json[cat.categoryId] = [c]
      })
    })
    return json;
  }

  const makeData = (nodes, cns) => { //nodes:categorymap(node), cns:counselors(leaf)

    Object.keys(nodes).forEach((key)=>{
      var node = nodes[key];
      var categoryId = node.categoryInfo.categoryId;
        if (node.path && Object.keys(node.path).length==0){
          if (categoryId in cns){
            var temp = {};
            cns[categoryId].forEach((item)=>{temp[item.counselorId] = {"path":{}, "categoryInfo":{"categoryId":item.counselorId, "leaf":true, "info":item, "parentId":categoryId, "name":item.counselorId}}})
            node.path = temp
          }
        }else{
          if (node.path.categoryInfo)
            node.path.categoryInfo["parentId"] = categoryId;
          makeData(node.path, cns);
        }
    });

    return nodes;
  }

  const handleData = (node)=>{
    if (clickItem){
      clickItem(node)
    }
    
    let leaf = node.categoryInfo.leaf;
    if( leaf ){
      // setTableData([node && node.categoryInfo &&node.categoryInfo.info])
      
      let expanded = []
      let nodeId = node.categoryInfo && node.categoryInfo.parentId;
      let pNode = findNodeById(categoryData, nodeId);

      while (pNode != undefined ){
        expanded.push(pNode && pNode.categoryInfo && pNode.categoryInfo.categoryId)
        pNode = findNodeById(categoryData, pNode.categoryInfo.parentId)
      }

      setCategoryExpanded(expanded)
    }
  }
  const MyTreeItem = withStyles(styles)((props) => {
    const {classes, nodeKey, node,  handleClick, handleData} = props;
    function treeItemClick(e){
      // e.preventDefault();
      handleData(node)
    }

    return (
    <TreeItem key={nodeKey} 
      nodeId={node.categoryInfo.categoryId} 
      // handleData={handleData}
      onLabelClick={treeItemClick}
      label={
        <div className={classes.labelRoot}>
          {node.categoryInfo.leaf
            ? <MaterialIcon icon={Person} classes={{root:classes.icon}}/>
            : <MaterialIcon icon={Folder} classes={{root:classes.icon}}/>
          }
          <Typography variant="body2" className={classes.labelText}>
            {node.categoryInfo.name}
          </Typography>
        </div>
      }
      classes={{
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        label: classes.label,
      }}
    >
      {renderTree(node.path)}
    </TreeItem>
    )
  });
  const getCategory = () => {
    getCategoryMap(me.currentBranch)
    .then((result) => {
      console.log("[get category] Query Result", result.data);
      var data = JSON.parse(result.data.getCategoryMap )
      const all_cat = getAllCategories(data)
      
      containCounselors ?
      getAssignableCounselor(me.currentBranch,  all_cat)
      .then((result) => {
        console.log("[getAssignableCounselor] Query Result", result.data);
        var assignable_arr = result.data.getAssignableCounselor;
        var assignable_json = makeCounselorData(assignable_arr);
        var newData = makeData(data, assignable_json);
        setCategoryData(newData);
        setAssignable(assignable_arr);
      })
      .catch((e) => {
        console.log("[getAssignableCounselor] Query Error", e);
      })
      :setCategoryData(data)
    })
    .catch((e) => {
      console.log("[get category] Query Error", e);
    })
  }
  useEffect(() => {
    getCategory();
  }, [me]);

  const renderTree = (nodes) =>{  
    // console.log("[node]", nodes)
    if ( !nodes || Object.keys(nodes).length===0)
      return null;
    
    return(<>
      {Object.keys(nodes).map((key) => (
        <MyTreeItem 
          key={key} 
          nodeKey = {key}
          node = {nodes[key]}
          // nodeId={nodes[key].categoryInfo.id} 
          handleData={handleData} 
          // label={
          //   <div className={classes.labelRoot}>
          //     {nodes[key].categoryInfo.leaf
          //       ? <MaterialIcon icon={Person} classes={{root:classes.icon}}/>
          //       : <MaterialIcon icon={Folder} classes={{root:classes.icon}}/>
          //     }
          //     <Typography variant="body2" className={classes.labelText}>
          //       {nodes[key].categoryInfo.name}
          //     </Typography>
          //   </div>
          // }
        >
          {/* {renderTree(nodes[key].path)} */}
        </MyTreeItem>
      ))}
      </>
  );
    };
  return (
    <>
       <TreeView
                className={classes.treeViewRoot}
                defaultExpanded={categoryExpanded} 
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                {renderTree(categoryData)}
              </TreeView>
    </>
  );
}
export default withStyles(styles)(CounselorCategory);