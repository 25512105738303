import React from "react";
import TX from "./TX";
import SG from "./SG";
// import SL from "./SL";
// import CB from "./CB";
import TL from "./TL";
// import BC from "./BC";
import CL from "./CL";
import Typing from "./Typing";
import LO from "./LO";
import ProductCard from "./ProductCard";
import BC from "./BC";
// import HelpCard from "./HELPCARD";
// import EventCard from "./EventCard";
// import CareCard from "./CareCard";
// import TICard from "./TICard";
// import Debug from "./Debug";
// import DeviceGrid from "./DeviceGrid";
// import PC from "./PC";
import Info from "./Info"

function Message(props) {
  const { data } = props;
  const routeCarousel = (data) => {
    let template_sub_type = data.template_sub_type.toLowerCase();
    switch(template_sub_type){
      case 'contentlist':
        return <CL data={data}/>
      case 'productcard':
        return <ProductCard data={data}/>
      // case 'helpcard':
      //   return <HelpCard data={data}/>
      // case 'eventcard':
      //   return <EventCard data={data}/>
      // case 'carecard':
      //   return <CareCard data={data}/>
      // case 'ticard':
      //   return <TICard data={data}/>
      default :
        return <></>
    }
  }

  const getMessage = () => {
    if (data) {
      switch(data.type.toUpperCase()) {
        case 'TX': return <TX data={data}/>
        // case 'CB': return <CB data={data}/>
        case 'TL': return <TL data={data}/>
        // case 'SL': return <SL data={data}/>
        case 'SG': return <SG data={data}/>
        case 'BC': return <BC data={data}/>
        case 'CAROUSEL': return routeCarousel(data)
        case 'LO': return <LO data={data}/>
        // case 'RESERVE': return <RESERVE data={data}/>
        // case 'HELPCARD': return <HELPCARD data={data}/>
        // case 'PRODUCTCARD': return <ProductCard data={data}/>
        // case 'EVENTCARD': return <EventCard data={data}/>
        // case 'CARECARD': return <CareCard data={data}/>
        // case 'TICARD': return <TICard data={data}/>
        // case 'DEBUG': return <Debug data={data}/>
        // case 'GRID': return <DeviceGrid data={data}/>
        // case 'PC': return <PC data={data}/>
        case 'TYPING': return <Typing data={data}/>
        case 'INFO': return <Info data={data}/>
        default: return null;
      }
    }
  }

  return (
    <>
      {getMessage()}
    </>
  );
}
export default Message;
