import React, { useEffect, useState } from 'react';
import { useUser } from '../../data/User';
import { Table, TableBody, TableRow, TableCell, TableContainer, withStyles, Button } from '@material-ui/core';
import { getCustomer, getTicketRecord } from '../../data/Loader';
import moment from "moment";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import LaunchIcon from '@material-ui/icons/Launch';
import { Base64 } from 'js-base64';
import axios from 'axios';

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px 10px",
    // fontSize:"0.9em"
  },
  customerInfo:{
    overflowY:"auto"
  },
  tableContainer:{
    borderRadius:"0px",
    border:"1px solid #ececec",
    borderBottom:"none"
  },
  td1:{
    height:"30px",
    padding:"0px 10px",
    width:"80px",
    backgroundColor:"#f5f5f5",
    fontSize:"0.85em"
  },
  td11:{
    height:"30px",
    padding:"0px 10px",
    fontSize:"0.9em",
    width: "100px",
  },
  td2:{
    fontSize:"0.9em",
    height:"300px"
  },
  td3:{
    fontSize:"0.9em",
    height:"30px",
    padding:"0px 10px",
    backgroundColor:"#ededed",
    borderBottom:"none",
  },
  td33:{
    fontSize:"0.9em",
    height:"30px",
    padding:"0px 10px",
    borderBottom:"none",
  },
  breakAll:{
    wordBreak:"break-all"
  },
  subTitle:{
    margin:"20px 10px 8px 0px",
    fontWeight:"600",
    color:"#5a5a5a",
    display:"flex",
    alignItems:"center"
  },
  argusBtn:{
    border: "1px solid #d7d6d6",
    padding: "0px 5px",
    margin: "0px 5px",
    fontSize: "0.9em",
    fontWeight: "600",
    background: "#f7f6f6",
  }

});

function CustomerInfo(props) {
  const { classes, data, argusKey} = props;
  const { me } = useUser();
  // const [log, setLog] = React.useState({});
  const [info, setInfo] = React.useState({});
  const ticket = data;
  const ticketId = ticket && ticket.ticketId;
  const customerId = ticket && ticket.customerId;
  const chatStr = ticket && ticket.chatInfo;
  const deviceStr = ticket && ticket.deviceInfo;

  function encodeParam(paramStr){
    const paramBase64 = Base64.encodeURI(paramStr);
    return paramBase64;
  }

  // const fnGetTicketRecord = () => {
  //   console.log(ticketId)
  //   getTicketRecord(ticketId)
  //   .then((result) => {
  //     console.log("[get_ticket_log] query result : ", result.data)
  //     // setLog(result.data.getTicketRecord);
  //   })
  //   .catch((e) => {
  //     console.log("[get_ticket_log] query error: ", e);
  //   });
  // }
  // console.log("[argusKey]",argusKey)
  const fnGetCustomerInfo = () => {
    getCustomer(customerId)
    .then((result) => {
      console.log("[get customer info] query result : ", result.data)
      setInfo(result.data.getCustomer)
    })
    .catch((e) => {
      console.log("[get customer info] query error: ", e);
    });
  }

  // const parseInfo = (str) =>{
  //   let json = {};
  //   try{
  //       str.slice(1,str.length-1).split(", ").forEach( 
  //       (item) =>{
  //         const temp = item.split("=");
  //         json[temp[0]] = temp[1]
  //       });
  //   }catch(e){
  //     console.log("parse error ", e)
  //   }
  //   return json;
  // }
  let chatJson = {};
  let deviceJson = {};

  try{
   chatJson = chatStr ? JSON.parse(chatStr):{};
   deviceJson =deviceStr? JSON.parse(deviceStr):{};
  }catch(e){
     console.log("parse error ", e)
  }

  useEffect(() => {
    fnGetCustomerInfo();
  }, [ticket])

  //chat
  const chatbot_map = {cs:"CS챗봇", mkt:"MKT챗봇", thinq:"ThinQ챗봇", kakao:"카카오챗봇", res:"상담 예약 알림톡"}
  const domainId_map = {NODE0000000001:"서비스 상담사", NODE0000000002:"마케팅 상담사"}

  //device
  const write_by_map = {H:"수기 입력", Q:"QR Code 입력", A:"자동 입력"}

  const [argusKeyVisible, setArgusKeyVisible] = useState(false);

  const handleArgusBtnClick = () => {
    console.log("argus button click,", deviceJson);
    if (deviceJson && deviceJson.device_id ) {
      
      const device_id = "0159e088-3be4-1395-92b3-acf10853868a"
      let encodedDeviceId = encodeParam(device_id)

      // let requestUrl = "http://jcloud.lge.com/apps/argus-ext/api?type=key&encodedDeviceId="+encodedDeviceId;
      // let requestUrl = "https://qa-us-ext-argus.lge.com/device?key=ZWZhMTdjZDItMjM2ZS00MjA2LTkyZDQtMjFiNTEwNGQzNTlk"
      
      //temp
      let requestUrl = "https://qa-us-ext-argus.lge.com/device?key="+argusKey;
      window.open(requestUrl)

      // let requestUrl = "http://10.178.92.43:3020/argusurl";

      // axios.get(requestUrl)
      // .then((result)=>{ 
      //   console.log("[get argus info] result:",result.data) 
      // }) // 요청 성공시 실행코드
      // .catch((e)=>{
      //   console.log("[get argus info] error:", e)  
      // }) // 요청 실패시 실행코드
    }

  }

  return (
    <div className={classes.root}>
      <div className={classes.customerInfo}>
        <div className={classes.subTitle}> <ArrowRightIcon/>고객 정보</div>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>고객 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{info.name?info.name:"-"}</TableCell>
                <TableCell classes={{root:classes.td1}}>전화번호</TableCell>
                <TableCell classes={{root:classes.td11}}>{info.mobilephone?info.mobilephone:"-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>아이디</TableCell>
                <TableCell classes={{root:classes.td11}}>{info.customerId?info.customerId:"비회원"}</TableCell>
                <TableCell classes={{root:classes.td1}}>개인정보동의</TableCell>
                <TableCell classes={{root:classes.td11}}>Y</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>연령대</TableCell>
                <TableCell classes={{root:classes.td11}}>{info.ageGroup?info.ageGroup:"-"}</TableCell>
                <TableCell classes={{root:classes.td1}}>성별</TableCell>
                <TableCell classes={{root:classes.td11}}>{info.gender?(info.gender=="M"?"남":"여"):"unknown"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>생년월일</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}}>{info.birthdate?info.birthdate:"unknown"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>Email</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}}>{info.email?info.email:"unknown"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.subTitle}> <ArrowRightIcon/>상담 정보</div>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{root:classes.td1}}> 챗봇 서비스</TableCell>
                <TableCell classes={{root:classes.td11}}> {chatJson.chatbot? `${chatbot_map[chatJson.chatbot]} (${chatJson.chatbot})` : "-"}</TableCell>
                <TableCell classes={{root:classes.td1}}>도메인</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.domainId? `${domainId_map[chatJson.domainId]} (${chatJson.domainId})` : "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>ticket ID</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}}>{ticket.ticketId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>상담사 요청 고객 발화</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.chat_in}</TableCell>
                <TableCell classes={{root:classes.td1}}>제품 문의 유형</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.chatbot_node}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>제품 그룹 코드</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.product_group_code}</TableCell>
                <TableCell classes={{root:classes.td1}}>제품 그룹 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.product_group_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>제품 코드</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.product_code}</TableCell>
                <TableCell classes={{root:classes.td1}}>제품 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.product_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>증상 코드</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.symptom_code}</TableCell>
                <TableCell classes={{root:classes.td1}}>증상 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.symptom_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>세부 증상 코드</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.sub_symptom_code}</TableCell>
                <TableCell classes={{root:classes.td1}}>세부 증상 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{chatJson.sub_symptom_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>상담사 요청 전 대화 Flow</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}}>{chatJson.dialog_flow}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.subTitle}> <ArrowRightIcon/>기기 정보</div>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{root:classes.td1}}> device ID</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}}>
                  {deviceJson && deviceJson.device_id?deviceJson.device_id:"-"} 
                  {deviceJson && deviceJson.device_id && <Button className={classes.argusBtn} onClick={handleArgusBtnClick} title={argusKey}><LaunchIcon/> Go to Argus</Button>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}> 모델 이름</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson && deviceJson.sales_model?deviceJson.sales_model:"-"}</TableCell>
                <TableCell classes={{root:classes.td1}}> 모델 입력 구분</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson.write_by? `${write_by_map[deviceJson.write_by]} (${deviceJson.write_by})` :"-"} </TableCell>
              </TableRow>
              <TableRow>
              <TableCell classes={{root:classes.td1}}> 시리얼 번호</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson.serial_number?deviceJson.serial_number:"712KYRJ00070"}</TableCell>
                <TableCell classes={{root:classes.td1}}>구입일</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson.purchase_date?moment(deviceJson.purchase_date * 1000).format("YYYY-MM-DD"):"-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>구입처</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson.vendor?deviceJson.vendor:"-"}</TableCell>
                <TableCell classes={{root:classes.td1}}>워런티 기간</TableCell>
                <TableCell classes={{root:classes.td11}}>{deviceJson.warranty?`${deviceJson.warranty}개월`:"24개월"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{root:classes.td1}}>영수증 파일 URL</TableCell>
                <TableCell colSpan={3} classes={{root:classes.td11}} className={classes.breakAll}>
                  {deviceJson.receipt_url ? 
                    <a href={deviceJson.receipt_url} target="_blank" rel="noopener noreferrer" title={deviceJson.receipt_url}>  
                      {deviceJson.receipt_url.length>70? deviceJson.receipt_url.slice(0,70):deviceJson.receipt_url}...
                    </a>
                    :"-"
                  }
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default withStyles(styles)(CustomerInfo);