import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  withStyles,
  Menu,
  MenuItem,
  Fade,
  Divider,
  ListItemIcon,
  ListSubheader,
  Switch, Badge, List, ListItem,
  Grid,
} from "@material-ui/core";
import {
  AccountCircle,
  ArrowDropDown,
  FiberManualRecord,
  ExitToApp,
  Person,
  Group, Settings, Notifications, DoneAll, AccessTime,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "../data/User";
import { Check } from "@material-ui/icons";
import { mutationUpdateCounselor, subscribeNewNotibyBranch } from "../data/Loader";
import { ListItemText } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import moment from "moment";

const styles = (theme) => ({
  root:{
    background:"#fff",
    padding:"0px 15px"
  },
  status: {
    color: theme.color.message.color,
    backgroundColor: "#fff",
    minHeight: "40px",
    minWidth: "40px",
    borderRadius: "50%",
    // "&:hover":{
    //   backgroundColor:"#bdc9cb",
    // }
  },
  profile: {
    color: theme.color.message.color,
    backgroundColor: "#fff",
    minHeight: "48px",
    maxHeight: "48px",
    borderRadius: "0px",
    // "&:hover":{
    //   backgroundColor:"#bdc9cb",
    // }
  },
  profileText: {
    margin: "0px 10px",
    textTransform: "none"
  },
  loginMenu:{
    "& .MuiMenu-paper":{
      boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px",
      borderRadius: "12px"
    }
  },
  menu: {
    // width: "250px"
    // paddingLeft:"20px"
  },
  online: {
    color: theme.palette.primary.main
  },
  offline: {
    color: "grey"
  },
  away: {
    color: "#ed961c"
  },
  listSubHeader:{
    color: "#959595",
    fontWeight: 600,
    lineHeight: "30px",
    paddingTop :"3px",
  },
  groupIcon:{
    marginRight: "10px",
  },
  listItemIcon:{
    paddingLeft: "10px",
  },
  checkIcon:{
    width:"0.75em",
    height  :"0.75em",
  },
  nameArea:{
    display:"flex",
    "& .MuiTypography-root":{
      // lineHeight: 2,
    }
  },
  nameBtn:{
    padding: "0px 3px",
    border: "1px solid #0a8297",
    width: "auto",
    minWidth: "auto",
    height: "1.2rem",
    margin: "0px 3px",
    fontWeight: "600",
    color: "#0a8297",
    lineHeight: 0,
    alignItems: "center",
    "& .MuiButton-label":{
      // alignItems:"baseline"
      fontSize: "0.8em"
    }
  },
  menuIcon:{
    minWidth:"30px"
  },
  notiTitle:{
    justifyContent:"space-between"
  },
  noti:{
    backgroundColor:"#fff",
    minHeight:"40px",
    minWidth:"40px",
    borderRadius:"50%",
    color: "#adadad" /*"#329cafbf"*/,
    "&:hover":{
      color: theme.palette.primary.main
    }
  },
  notiBox:{
    display: "flex",
    alignItems: "center",
    padding: "10px 10px",
  },
  notiAvatarDiv:{
    marginLeft:"-10px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  notiAvatar:{
    backgroundColor: "#0a8297",
  },
  notiContentBox:{
    width:"500px",
    // display: "flex",
    alignItems: "center",
    padding: "10px 10px",
  },
  notiTime:{
    marginLeft:"10px",
    fontSize:"18px",
    color:"#cdcdcd",
  },
  notiTimeText:{
    fontSize:"14px",
  },
  boldText:{
    fontSize: "16px",
    fontWeight: "600",
    color: "#4a4a4a",
    lineHeight:"1.5",
  },
  plainText:{
    fontSize:"14px",
    color:"#959595",
    lineHeight:"2.0",
  },
  notiText:{
    display:"flex",
    fontSize:"14px",
    color:"#959595",
    lineHeight:"1.5",
    whiteSpace:"normal",
  },
  viewAll:{
    justifyContent:"center"
  },
  // notiBadge:{
  //   background:"red"
  // },
  divider:{
    margin: "0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    background:"none",
    borderStyle: "dashed",
  }
});

const Status = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusEl, setStatusEl] = useState(null);
  const [notiEl, setNotiEl] = useState(null);
  const { me, status, logout, updateCounselorStatus } = useUser();
  const history = useHistory();

  const [noti, setNoti] = useState();
  const [notiBadge, setNotiBadge] = useState(0);
  const [notiList, setNotiList] = useState();
  
  useEffect(() => {
    const notiSubscription = setSubNoti();
    // View 다시 생성할 경우 구독 취소
    return () => {
      if (notiSubscription) {
        notiSubscription.unsubscribe();
      }
    };
  }, [me]);

  const setSubNoti = () => {
    console.log("set sub noti")
    const subscription = subscribeNewNotibyBranch(
      me.currentBranch,
      "noti"
    ).subscribe({
      next: (result) => {
        console.log("[subscribeNewNotibyBranch] Subscribe Result", result);
        console.log(result.value.data.newNoti)
        const newNoti = result.value.data.newNoti
        setNotiList ( prev=>(
          prev?  [...prev, newNoti] :[newNoti]
        ))


        setNotiBadge( prev=>(prev+1))
        
      },
      error: (error) => {
        console.log("[subscribeNewNotibyBranch] Subscribe Error", error);
      },
    });
    return subscription;
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setStatusEl(null);
    setNotiEl(null);
  }

  const handleSetting = () => {
    
  }
  const handleLogout = () => {

    const success = () => {
      console.log('[handleLogout] Success!')
      history.push('/login');
    }

    const failure = (e) => {
      console.log('[handleLogout] Failure', e);
    }
    handleClose();
    logout(success, failure);
  }

  const handleClickStatus = (e) => {
    setStatusEl(e.currentTarget)
  }

  const handleChangeStatus = status => {
    updateCounselorStatus(status, true);
  }

  const handleClickBranch = (e, branch) =>{

    if (me.currentBranch != branch){
      console.log(branch);
      mutationUpdateCounselor(me.counselorId, branch, null, null, null, null, null)
      .then((result) => {
        console.log("[Update branch] Result", result);
        window.location.reload();
      })
      .catch((error) => {
        console.log("[Update branch] Error", error);
      });
    }

  }

  const handleClickNotiBtn = (e) =>{
    setNotiEl(e.currentTarget)
  }
  const handleNotiClick = (noti)=>{
    console.log("<<<<", noti, notiList)
    setNotiList( prev=> (
      prev.filter( p=> p!=noti)
    ))
    setNotiBadge( prev=> --prev)

  }
  function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
    (me.name && status.status) ? (
      <div className={classes.root}>

        <Button
          className={classes.status}
          onClick={handleClickStatus}
          transitioncomponent={Fade}>
          <AccountCircle className={status.status === 'status#online' ? classes.online : status.status === 'status#away' ? classes.away : classes.offline }/>
        </Button>
        <Button className={classes.noti}
          onClick={handleClickNotiBtn}
          transitioncomponent={Fade}>
          <Badge badgeContent={notiBadge} className={classes.notiBadge} color="error">
            <Notifications/>
          </Badge>
        </Button>
        <Button
          className={classes.profile}
          onClick={handleClick}
          transitioncomponent={Fade}
        >
          <div className={classes.profileText}>
            <div className={classes.nameArea}>
              <Typography component={"div"} variant={"caption"}>
                {me.name}
              </Typography>
              <Button className={classes.nameBtn}>{me.currentBranch.toUpperCase()}</Button>
            </div>
            <Typography component={"div"} variant={"caption"}>
              ({me.email})
            </Typography>
          </div>
          <ArrowDropDown />
        </Button>
        <Menu 
          className={classes.loginMenu}
          id={"status-menu"}
          anchorEl={statusEl}
          keepMounted
          open={Boolean(statusEl)}
          onClick={handleClose}
          onClose={handleClose}
          transitioncomponent={Fade}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          transformOrigin={{vertical:"top", horizontal:"right"}}>
          <MenuItem className={classes.menu} dense onClick={() => handleChangeStatus('status#online')}>
            <FiberManualRecord className={classes.online}/>
            Online
          </MenuItem>
          <MenuItem className={classes.menu} dense onClick={() => handleChangeStatus('status#away')}>
            <FiberManualRecord className={classes.away}/>
            Away
          </MenuItem>
          <MenuItem className={classes.menu} dense onClick={() => handleChangeStatus('status#offline')}>
            <FiberManualRecord className={classes.offline}/>
            Offline
          </MenuItem>
        </Menu>
        <Menu
          className={classes.loginMenu}
          id={"login-menu"}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          onClose={handleClose}
          transitioncomponent={Fade}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          transformOrigin={{vertical:"top", horizontal:"right"}}>
          <MenuItem className={classes.menu} dense>
            <ListItemIcon className={classes.menuIcon}>
              <Person fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary="로그인 현황" />
          </MenuItem>
          <MenuItem className={classes.menu} dense button component={Link} to={"/setting/personal"}>
            <ListItemIcon className={classes.menuIcon}>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="설정" />
          </MenuItem>
          <MenuItem className={classes.menu} dense onClick={handleLogout}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="로그아웃" />
          </MenuItem>
          <Divider />
          <MenuItem className={classes.menu} dense>
            <ListItemText primary="자동 배정 on/off" />
            <Switch defaultChecked />
          </MenuItem>
          <Divider />
          <ListSubheader className={classes.listSubHeader}><Group className={classes.groupIcon} fontSize="small"/>상담조직</ListSubheader>
          { me && me.branch &&  me.branch.map(branch => (
            <MenuItem className={classes.menu} dense onClick={e=>handleClickBranch(e, branch)}>
              {branch.toUpperCase()}
              {branch===me.currentBranch && (
                <ListItemIcon className={classes.listItemIcon}><Check className={classes.checkIcon} /></ListItemIcon>
              )}
            </MenuItem>
           ))}
        </Menu>
        <Menu
          className={classes.loginMenu}
          id={"noti-menu"}
          anchorEl={notiEl}
          keepMounted
          open={Boolean(notiEl)}
          onClose={handleClose}
          transitioncomponent={Fade}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          transformOrigin={{vertical:"top", horizontal:"right"}}>
          <MenuItem className={classes.notiTitle} dense onClick={handleClose}>
            <Box className={classes.notiBox}>
              <Box>
                <Typography className={classes.boldText}>Notifications</Typography>
                {notiBadge?<Typography className={classes.plainText}>{notiBadge}개의 알림이 있습니다.</Typography>
                :<Typography className={classes.plainText}>새로운 알림이 없습니다.</Typography>}
              </Box>
            </Box>  
            {notiBadge!=0 && 
            <Tooltip title="Mark all as read">
              <Button className={classes.noti}>
              <DoneAll/>
              </Button>
            </Tooltip>        }
          </MenuItem>
          <Divider className={classes.divider}/>
          {notiList && notiList.map(noti =>{
            console.log(noti)
            let jsn = null;
            try {
              jsn = JSON.parse(noti.contentJson);

            } catch (e) {
              jsn = {};
              jsn.message = "메시지를 불러 올 수 없습니다.";  
            }

            return(
              <MenuItem className={classes.menu} dense onClick={()=>handleNotiClick(noti)} button component={Link} 
              to={`/management/notice/${noti.notiId.split("#")&&noti.notiId.split("#").length>1?noti.notiId.split("#")[1]:""}`}>
              <Box className={classes.notiContentBox}>
                <Grid container>
                  <Grid item xs={2} className={classes.notiAvatarDiv}>
                    <Avatar className={classes.notiAvatar}>{noti.branch.toUpperCase()}</Avatar>
                  </Grid> 
                  <Grid item xs={10}>
                    <Typography className={classes.boldText}>[{noti.branch.toUpperCase()}][{jsn&&jsn.title}] </Typography>
                    <div className={classes.notiText}>
                      <span>{jsn&&jsn.content.replace(/<[^>]*>/g,'')}
                      <AccessTime className={classes.notiTime}/> 
                      <span className={classes.notiTimeText}> {timeSince(new Date(noti.created*1000))} ago </span>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </MenuItem>
            )
          })}
          <Divider className={classes.divider}/>
          <MenuItem className={classes.viewAll} dense onClick={handleClose} button component={Link} to={"/management/notice"}>
            <Box className={classes.notiContentBox}>
              {/* <Button> */}
                더보기
              {/* </Button> */}
            </Box>
          </MenuItem>
        </Menu>
      </div>
    ) : (
      <Button
        color="inherit"
        className={classes.login}
        component={Link}
        to={"/login"}
      >
        LOGIN
      </Button>
    )
  );
}
export default withStyles(styles)(Status);