const QUERY = {
  extGetGeocode: `extGetGeocode(address: $address, apiType: $apiType, countryCode: $countryCode, isPostalCode: $isPostalCode, language: $language)`,
  extSearchCSCenterOrBestshop: `extSearchCSCenterOrBestshop(
		searchType:$searchType,
		countryCode:$countryCode,
		latitude:$latitude,
		longitude:$longitude,
		limit:$limit
	)`,
  getStatusList: `getStatusList {
    name
    statusId
  }`,
  getTicketsNotAssigned: `getTicketsNotAssigned(branch: $branch,  nextCursor: $nextCursor, orderAsc: $orderAsc, resultLimit: $resultLimit) {
    info {
      __typename
      ... on Ticket {
        ticketId
        created
        assignedAt
        branch
        categoryId
        chatInfo
        chatUrl
        client
        convId
        counselorId
        customerEmail
        customerId
        customerMobile
        customerName
        description
        deviceInfo
        statusId
        userId
      }
    }
    pageinfo {
      endCursor
      hasNextPage
    }
  }`,

  whoami: `whoami {
    availCounselNum
    branch
    category {
      branch
      categoryId
    }
    cognitoId
    counselorId
    created
    currentBranch
    currentCounselNum
    department
    email
    maxCounselNum
    mobilephone
    name
    phone
    role {
      branch
      roleName
    }
  }`,
  getBranchPhrase: `getBranchPhrase(branch:$branch, phraseType: $phraseType)`,
  getCounselorStatus: `getCounselorStatus(counselorId: $counselorId) {
    tokenExpire
    status
    counselorId
    branch
  }`,

  getTicket:` getTicket(ticketId: $ticketId) {
    assignedAt
    branch
    categoryId
    chatInfo
    chatUrl
    client
    convId
    counselorId
    created
    customerEmail
    customerId
    customerMobile
    customerName
    description
    deviceInfo
    statusId
    ticketId
    userId
  }`,

  // getTicketsAll:` getTicketsAll(branch: $branch, categoryId: $categoryId) {
  //   ticketId
  //   customerId
  //   created
  //   categoryId
  //   description
  //   statusId
  //   convId
  //   branch
  //   counselorId
  // }`,
  getTicketsAll: `getTicketsAll(branch: $branch, nextCursor: $nextCursor, orderAsc: $orderAsc, resultLimit: $resultLimit) {
    info {
      __typename
      ... on Ticket {
        ticketId
        created
        assignedAt
        branch
        categoryId
        chatInfo
        chatUrl
        client
        convId
        counselorId
        customerEmail
        customerId
        customerMobile
        customerName
        description
        deviceInfo
        statusId
        userId
      }
    }
    pageinfo {
      endCursor
      hasNextPage
    }
  }`,

  getTicketRecord:` getTicketRecord(ticketId: $ticketId) {
    ticketId
    created
    counselorComment
    modelName
    productGroupCode
    productCode
    productGroupName
    productName
    symptomCode
    symptomName
    subSymptomCode
    subSymptomName
    costFree
    counselResult
  }`,
  
  getTicketsMine:`getTicketsMine (branch:$branch, statusId:$statusId, nextCursor: $nextCursor, orderAsc: $orderAsc, resultLimit: $resultLimit){
    info {
      __typename
      ... on Ticket {
        ticketId
        created
        assignedAt
        branch
        categoryId
        chatInfo
        chatUrl
        client
        convId
        counselorId
        customerEmail
        customerId
        customerMobile
        customerName
        description
        deviceInfo
        statusId
        userId
      }
    }
    pageinfo {
      endCursor
      hasNextPage
    }
  }`,
  
  getConversation:`getConversation(convId: $convId) {
    convId
    customerId
    ended
    counselorId
    ticketId
    created
  }`
,
  getMessages:`getMessages(convId: $convId, created: $created, nextCursor: $nextCursor, orderAsc: $orderAsc, resultLimit: $resultLimit) {
    info {
      ... on Message {
        msgId
        convId
        created
        senderId
        contentJson
        isSent
      }
    }
    pageinfo {
      endCursor
      hasNextPage
    }
  }`
  , 
  getNotis:`getNotis(recipientId:$recipientId) {
    notiId
    notiType
    branch
    recipientId
    senderId
    isRead
    created
    end
    contentJson
  }`
  ,
  getNotisByBranch:`getNotisByBranch(branch:$branch, notiType:$notiType) {
    notiId
    notiType
    branch
    recipientId
    senderId
    isRead
    created
    end
    contentJson
  }`
  ,
  getTicketHistoryList:`  getTicketHistoryList(ticketId: $ticketId, nextCursor: $nextCursor, orderAsc: $orderAsc, resultLimit: $resultLimit) {
    info {
      ... on Ticket {
        ticketId
        customerId
        created
        assignedAt
        branch
        categoryId
        description
        statusId
        counselorId
        convId
        chatUrl
        chatInfo
        customerName
        customerEmail
        customerMobile
        client
      }
    }
    pageinfo {
      endCursor
      hasNextPage
      totalCount
    }
  }`
  ,
  getCounselorMacro: `getCounselorMacro(counselorId: $counselorId) {
    macroId
    counselorId
    key
    description
    order
    action
  }`
  ,
  getCategoryMap:' getCategoryMap(branch: $branch) ',
  getCategoryList: ` getCategoryList(branch: $branch) {
    categoryId
    branch
    name
    editable
    order
    parentId
  }`,

  getAssignableCounselor: `getAssignableCounselor(branch: $branch, categoryId : $categoryId) {
    counselorId
    name
    phone
    mobilephone
    department
    email
    created
    maxCounselNum
    availCounselNum
    currentCounselNum
    cognitoId
    branch
    category {
      categoryId
      branch
    }
  }`,

  getCounselors:`  getCounselorList(branch: $branch) {
    counselorId
    name
    phone
    mobilephone
    department
    email
    created
    maxCounselNum
    availCounselNum
    currentCounselNum
    cognitoId
    currentBranch
    branch
    category {
      branch
      categoryId
    }
    role {
      branch
      roleName
    }
  }`,

  getCounselor:` getCounselor(counselorId: $counselorId, branch: $branch) {
    counselorId
    name
    phone
    mobilephone
    department
    email
    created
    maxCounselNum
    availCounselNum
    currentCounselNum
    cognitoId
    currentBranch
    isPWInit
    branch
    category {
      branch
      categoryId
    }
  }`,

  getRoleInfo: ` getRoleInfo(branch: $branch){
    branch
    roleName
  }
  `,
  
  getCustomer : ` getCustomer(customerId: $customerId) {
    customerId
    name
    phone
    mobilephone
    address
    email
    gender
    birthdate
    ageGroup
  }`,

  getTransferInfo : ` getTransferInfo(transferStatus: $transferStatus, counselorIdFrom: $counselorIdFrom, counselorIdTo: $counselorIdTo, requestId: $requestId, ticketId: $ticketId, branchFrom: $branchFrom, branchTo: $branchTo,) {
    branchFrom
    branchTo
    comment
    counselorIdFrom
    counselorIdTo
    feedback
    requestId
    ticketId
    transferStatus
  }`,

  getSearchModel: `AIMLSearchModel(searchModel: $searchModel)`,

  getAIMLSummarizeConversation: `AIMLSummarizeConversation(ticketId: $ticketId)`,

  getAIMLSearchSymptomSummaries:`AIMLSearchSymptomSummaries(searchTxt: $searchTxt) {
    symptomBranch
    contentId
    productCode
    productName
    productGroupName
    productGroupCode
    symptomName
    symptomCode
    subSymptomName
    subSymptomCode
    contentTitle
    videoYn
    updatedDate
    contentSummaryChatbot
  }`,

  searchHolidayInfo :` searchHolidayInfo(fromDate:$fromDate, toDate:$toDate, updatedBy:$updatedBy) {
      date
      dateName
      updatedBy
  }`,

  getBranchPhraseAll:`getBranchPhraseAll(branch: $branch){
    branch
    type
    typeName
    phrase
    description
  }`,

  getBranchMacro:`getBranchMacro(branch: $branch){
    macroId
    branch
    key
    action
    description
  }`,

  getBranchSetting:`getBranchSetting(branch: $branch, settingType:$settingType){
    branch
    settingType
    dataJson
  }`,
}


const MUTATION = {
  assignTicketToCounselor: `assignTicketToCounselor(counselorId: $counselorId, ticketId: $ticketId) {
    ticketId
    created
    branch
    categoryId
    description
    statusId
    chatUrl
    chatInfo
    deviceInfo
    client
    userId
    convId
    counselorId
    assignedAt
    customerId
    customerName
    customerEmail
    customerMobile
  }`,
  sendMessage: `sendMessage(contentJson:$contentJson , convId:$convId , senderId: $senderId ) {
    msgId
    convId
    created
    senderId
    contentJson
    isSent
  }`,
  startConversation: `startConversation(ticketId: $ticketId) {
    ticketId
    created
    branch
    categoryId
    description
    statusId
    chatUrl
    chatInfo
    deviceInfo
    client
    userId
    convId
    counselorId
    assignedAt
    customerId
    customerName
    customerEmail
    customerMobile
  }`,
  endConversation: `endConversation(convId: $convId) {
    ticketId
    customerId
    created
    assignedAt
    branch
    categoryId
    description
    statusId
    counselorId
    convId
    chatUrl
    chatInfo
    customerName
    customerEmail
    customerMobile
    client
  }`,
  closeTicket:`closeTicket(ticketId: $ticketId) {
    ticketId
    customerId
    created
    assignedAt
    branch
    categoryId
    description
    statusId
    counselorId
    convId
    chatUrl
    chatInfo
    customerName
    customerEmail
    customerMobile
    client
  }`,
  updateCounselorStatus: `updateCounselorStatus(counselorId: $counselorId, status: $status) {
    branch
    counselorId
    status
    tokenExpire
  }`,

  counselorBranchAdd:`counselorBranchAdd(branch: $branch, counselorId: $counselorId) {
    availCounselNum
    branch
    category {
      branch
      categoryId
    }
    cognitoId
    counselorId
    created
    currentBranch
    currentCounselNum
    department
    email
    isPWInit
    maxCounselNum
    mobilephone
    name
    phone
    role {
      branch
      roleName
    }
  }`,
  counselorCategoryAdd:`counselorCategoryAdd(branch: $branch, counselorId: $counselorId, targetCategoryId: $targetCategoryId)`,
  counselorCategoryDelete:`counselorCategoryDelete(branch: $branch, counselorId: $counselorId, targetCategoryId: $targetCategoryId)`,

  updateTicketRecord:` updateTicketRecord(counselorComment: $counselorComment , ticketId: $ticketId,
    costFree: $costFree, counselResult: $counselResult, productCode: $productCode, productGroupCode: $productGroupCode, productGroupName: $productGroupName, productName: $productName, 
    subSymptomCode: $subSymptomCode, symptomCode: $symptomCode, symptomName: $symptomName, subSymptomName: $subSymptomName, modelName: $modelName,
    ) {
      costFree
      counselResult
      counselorComment
      created
      productCode
      productGroupCode
      productGroupName
      productName
      subSymptomCode
      subSymptomName
      symptomCode
      symptomName
      ticketId
  }
  `,
  sendNoti:` sendNoti(contentJson: $contentJson, notiType: $notiType, recipientId: $recipientId, branch: $branch,  TTLinSec: $TTLinSec) {
    branch
    contentJson
    created
    end
    isRead
    notiId
    notiType
    recipientId
    senderId
  }`,
  updateCounselorMacro: `updateCounselorMacro(macroId: $macroId, action: $action) {
    action
    counselorId
    description
    key
    macroId
    order
  }`,
  ticketTransferRequest: `ticketTransferRequest(counselorIdTo: $counselorIdTo, ticketId: $ticketId, comment: $comment, branchFrom: $branchFrom, branchTo: $branchTo) {
    transferStatus
    ticketId
    requestId
    feedback
    counselorIdTo
    counselorIdFrom
    comment
    branchTo
    branchFrom
  }`,
  ticketTransferAccept: `ticketTransferAccept(feedback: $feedback, requestId: $requestId) {
    branchFrom
    branchTo
    comment
    counselorIdFrom
    counselorIdTo
    feedback
    requestId
    ticketId
    transferStatus
  }`,
  ticketTransferComplete: `ticketTransferComplete(requestId: $requestId) {
    branchFrom
    branchTo
    comment
    counselorIdFrom
    counselorIdTo
    feedback
    requestId
    ticketId
    transferStatus
  }`,
  ticketTransferReject: `ticketTransferReject(requestId: $requestId, feedback: $feedback) {
    branchFrom
    branchTo
    comment
    counselorIdFrom
    counselorIdTo
    feedback
    requestId
    ticketId
    transferStatus
  }`,
  
  categoryAdd: `categoryAdd(branch: $branch, categoryName: $categoryName, parentCategoryId: $parentCategoryId) {
    branch
    categoryId
  }`,
  categoryDelete: `categoryDelete(targetCategoryId: $targetCategoryId)`,
  ticketTransferCancel: `ticketTransferCancel(requestId: $requestId) {
    branchFrom
    branchTo
    comment
    counselorIdFrom
    counselorIdTo
    created
    end
    feedback
    requestId
    ticketId
    transferStatus
  }`,
  categoryUpdate: `categoryUpdate(targetCategoryId: $targetCategoryId, parentId: $parentId, categoryName: $categoryName) {
    branch
    categoryId
  }`,
  counselorRoleAdd: `counselorRoleAdd(branch: $branch, counselorId: $counselorId, roleName: $roleName){
    availCounselNum
    branch
    category {
      branch
      categoryId
    }
    cognitoId
    counselorId
    created
    currentBranch
    currentCounselNum
    department
    email
    maxCounselNum
    mobilephone
    name
    phone
    role {
      branch
      roleName
    }
  }`,
  counselorRoleDelete: `counselorRoleDelete(branch: $branch, counselorId: $counselorId, roleName: $roleName)`,

  counselorDelete: `deleteCounselor(counselorId: $counselorId)`,

  addHolidayInfo:`addHolidayInfo( branch: $branch, date: $date, dateName: $dateName){
    branch
    date
    dateName
    updatedBy
  }`,
  deleteHolidayInfo:`deleteHolidayInfo(date: $date, branch: $branch) {
    branch
    date
    dateName
    updatedBy
  }`,
  syncHolidayInfo :`syncHolidayInfo(year: $year) {
    date
    dateName
    updatedBy
  }`,
  updateBranchPhrase: `updateBranchPhrase(branch: $branch, phrase: $phrase, type: $type, description: $description) {
    branch
    description
    typeName
    type
    phrase
  }`,
  updateBranchMacro: `updateBranchMacro(action: $action, branch: $branch, description: $description,  key: $key) {
    action
    branch
    description
    key
    macroId
  }`,
  updateBranchSetting: `updateBranchSetting(branch: $branch, dataJson: $dataJson, settingType: $settingType) {
    branch
    settingType
    dataJson
  }`,
  createCounselor:` createCounselor(branch: $branch, categoryId: $categoryId,  counselorId: $counselorId, 
    department:$department, name: $name, roleName: $roleName,  email: $email, mobilephone: $mobilephone) {
      availCounselNum
      branch
      category {
        branch
        categoryId
      }
      cognitoId
      counselorId
      created
      currentBranch
      currentCounselNum
      department
      email
      isPWInit
      maxCounselNum
      mobilephone
      name
      phone
      role {
        branch
        roleName
      }
  }`,
}

const SUBSCRIBE = {
  ticketUpdated: `ticketUpdated(ticketId: $ticketId) {
    assignedAt
    branch
    categoryId
    chatInfo
    chatUrl
    convId
    counselorId
    created
    customerEmail
    customerId
    customerMobile
    customerName
    description
    statusId
    ticketId
  }`,
  myTicketUpdated: `ticketUpdated(counselorId: $counselorId, statusId:$statusId) {
    assignedAt
    branch
    categoryId
    chatInfo
    chatUrl
    client
    convId
    counselorId
    created
    customerEmail
    customerId
    customerMobile
    customerName
    description
    deviceInfo
    statusId
    ticketId
    userId
  }`,
  ticketStatusUpdated:`ticketUpdated(statusId: $statusId) {
    branch
    categoryId
    convId
    counselorId
    created
    customerId
    description
    statusId
    ticketId
  }`,
  newTicket: `newTicket(branch: $branch) {
    ticketId
    created
    branch
    categoryId
    description
    statusId
    chatUrl
    chatInfo
    deviceInfo
    client
    userId
    convId
    counselorId
    assignedAt
    customerId
    customerEmail
    customerMobile
    customerName


  }`,
  newMessage: `newMessage(convId: $convId) {
    msgId
    convId
    created
    senderId
    contentJson
    isSent
  }`,
  closeTicket:`closeTicket(branch:$branch, categoryId:$categoryId){
    ticketId
    customerId
    created
    branch
    categoryId
    description
    statusId
    counselorId
    convId
  }`,
  counselorStatusUpdated: `counselorStatusUpdated(branch: $branch, counselorId: $counselorId) {
    branch
    counselorId
    status
    tokenExpire
  }`,
  newNoti:`newNoti(branch: $branch, recipientId: $recipientId){
    branch
    contentJson
    created
    end
    isRead
    notiId
    notiType
    recipientId
    senderId
  }`,
  newNotibyBranch:`newNoti(branch: $branch, notiType: $notiType){
    branch
    contentJson
    created
    end
    isRead
    notiId
    notiType
    recipientId
    senderId
  }`,
  
  ticketTransferTo: `  ticketTransfer(counselorIdTo: $counselorIdTo) {
    branchFrom
    branchTo
    comment
    feedback
    counselorIdFrom
    counselorIdTo
    requestId
    ticketId
    transferStatus
  }`,
  ticketTransfer: `  ticketTransfer(ticketId: $ticketId) {
    branchFrom
    branchTo
    comment
    feedback
    counselorIdFrom
    counselorIdTo
    requestId
    ticketId
    transferStatus
  }`,
}



export {
  QUERY,
  MUTATION,
  SUBSCRIBE
}