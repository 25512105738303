import React, { useEffect, useState } from "react";
import { withStyles, Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useUser } from "../data/User";
import {
  getTicketsMine,
  subscribeMyTicketUpdated,
  subscribeCloseTicket,
  subscribeNewNoti,
  mutationSendNoti,
} from "../data/Loader";
import ChatTabPanel from "./ChatTabPanel";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { CallMade, CallReceived, Clear } from "@material-ui/icons";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useToasts } from "react-toast-notifications";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    margin: "0px",
    flex: "1",
    paddingTop: "5px",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#ffffff",
  },
  tabSelected: {},
  indicator: {
    background: theme.palette.primary.main,
  },
  tabPanel: {
    height: "100%",
    marginTop: "0px",
  },
  tabs: {
    minHeight: "35px",
    width: "100%",
  },
  tab: {
    padding: "5px 10px",
    minWidth: "100px",
    border: "1px solid #ffffff",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ece6e6",
    fontSize: "0.75rem",
    color: "#000000",
    minHeight: "35px",
  },
  panelContainer: {
    backgroundColor: "#ece6e6",
    padding: "10px",
    minHeight: "500px",
    borderRadius: "0px 10px 10px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    boxSizing: "border-box",
  },
  noContentContainer: {
    borderRadius: "5px",
  },
  chatContent: {
    overflowY: "auto",
    height: "400px",
    background: "#ffffff",
    marginBottom: "10px",
    boxShadow: "1px 1px 5px #888",
  },
  inputChat: {
    width: "100%",
    height: "70px",
    display: "flex",
    boxSizing: "border-box",
    marginBottom: "15px",
  },
  chatTextarea: {
    width: "90%",
    marginRight: "10px",
    border: "3px #e8e8e8 solid",
    borderRadius: "2px",
  },
  chatSendBtn: {
    width: "10%",
    background: "#bf134a",
    border: "0px",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px #888",
  },
  chatBtnGrp: {
    background: "#ffffff",
    border: "1px",
    borderRadius: "2px",
    margin: "3px",
    padding: "5px",
    boxShadow: "1px 1px 5px #888",
  },
  btnGroup1: {
    marginRight: "20px",
  },
  btnGroup2: {
    float: "right",
  },
  check: {
    color: "#616161",
  },
  startConv: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  noTicketMsg: {
    color: "#a8a8a8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chatTabPanel: {
    width: "100%",
  },
  muiTab: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  tabIcon: {
    fontSize: "1.0rem",
    marginTop: "3px",
    marginLeft: "3px",
  },
  alertText: {
    padding: "0px 24px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  tabsScroller: {
    overflow: "auto !important",
  },
  blink: {
    background: "#0a8297",
    color: "#fff",
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    opacity: 1,
  },
  "@keyframes blinker": {
    "0%": { background: "#0a8297" },
    "50%": { background: "#ffffff" },
    "100%": { background: "#0a8297" },
  },
  transferIconRed: {
    fontSize: "0.7rem",
    color: "red",
  },
  transferIconGreen: {
    fontSize: "0.7rem",
    color: "green",
  },
});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function ChatTab(props) {
  const { classes, type, ticket, transferredTicket, argusKey } = props;
  const [data, setData] = useState({});
  const tickets = data && data.getTicketsMine && data.getTicketsMine.info;

  const [value, setValue] = React.useState(0);
  const { addToast } = useToasts();
  const { me } = useUser();

  const getInfo = () => {
    if (!me.name) return;

    const status_arr = [
      "status_assigned",
      "status_chat",
      "status_followup",
      "status_transfer",
    ];

    if (type === 0) {
      getTicketsMine(me.currentBranch, status_arr)
        .then((result) => {
          let tickets = result.data;
          tickets.getTicketsMine &&
            tickets.getTicketsMine.info.sort(
              (a, b) => a.assignedAt - b.assignedAt
            );
          console.log("[initialize_get_tickets_mine] Query Result", tickets);
          setData(tickets);
          setValue(
            tickets &&
              tickets.getTicketsMine &&
              tickets.getTicketsMine.info.length > 0
              ? tickets.getTicketsMine.info.length - 1
              : 0
          );

          const blinks = [];
          tickets &&
            tickets.getTicketsMine.info.forEach(() => {
              blinks.push(false);
            });
          setBlink(blinks);
        })
        .catch((e) => {
          console.log("[initialize_get_tickets_mine] Query Error", e);
        });
    } else if (type === 1 || type === 2 || type === 3) {
      if (ticket) {
        setData({
          getTicketsMine: {
            info: [ticket],
          },
        });
      } else {
        setData({});
      }
      setValue(0);
    }

    console.log("[ChatTab] getInfo", type, ticket);
  };

  const setSubscription = () => {
    // Ticket update subscription
    const subscription = subscribeMyTicketUpdated(
      me.counselorId,
      "status_assigned"
    ).subscribe({
      next: (result) => {
        console.log("[ChatTab] Subscribe Ticket Updated by conselorId", result);
        setData((prev) => {
          console.log("setValue", prev.getTicketsMine);
          setValue(
            prev && prev.getTicketsMine && prev.getTicketsMine.info.length
          );
          return {
            ...prev,
            getTicketsMine: {
              info: [
                ...prev.getTicketsMine.info,
                result.value.data.ticketUpdated,
              ],
            },
          };
        });
      },
      error: (error) => {
        console.log(
          "[ChatTab] Subscribe Ticket Updated by conselorId Error",
          error
        );
      },
    });
    return subscription;
  };

  const setCloseTicketSubscription = () => {
    // Ticket update subscription

    const subscription = subscribeCloseTicket(
      me.currentBranch,
      me.categoryId
    ).subscribe({
      next: (result) => {
        console.log("[ChatTab] Subscribe Close Ticket ", result);
        result.value.data &&
          setData((prev) => {
            console.log(
              prev.getTicketsMine.info.filter(
                (a) => a.ticketId !== result.value.data.closeTicket.ticketId
              )
            );
            return {
              getTicketsMine: {
                info: prev.getTicketsMine.filter(
                  (a) => a.ticketId !== result.value.data.closeTicket.ticketId
                ),
              },
            };
          });
      },
      error: (error) => {
        console.log(
          "[ChatTab] Subscribe Ticket Updated by conselorId Error",
          error
        );
      },
    });
    return subscription;
  };

  useEffect(() => {
    getInfo();
    var subscription;
    var closeTicketSubscription;

    if (type === 0) {
      subscription = setSubscription();
      closeTicketSubscription = setCloseTicketSubscription();
    }
    // View 다시 생성할 경우 구독 취소
    return () => {
      if (type === 0) {
        if (subscription) {
          subscription.unsubscribe();
        }
        if (closeTicketSubscription) {
          closeTicketSubscription.unsubscribe();
        }
      }
    };
  }, [me, type, ticket]);

  useEffect(() => {
    const notiSubscription = setSubNoti();
    // View 다시 생성할 경우 구독 취소
    return () => {
      if (notiSubscription) {
        notiSubscription.unsubscribe();
      }
    };
  }, [me]);

  useEffect(() => {
    if (!tickets) return;
    setStatusInfo(
      tickets.map((t) =>
        t.statusId === "status_transfer"
          ? t.counselorId === me.counselorId
            ? "from"
            : "to"
          : ""
      )
    );
  }, [tickets]);

  useEffect(() => {
    if (type === 0) {
      transferredTicket &&
        setData((prev) => {
          console.log("setValue", prev.getTicketsMine);
          setValue(
            prev && prev.getTicketsMine && prev.getTicketsMine.info.length
          );
          return {
            ...prev,
            getTicketsMine: {
              info: [...prev.getTicketsMine.info, transferredTicket],
            },
          };
        });
      transferredTicket && handleMessage(panelList.length);
      transferredTicket &&
        fnMutationSendNoti(
          "티켓이 생성 되었습니다.",
          "info",
          transferredTicket.ticketId
        );
    }
  }, [transferredTicket]);

  const fnMutationSendNoti = (message, notiType, ticketId) => {
    var contentJson = JSON.stringify({
      message: message,
      details: { ticketId: ticketId },
    });
    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setSubNoti = () => {
    const subscription = subscribeNewNoti(
      me.currentBranch,
      me.counselorId
    ).subscribe({
      next: (result) => {
        console.log("[subscribeNewNoti] Subscribe Result", result);
        let jsn = null;
        try {
          jsn = JSON.parse(result.value.data.newNoti.contentJson);
        } catch (e) {
          jsn = {};
          jsn.message = "메시지를 불러 올 수 없습니다.";
        }
        addToast(jsn && jsn.message, { appearance: "info" });
      },
      error: (error) => {
        console.log("[subscribeNewNoti] Subscribe Error", error);
      },
    });
    return subscription;
  };

  const handleTabClick = (e) => {
    let index = e.currentTarget.getAttribute("index");
    setBlink((prev) => {
      prev[index] = false;
      return [...prev];
    });
  };

  const closeTicket = () => {
    if (type == 0) {
      getInfo();
      setValue(0);
    } else if (type == 1 || type == 2) {
      setData({});
    }
  };

  const [tabList, setTabList] = useState([]);
  const [panelList, setPanelList] = useState([]);
  let blink_states = [];

  const [blink, setBlink] = useState(blink_states);
  const [statusInfo, setStatusInfo] = useState([]);

  const handleMessage = (index) => {
    console.log("handleMessage", blink, index);
    setBlink((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  const updateStatus = (index, status) => {
    // console.log(index, status)
    setStatusInfo((prev) => {
      prev[index] = status;
      return [...prev];
    });
  };

  useEffect(() => {
    if (tickets) {
      setTabList(
        tickets.map((ticket, i) => {
          const customerName = ticket.customerName + " (ThinQ챗봇)"; // 임시 채널
          return (
            <Tab
              classes={{
                root: [classes.tab, blink[i] ? classes.blink : ""].join(" "),
                selected: classes.tabSelected,
                wrapper: classes.muiTab,
              }}
              onClick={handleTabClick}
              label={customerName}
              key={`chat-key-${i + 1}`}
              {...a11yProps(i)}
              index={i}
              icon={
                statusInfo[i] === "from" ? (
                  <CallMade className={classes.transferIconRed} />
                ) : statusInfo[i] === "to" ? (
                  <CallReceived className={classes.transferIconGreen} />
                ) : statusInfo[i] === "cancel" ? (
                  <Clear className={classes.transferIconRed} />
                ) : null
              }
            />
          );
        })
      );

      setPanelList(
        tickets.map((ticket, i) => {
          return (
            <ChatTabPanel
              className={classes.chatTabPanel}
              value={value}
              key={`panel-key-${i + 1}`}
              index={i}
              data={ticket}
              closeTicket={closeTicket}
              handleMessage={handleMessage}
              updateStatus={updateStatus}
              argusKey={argusKey}
              tabClick={(e) => {
                handleTabClick(e);
              }}
            />
          );
        })
      );
    }
  }, [tickets, value]);

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([]);
  const alertMsg = (msg) => {
    setMsg(msg);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
            scroller: classes.tabsScroller,
          }}
          value={value}
          onChange={handleChange}
          scrollButtons={false}
          allowScrollButtonsMobile
          visibleScrollbar={false}
        >
          {tabList}
        </Tabs>
      </AppBar>
      {panelList && panelList.length > 0 ? (
        <div className={classes.panelContainer}>{panelList}</div>
      ) : (
        <div
          className={[classes.panelContainer, classes.noContentContainer].join(
            " "
          )}
        >
          {type === 0 && (
            <div className={classes.noTicketMsg}>
              <ErrorOutline></ErrorOutline>진행중인 대화가 없습니다.
            </div>
          )}
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Alert:</DialogTitle> */}
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.alertText}>
            {msg.map((m, i) => (
              <>
                <br />
                {m}
              </>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withStyles(styles)(ChatTab);
