import React, { useState } from "react";
import { withStyles, Grid, AppBar, Tabs, Tab } from "@material-ui/core";
import LiveChat from "./LiveChat";
import Support from "./chatInfo/Support";
import ChatHistory from "./chatInfo/ChatHistory";

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  livechat: {},
  infosearch: {},
  serviceHistory: {},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  tabPanel: {
    width: "100%",
    height: "100%",
    // background: "#fff"
  },
  container: {
    width: "100%",
  },
  panel: {
    width: "100%",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  tabs: {
    minHeight: "35px",
    height: "35px",
  },
  tab: {
    minWidth: "90px",
    // border: '1px solid #827f80' ,
    backgroundColor: "#fff",
    fontSize: "0.75rem",
    color: "#000000",
    minHeight: "30px",
    height: "30px",
    boxShadow: "inset 0px 0px 9px 3px rgba(122,122,122,0.7)",
  },
  tabFirst: {
    borderRight: "none",
    borderRadius: "3px 0px 0px 3px",
  },
  tabCenter: {
    borderRight: "none",
    borderRadius: "0px 0px 0px 0px",
  },
  tabLast: {
    borderRadius: "0px 3px 3px 0px",
  },
  tabSelected: {
    color: "#fff",
    background: theme.palette.primary.main, //"#bf134a",
    fontWeight: 500,
    boxShadow: "none",
  },
  indicator: {
    background: "none",
  },
});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const Panel = withStyles(styles)((props) => {
  const { Component, key, index, value, data, argusKey } = props;

  return (
    index === value && <Component key={key} data={data} argusKey={argusKey} />
  );
});

const SupportPanel = withStyles(styles)((props) => {
  const { classes, tabs, data, argusKey } = props;
  const [select, setSelect] = useState(0);

  const handleChange = (_, value) => {
    setSelect(value);
  };

  return (
    <>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          centered
          value={select}
          onChange={handleChange}
          classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`chat_tab_${tab.title}`}
              label={tab.title}
              index={index}
              className={[tab.class, classes.tab].join(" ")}
              classes={{ selected: classes.tabSelected }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, i) => (
        <Panel
          key={`chat_tabpanel_${i}`}
          index={i}
          value={select}
          Component={tab.panel}
          data={data}
          argusKey={argusKey}
        />
      ))}
    </>
  );
});

function ChatTabPanel(props) {
  const {
    classes,
    value,
    index,
    data,
    closeTicket,
    handleMessage,
    updateStatus,
    argusKey,
    tabClick,
  } = props;

  const tabs = [
    {
      title: "상담정보",
      panel: ChatHistory,
      class: classes.tabFirst,
    },
    {
      title: "상담지원",
      panel: Support,
      class: classes.tabLast,
    },
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      index={index}
      onClick={(e) => tabClick(e)}
    >
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={12} md={6} className={classes.rightLayout}>
            <LiveChat
              data={data}
              closeTicket={closeTicket}
              index={index}
              onMessage={handleMessage}
              updateStatus={updateStatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.rightLayout}>
            <SupportPanel tabs={tabs} data={data} argusKey={argusKey} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(ChatTabPanel);
