import React, { useEffect, useState } from 'react';
import { withStyles, Typography, Box, MenuItem, ButtonGroup, Button, Select, Card, TextField, Paper, Switch,
TableContainer, Table, TableRow, TableCell, TableHead, TableBody,
Dialog, DialogContent, DialogTitle, DialogActions, FormControl,
} from '@material-ui/core';
import { ArrowRight, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardTimePicker,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TimePicker } from '@material-ui/pickers';
import { getBranchMacro, getBranchPhraseAll, getBranchSetting, mutationUpdateBranchMacro, mutationUpdateBranchPhrase, mutationUpdateBranchSetting } from '../data/Loader';
import { useUser } from '../data/User';
import { SettingsEthernet } from '@material-ui/icons';
const styles = (theme) => ({
  root: {},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
  },
  roleCard:{
    margin:"20px 0px",
    padding:" 20px"
  },
  detailBox:{
    padding:"10px 0px",
  },
  detailTitle:{
    marginTop:"30px",
    marginBottom:"5px",
    fontSize: "1.0em",
    fontWeight: 600,
    display:"flex",
    alignItems:"center",
    color:"#787281"
  },
  detailItemBox:{
    padding:"10px 0px",
    color:"#787281",
    fontWeight:400,
    fontSize:"1em",
    borderBottom:"1px solid #ececec",
    // "& span":{
    //   float:"right",
    //   fontWeight:400,
    //   marginRight:"5px"
    // },
  },
  // 인사말
  branchPhrase:{
    "& table":{
      // border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& td, th":{
      padding:"8px 20px",
      fontSize:"0.8rem",
      // border: "1px solid rgba(224, 224, 224, 1)",
    }
  },
  updateBranchPhraseBtn:{
    background: "#fff",
    color: "#0a8297",
    border: "1px solid",
    padding: "2px 10px",
    "& span":{
      marginRight:"0px"
    }
  },
  phrase:{
    width:"50%",
  },
  branchPhraseText2:{
    fontSize: "0.8em",
    paddingTop: "5px",
    fontWeight: "600",
  },
  commentTextarea:{
    borderRadius: "4px",
    marginRight: "-1px",
    width: "100%",
    "&:focus": {
      border: "1px solid #b6b6b6",
      outline: "none",
    },
    border: "1px solid #d7d7d7 !important",
    fontFamily: "inherit",
    padding: "5px",
    fontSize: "1.0em",
    minHeight: "1.5rem"
  },
  textfield1:{
    background: "#f7f7f7",
    color: "#747474",
  },
  textfield2:{
    color: "#747474",
    "& .MuiInputBase-input":{
      fontSize:'0.8em'
    }
  },
  dialogActions:{
    padding:"15px",
    "& button":{
      color:"#0A8297"
    }
  },

  // time picker
  timeContainer:{
    // border: "1px solid red"
    display:"flex",
  },
  timePicker:{
    minWidth: "150px",
    margin: "10px 20px"
  },
  lunchCheck:{
  },

  timeSaveBtn:{
    background: "#fff",
    color: "#0a8297",
    border: "1px solid",
    padding: "2px 10px",
    margin:"14px"

  },

  timeText:{
    display:"flex",
    alignItems:"center",
  }




});

function Management(props) {
  const { classes } = props;
  const { me } = useUser();

  const [branchPhraseData, setBranchPhraseData] = useState();
  const [branchPhraseDialogOpen, setBranchPhraseDialogOpen] = useState(false);
  const [branchPhraseRow, setBranchPhraseRow] = useState({});

  const [branchMacroData, setBranchMacroData] = useState();
  const [branchMacroDialogOpen, setBranchMacroDialogOpen] = useState(false);
  const [branchMacroRow, setBranchMacroRow] = useState({});

  const [branchSettingData, setBranchSettingData] = useState({
    LUNCH_WEEKDAY : {},
    LUNCH_WEEKEND : {enable:""},
    OP_HOUR_WEEKDAY : {},
    OP_HOUR_WEEKEND : {},
  });
  useEffect(
    () =>{

      // 인사말
      getBranchPhraseAll(me.currentBranch)
        .then((result)=>{
          console.log("[Get Branch Phrase All] Query Result", result.data);
          setBranchPhraseData(result.data.getBranchPhraseAll)
        })
        .catch((e)=>{
          console.log("[Get Branch Phrase All] Query Error" , e)
        });
      
      // 매크로
      getBranchMacro(me.currentBranch)
      .then((result)=>{
        console.log("[Get Branch Macro] Query Result", result.data);
        setBranchMacroData(result.data.getBranchMacro)
      })
      .catch((e)=>{
        console.log("[Get Branch Macro] Query Error" , e)
      });

      //근무시간
      getBranchSetting(me.currentBranch, "")
      .then((result)=>{
        console.log("[Get Branch Setting Query Result", result.data )
        const setting = result.data.getBranchSetting
        const data = {}
        setting.forEach(s => 
          {
            data[s.settingType] = JSON.parse(s.dataJson)
          }  
        )

        // console.log("converted:", data)
        setBranchSettingData(data)
      })
      .catch((e)=>{
        console.log("[Get Branch Setting] Query Error", e)
      });
    },
    [])
  
    // open branch phrase popup 
    const handleBranchPhrase = (row) => {
      setBranchPhraseDialogOpen(true);
      setBranchPhraseRow(row)
      console.log(row)
    }

    // submit branch phrase update
    const handleUpdateBranchPhrase = () => {
      console.log(">",branchPhraseRow);
      mutationUpdateBranchPhrase(branchPhraseRow.branch, branchPhraseRow.phrase, branchPhraseRow.type, branchPhraseRow.description)
      .then((result)=>{
        console.log("[Update Branch Phrase] Query Result", result.data);
        
        setBranchPhraseData(prv =>{
          const newState = prv.map(obj =>{
            if (obj.type==branchPhraseRow.type){
              return {...obj, phrase: branchPhraseRow.phrase}
            }
            return obj;
          });
          return newState;
        })
        setBranchPhraseDialogOpen(false);
        
      })
      .catch((e)=>{
        console.log("[Update Branch Phrase All] Query Error" , e)
      });

    }

    //on change: branch phrase textfield
    const handlePhraseUpdate = (e) => {
      console.log(e.target.value)
      setBranchPhraseRow(
        prev=>({
          ...prev,
          phrase:e.target.value
        })
      )
    }


    // open branch Macro popup 
    const handleBranchMacro = (row) => {
      setBranchMacroDialogOpen(true);
      // row.action = JSON.parse(row.action).text
      setBranchMacroRow(row)
      console.log(row)
    }

    // submit branch Macro update
    const handleUpdateBranchMacro = () => {
      console.log(">",branchMacroRow);
      mutationUpdateBranchMacro(branchMacroRow.action, branchMacroRow.branch, branchMacroRow.description, branchMacroRow.key)
      .then((result)=>{
        console.log("[Update Branch Macro] Query Result", result.data);
        setBranchMacroData(prv =>{
          const newState = prv.map(obj =>{
            if (obj.macroId==branchMacroRow.macroId){
              return {...obj, action: branchMacroRow.action}
            }
            return obj;
          });
          return newState;
        })
        setBranchMacroDialogOpen(false);
        
      })
      .catch((e)=>{
        console.log("[Update Branch Macro All] Query Error" , e)
      });

    }

    //on change: branch Macro textfield
    const handleMacroUpdate = (e) => {
      console.log(e.target.value)
      setBranchMacroRow(
        prev=>({
          ...prev,
          action: JSON.stringify({text:e.target.value})
        })
      )
    }

    const handleChangeTime = (e, settingType, fromto) =>{
      setBranchSettingData(
        prev=>({
          ...prev,
          [settingType]:{...prev[settingType], [fromto]:e.target.value}
        })
      )
    }

    const handleChangeSwitch = (e) =>{
      setBranchSettingData(
        prev=>({
          ...prev,
          LUNCH_WEEKEND:{...prev.LUNCH_WEEKEND, enable:e.target.checked}
        })
      )

      const orgData = branchSettingData.LUNCH_WEEKEND
      orgData.enable = e.target.checked
      const dataJson = JSON.stringify(orgData)
      updateBranchSetting(me.currentBranch, dataJson, "LUNCH_WEEKEND")
    }

    const updateBranchSetting = (branch, dataJson, settingType) => {
      mutationUpdateBranchSetting(branch, dataJson, settingType)
      .then((result)=>{
        console.log("[Update Branch Setting] Query Result", result.data);
        return true
      })
      .catch((e)=>{
        console.log("[Update Branch Setting] Query Error" , e)
        return false
      });

    }

    const handleTimeSave = ( settingType) =>{
      const orgDataJson = branchSettingData[settingType]
      const dataJson = JSON.stringify(orgDataJson)
      updateBranchSetting(me.currentBranch, dataJson , settingType)
    }

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} color={"primary"}>
          기타 관리
        </Typography>
        <div className={classes.subTitle}>
          <ArrowRight color="primary" />
          <Typography variant={"subtitle2"}> 근무시간 관리</Typography> 
        </div>
        {/* <Card className={classes.roleCard}> */}
            <Box className={classes.detailBox}>
              <Box className={classes.detailItemBox}>
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>주중 업무시간</Typography>
                <form className={classes.timeContainer} noValidate>
                  <TextField
                    id="workingday_start"
                    label="업무 시작시간"
                    type="time"
                    value={branchSettingData.OP_HOUR_WEEKDAY && branchSettingData.OP_HOUR_WEEKDAY.from}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "OP_HOUR_WEEKDAY", "from")}
                  />  <span className={classes.timeText} >~</span> 
                  <TextField
                    id="workingday_end"
                    label="업무 종료시간"
                    type="time"
                    value={branchSettingData.OP_HOUR_WEEKDAY && branchSettingData.OP_HOUR_WEEKDAY.to}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "OP_HOUR_WEEKDAY", "to")}
                  />
                  <Button className={classes.timeSaveBtn} onClick={()=>handleTimeSave("OP_HOUR_WEEKDAY")}>Save</Button>
                </form>
                
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>주중 점심시간</Typography> 
                <form className={classes.timeContainer} noValidate>
                  <TextField
                    id="workingday_lunch_start"
                    label="시작시간"
                    type="time"
                    value={branchSettingData.LUNCH_WEEKDAY && branchSettingData.LUNCH_WEEKDAY.from}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "LUNCH_WEEKDAY", "from")}
                  />  <span className={classes.timeText} >~</span> 
                  <TextField
                    id="workingday_lunch_end"
                    label="종료시간"
                    type="time"
                    value={branchSettingData.LUNCH_WEEKDAY && branchSettingData.LUNCH_WEEKDAY.to}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "LUNCH_WEEKDAY", "to")}
                  />
                  <Button className={classes.timeSaveBtn} onClick={()=>handleTimeSave("LUNCH_WEEKDAY")}>Save</Button>
                </form>

                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>토요일 업무시간</Typography> 
                <form className={classes.timeContainer} noValidate>
                  <TextField
                    id="weekend_start"
                    label="업무 시작시간"
                    type="time"
                    value={branchSettingData.OP_HOUR_WEEKEND && branchSettingData.OP_HOUR_WEEKEND.from}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "OP_HOUR_WEEKEND", "from")}
                  />  <span className={classes.timeText} >~</span> 
                  <TextField
                    id="weekend_end"
                    label="업무 종료시간"
                    type="time"
                    value={branchSettingData.OP_HOUR_WEEKEND.to}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1min
                    }}
                    onChange={(e) => handleChangeTime(e, "OP_HOUR_WEEKEND", "to")}
                  />
                  <Button className={classes.timeSaveBtn} onClick={()=>handleTimeSave("OP_HOUR_WEEKEND")}>Save</Button>
                </form>


                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>점심시간 사용여부</Typography> 
                <div>
                  <Switch
                    className={classes.lunchCheck}
                    checked={branchSettingData.LUNCH_WEEKEND.enable}
                    onChange={handleChangeSwitch}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                { branchSettingData.LUNCH_WEEKEND.enable &&
                  (<div>
                    <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>토요일 점심시간</Typography> 
                    <form className={classes.timeContainer} noValidate>
                      <TextField
                        id="weekend_lunch_start"
                        label="업무 시작시간"
                        type="time"
                        value={branchSettingData.LUNCH_WEEKEND.from}
                        className={classes.timePicker}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 60, // 1min
                        }}
                        onChange={(e) => handleChangeTime(e, "LUNCH_WEEKEND", "from")}
                      />  <span className={classes.timeText} >~</span> 
                      <TextField
                        id="weekend_lunch_end"
                        label="업무 종료시간"
                        type="time"
                        value={branchSettingData.LUNCH_WEEKEND.to}
                        className={classes.timePicker}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 60, // 1min
                        }}
                        onChange={(e) => handleChangeTime(e, "LUNCH_WEEKEND", "to")}
                      />
                      <Button className={classes.timeSaveBtn} onClick={()=>handleTimeSave("LUNCH_WEEKEND")}>Save</Button>
                    </form>
                  </div>)
                }
              </Box>
              
              <Box className={classes.detailItemBox}>
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>인사말</Typography> 
                <TableContainer className={classes.branchPhrase} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Branch</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Phrase</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {branchPhraseData && branchPhraseData.map((row) => (
                        <TableRow key={row.type} >
                          <TableCell>{row.branch.toUpperCase()}</TableCell>
                          <TableCell>
                            <div className={classes.branchPhraseText1}>{row.typeName}</div>
                            <div className={classes.branchPhraseText2}>{row.type}</div>
                          </TableCell>
                          <TableCell className={classes.phrase} >{row.phrase}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell><Button className={classes.updateBranchPhraseBtn} onClick={ () => {handleBranchPhrase(row)}}>수정</Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box className={classes.detailItemBox}>
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>매크로</Typography> 
                <TableContainer className={classes.branchPhrase} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Macro Id</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {branchMacroData && branchMacroData.map((row) => (
                        <TableRow key={`macroId_${row.macroId}`} >
                          <TableCell>{row.macroId}</TableCell>
                          <TableCell>{row.branch.toUpperCase()}</TableCell>
                          <TableCell>{row.key}</TableCell>
                          <TableCell className={classes.phrase} >{JSON.parse(row.action).text}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell><Button className={classes.updateBranchPhraseBtn} onClick={ () => {handleBranchMacro(row)}}>수정</Button></TableCell>
                        </TableRow>
                      ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
        {/* </Card> */}
      </div> 
      <Dialog 
        maxWidth="sm"
        fullWidth
        open={branchPhraseDialogOpen}
        onClose={()=>{setBranchPhraseDialogOpen(false)}}
        aria-labelledby="Update Branch Phrase"
        aria-describedby="Update Branch Phrase"
      >
        <DialogTitle id="alert-dialog-title">
          인사말 수정
        </DialogTitle>
        <DialogContent>
          <Box> 
            <form className={classes.root} noValidate autoComplete="off">
              <div className={classes.newDialogContent}>
                {/* <FormControl className={classes.newDialogText}> */}
                  <TextField id="outlined-basic" label="인사말 Type" 
                  fullWidth 
                  variant="outlined" 
                  value={`${branchPhraseRow.typeName}(${branchPhraseRow.type})`} 
                  className={classes.textfield1} 
                  margin="normal"
                  disabled/>
                  <TextField id="outlined-basic" label="인사 문구" 
                  fullWidth 
                  autoFocus
                  variant="outlined" 
                  multiline
                  value={branchPhraseRow.phrase} 
                  className={classes.textfield2} 
                  onChange={handlePhraseUpdate}
                  margin="normal"
                  />
                {/* </FormControl> */}
                
              </div>
            </form>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleUpdateBranchPhrase} autoFocus>수정 하기</Button>
          <Button onClick={()=>{setBranchPhraseDialogOpen(false)}} autoFocus>취소</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        maxWidth="sm"
        fullWidth
        open={branchMacroDialogOpen}
        onClose={()=>{setBranchMacroDialogOpen(false)}}
        aria-labelledby="Update Branch Macro"
        aria-describedby="Update Branch Macro"
      >
        <DialogTitle id="alert-dialog-title">
          매크로 수정
        </DialogTitle>
        <DialogContent>
          <Box> 
            <form className={classes.root} noValidate autoComplete="off">
              <div className={classes.newDialogContent}>
                {/* <FormControl className={classes.newDialogText}> */}
                  <TextField id="outlined-basic" label="Key" 
                  fullWidth 
                  variant="outlined" 
                  value={`${branchMacroRow.key}`} 
                  className={classes.textfield1} 
                  margin="normal"
                  disabled/>
                  <TextField id="outlined-basic" label="Action" 
                  fullWidth 
                  autoFocus
                  variant="outlined" 
                  multiline
                  // value={branchMacroRow&&bran? JSON.parse(branchMacroRow.action).text:""}
                  value = {branchMacroRow.action && JSON.parse(branchMacroRow.action).text}
                  className={classes.textfield2} 
                  onChange={handleMacroUpdate}
                  margin="normal"
                  />
                {/* </FormControl> */}
                
              </div>
            </form>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleUpdateBranchMacro} autoFocus>수정 하기</Button>
          <Button onClick={()=>{setBranchMacroDialogOpen(false)}} autoFocus>취소</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default withStyles(styles)(Management);