import React, { useEffect, useState } from 'react';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, withStyles, Input } from '@material-ui/core';
import { getCounselorMacro, mutationCounselorMacro } from '../data/Loader';
import { useUser } from '../data/User';
import { useToasts } from "react-toast-notifications";

const styles = (theme) => ({
  root: {

  },
  title: {
    fontWeight: "bold",
    fontSize: theme.font.size16,
    height: "45px"
  },
  description: {
    backgroundColor: "#f9f9f9",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    border: "1px solid #f9f9f9",
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "end"
  },
  input: {
    width: "100%",
    outline: "none",
    border: "1px solid #cdcdcd"
  },
  button: {
    color: "#ffffff",
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    width: "100px",
    height: "32px",
    outline: 0,
    border: 0,
    borderRadius: "16px",
    margin: theme.spacing(2),
  }
});

function Setting_Macro(props) {
  const { classes } = props;
  const { me } = useUser();
  const [macro, setMacro] = useState([]);
  const [changed, setChanged] = useState([]);
  const { addToast } = useToasts();

  const getMyMacro = () => {
    if (!me || !me.counselorId) return;

    getCounselorMacro(me.counselorId)
    .then((result) => {
      console.log("[get_counselorMacro] Query Result", result.data);
      setMacro(result.data.getCounselorMacro);
      setChanged(result.data.getCounselorMacro.map(_ => false));
    })
    .catch((e) => {
      console.log("[get_counselorMacro] Query Error", e);
    })
  }

  useEffect(() => {
    getMyMacro();
  }, [me]);

  const handleChange = (index, e) => {
    setMacro(prev => {
      prev[index].action = JSON.stringify({text: e.target.value});
      return [ ...prev ]; 
    });

    setChanged(prev => {
      prev[index] = true;
      return [ ...prev ];
    });
  }

  const handleSave = () => {
    changed.forEach((c, index) => {
      if (c) {
        mutationCounselorMacro(macro[index].macroId, macro[index].action)
        .then(result => {
          console.log("[Setting Macro] SAVE Success", result);
          addToast("매크로 저장이 완료되었습니다.", {appearance: "success"});
        })
        .catch(error => {
          console.log("[Setting Macro] SAVE Error", error);
          addToast("매크로 저장이 실패했습니다. 다시 시도해 주세요!", {appearance: "error"});
        })
      }
    });
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color={"primary"}>매크로 설정</Typography>
      <Typography className={classes.description} color={"textSecondary"} variant={"subtitle2"}>
        자주 사용하는 문장을 등록합니다.<br/>
        단축키는 미리 저장된 Alt + 키값으로 사용 가능합니다.<br/>
        자바스크립트는 사용하실 수 없습니다.<br/>
        HTML 태그는 사용하실 수 없습니다.<br/>
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size={"small"} className={classes.table} aria-label="Service History">
          <TableHead>
            <TableRow>
              <TableCell classes={{root:classes.th}}>단축키</TableCell>
              <TableCell classes={{root:classes.th}}>자주 사용하는 문장</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {macro && macro.map((item, index) => (
              <TableRow className={classes.tableButton} key={`setting_macro_table_index_${index}`}>
                <TableCell classes={{root:classes.td}}>{item.key}</TableCell>
                <TableCell classes={{root:classes.td}}>
                  <input className={classes.input} value={JSON.parse(item.action).text} onChange={e => handleChange(index, e)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(changed && changed.find(c => c === true) !== null) && <button className={classes.button} onClick={handleSave}>저장하기</button>}
      </TableContainer>

    </div>
  );
}
export default withStyles(styles)(Setting_Macro);