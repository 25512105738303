import { ArrowRight } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { useDragOver } from "@minoru/react-dnd-treeview";
import { useRef, useState } from "react";
import { TreeIcon } from "./TreeIcon";
import { Add, Remove } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    alignItems: "center",
    display: "grid",
    "grid-template-columns": "auto auto 1fr auto",
    height: "32px",
    "padding-inline-end": "8px",
  },
  expandIconWrapper: {
    alignItems: "center",
    fontSize: 0,
    cursor: "pointer",
    display: "flex",
    height: "24px",
    justifyContent: "center",
    width: "24px",
    transition: "transform linear 0.1s",
    transform: "rotate(0deg)",
  },
  isOpen: {
    transform: "rotate(90deg)",
  },
  labelGridItem: {
    "padding-inline-start": "8px",
  },
  input: {
    outline: "none",
    border: "none",
    "&:focus": {
      border: "1px solid #cdcdcd",
    },
  },
  icon: {
    fontSize: "1rem",
    margin: "0px 4px",
    color: theme.palette.text.secondary,
    border: "none",
    borderRadius: "50%",
    "&:active": {
      backgroundColor: "#0a8297",
      color: "#fff",
    },
  },
});

const TreeNode = (props) => {
  const { classes, node, onToggle, isOpen, depth, onChange, hasChild, onAdd, onRemove } = props;
  const { id, droppable, data, editable } = node;
  const [input, setInput] = useState(node.text);
  const inputRef = useRef(null);

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(id);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value);
    onChange(e.target.value, node);
  };

  const dragOverProps = useDragOver(id, isOpen, onToggle);
  const indent = depth * 24;

  const handleButtonClick = (e, node, action) => {
    if (action === "add") {
      onAdd(node);
    } else if (action === "remove") {
      onRemove(node);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      inputRef.current.blur();
    }
  };

  return (
    <div
      className={classes.root}
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
    >
      <div
        className={[
          classes.expandIconWrapper,
          isOpen ? classes.isOpen : "",
        ].join(" ")}
      >
        {hasChild && (
          <div onClick={handleToggle}>
            <ArrowRight />
          </div>
        )}
      </div>
      <div>
        <TreeIcon droppable={droppable} fileType={data?.fileType} />
      </div>
      <div className={classes.labelGridItem}>
        <input
          className={classes.input}
          value={input}
          variant="body2"
          onChange={handleChange}
          disabled={!editable}
          ref={inputRef}
          onKeyPress={handleKeyPress}
        />
      </div>

      {data.fileType !== "user" && editable && !hasChild && (
        <div>
          <Remove
            classes={{ root: classes.icon }}
            onClick={(e) => handleButtonClick(e, node, "remove")}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(TreeNode);