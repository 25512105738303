import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import ConversationPanel from '../tab/ConversationPanel';

import aws_export from '../../asset/aws/aws-exports';

Amplify.configure(aws_export);

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    overflow: "none",
    position: "absolute",
    top: 0,
    bottom: 0
  },
  contents: {
    flex: 1,
    overflow: "auto",
    bottom: "50px",
    padding: "16px"
  },
  table: {
    width: "100%",
    border: "1px solid #343434",
    borderCollapse: "collapse"
  },
  tableItem: {
    border: "1px solid #343434",
    padding : "8px"
  },
  signOut: {
    width: "100%",
  }
});

// messages {
          //   messages {
          //     content
          //     sender
          //     createdAt
          //     id
          //     isSent
          //   }
          //   nextToken
          // }

const queryMe = `query queryMe {
  me {
    cognitoId
    id
    registered
    username
    conversations {
      nextToken
      userConversations {
        conversation {
          createdAt
          id
          name
          
        }
        conversationId
        userId
      }
    }
  }
}`;

const queryAllUser = `query queryAllUser {
  allUser {
    cognitoId
    id
    registered
    username
    conversations {
      nextToken
      userConversations {
        conversation {
          createdAt
          id
          name
        }
        conversationId
        userId
      }
    }
  }
}`;

const queryAllMessage = `query queryAllMessage($conversationId: ID!) {
  allMessage(conversationId: $conversationId) {
    author {
      username
      id
    }
    content
    conversationId
    createdAt
    id
    isSent
    sender
  }
}`;

const Test = (props) => {
  const { classes } = props;
  const [me, setMe] = useState();
  const [allUser, setAllUser] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(async () => {
    getMe();
    getAllUser();
  }, []);

  const getMe = async () => {
    const result = await API.graphql(graphqlOperation(queryMe));
    console.log("me", result);
    setMe(result.data.me);
  }

  const getAllUser = async () => {
    const result = await API.graphql(graphqlOperation(queryAllUser));
    console.log("users", result);
    setAllUser(result.data.allUser);
  }

  const getAllMessage = async (conversationId) => {
    const result = await API.graphql(graphqlOperation(queryAllMessage, {conversationId: conversationId}));
    console.log("allMessage", result);
    alert(result.data.allMessage.map(message => message.content));
  }

  return (
    <div className={classes.root}>
      <div className={classes.contents}>
        <h1>QUERY</h1>
        <h2>내정보</h2>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableItem}>사용자 이름</th>
              <th className={classes.tableItem}>ID</th>
              <th className={classes.tableItem}>등록 여부</th>
            </tr>
          </thead>
          {me && (
            <tbody>
              <tr>
                <td className={classes.tableItem}>{me.username}</td>
                <td className={classes.tableItem}>{me.id}</td>
                <td className={classes.tableItem}>{me.registered === true ? "YES" : "NO"}</td>
              </tr>
            </tbody>
          )}
        </table>

        <h2>사용자 정보</h2>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableItem}>사용자 이름</th>
              <th className={classes.tableItem}>ID</th>
              <th className={classes.tableItem}>등록 여부</th>
              <th className={classes.tableItem}>대화 목록</th>
            </tr>
          </thead>
          {allUser && (
            <tbody>
              {allUser.map((user) => (
                <tr key={user.id}>
                  <td className={classes.tableItem}>{user.username}</td>
                  <td className={classes.tableItem}>{user.id}</td>
                  <td className={classes.tableItem}>{user.registered === true ? "YES" : "NO"}</td>
                  <td className={classes.tableItem}>
                    {user.conversations.userConversations.map(conv => (
                      <div key={conv.conversationId}><a href="#" onClick={() => getAllMessage(conv.conversationId)}>{conv.conversation.name}</a></div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
        )}
        </table>

        <h1>SUBSCRIPTION & MUTATION</h1>

        {me && (
          <div>
            <AppBar position={"static"}>
              <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
                {me.conversations.userConversations.map(conv => (
                  <Tab key={conv.conversation.name} label={conv.conversation.name}/>
                ))}
              </Tabs>
            </AppBar>

            {me.conversations.userConversations.map((conv, i) => (
              <ConversationPanel key={conv.conversationId} value={tabIndex} index={i} conversationId={conv.conversationId} myId={me.id}/>
            ))}
            
          </div>
        )}

      </div>
      <AmplifySignOut className={classes.signOut}/>
    </div>
  );
}
export default withAuthenticator(withStyles(styles)(Test));