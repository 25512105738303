import React, { useEffect, useState } from "react";
import { withStyles, Typography, Button, Fade } from "@material-ui/core";
import CommonTable from "./CommonTable";
import {
  useParams
} from "react-router-dom";

import {
  getCategoryList,
  getNotisByBranch,
  getRoleInfo,
  mutationCounselorCategoryAdd,
  mutationCounselorCategoryDelete,
  mutationCounselorDelete,
  mutationCounselorRoleAdd,
  mutationCounselorRoleDelete,
  mutationSendNoti,
} from "../data/Loader";
import { ArrowDropDown, Add, Search, ArrowRight, Message, Group, Person, Home, Folder, Assignment, Remove, People, Clear} from '@material-ui/icons';
import {Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText,} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useUser } from "../data/User";
import CounselorCategory from "./CounselorCategory";
import { Grid, Box, List, ListItem,  ListItemText, ListItemIcon,ListItemSecondaryAction,  Menu, MenuItem, TextField , IconButton} from "@material-ui/core";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import NoticeTable from "./NoticeTable";

const styles = (theme) => ({
  root: {},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  content: {
    padding: "5px 10px",
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "5px",  
  },
  newMemBtn: {
    background: "#0a8297",
    color: "#fff",
    // fontWeight: "600",
    padding: "5px 15px",
    fontSize: "1.0em",
    marginRight: "10px",
    "&:hover": {
      background: "#109ab3",
      color: "#fff",
    },
  },
  actionBtn: {
    // background: "#0a8297",
    // color: "#fff",
    // fontWeight: "600",
    padding: "5px 15px",
    fontSize: "1.0em",
    border: "1px solid #c3bdbd",
    marginRight: "1px",
  },
  bigDialog: {
    "& .MuiPaper-root" :{
      minWidth: "650px",    
    },
    borderRight:"15px",
    padding:"10px", 

  },
  emailInput: { 
    marginBottom: "20px",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
    },
    "& .MuiInputBase-input":{
      width:"100%"
    }
  },
  roleInput: {
    margin:"10px 0px",
    "& .MuiInputBase-root": {
      borderRadius: "0px",
    },
  },
  noticeInput: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
    },
  },
  category:{
    border:"1px solid #c4c4c4",
    margin: "20px 0px",
    padding: "10px",
    
  },
  categoryTitle:{
    color: "#757575",
    padding: "5px",
    marginBottom: "10px",
    fontSize: "0.95rem",
  },
  categoryContent:{
    height: "300px",
    overflow: "auto",
    borderRight:"1px solid #c4c4c4"
  },
  categorySelected:{
    height: "300px",
    overflow: "auto",
  },
  list:{
    padding: "2px 0px",
    "& .MuiList-padding":{
      padding: "2px 0px"
    }
  },
  selectedItem:{
    border: "1px solid #f3f1f1",
    marginLeft: "7px",
    padding: "0px",
    background: "#f7f7f7",
    // line-height: 1.0;
    "& .MuiTypography-root":{
      fontSize: "0.77rem"
    }
  },

  noticeNewDialog:{
    // "& .quill":
    "& .ql-toolbar": {
      borderRadius:"10px 10px 0px 0px"
      },
    "& .ql-container":{
      borderRadius:"0px 0px 10px 10px",
      height: "250px",
      fontSize: "15px",
      fontFamily: "inherit",
      },
    
  }
  
});

function Management(props) {
  const [tableData, setTableData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState([]);
  const { classes } = props;
  const { me } = useUser();
  let { id } = useParams();

  const fnGetNotis = (branch) =>{
    getNotisByBranch(branch, "noti")
      .then((result)=>{
        console.log("[get notis] Query Result", result.data);
        result.data.getNotisByBranch && setTableData(result.data.getNotisByBranch)
        if (id && rows){
          // console.log(">>>>>>>>>>>>>>>>", id, rows)
          const rowView = rows.filter(r=>r.id=="notiId#"+id);
          // console.log(">>>>>>>>>>>>>>>>", rowView)
          rowView && rowView.length && selectRow(rowView[0])
        } 
      })
      .catch((e)=>{
        console.log("[get notis] Query Error", e)
      })
  }


  //table data
  const headCells = [
    // { id: "id", numeric: false, disablePadding: true, label: "ID" },
    { id: "branch", numeric: false, disablePadding: true, label: "Branch" },
    { id: "title", numeric: false, disablePadding: true, label: "제목" },
    { id: "contentText", numeric: false, disablePadding: true, label: "내용" },
    { id: "created", numeric: false, disablePadding: true, label: "날짜" },
    { id: "notiType", numeric: false, disablePadding: true, label: "Type" },
    { id: "sender", numeric: false, disablePadding: true, label: "Sender" },
  ];


  

  const rows = (tableData && tableData.length)? tableData.map((d) =>
        {
          // console.log(">>>>>>", tableData, )
          var content_json= ""
          try {
            content_json = JSON.parse(d.contentJson)
            // console.log("json>>", content_json);
            var contentText = content_json && content_json.content.replace(/<[^>]*>/g, '')
            contentText = contentText && (contentText.length>20? contentText.substring(0,20)+"...": contentText)
            // console.log(contentText)
          } catch (error) {
            // console.log("content parse error, ", error)
          }
          return createData(
            d.notiId,
            d.branch,
            content_json?content_json.title:"-",
            contentText,
            moment(d.created * 1000).format("YYYY-MM-DD HH:mm"),
            d.notiType,
            d.senderId,
            
            content_json&&content_json.content
          )
        }
      )
    : [];

  function createData(
    id,
    branch,
    title,
    contentText,
    created, 
    notiType,
    sender,
    content,
  ) {
    return { id, branch, title, contentText, created, notiType, sender,content};
  }

  const [selectedRow, setSelectedRow] = useState();
  const [disabledAction, setDisbledAction] = useState({ disabled: true });

  const checkRow = (row) => {
    // console.log("select row:", row)
    if (row) {
      setSelectedRow(row);
      setDisbledAction({ disabled: false });
    }   else {
      setSelectedRow(null);
      setDisbledAction({ disabled: true });
    }
    // console.log("[row:::::]", row);
  };

  const selectRow = (row)=> {
    //popup
    console.log(row)
    setTitle(row.title)
    setContent(row.content)
    setNotiViewDialOpen(true)
  }
  useEffect(()=>{
    fnGetNotis(me.currentBranch);
    
  }, [me, id])
  // useEffect(()=>{
  //   console.log("use_effect_id_updated")

  //   if (id && rows){
  //     console.log(">>>>>>>>>>>>>>>>", id, rows)
  //     const rowView = rows.filter(r=>r.id=="notiId#"+id);
  //     console.log(">>>>>>>>>>>>>>>>", rowView)
  //     rowView && rowView.length && selectRow(rowView[0])

  //   } 

  // }, [id])


  const [actionEl, setActionEl] = useState(null);
  const handleActionClick = (e) => {
    setActionEl(e.target);
  };
  const handleActionMenuClose = () => {
    setActionEl(null);
  };

  const handleNewNoti = () => {
    setnewNotiDialOpen(true);
    setSelectedItem([])
    setTitle("")
    setContent("")
  };
  const handleNotiView = () => {
    setNotiViewDialOpen(true);
    setSelectedItem([])
    setTitle("")
    setContent("")
  };
  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const handleCategory = () => {
    setCategoryOpen(true);
    console.log(selectedRow.categoryItem)
    selectedRow.categoryItem && setSelectedItem(selectedRow.categoryItem)
  };

  // 팝업 공통 //
  const [notiViewDialOpen, setNotiViewDialOpen] = useState(false);
  const [newNotiDialOpen, setnewNotiDialOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const handleClose = () => {
    setNotiViewDialOpen(false);
    setnewNotiDialOpen(false);
    setRoleOpen(false);
    setDeleteOpen(false);
    setCategoryOpen(false);
  };
  // 팝업 공통 끝//

  // 초대 팝업//
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [simpleAlertOpen, setSimpleAlertOpen] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");
  
  const simpleAlert = (msg) => {
    setSimpleAlertMsg(msg)
    setSimpleAlertOpen(true)
  }

  const handleRoleOptionChange = (e, newInputValue) => {
    console.log("[newInputValue]", newInputValue);
    // setRoles(newInputValue);
  };
  const handleTitleChange = (e) => {
    //validation check

    setTitle(e.target.value);
  };
  const handleContentChange = (html) => {
    //validation check
    // console.log(html)
    setContent(html);
  };


  const handleSave = () => {
    // console.log(email, roles);
    console.log("공지 저장")
    if (!title.length){
      simpleAlert( "제목을 입력해주세요.")
      return
    }
    if (!content.length){
      simpleAlert( "내용을 입력해 주세요")
      return
    }
   
    const currentTime = Math.floor(Date.now()/1000);

    fnMutationSendNoti(title, content, currentTime, "noti");
    

  };
  //초대 팝업 끝//

  //권한 수정
  const handleRole = () => {
    //role edit popup open
    setRoleOpen(true);
    setDisabledRoleEdit({ disabled: true });
  };
  const [editRoles, setEditRoles] = useState(null);
  const [disabledRoleEdit, setDisabledRoleEdit] = useState({ disabled: true });
  const handleRoleEditChange = (e, newInputValue) => {
    console.log(newInputValue);
    setEditRoles(newInputValue);
    setDisabledRoleEdit({ disabled: false });
  };
  const handleRoleSubmit = () => {
    console.log("변경");
    console.log(
      me.currentBranch,
      selectedRow.id,
      roleData.map((r) => r.roleName)
    );
    selectedRow &&
      selectedRow.id &&
      mutationCounselorRoleDelete(
        me.currentBranch,
        selectedRow.id,
        roleData.map((r) => r.roleName)
      )
        .then((result) => {
          console.log(
            "[mutationCounselorRoleDelete] Query result",
            result.data
          );
          mutationCounselorRoleAdd(me.currentBranch, selectedRow.id, editRoles)
            .then((result) => {
              console.log(
                "[mutatiomutationCounselorRoleAdd] Query result",
                result.data
              );
            })
            .catch((e) => {
              console.log("[mutationCounselorRoleAdd] Query Error", e);
            });
        })
        .catch((e) => {
          console.log("[mutationCounselorRoleDelete] Query Error", e);
        });

    mutationCounselorRoleAdd(me.currentBranch, selectedRow.id, editRoles)
      .then((result) => {
        console.log(
          "[mutatiomutationCounselorRoleAdd] Query result",
          result.data
        );
        console.log("변경 완료.");
        setRoleOpen(false);
        // fnGetCounselors(me.currentBranch);
      })
      .catch((e) => {
        console.log("[mutationCounselorRoleAdd] Query Error", e);
      });
  };
  //권한 수정 끝

  //상담사 삭제
  const handleDeleteSubmit = () => {
    selectedRow &&
      selectedRow.id &&
      mutationCounselorDelete(selectedRow.id)
        .then((result) => {
          console.log("[counselor delete] Query result", result.data);
          setDeleteOpen(false);
          // fnGetCounselors(me.currentBranch);
        })
        .catch((e) => {
          console.log("[counselor delete] Query Error", e);
        });
  };
  //상담사 삭제 끝


  const fnMutationSendNoti = (title, content, created, notiType, ) => {
    var contentJson = JSON.stringify({
      title: title,
      content: content,
      created: created,
      // updated: updated,
    });
    mutationSendNoti(contentJson, notiType, me.counselorId, me.currentBranch,0)
      .then((result) => {
        console.log("[Mutation Send Noti] Result: ", result);
        setnewNotiDialOpen(false);
        setSimpleAlertMsg("공지 등록이 완료되었습니다.");
        setSimpleAlertOpen(true);
      })
      .catch((error) => {
        console.log("[Mutation Send Noti] Error: ", error);
        setSimpleAlertMsg("공지 등록이 완료되지 않았습니다.");
        setSimpleAlertOpen(true);
      });
  };

  const clickItem = (item) =>{
    !selectedItem.map(val=>val.categoryInfo.categoryId).includes(item.categoryInfo.categoryId) && setSelectedItem( prev=>([...prev, item]))
  }

  const itemClear = (categoryId) => {
    console.log("item clear::", categoryId)
    console.log(selectedItem)
    setSelectedItem(
      prev=> (prev.filter(val => (val.categoryInfo.categoryId !=categoryId)))
    );
  }

  const [selectedItem, setSelectedItem] = useState([]);

  //카테고리 변경
  const handleCategoryUpdate = () =>{
    console.log(selectedRow)
    let org = selectedRow.categoryItem
    let neww = selectedItem
    let o_arr = org.map(a=> a.categoryId)
    let n_arr = neww.map(a=> a.categoryInfo.categoryId)
    let add_arr = []
    let del_arr = []
    o_arr.forEach( o => {
      if ( ! n_arr.includes(o) )
        del_arr.push(o)
    })
    n_arr.forEach( n=>{
      if ( ! o_arr.includes(n)){
        add_arr.push(n)
      }
    })
    console.log("ADD::", add_arr)
    console.log("DEL::", del_arr)

    let cnt = add_arr.length + del_arr.length;

    add_arr.forEach(
      add_cat=>{
        mutationCounselorCategoryAdd(me.currentBranch, selectedRow.id, add_cat )
        .then((result) => {
          console.log("[add category] Query result", result.data);
        })
        .catch((e) => {
          console.log("[add category] Query Error", e);
        })
        .finally(()=>{
          cnt--;
          console.log("complete", cnt)
          
          if (cnt==0){
            setCategoryOpen(false);
            // fnGetCounselors(me.currentBranch)
            setSelectedRow(null);
            setDisbledAction({ disabled: true });
          }
        });
      }
    )
    
    del_arr.forEach(
    del_cat=>{
        mutationCounselorCategoryDelete(me.currentBranch, selectedRow.id, del_cat )
        .then((result) => {
          console.log("[del category] Query result", result.data);
        })
        .catch((e) => {
          console.log("[del category] Query Error", e);
        }).finally(()=>{
          cnt--;
          console.log("complete", cnt)
          
          if (cnt==0){
            setCategoryOpen(false);
            // fnGetCounselors(me.currentBranch)
            setSelectedRow(null);
            setDisbledAction({ disabled: true });
          }
        });

      }
    )
    if (!add_arr.length && !del_arr.length){
      simpleAlert("변경된 카테고리가 없습니다.")
    }

  }
  //카테고리 변경 끝

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} color={"primary"}>
          공지사항 관리
        </Typography>
        <div className={classes.content}>
          <div className={classes.action}>
            <Button
              className={classes.newMemBtn}
              startIcon={<Add />}
              onClick={handleNewNoti}
            >
              {" "}
              새 글 쓰기
            </Button>
            <Button
              className={classes.actionBtn}
              onClick={handleActionClick}
              transitioncomponent={Fade}
              {...disabledAction}
            >
              Action
              <ArrowDropDown />
            </Button>
            <Menu
              id={"action-menu"}
              anchorEl={actionEl}
              keepMounted
              open={Boolean(actionEl)}
              onClick={handleActionMenuClose}
              onClose={handleActionMenuClose}
              transitioncomponent={Fade}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {/* <MenuItem className={classes.menu} dense onClick={handleReset}>
                <ListItemText primary="패스워드 초기화" />
              </MenuItem>
              <MenuItem className={classes.menu} dense onClick={handleRole}>
                <ListItemText primary="권한 편집" />
              </MenuItem> */}
              <MenuItem className={classes.menu} dense onClick={handleDelete}>
                <ListItemText primary="공지 삭제" />
              </MenuItem>
              <MenuItem className={classes.menu} dense onClick={handleCategory}>
                <ListItemText primary="카테고리 설정" />
              </MenuItem>
            </Menu>
          </div>
          <NoticeTable
            data={tableData}
            headCells={headCells}
            rows={rows}
            checkRow={checkRow}
            selectRow = {selectRow}
          ></NoticeTable>
        </div>
        {/* 새 글 쓰기 팝업 */}
        <Dialog
          fullWidth
          className={classes.bigDialog}
          open={newNotiDialOpen}
          onClose={() => {
            setnewNotiDialOpen(false);
          }}
          aria-labelledby="invite-title"
        >
          <DialogTitle id="invite-title">새 글 쓰기 </DialogTitle>
          <DialogContent className={classes.noticeNewDialog}>
            
            
            <TextField
              className={classes.emailInput}
              // autoFocus
              id="title"
              label="Title"
              type="email"
              fullWidth
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
            <ReactQuill theme="snow" value={content} onChange={handleContentChange  } />

            {/* <TextField
              multiline
              className={classes.noticeInput}
              // autoFocus
              // margin="dense"
              id="name"
              label=""
              // type="email"
              fullWidth
              variant="outlined"
              onChange={handleContentChange}
              // maxRows={4}
              rows={7}
              // defaultValue="Default Value"
            /> */}

           
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              저장
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 글 뷰 팝업 */}
        <Dialog
          fullWidth
          className={classes.bigDialog}
          open={notiViewDialOpen}
          onClose={() => {
            setNotiViewDialOpen(false);
          }}
          aria-labelledby="invite-title"
        >
          <DialogTitle id="invite-title">공지사항 보기</DialogTitle>
          <DialogContent className={classes.noticeNewDialog}>
            
            
            <TextField
              className={classes.emailInput}
              // autoFocus
              id="title"
              label="Title"
              type="email"
              fullWidth
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
            <ReactQuill theme="snow" value={content} onChange={handleContentChange  } />

           

           
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              수정
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 권한 편집 */}
        <Dialog
          className={classes.dialog}
          open={roleOpen}
          onClose={() => {
            setRoleOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">권한 수정</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="dense"
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="dense"
              />
            </div>
            {/* 권한수정>권한 */}
            <Autocomplete
              className={classes.roleInput}
              multiple
              id="tags-outlined"
              options={roleData.map((r) => r.roleName)}
              // getOptionLabel={(option) => option.role}
              filterSelectedOptions
              defaultValue={
                selectedRow &&
                selectedRow.role &&
                selectedRow.role.map((r) => r.roleName)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="권한*" />
              )}
              onChange={handleRoleEditChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRoleSubmit}
              color="primary"
              {...disabledRoleEdit}
            >
              변경
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 상담사 삭제 */}
        <Dialog
          className={classes.dialog}
          open={deleteOpen}
          onClose={() => {
            setDeleteOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">상담사 삭제</DialogTitle>
          <DialogContent>
            <DialogContentText>
              해당 상담사를 삭제하시겠습니까?
            </DialogContentText>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteSubmit} color="primary">
              상담사 삭제
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 카테고리 설정 */}
        <Dialog
          className={classes.bigDialog}
          open={categoryOpen}
          onClose={() => {
            setCategoryOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">카테고리 설정</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
                fullWidth
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </div>

            <div className={classes.category}>
              <div className={classes.categoryTitle}>
                카테고리
              </div>
              <div>
                <Grid container className={classes.container}>
                  <Grid item className={classes.categoryContent} xs={12} sm={6} md={6} >

                      <CounselorCategory
                        containCounselors = {false}
                        clickItem={clickItem}
                      >
                      </CounselorCategory>
                  </Grid>
                  <Grid item className={classes.categorySelected} xs={12} sm={6} md={6} >
                    {/* <div className={classes.selected}/> */}
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      {selectedItem && selectedItem.map(value=>{
                        return(
                        <List className={classes.list}  key={`${value.categoryInfo.categoryId}_0`}>
                          <ListItem key={`${value.categoryInfo.categoryId}_1`} role={undefined} dense button className={classes.selectedItem} >
                            <ListItemIcon>
                              <Folder/>
                            </ListItemIcon>
                            <ListItemText primary={`${value.categoryInfo.name}(${value.categoryInfo.categoryId})`}/>
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="comments" onClick={()=>itemClear(value.categoryInfo.categoryId)}>
                                <Clear />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>


                        )


                      })}
                      
                    </Box>

                  
                  </Grid>
                </Grid>
              </div>
            </div>  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCategoryUpdate} color="primary">
              카테고리 변경
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* Simple Alert  */}
        <Dialog
          open={simpleAlertOpen}
          onClose={()=>setSimpleAlertOpen(false)}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {simpleAlertMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setSimpleAlertOpen(false)} autoFocus>확인</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
export default withStyles(styles)(Management);
