import React, { useEffect, useState } from "react";
import { withStyles, Typography, Button, Fade } from "@material-ui/core";
import CommonTable from "./CommonTable";
import {
  getCategoryList,
  getCounselor,
  getCounselors,
  getRoleInfo,
  mutationCounselorBranchAdd,
  mutationCounselorCategoryAdd,
  mutationCounselorCategoryDelete,
  mutationCounselorDelete,
  mutationCounselorRoleAdd,
  mutationCounselorRoleDelete,
  mutationCreateCounselor,
  mutationUpdateCounselor,
} from "../data/Loader";
import { ArrowDropDown, Add, Search, ArrowRight, Message, Group, Person, Home, Folder, Assignment, Remove, People, Clear} from '@material-ui/icons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useUser } from "../data/User";
import CounselorCategory from "./CounselorCategory";
import { Grid, Box, List, ListItem,  ListItemText, ListItemIcon,ListItemSecondaryAction,  Menu, MenuItem, TextField , IconButton} from "@material-ui/core";

const styles = (theme) => ({
  root: {},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  content: {
    padding: "5px 10px",
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "5px",
  },
  newMemBtn: {
    background: "#0a8297",
    color: "#fff",
    // fontWeight: "600",
    padding: "5px 15px",
    fontSize: "1.0em",
    marginRight: "10px",
    "&:hover": {
      background: "#109ab3",
      color: "#fff",
    },
  },
  actionBtn: {
    // background: "#0a8297",
    // color: "#fff",
    // fontWeight: "600",
    padding: "5px 15px",
    fontSize: "1.0em",
    border: "1px solid #c3bdbd",
    marginRight: "1px",
  },
  bigDialog: {
    "& .MuiPaper-root" :{
      minWidth: "750px",    
    }
  },
  emailInput: {
    marginBottom: "10px",
    "& .MuiInputBase-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input":{
      width:"100%"
    }
  },
  roleInput: {
    margin:"10px 0px",
    "& .MuiInputBase-root": {
      borderRadius: "0px",
    },
  },
  category:{
    border:"1px solid #c4c4c4",
    margin: "20px 0px",
    padding: "10px",
    
  },
  categoryTitle:{
    color: "#757575",
    padding: "5px",
    marginBottom: "10px",
    fontSize: "0.95rem",
  },
  categoryContent:{
    height: "300px",
    overflow: "auto",
    borderRight:"1px solid #c4c4c4"
  },
  categorySelected:{
    height: "300px",
    overflow: "auto",
  },
  list:{
    padding: "2px 0px",
    "& .MuiList-padding":{
      padding: "2px 0px"
    }
  },
  selectedItem:{
    border: "1px solid #f3f1f1",
    marginLeft: "7px",
    padding: "0px",
    background: "#f7f7f7",
    // line-height: 1.0;
    "& .MuiTypography-root":{
      fontSize: "0.77rem"
    }
  }
  
});

function Management(props) {
  const [tableData, setTableData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState([]);
  const { classes } = props;
  const { me } = useUser();

  // user effect //
  const fnGetCounselors = (branch) => {
    getCounselors(branch)
      .then((result) => {
        console.log("[get counselors] Query result", result.data);
        result.data.getCounselorList &&
          setTableData(result.data.getCounselorList);
      })
      .catch((e) => {
        console.log("[get counselors] Query Error", e);
      });
  };

  const fnGetRoles = (branch) => {
    getRoleInfo(branch)
      .then((result) => {
        console.log("[get roles] Query result", result.data);
        result.data.getRoleInfo && setRoleData(result.data.getRoleInfo);
      })
      .catch((e) => {
        console.log("[get roles] Query Error", e);
      });
  };

  const fnGetCategoryList = (branch)=>{
    getCategoryList( branch)
    .then((result) => {
      console.log("[get category list] Query result", result.data);
      result.data.getCategoryList && setCategoryInfo(result.data.getCategoryList);
    })
    .catch((e) => {
      console.log("[get category list] Query Error", e);
    });
  }

  //table data
  const headCells = [
    { id: "id", numeric: false, disablePadding: true, label: "상담사 ID" },
    { id: "name", numeric: false, disablePadding: true, label: "이름" },
    {
      id: "mobilephone",
      numeric: false,
      disablePadding: true,
      label: "핸드폰",
    },
    { id: "department", numeric: false, disablePadding: true, label: "부서" },
    { id: "email", numeric: false, disablePadding: true, label: "이메일" },
    {
      id: "counselNum",
      numeric: false,
      disablePadding: true,
      label: "연결수(현재/최대)",
    } ,
    { id: "category", numeric: false, disablePadding: true, label: "카테고리" },
    { id: "role", numeric: false, disablePadding: true, label: "권한" },
  ];

  const rows = tableData
    ? tableData.map((d) =>
        createData(
          d.counselorId,
          d.name,
          d.phone,
          d.mobilephone,
          d.department,
          d.email,
          d.currentCounselNum + "/" + d.maxCounselNum,
          d.category.map( c => {
            var f = categoryInfo && categoryInfo.filter(
                  a => a.categoryId==c.categoryId
                )
            if (f && f.length)
              return f[0].name
          }).join(' | '),
          d.category.map( c => {
            var f = categoryInfo && categoryInfo.filter(
                  a => a.categoryId==c.categoryId
                )
            if (f && f.length){
              c.categoryInfo = {categoryId: f[0].categoryId, branch:f[0].branch, name:f[0].name}
              return c
            }
          }),
          d.role
        )
      )
    : [];

  function createData(
    id,
    name,
    phone,
    mobilephone,
    department,
    email,
    counselNum,
    category, 
    categoryItem,
    role
  ) {
    return { id, name, mobilephone, department, email, counselNum, category, categoryItem,  role };
  }

  const [selectedRow, setSelectedRow] = useState();
  const [disabledAction, setDisbledAction] = useState({ disabled: true });

  const selectRow = (row) => {
    if (row) {
      setSelectedRow(row);
      setDisbledAction({ disabled: false });
    } else {
      setSelectedRow(null);
      setDisbledAction({ disabled: true });
    }
    console.log("[row:::::]", row);
  };

  useEffect(() => {
    fnGetCounselors(me.currentBranch);
    fnGetRoles(me.currentBranch);
    fnGetCategoryList(me.currentBranch);
  }, []);

  const [actionEl, setActionEl] = useState(null);
  const handleActionClick = (e) => {
    setActionEl(e.target);
  };
  const handleActionMenuClose = () => {
    setActionEl(null);
  };

  const handleInvite = () => {
    setInviteOpen(true);
    setSelectedItem([])
  };
  const handleReset = () => {
    setResetOpen(true);
  };
  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const handleCategory = () => {
    setCategoryOpen(true);
    console.log(selectedRow.categoryItem)
    selectedRow.categoryItem && setSelectedItem(selectedRow.categoryItem)
  };

  // 팝업 공통 //
  const [inviteOpen, setInviteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const handleClose = () => {
    setInviteOpen(false);
    setResetOpen(false);
    setRoleOpen(false);
    setDeleteOpen(false);
    setCategoryOpen(false);
  };
  // 팝업 공통 끝//

  // 초대 팝업//
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [name, setName] = useState("");

  const [simpleAlertOpen, setSimpleAlertOpen] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");
  
  const simpleAlert = (msg) => {
    setSimpleAlertMsg(msg)
    setSimpleAlertOpen(true)
  }

  const handleRoleOptionChange = (e, newInputValue) => {
    console.log("[newInputValue]", newInputValue);
    setRoles(newInputValue);
  };
  const handleEmailChange = (e) => {
    //validation check

    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    //validation check

    setName(e.target.value);
  };


  const handleInviteSubmit = () => {
    console.log(email, roles);
    console.log("상담사 초대 버튼 클릭.")
    if (!email.length){
      simpleAlert( "이메일을 입력해주세요.")
      return
    }
    if (!name.length){
      simpleAlert( "이름을 입력해 주세요")
      return
    }
    if (!roles.length){
      simpleAlert( "권한을 선택해 주세요.")
      return
    }
    if (!selectedItem || !selectedItem.length){
      simpleAlert( "카테고리를 선택해 주세요.")
      return
    }

    const counselorId =email.split("@")[0];
    getCounselor(counselorId, me.currentBranch )
      .then((result) => {
        console.log("[get counselor] Query result", result.data);
        // result.data.getCounselor
        const counselor = result.data.getCounselor;
        // console.log(counselor.branch)
        // console.log(me.curcounselor.branch)
        if (counselor.branch.includes(me.currentBranch)){
          console.log("해당 brnach에 상담사가 존재합니다.");
          simpleAlert("해당 Branch에 상담사가 존재합니다.");
        }else{
          //add branch

          mutationCounselorBranchAdd(me.currentBranch, counselorId) 
          .then((result) => {
            console.log("[add brnach] Query result", result.data);
          })
          .catch((e) => {
            console.log("[add brnach] Query Error", e);
          });

          mutationCounselorRoleAdd(me.currentBranch,  counselorId,  roles)
          .then((result) => {
            console.log("[add role] Query result", result.data);
          })
          .catch((e) => {
            console.log("[add role] Query Error", e);
          });

          mutationUpdateCounselor(counselorId, me.currentBranch, null, 10, null, null)
          .then((result) => {
            console.log("[update counselor] Query result", result.data);
          })
          .catch((e) => {
            console.log("[update counselor] Query Error", e);
          });
        }
      })
      .catch((e) => {
        console.log("[get counselor] Query Error", e);
        if (e.errors && e.errors.length && e.errors[0].errorType =="ER_COUNSELOR_NOT_EXIST"){
          console.log("해당 아이디가 존재 하지 않음. -> create new counselor")
          const roles1 =roles.map( a=>a.roleName)
          const categoryIds = selectedItem.map( val => val.categoryInfo.categoryId)
          console.log("categoryIds>>>",categoryIds);
          mutationCreateCounselor( me.currentBranch, categoryIds,  counselorId, "", name, roles1, email, "" )
          .then((result) => {
            console.log("[create counselor] Query result", result.data);
            setInviteOpen(false);
            setSimpleAlertMsg("상담사 초대가 완료되었습니다.");
            setSimpleAlertOpen(true);
            setTableData(
              (prev)=>([...prev, result.data.createCounselor])
            )
          })
          .catch((e) => {
            console.log("[create counselor] Query Error", e);
            let err_msg = ""
            if (e.errors && e.errors.length )
              err_msg = e.errors[0].message
            simpleAlert(" 상담사 초대가 완료되지 않았습니다.(" + err_msg+")")
            
          });
        }
      });


  };
  //초대 팝업 끝//

  //권한 수정
  const handleRole = () => {
    //role edit popup open
    setRoleOpen(true);
    setDisabledRoleEdit({ disabled: true });
  };
  const [editRoles, setEditRoles] = useState(null);
  const [disabledRoleEdit, setDisabledRoleEdit] = useState({ disabled: true });
  const handleRoleEditChange = (e, newInputValue) => {
    console.log(newInputValue);
    setEditRoles(newInputValue);
    setDisabledRoleEdit({ disabled: false });
  };
  const handleRoleSubmit = () => {
    console.log("변경");
    console.log(
      me.currentBranch,
      selectedRow.id,
      roleData.map((r) => r.roleName)
    );
    selectedRow &&
      selectedRow.id &&
      mutationCounselorRoleDelete(
        me.currentBranch,
        selectedRow.id,
        roleData.map((r) => r.roleName)
      )
        .then((result) => {
          console.log(
            "[mutationCounselorRoleDelete] Query result",
            result.data
          );
          mutationCounselorRoleAdd(me.currentBranch, selectedRow.id, editRoles)
            .then((result) => {
              console.log(
                "[mutatiomutationCounselorRoleAdd] Query result",
                result.data
              );
            })
            .catch((e) => {
              console.log("[mutationCounselorRoleAdd] Query Error", e);
            });
        })
        .catch((e) => {
          console.log("[mutationCounselorRoleDelete] Query Error", e);
        });

    mutationCounselorRoleAdd(me.currentBranch, selectedRow.id, editRoles)
      .then((result) => {
        console.log(
          "[mutatiomutationCounselorRoleAdd] Query result",
          result.data
        );
        console.log("변경 완료.");
        setRoleOpen(false);
        fnGetCounselors(me.currentBranch);
      })
      .catch((e) => {
        console.log("[mutationCounselorRoleAdd] Query Error", e);
      });
  };
  //권한 수정 끝

  //상담사 삭제
  const handleDeleteSubmit = () => {
    selectedRow &&
      selectedRow.id &&
      mutationCounselorDelete(selectedRow.id)
        .then((result) => {
          console.log("[counselor delete] Query result", result.data);
          setDeleteOpen(false);
          fnGetCounselors(me.currentBranch);
        })
        .catch((e) => {
          console.log("[counselor delete] Query Error", e);
        });
  };
  //상담사 삭제 끝

  const clickItem = (item) =>{
    !selectedItem.map(val=>val.categoryInfo.categoryId).includes(item.categoryInfo.categoryId) && setSelectedItem( prev=>([...prev, item]))
  }

  const itemClear = (categoryId) => {
    console.log("item clear::", categoryId)
    console.log(selectedItem)
    setSelectedItem(
      prev=> (prev.filter(val => (val.categoryInfo.categoryId !=categoryId)))
    );
  }

  const [selectedItem, setSelectedItem] = useState([]);

  //카테고리 변경
  const handleCategoryUpdate = () =>{
    console.log(selectedRow)
    let org = selectedRow.categoryItem
    let neww = selectedItem
    let o_arr = org.map(a=> a.categoryId)
    let n_arr = neww.map(a=> a.categoryInfo.categoryId)
    let add_arr = []
    let del_arr = []
    o_arr.forEach( o => {
      if ( ! n_arr.includes(o) )
        del_arr.push(o)
    })
    n_arr.forEach( n=>{
      if ( ! o_arr.includes(n)){
        add_arr.push(n)
      }
    })
    console.log("ADD::", add_arr)
    console.log("DEL::", del_arr)

    let cnt = add_arr.length + del_arr.length;

    add_arr.forEach(
      add_cat=>{
        mutationCounselorCategoryAdd(me.currentBranch, selectedRow.id, add_cat )
        .then((result) => {
          console.log("[add category] Query result", result.data);
        })
        .catch((e) => {
          console.log("[add category] Query Error", e);
        })
        .finally(()=>{
          cnt--;
          console.log("complete", cnt)
          
          if (cnt==0){
            setCategoryOpen(false);
            fnGetCounselors(me.currentBranch)
            setSelectedRow(null);
            setDisbledAction({ disabled: true });
          }
        });
      }
    )
    
    del_arr.forEach(
      del_cat=>{
        mutationCounselorCategoryDelete(me.currentBranch, selectedRow.id, del_cat )
        .then((result) => {
          console.log("[del category] Query result", result.data);
        })
        .catch((e) => {
          console.log("[del category] Query Error", e);
        }).finally(()=>{
          cnt--;
          console.log("complete", cnt)
          
          if (cnt==0){
            setCategoryOpen(false);
            fnGetCounselors(me.currentBranch)
            setSelectedRow(null);
            setDisbledAction({ disabled: true });
          }
        });

      }
    )
    if (!add_arr.length && !del_arr.length){
      simpleAlert("변경된 카테고리가 없습니다.")
    }

  }
  //카테고리 변경 끝

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} color={"primary"}>
          상담사관리
        </Typography>
        <div className={classes.content}>
          <div className={classes.action}>
            <Button
              className={classes.newMemBtn}
              startIcon={<Add />}
              onClick={handleInvite}
            >
              {" "}
              상담사 초대
            </Button>
            <Button
              className={classes.actionBtn}
              onClick={handleActionClick}
              transitioncomponent={Fade}
              {...disabledAction}
            >
              Action
              <ArrowDropDown />
            </Button>
            <Menu
              id={"action-menu"}
              anchorEl={actionEl}
              keepMounted
              open={Boolean(actionEl)}
              onClick={handleActionMenuClose}
              onClose={handleActionMenuClose}
              transitioncomponent={Fade}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem className={classes.menu} dense onClick={handleReset}>
                <ListItemText primary="패스워드 초기화" />
              </MenuItem>
              <MenuItem className={classes.menu} dense onClick={handleRole}>
                <ListItemText primary="권한 편집" />
              </MenuItem>
              <MenuItem className={classes.menu} dense onClick={handleDelete}>
                <ListItemText primary="상담사 삭제" />
              </MenuItem>
              <MenuItem className={classes.menu} dense onClick={handleCategory}>
                <ListItemText primary="카테고리 설정" />
              </MenuItem>
            </Menu>
          </div>
          <CommonTable
            key={`common_table_counselor`}
            data={tableData}
            headCells={headCells}
            rows={rows}
            checkSelect = {true}
            // selectRow={selectRow}
            checkRow = {selectRow}
          ></CommonTable>
        </div>
        {/* 상담사 초대 팝업 */}
        <Dialog
          fullWidth
          className={classes.bigDialog}
          open={inviteOpen}
          onClose={() => {
            setInviteOpen(false);
          }}
          aria-labelledby="invite-title"
        >
          <DialogTitle id="invite-title">상담사 초대</DialogTitle>
          <DialogContent>
            <DialogContentText>
              초대할 상담사의 이메일 주소와 권한을 입력해주세요.
            </DialogContentText>
            <TextField
              className={classes.emailInput}
              autoFocus
              margin="dense"
              id="email"
              label="Email Address*"
              type="email"
              fullWidth
              variant="outlined"
              onChange={handleEmailChange}
              // error={!email.length}
            />
            <TextField
              className={classes.emailInput}
              autoFocus
              margin="dense"
              id="name"
              label="Name*"
              // type="email"
              fullWidth
              variant="outlined"
              onChange={handleNameChange}
              // error={!email.length}
            />
            <Autocomplete
              className={classes.roleInput}
              multiple
              id="tags-outlined"
              options={roleData}
              getOptionLabel={(option) => option.roleName}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="권한*"
                  placeholder="Roles"
                />
              )}
              onChange={handleRoleOptionChange}
            />
            <div className={classes.category}>
              <div className={classes.categoryTitle}>
                카테고리
              </div>
              <div>
              <Grid container className={classes.container}>
                  <Grid item className={classes.categoryContent} xs={12} sm={6} md={6} >

                      <CounselorCategory
                        containCounselors = {false}
                        clickItem={clickItem}
                      >
                      </CounselorCategory>
                  </Grid>
                  <Grid item className={classes.categorySelected} xs={12} sm={6} md={6} >
                    {/* <div className={classes.selected}/> */}
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      {selectedItem && selectedItem.map(value=>{
                        return(
                        <List className={classes.list} >
                          <ListItem key={value.categoryInfo.categoryId} role={undefined} dense button className={classes.selectedItem} >
                            <ListItemIcon>
                              <Folder/>
                            </ListItemIcon>
                            <ListItemText primary={`${value.categoryInfo.name}(${value.categoryInfo.categoryId})`}/>
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="comments" onClick={()=>itemClear(value.categoryInfo.categoryId)}>
                                <Clear />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>


                        )


                      })}
                      
                    </Box>

                  
                  </Grid>
                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInviteSubmit} color="primary">
              초대
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 패스워드 초기화 */}
        <Dialog
          className={classes.dialog}
          open={resetOpen}
          onClose={() => {
            setResetOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">패스워드 초기화</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
            </div>
            <div>
              <TextField
                className={classes.password}
                id="filled-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                margin="dense"
              />
            </div>
            <div>
              <TextField
                className={classes.password}
                id="filled-password-input"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                margin="dense"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary">
              변경
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 권한 편집 */}
        <Dialog
          className={classes.dialog}
          open={roleOpen}
          onClose={() => {
            setRoleOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">권한 수정</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="dense"
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="dense"
              />
            </div>
            {/* 권한수정>권한 */}
            <Autocomplete
              className={classes.roleInput}
              multiple
              id="tags-outlined"
              options={roleData.map((r) => r.roleName)}
              // getOptionLabel={(option) => option.role}
              filterSelectedOptions
              defaultValue={
                selectedRow &&
                selectedRow.role &&
                selectedRow.role.map((r) => r.roleName)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="권한*" />
              )}
              onChange={handleRoleEditChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRoleSubmit}
              color="primary"
              {...disabledRoleEdit}
            >
              변경
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 상담사 삭제 */}
        <Dialog
          className={classes.dialog}
          open={deleteOpen}
          onClose={() => {
            setDeleteOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">상담사 삭제</DialogTitle>
          <DialogContent>
            <DialogContentText>
              해당 상담사를 삭제하시겠습니까?
            </DialogContentText>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteSubmit} color="primary">
              상담사 삭제
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* 카테고리 설정 */}
        <Dialog
          className={classes.bigDialog}
          open={categoryOpen}
          onClose={() => {
            setCategoryOpen(false);
          }}
          aria-labelledby="reset-title"
        >
          <DialogTitle id="reset-title">카테고리 설정</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 이름"
                defaultValue={selectedRow && selectedRow.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="dense"
                fullWidth
              />
              <div></div>
              <TextField
                className={classes.emailInput}
                id="outlined-read-only-input"
                label="상담사 ID"
                defaultValue={selectedRow && selectedRow.id}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </div>

            <div className={classes.category}>
              <div className={classes.categoryTitle}>
                카테고리
              </div>
              <div>
                <Grid container className={classes.container}>
                  <Grid item className={classes.categoryContent} xs={12} sm={6} md={6} >

                      <CounselorCategory
                        containCounselors = {false}
                        clickItem={clickItem}
                      >
                      </CounselorCategory>
                  </Grid>
                  <Grid item className={classes.categorySelected} xs={12} sm={6} md={6} >
                    {/* <div className={classes.selected}/> */}
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      {selectedItem && selectedItem.map(value=>{
                        return(
                        <List className={classes.list}  key={`${value.categoryInfo.categoryId}_0`}>
                          <ListItem key={`${value.categoryInfo.categoryId}_1`} role={undefined} dense button className={classes.selectedItem} >
                            <ListItemIcon>
                              <Folder/>
                            </ListItemIcon>
                            <ListItemText primary={`${value.categoryInfo.name}(${value.categoryInfo.categoryId})`}/>
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="comments" onClick={()=>itemClear(value.categoryInfo.categoryId)}>
                                <Clear />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>


                        )


                      })}
                      
                    </Box>

                  
                  </Grid>
                </Grid>
              </div>
            </div>  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCategoryUpdate} color="primary">
              카테고리 변경
            </Button>
            <Button onClick={handleClose} color="primary">
              취소
            </Button>
          </DialogActions>
        </Dialog>

        {/* Simple Alert  */}
        <Dialog
          open={simpleAlertOpen}
          onClose={()=>setSimpleAlertOpen(false)}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {simpleAlertMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setSimpleAlertOpen(false)} autoFocus>확인</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
export default withStyles(styles)(Management);
