import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
  },
});

const Circular = (props) => {
  const { classes, series, width, height } = props;

  const options = {
    chart: {
      type: "pie",
      style: {
        fontFamily: 'LG'
      }
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
    },
    title: {
      text: "",
      align: "center",
      verticalAlign: "center",
      y: 0,
    },
    subtitle: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          format: "{point.name}: {point.percentage:.1f} %",
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
            fontSize: "10px",
          },
          crop: true,
          distance: -80,
        },
      },
    },
    tooltip: {
      formatter: function() {
        return "<b>" + this.point.name + "</b>: " + this.point.y.toFixed(1);
      },
    },
    colors: [
      "#0A8297", //"#e91e5f", // "#277abe",
      "#e1e1e1",
    ],

    // DATA
    series: [],
  };

  options.series = series;
  options.chart.height = height;
  options.chart.width = width;
  options.title.y = height / 2;

  if (series && series[0] && series[0].data[0] && series[0].data[0].y) {
    options.title.text = `<b style="font-size: 30px">${series[0].data[0].y}%</b>`;
  } else {
    options.title.text = `<b style="font-size: 30px">No Data</b>`;
  }

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
      />
    </div>
  );
};
export default withStyles(styles)(Circular);
