import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Add, Folder } from "@material-ui/icons";
import {
  getAssignableCounselor,
  getCategoryMap,
  mutationCategoryAdd,
  mutationCategoryDelete,
  mutationCategoryUpdate,
} from "../data/Loader";
import { useUser } from "../data/User";
import { Tree } from "@minoru/react-dnd-treeview";
import TreeNode from "../tree/TreeNode";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    fontWeight: "bold",
    minHeight: "45px",
  },
  panel: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  addContainer: {
    width: "100%",
    height: "24px",
    borderRadius: "8px",
    border: "1px dashed #cdcdcd",
    "&:active": {
      backgroundColor: "#0a8297",
      color: "#fff",
      border: "none",
    },
    marginBottom: "24px",
  },
  leftPanel: {
    width: "360px",
  },
  rightPanel: {
    flexGrow: 1,
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
  },

  // NEW
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  icon: {
    fontSize: "1rem",
    margin: "0px 4px",
    color: theme.palette.text.secondary,
    border: "none",
    borderRadius: "50%",
    "&:active": {
      backgroundColor: "#0a8297",
      color: "#fff",
    },
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  dialog: {
    minWidth: "300px",
    minHeight: "200px",
  },
  textfield: {
    width: "100%",
  },
  treeRoot: {
    height: "100%",
  },
  draggingSource: {
    opacity: ".3",
  },
  dropTarget: {
    backgroundColor: "#e8f0fe",
  },
  button: {
    color: "#ffffff",
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    width: "100px",
    height: "32px",
    outline: 0,
    border: 0,
    borderRadius: "16px",
    margin: theme.spacing(2),
  },
  paper: {
    margin: "0px 12px",
  },
  tableTitle: {
    margin: "12px",
  },
  searchTh: {
    padding: "2px",
    fontSize: "0.85em",
    textAlign: "center",
    background: "#f5f5f5",
    fontWeight: "600",
  },
  searchCol: {
    padding: "2px",
    fontSize: "0.85em",
    // borderLeft: " 1px solid #ececec",
    textAlign: "center",
  },
  rightComponent: {
    alignSelf: "flex-end",
  },
});

const NewCategoryDialog = withStyles(styles)((props) => {
  const { classes, open, onCancel, onOK } = props;
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleOK = (e) => {
    setValue("");
    onOK(value);
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open}>
      <DialogTitle>신규 카테고리 생성</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.textfield}
          label={"카테고리이름"}
          value={value}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>취소</Button>
        <Button onClick={handleOK}>생성</Button>
      </DialogActions>
    </Dialog>
  );
});

const RemoveCategoryDialog = withStyles(styles)((props) => {
  const { classes, open, onCancel, onOK, target } = props;

  if (!target) return null;

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open}>
      <DialogTitle>카테고리 삭제</DialogTitle>
      <DialogContent>
        <Typography color="primary">
          <Folder icon={Folder} color={"primary"} />
          <b>{target.text}</b>
        </Typography>
        삭제하시겠습니까?
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>취소</Button>
        <Button onClick={() => onOK(target)}>삭제</Button>
      </DialogActions>
    </Dialog>
  );
});

function Management(props) {
  const { classes } = props;
  const { me } = useUser();
  const [newCategoryOpen, setNewCategoryOpen] = useState(false);
  const [removeCategoryOpen, setRemoveCategoryOpen] = useState(false);
  const [removeCategoryItem, setRemoveCategoryItem] = useState();
  const [expandedAll, setExpandedAll] = useState(false);
  const treeRef = useRef(null);

  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  var categories = [];

  const getAllCategories = (nodes) => {
    Object.keys(nodes).forEach((key) => {
      categories.push(nodes[key].categoryInfo.categoryId);
      if (nodes[key].path && Object.keys(nodes[key].path).length !== 0)
        getAllCategories(nodes[key].path);
    });
    return categories;
  };

  const makeCounselorData = (cns) => {
    // -> {categoryId: [ ]}
    var json = {};
    cns.forEach((c) => {
      c.category.forEach((cat) => {
        if (cat.categoryId in json) json[cat.categoryId].push(c);
        else json[cat.categoryId] = [c];
      });
    });
    return json;
  };

  const makeData = (nodes, parentId, counselor) => {
    var data = [];
    Object.keys(nodes).forEach((categoryKey) => {
      var node = nodes[categoryKey];
      var categoryId = node.categoryInfo.categoryId;

      data.push({
        id: categoryId,
        parent: parentId,
        text: node.categoryInfo.name,
        droppable: true,
        editable: node.categoryInfo.editable,
        data: {
          fileType: "category",
        },
      });

      Object.keys(counselor).forEach((counselorKey) => {
        if (categoryKey === counselorKey) {
          counselor[counselorKey].forEach((user) => {
            user.category.forEach((category) => {
              if (category.categoryId === categoryId) {
                data.push({
                  id: user.counselorId,
                  parent: categoryId,
                  text: user.name,
                  droppable: false,
                  editable: false,
                  data: {
                    fileType: "user",
                  },
                });
              }
            });
          });
        }
      });

      if (Object.keys(node.path).length > 0) {
        data = [
          ...data,
          ...makeData(node.path, node.categoryInfo.categoryId, counselor),
        ];
      }
    });

    return data;
  };

  const getCategory = () => {
    getCategoryMap(me.branch[0])
      .then((result) => {
        console.log("[Category] Query Result", result.data);
        var data = JSON.parse(result.data.getCategoryMap);
        const all_cat = getAllCategories(data);
        getAssignableCounselor(me.branch[0], all_cat)
          .then((result) => {
            console.log("[getAssignableCounselor] Query Result", result.data);
            var assignable_arr = result.data.getAssignableCounselor;
            var assignable_json = makeCounselorData(assignable_arr);
            var newData = makeData(data, null, assignable_json);
            var original = makeData(data, null, assignable_json);

            console.log("newData", newData);
            setTreeData(newData);
            setOriginalData(original);
          })
          .catch((e) => {
            console.log("[getAssignableCounselor] Query Error", e);
          });
      })
      .catch((e) => {
        console.log("[get category] Query Error", e);
      });
  };

  const handleDialogCancel = () => {
    setNewCategoryOpen(false);
    setRemoveCategoryOpen(false);
  };

  const handleNewCategory = (name) => {
    setNewCategoryOpen(false);
    console.log(name);

    mutationCategoryAdd(me.branch[0], name)
      .then((result) => {
        console.log("[handleNewCategory] Result", result);
        getCategory();
      })
      .catch((e) => {
        console.log("[handleNewCategory] ERROR", e);
      });
  };

  const handleRemoveCategory = (item) => {
    setRemoveCategoryItem(null);
    setRemoveCategoryOpen(false);

    mutationCategoryDelete(item.id)
      .then((result) => {
        console.log("[handleRemoveCategory] Result", result);
        getCategory();
      })
      .catch((e) => {
        console.log("[handleRemoveCategory] ERROR", e);
      });
  };

  const [treeData, setTreeData] = useState();
  const [change, setChange] = useState([]);

  // Compare Object
  const compare = (x, y) => {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue;
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue;
      // if they have the same strict value or identity then they are equal

      if (typeof x[p] !== "object") return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!compare(x[p], y[p])) return false;
      // Objects and Arrays must be tested recursively
    }

    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined

    return true;
  };

  // 카테고리 위치 변경
  const handleNodeDrop = (
    newTree,
    { dragSourceId, dropTargetId, dragSource, dropTarget }
  ) => {
    setTreeData(newTree);
    setChange((prev) => {
      const target = prev.find((t) => t.id === dragSourceId);
      if (target) {
        target.parent = dropTargetId;
        const original = originalData.find((o) => o.id === dragSourceId);
        if (original && original.parent === dropTargetId) {
          const filtered = prev.filter((p) => p.id !== dragSourceId);
          return filtered;
        }
      } else {
        prev.push({
          id: dragSourceId,
          parent: dropTargetId,
          text: dragSource.text,
        });
      }
      return [...prev];
    });
  };

  // 카테고리 텍스트 변경
  const handleNodeTextChange = (text, node) => {
    setTreeData((prev) => {
      const target = prev.find((data) => data.id === node.id);
      target.text = text;
      return [...prev];
    });
    setChange((prev) => {
      const target = prev.find((t) => t.id === node.id);
      if (target) {
        target.text = text;
        const original = originalData.find((o) => o.id === node.id);
        if (original && original.text === text) {
          const filtered = prev.filter((p) => p.id !== node.id);
          return filtered;
        }
      } else {
        prev.push({ id: node.id, parent: node.parent, text: text });
      }
      return [...prev];
    });
  };

  const handleSave = () => {
    change.forEach((c) => {
      mutationCategoryUpdate(c.id, c.parent, c.text)
        .then((success) => {
          console.log("[Management_Category] Save Success", success);
        })
        .catch((error) => {
          console.log("[Management_Category] Save Error", error);
        });
    });
    console.log("handleSave", change);
    setOriginalData(treeData);
    setChange([]);
  };

  const handleExpandChanged = (_, value) => {
    console.log("handleExpandChanged", value, treeRef);
    setExpandedAll(value);
    if (treeRef) {
      if (value) treeRef.current.openAll();
      else treeRef.current.closeAll();
    }
  };

  const handleNodeRemove = (node) => {
    setRemoveCategoryItem(node);
    setRemoveCategoryOpen(true);
  };

  const handleCanDrag = (node) => {
    return node.editable;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color={"primary"}>
        카테고리 관리
      </Typography>
      {treeData && (
        <div className={classes.panel}>
          <div className={[classes.column, classes.leftPanel].join(" ")}>
            <div className={[classes.row, classes.rightComponent].join(" ")}>
              펼쳐보기
              <Switch
                checked={expandedAll}
                onChange={handleExpandChanged}
                color={"primary"}
              />
            </div>
            <Tree
              id="tree"
              tree={treeData}
              rootId={null}
              onDrop={handleNodeDrop}
              ref={treeRef}
              canDrag={handleCanDrag}
              insertDroppableFirst={false}
              render={(node, { depth, isOpen, onToggle, hasChild }) => (
                <TreeNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={onToggle}
                  onChange={handleNodeTextChange}
                  hasChild={hasChild}
                  onRemove={handleNodeRemove}
                />
              )}
              classes={{
                root: classes.treeRoot,
                draggingSource: classes.draggingSource,
                dropTarget: classes.dropTarget,
              }}
            />
            <Add
              className={classes.addContainer}
              onClick={() => setNewCategoryOpen(true)}
            />
          </div>
          <div className={[classes.rightPanel, classes.column].join(" ")}>
            {change.length > 0 && (
              <Paper className={classes.paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ root: classes.searchTh }}>
                        Category
                      </TableCell>
                      <TableCell classes={{ root: classes.searchTh }}>
                        변경된 이름
                      </TableCell>
                      <TableCell classes={{ root: classes.searchTh }}>
                        변경된 상위 카테고리
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {change.map((c) => {
                      const original = originalData.find((o) => o.id === c.id);
                      if (!original) return null;

                      const changedParent =
                        original &&
                        original.parent !== c.parent &&
                        c.parent === null
                          ? "0"
                          : originalData.find((o) => o.id === c.parent);
                      const originalParent =
                        changedParent &&
                        original &&
                        originalData.find((o) => o.id === original.parent);

                      console.log(
                        "original",
                        original,
                        "c",
                        c,
                        "changedParent",
                        changedParent,
                        "originalParent",
                        originalParent
                      );
                      return (
                        <TableRow key={`change_${c.id}`}>
                          <TableCell classes={{ root: classes.searchCol }}>
                            {original.text}
                          </TableCell>
                          <TableCell classes={{ root: classes.searchCol }}>
                            {original.text !== c.text ? c.text : "-"}
                          </TableCell>
                          <TableCell classes={{ root: classes.searchCol }}>
                            {changedParent
                              ? changedParent === "0"
                                ? originalParent.text + " → 최상위"
                                : originalParent
                                ? originalParent.text +
                                  " -> " +
                                  changedParent.text
                                : "최상위 → " + changedParent.text
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            )}

            {/* 변경점 추가 */}
            {change.length > 0 && (
              <button
                className={[classes.button, classes.rightComponent].join(" ")}
                onClick={handleSave}
              >
                저장하기
              </button>
            )}
          </div>
        </div>
      )}
      <NewCategoryDialog
        open={newCategoryOpen}
        onCancel={handleDialogCancel}
        onOK={handleNewCategory}
      />
      <RemoveCategoryDialog
        target={removeCategoryItem}
        open={removeCategoryOpen}
        onCancel={handleDialogCancel}
        onOK={handleRemoveCategory}
      />
    </div>
  );
}
export default withStyles(styles)(Management);
