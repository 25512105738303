import React, { useEffect, useState, Fragment } from 'react';
import { withStyles, Typography, Box,Container, 
Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid,
Button, CircularProgress,
TextField} from '@material-ui/core';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

import interactionPlugin from "@fullcalendar/interaction";

import { ArrowRight, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { mutationAddHoliday, mutationDeleteHoliday, mutationSyncHolidayInfo, searchHolidayInfo } from '../data/Loader';
import { Paper } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import moment from 'moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import { useUser } from "../data/User";


const styles = (theme) => ({
  root: { height:"100%"},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
  },
  calendarPaper:{
    height:"calc(100% - 45px)",
    padding:"20px 35px",

    backgroundColor: "rgb(255, 255, 255)", 
    color: "rgb(33, 43, 54)", 
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", 
    overflow: "hidden", 
    position: "relative", 
    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px", 
    borderRadius: "16px", 
    zIndex: "0", 


  },
  calContainer:{
    height:"100%",
    "& .fc-h-event":{
      border:"none",
      background:"none",
    },
    "& .fc-event-main":{
      color:"red",
      padding:"5px",
      backgroundColor: "rgb(255, 255, 255)",

      "&::before":{
        zIndex:"8",
        opacity:"0.32",
        border:"1px solid currentcolor",
        content: '""',
        position:"absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "3px",
      },
      "&::after":{
        zIndex:"7",
        opacity:"0.24",
        backgroundColor: "currentcolor",
        content: '""',
        position: "absolute",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
      },
      "& .fc-event-main-frame":{
        fontSize: "13px",
        lineHeight: "20px",
        filter: "brightness(0.24)",
      },
    }, //fc-event-main
    "& .fc-header-toolbar":{
      display:"flex",
      height:"40px",
      "& .fc-toolbar-chunk":{
        display:"flex"
      },
      "& .fc-prev-button, & .fc-next-button":{
        display: "inline-flex",
        background: "none",
        border: "none",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        textDecoration: "none",
        textAlign: "center",
        flex: "0 0 auto",
        fontSize: "1rem",
        padding: "8px",
        borderRadius: "50%",
        overflow: "visible",
        color: "rgb(99, 115, 129)",
      },
      "& .fc-today-button, & .fc-sync-button":{
        borderRadius:"10px",
        fontWeight:"600",
        background: "#0a8297",
        color: "#fff",
        border: "none",
        width: "80px",
      }
    }
  },
  // new holiday dialog
  newDialogContent:{
     display:"flex",
     flexDirection:"column",
  },
  newDialogText:{

  },
  newDialogDatePicker:{
    marginTop:"24px"
  },

  calendarList:{
    // height:"100%",
    // border:"1px solid red",
    // marginTop:"36px",
    paddingLeft:"0px",
    "& .fc-theme-standard":{
      height:"100%",
    },
    "& .fc-list-event-time":{
      display:"none",
    }

  },
  loading:{
    position:"absolute",
    top:"50%",
    left:"50%"
  },
  loadingbg:{
    color:"#fff",
    position:"absolute",
    width:"100%",
    height:"100%",
    top: 0,
    left:0,
    zIndex: 2000,
    background: "rgb(255 255 255 / 70%)",
  }
});

function Management(props) {
  const { classes } = props;

  const [userEvent, setUserEvent] = useState([]);
  const [syncEvent, setSyncEvent] = useState([]);
  const [validRange, setValidRange] = useState({});
  const [currYear, setCurrYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [simpleAlertOpen, setSimpleAlertOpen] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");

  const { me } = useUser();

  useEffect(
    ()=>{

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      
      const fromDate = parseInt(yyyy + "0101")
      const toDate = parseInt(yyyy + "1231")
      setValidRange({
        start:yyyy + "-01-01",
        end:yyyy + '-12-31',
      });
      setCurrYear(yyyy);

      searchHolidayInfo(fromDate, toDate, "")
        .then((result) => {
          console.log("[search_holiday_info] Query Result", result.data)
          const info = result.data.searchHolidayInfo;
          setSyncEvent(
            info.filter(i=>i.updatedBy=="sync").map(i=>({
              title:i.dateName,
              date:String(i.date).slice(0,4)+"-"+String(i.date).slice(4,6)+"-"+String(i.date).slice(6,8) ,
              updatedBy:"SYNC"
              }))
          );
          setUserEvent(
            info.filter(i=>i.updatedBy=="user").map(i=>({
              title:i.dateName,
              date:String(i.date).slice(0,4)+"-"+String(i.date).slice(4,6)+"-"+String(i.date).slice(6,8) ,
              updatedBy:"USER"
              }))
          )
        })
        .catch((e) => {
          console.log("[search_holiday_info] Query Error", e);
        });
    }
    ,[]
  )

  const openNewEventDialog = () => {
    setNewEventDialogOpen(true);
  }
  const handleDateClick = (e) =>{

    // 클릭한 날짜가 holiday면  event click으로 변경 (Event View Dialoge open)
    const user = userEvent.filter(i=>i.date==e.dateStr)
    const sync = syncEvent.filter(i=>i.date==e.dateStr)
    
    if (user.length){
      console.log(user)
      handleEventClick(
        {
          event:{
            title:user[0].title,
            start:e.date
          }
        }
      )
    }else if (sync.length){
      console.log(sync)
      handleEventClick(
        {
          event:{
            title:sync[0].title,
            start:e.date
          }
        }
      )
    }else{
      //새로운 이벤트 생성
      setHolidayDesc("")
      setSelectedDate(e.date)
      openNewEventDialog();
    }

  }
  const handleEventClick = (info) =>{
    console.log("event_click")
    setViewEventDialogOpen(true);
    setHolidayDesc(info.event.title)
    setSelectedDate(info.event.start)
  }
  const handleNewEventDialogClose = () =>{
    setNewEventDialogOpen(false)
  }
  const submitNewHoliday = () => {
    const datestr =  moment(selectedDate).format("YYYYMMDD");
    const datestr_ =  moment(selectedDate).format("YYYY-MM-DD");
    
    mutationAddHoliday(me.branch, datestr, holidayDesc)
    .then((result) => {
      console.log("[Add Holiday] Query Result", result.data)
      const i= result.data.addHolidayInfo;
      const newVal = {
              title: holidayDesc,
              dateName: holidayDesc,
              date:datestr_,
               updatedBy: "user"
            }
      setUserEvent(
        prev=>{
          return [...prev, newVal]
        }
      )
      handleNewEventDialogClose();
      
    })
    .catch((e) => {
      console.log("[Add Holiday] Query Error", e);
    });

  }
  const handleHolidayDesc = (e) => {
    setHolidayDesc(e.target.value);
  }
  const handleDeleteHoliday = () =>{
    const datestr =  moment(selectedDate).format("YYYYMMDD");
    const datestr_ =  moment(selectedDate).format("YYYY-MM-DD");
    console.log("date selected:", datestr)
    mutationDeleteHoliday(me.branch, datestr)
    .then((result) => {
      console.log("[Delete Holiday] Query Result", result.data)
      setUserEvent(
        userEvent.filter(i=>i.date!=datestr_)
      )
      setViewEventDialogOpen(false)
    })
  }

  const [newEventDialogOpen, setNewEventDialogOpen] = useState(false);
  const [viewEventDialogOpen, setViewEventDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(""); //useState(new Date());
  const [holidayDesc, setHolidayDesc] = useState("");

  const handleSyncClick = () =>{

    setLoading(true)

    mutationSyncHolidayInfo(parseInt(currYear))
    .then((result) => {
      console.log("[sync_holiday_info] Query Result", result.data)

      const newEvent = result.data.syncHolidayInfo.map(i=>({
          title:i.dateName,
          date:String(i.date).slice(0,4)+"-"+String(i.date).slice(4,6)+"-"+String(i.date).slice(6,8) ,
      }))
      const updatedRecordNum = compareEvents(syncEvent, newEvent);

      console.log("updatedRecordNum::::", updatedRecordNum);

      setLoading(false);
      setSimpleAlertMsg(`${updatedRecordNum}건이 업데이트 되었습니다.`);
      setSimpleAlertOpen(true);
    })
    .catch((e) => {
      console.log("[sync_holiday_info] Query Error", e);
      setLoading(false);
      setSimpleAlertMsg(`업데이트에 실패하였습니다.`);
      setSimpleAlertOpen(true);
    });

  }

  const compareEvents = (orgEvent, newEvent) =>{

    var deleted = []
    var added= []

    orgEvent.forEach( o=>{
	
      var diff = false;
      newEvent.forEach( n=>{
        if (equal(o, n))
          diff = true;
      })
      
      if (!diff){
        deleted.push(o)
      }
    })
    
    newEvent.forEach( n =>{
    
      var diff = false;
      orgEvent.forEach( o =>{
        if (equal(o, n))
          diff = true;
      })
      
      if (!diff){
        added.push(n)
      }
    })

    return added.length + deleted.length
    

  }
  
  const equal= ( one, two ) => (one.date == two.date && one.title == two.title);


  const listCalendarEventHandler = (info)=>{
    const span = info.el.getElementsByClassName("fc-list-event-dot");
    const eventTitleEl = info.el.getElementsByClassName("fc-list-event-title");
    if (span && span.length){
      const update = info.event.extendedProps.updatedBy;
      span[0].innerHTML = update;
      span[0].style.color = update=="SYNC"?"#6b0000":"#003700";
      span[0].style.border= update=="SYNC"?"1px solid #ff8585":"1px solid #85c385";
      span[0].style.width= "100%";
      span[0].style.height= "100%";
      span[0].style.borderRadius= "3px";
      span[0].style.backgroundColor= update=="SYNC"?"#ffc2c2":"#c2e1c2";
      span[0].style.fontSize= "0.8em";
      span[0].style.padding= "2px";
      span[0].style.display= "inline";
    }
  }
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} color={"primary"}>
          휴일/업무시간 관리
        </Typography>
        {/* <div className={classes.subTitle}>
          <ArrowRight color="primary" />
          <Typography variant={"subtitle2"}> 휴일 달력</Typography> 
        </div> */}

        {/* <Container > */}
          {/* <Box> */}
            <Paper className={classes.calendarPaper}> 
              <Grid container  className={classes.calContainer} spacing={3}>
                <Grid item xs={12} sm={6} md={8} lg={8} >
                  <FullCalendar className={classes.calendar}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    locale={["ko"]}
                    eventSources={[
                        {
                          events: syncEvent,
                          textColor:"red",
                          updatedBy:"sync"
                        },
                        {
                          events: userEvent,
                          textColor:"green",
                          updatedBy:"user"
                        }
                      ]}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    validRange={validRange}
                    // contentHeight={650}
                    height={"100%"}
                    buttonText={{
                      today : "Today"
                    }}
                    headerToolbar={{
                      left : "",
                      center : 'prev title next',
                      right : "today",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.calendarList}>
                  <FullCalendar 
                    plugins={[listPlugin, interactionPlugin]}
                    initialView="listYear"
                    locale={["ko"]}
                    eventSources={[
                        {
                          events: syncEvent,
                          updatedBy:"sync",
                        },
                        {
                          events: userEvent,
                          updatedBy:"user"
                        }
                      ]}
                    // dateClick={handleDateClick}
                    // eventClick={handleEventClick}
                    validRange={validRange}
                    headerToolbar = {{ 
                        left: '',
                        center: '',
                        right: 'sync',
                      }}

                    customButtons = {{
                      sync: {
                        text: 'Sync',
                        click: handleSyncClick,
                      }
                    }}
                    eventDidMount= { listCalendarEventHandler}
                  />
                </Grid>
              </Grid>
            </Paper>
          {/* </Box> */}
        {/* </Container> */}
         
      </div> 
       {/* Create New Event */}
       <Dialog
        open={newEventDialogOpen}
        onClose={handleNewEventDialogClose}
        aria-labelledby="Create New Holiday"
        aria-describedby="Create New Holiday"
      >
        <DialogTitle id="alert-dialog-title">
          휴일 등록
        </DialogTitle>
        <DialogContent>
          <Box> 
            <form noValidate autoComplete="off">
              <div className={classes.newDialogContent}>
                <FormControl className={classes.newDialogText}>
                  <TextField id="outlined-basic" label="휴일 내용" variant="outlined" value={holidayDesc || ''} 
                  className={classes.textfield} onChange={handleHolidayDesc}/>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker className={classes.newDialogDatePicker}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="휴일"
                    format="yy-MM-dd"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => setSelectedDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitNewHoliday} autoFocus>등록 하기</Button>
          <Button onClick={handleNewEventDialogClose} autoFocus>취소</Button>
        </DialogActions>
       </Dialog>
       {/* View New Event */}
       <Dialog
        open={viewEventDialogOpen}
        onClose={()=>setViewEventDialogOpen(false)}
        aria-labelledby="Create New Holiday"
        aria-describedby="Create New Holiday"
      >
        <DialogTitle id="alert-dialog-title">
          휴일 View
        </DialogTitle>
        <DialogContent>
          <Box> 
            <form noValidate autoComplete="off">
              <div className={classes.newDialogContent}>
                <FormControl className={classes.newDialogText}>
                  <TextField id="outlined-basic" label="휴일 내용" variant="outlined" value={holidayDesc} disabled
                  className={classes.textfield} />
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker className={classes.newDialogDatePicker}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="휴일"
                    format="yy-MM-dd"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "start" }}
                    // onChange={(date) => setSelectedDate(date)}
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </div>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteHoliday} autoFocus>삭제 하기</Button>
          <Button onClick={()=>setViewEventDialogOpen(false)} autoFocus>확인</Button>
        </DialogActions>
       </Dialog>
       {loading && (
          <div className={classes.loadingbg}>
          <CircularProgress className={classes.loading}
            size={50}
          />
          </div>
        )}

        {/* Simple Alert  */}
        <Dialog
          open={simpleAlertOpen}
          onClose={()=>setSimpleAlertOpen(false)}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {simpleAlertMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setSimpleAlertOpen(false)} autoFocus>확인</Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
export default withStyles(styles)(Management);