import React, { useContext, Fragment } from "react";
import { withStyles, Divider } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import { openLink } from "../../util/utilNative";
import LeftTail from "../../../asset/img/bubble_point1.png";
import RightTail from "../../../asset/img/bubble_point3.png";
import Arrow from "../../../asset/img/ic_list_arrow_nor.png";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "lottie-react";

const styles = theme => ({
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  rightContainer: {
    justifyContent: "flex-end"
  },
  message: {
    fontSize: theme.font.size15, // "15px",
    borderRadius: "8px",
    border:"1px solid #d7d7d7",
    flexWrap: "wrap",
    color: theme.color.message.color,
    maxWidth: "75%",
    zIndex: 10,
    wordWrap: "break-word",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 6px 6px 9px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
    fontFamily: "LGSmHaTL",
  },
  rightMessage: {
    // backgroundColor: theme.color.message.background,
    margin: "8px 10px",
    boxShadow: "none",
    zIndex:"12"
  },
  text: {
    padding: "9px 20px",
  },
  date: {
    color: "#7f7f7f",
    fontSize: theme.font.size14,
    fontFamily: "LGSmHaTL",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
  },
  typing: {
    width: "30px",
    height: "6px"
  },
  list: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  item: {
    height: "40px",
    width: "100%",
    textAlign: "center",
    color: theme.color.accent,
    fontSize: theme.font.size14,
    fontFamily: "LGSmHaTSB",
    padding: "0px",
    margin: "0px",
    flex: 1,
    backgroundColor: "#FAFAFA",
    textTransform: "none",
    outline: "0 none",
    border: "0px",
    borderRadius: "0px 0px 8px 8px",
    borderTop:"none",
    "&:active": {
      backgroundColor: "rgba(38, 38, 38, 0.1)",
    },
  },
  vertical: {
    height: "auto"
  },
  leftTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid #fff',
    // borderRadius: '5px',
    zIndex: 11,
    margin: "6px -21px 0px 11px",
    backgroundImage: `url(${LeftTail})`,
    backgroundSize: "contain",
    width: "21px",
    height: "12px",
  },
  rightTail: {
    // borderLeft: '14px solid transparent',
    // borderRight: '14px solid transparent',
    // borderTop: '18px solid ' + theme.color.message.background,
    // borderRadius: '5px',
    zIndex: 9,
    margin: '26px 11px 0px -21px',
    backgroundImage: `url(${RightTail})`,
    backgroundSize: "contain",
    width: "21px",
    height: "12px"
  },
  buttonText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center",
    width: '100%',
    height: "40px",
    color: '#262626',
    padding: '0px 20px',
    // fontSize: theme.font.size14,
  },
  arrow: {
    width: "24px",
    height: "24px",
    backgroundSize: "24px",
    backgroundImage: `url(${Arrow})`
  }
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function TL(props) {
  const { classes, data } = props;
  const context = useContext(DataContext);

  const handleClick = (link) => {
    var type = link.type;
    var url = link.url; 
    if (type.toLowerCase() === "faq") {
      type = "inapp";
      url = `${window.location.href}ext/faq/faq.html?url=${url}`;
    }
    openLink(context, type, url, link.event_action);
  };

  return (
    <>
      <div
        className={[
          classes.root,
          data.mine ? classes.rightContainer : classes.leftContainer
        ].join(" ")}
      >
        {!data.loading && data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
        {data.mine==="true" && <div className={classes.leftTail}/>}
        <div
          className={[
            classes.message,
            data.mine ? classes.rightMessage : classes.leftMessage
          ].join(" ")}
        >
          {data.loading && (
            <Lottie
              {...lottieTypingOptions}
              className={classes.messageLoading}
              width={50}
              height={22}
            />
          )}

          <div className={classes.text}>
            {!data.loading &&
              data.text.split("\n").map((content, index) => (
                <Fragment key={index}>
                  {content}
                  <br />
                </Fragment>
              ))}
          </div>
          <Divider />
          <div className={classes.list}>
            {!data.loading && data.link && (
              data.link.map((link, index) => (
                <div key={index}>
                  {index !== 0 && <Divider />}
                  <button key={index}
                    className={classes.item} 
                    // disabled={context.conversationEnd}
                    onClick={() => handleClick(link)}>
                    <div className={classes.buttonText}>
                      <div>
                        {link.label}
                      </div>
                      <div className={classes.arrow}/>
                    </div>
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
        {/* {data.mine==="false" && <div className={classes.rightTail}/>} */}
        {!data.loading && !data.mine && (
          <p className={classes.date}>{data.date}</p>
        )}
      </div>
    </>
  );
}
export default withStyles(styles)(TL);
