import { withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import moment from "moment";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const styles = (theme) => ({
  root: {
    border: 0,
    flexGrow: 1,
    margin: "8px"
  },
  expired: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#262626",
  },
});


const ChatbotHistory = props => {
  const { classes, data } = props;
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    // URL의 만료 날짜를 계산
    if (data.chatUrl) {
      const amzParams = new URL(data.chatUrl).searchParams;
      const amzDate = amzParams.get("X-Amz-Date");
      const amzYYYYMMdd = amzDate.split("T")[0];
      const amzhhmmss = amzDate.split("T")[1];
      const amzExpires = amzParams.get("X-Amz-Expires");

      const date = new Date();
      date.setFullYear(amzYYYYMMdd.slice(0, 4), amzYYYYMMdd.slice(4, 6) - 1, amzYYYYMMdd.slice(6, 8));
      date.setHours(amzhhmmss.slice(0, 2), amzhhmmss.slice(2, 4), amzhhmmss.slice(4, 6));

      const current = moment.utc();
      console.log("[[ChatbotHistory]\ncurrent:", current.toString(), "\ncreated:", moment(date).toString(), "\nexpired:", amzExpires,"\ndiff:", current.diff(moment(date).utc(), "second")); 
      
      setExpired(current.diff(moment(date).utc(), "second") > amzExpires);
    }
  }, [])
  


  return (
    expired ? 
    <div className={classes.expired}>
      <AccessTimeIcon fontSize='large'/>
      <p>기간이 만료되어 확인할 수 없습니다.</p>
    </div> :
    <iframe title={"Chatbot History"} className={classes.root} src={data.chatUrl}/>
  );
}

export default withStyles(styles)(ChatbotHistory);