import React, { useContext, useState, Fragment } from "react";
import { withStyles, Grid, makeStyles } from "@material-ui/core";
import DataContext from "../../data/DataContext";
import Carousel from "../../carousel/Carousel";
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "lottie-react";

const styles = (theme) => ({
  root: {
    display: "flex",
    // letterSpacing: "-0.5px",
    fontFamily: "LGSmHaTL"
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  message: {
    fontSize: theme.font.size14,
    borderRadius: "12px",
    flexWrap: "wrap",
    color: theme.color.message.color,
    padding: "13px 19px 13px 20.5px",
    maxWidth: "75%",
  },
  leftMessage: {
    backgroundColor: "white",
    margin: "6px 10px",
    boxShadow: "0px 6px 12px rgba(194, 194, 194, 0.58)",
  },
  rightMessage: {
    backgroundColor: theme.color.message.background,
    margin: "26px 10px",
    boxShadow: "none",
  },
  carousel: {
    width: "100%",
    height: "144px",
    position: "initial",
  },
  date: {
    color: theme.color.message.date,
    fontSize: theme.font.size11,
    alignSelf: "flex-end",
    margin: "5px 0 6px 0",
    fontFamily: "LGSmHaTL"
  },
  typing: {
    width: "30px",
    height: "6px",
  },
  list: {
    display: "flex",
    overflowX: "auto",
    margin: "0px 8px 9px 8px",
  },
  listBottom: {
    position: "fixed",
    bottom: "60px",
  },
  listbutton: {
    minWidth: "fit-content",
    padding: "0px 4px",
    margin: "0px",
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    outline: "0 none",
    border: "none",
    backgroundColor: "transparent",
    // fontSize: theme.font.size14,
    fontSize: "1em"
  },
  listitem: {
    height: "2em",
    padding: "4px 20px",
    margin: "0px",
    background: "#fff",
    border: "1px solid #00a0b6",
    fontSize: theme.font.size14,
    fontFamily: "LGSmHaTSB",
    color: "#00a0b6",
    outline: "0 none",
    borderRadius: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:active": {
      background: "#00a0b6",
      color: "#fff"
    },
    zIndex: 11
  },
  leftTail: {
    borderLeft: "14px solid transparent",
    borderRight: "14px solid transparent",
    borderTop: "18px solid #fff",
    borderRadius: "5px",
    zIndex: 10,
    margin: "6px -28px 0px 10px",
  },
  grid: {
    width: "100%",
    display: "flex",
  },
  bottom: {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end",
    zIndex: 10,
    margin: "6px 0px"
  },
  bottomSpace: {
    height: "50px",
  },
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const SGButton = React.forwardRef((props, ref) => {
  const { classes, data } = props;
  const [buttonVisible, setButtonVisible] = useState(true);
  const context = useContext(DataContext);

  const handleClick = (button) => {
    if (ref.clickAllowed()) {
      setButtonVisible(false);
      context.request(context, button.label, "button");
    }
  };
  return data.buttons.map(
    (button, key) =>
      buttonVisible && (
        <button
          key={key}
          className={classes.listbutton}
          onClick={() => handleClick(button)}
        >
          <div className={classes.listitem}>{button.label}</div>
        </button>
      )
  );
});

const SGGrid = (props) => {
  const { data } = props;
  const [buttonVisible, setButtonVisible] = useState(true);
  const context = useContext(DataContext);
  const useStyles = makeStyles({
    list: {
      width: "100%",
      display: "flex",
      padding: "0px 20px 9px 20px",
    },
    listitemContainer: {
      padding: "2px",
    },
    listitem: {
      // minWidth: 'fit-content',
      width: "100%",
      height: "32px",
      padding: "4px 10px",
      background: "#fff",
      border: "1px solid #00a0b6",
      fontSize: ".875em",
      color: "#00a0b6",
      borderRadius: "16px",
      textTransform: "none",
      fontFamily: "LGSmHaTSB",
      outline: "0 none",
      "&:active": {
        background: "#00a0b6",
        color: "#fff"
      }
    },
  });
  const classes = useStyles();

  const handleClick = (button) => {
    setButtonVisible(false);
    context.request(context, button.label, "button");
  };
  return (
    buttonVisible &&
    !context.conversationEnd && (
      <Grid container className={classes.list} direction="row">
        {data.buttons.map((button, key) => (
          <Grid item xs={6} key={key} className={classes.listitemContainer}>
            <button
              className={classes.listitem}
              onClick={() => handleClick(button)}
            >
              {button.label}
            </button>
          </Grid>
        ))}
      </Grid>
    )
  );
};

function SG(props) {
  const { classes, data } = props;
  return (
    <>
      {data.text && data.text !== "" && (
        <div
          className={[
            classes.root,
            data.mine ? classes.rightContainer : classes.leftContainer,
          ].join(" ")}
        >
          {!data.loading && data.mine && (
            <p className={classes.date}>{data.date}</p>
          )}
          {!data.mine && <div className={classes.leftTail} />}
          <p
            className={[
              classes.message,
              data.mine ? classes.rightMessage : classes.leftMessage,
            ].join(" ")}
          >
            {data.loading && (
              <Lottie
                {...lottieTypingOptions}
                className={classes.messageLoading}
                width={50}
                height={22}
              />
            )}
            {!data.loading &&
              data.text.split("\n").map((content, key) => (
                <Fragment key={key}>
                  {content}
                  <br />
                </Fragment>
              ))}
          </p>
          {!data.loading && !data.mine && (
            <p className={classes.date}>{data.date}</p>
          )}
        </div>
      )}
      {data.isLast &&
        !data.loading &&
        (data.isWelcome ? (
          <SGGrid className={classes.grid} data={data} />
        ) : (
          data.buttons.length > 0 && (
            <div className={classes.bottom}>
              <Carousel
                className={classes.carousel}
                component={withStyles(styles)(SGButton)}
                data={data}
                indicator={false}
                arrows={false}
                blur={true}
              />
            </div>
          )
        ))}
    </>
  );
}
export default withStyles(styles)(SG);