import React, { useState } from 'react';
import { withStyles, Grid, AppBar, Tabs, Tab } from "@material-ui/core";
import ProductSearch from './ProductSearch'
import CenterSearch from './CenterSearch'
import SymptomSearch from './SymptomSearch'
import EvRequest from './EvRequest';
const styles = (theme) => ({
  root:{
    display:"flex",
    background:"#fff",
    height: "100%",
  },
  tabPanel: {
    width: "100%",
    margin: "-17px 0px 0px 0px",
    paddingLeft: "10px",
    height: "calc(100% - 17px)"
  },
  container: {
    width: "100%"
  },
  panel: {
    width: "100%"
  },
  appBar:{
    margin:'25px 10px 10px',
    boxShadow:'none',
    backgroundColor:'transparent',
  },
  tabs:{
    minHeight:'25px',
    height:'25px',
  },
  tab:{
    padding:'5px 10px',
    minWidth:'90px',
    // border: '1px solid #827f80' ,
    backgroundColor: '#fff',
    fontSize: '0.75rem',
    color:'#000000',
    minHeight:'25px',
    height:'25px',
    boxShadow:'none',
  },
  tabFirst:{
    borderRight:"none",
    borderRadius: '5px 0px 0px 5px',
  },
  tabCenter:{
    borderRight:"none",
    borderRadius: '0px 0px 0px 0px',
  },
  tabLast:{
    borderRadius: '0px 5px 5px 0px',
  },

  tabSelected:{
    // color: "#ffffff",
    // fontWeight:"600",
    // // color: #bf134a;
    // background: "#827f80",
    // fontWeight: "600",
  },

  indicator:{
    background:"#0a8297",
  },

});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

const Panel = withStyles(styles)((props) => {
  const { Component, classes, key, index, value, data } = props;

  return (index === value &&
    <Component key={key} data={data} />
  );
});


function Support(props) {
    const { classes, value, index, data } = props;
    const [select, setSelect] = useState(0);
  
    const tabs = [
      // {
      //   title: "GSCS",
      //   panel: InfoSearch,
      //   class:classes.tabFirst
      // }, 
      {
        title: "센터검색",
        panel: CenterSearch,
        class:classes.tabCenter
      }, 
      {
        title: "고장증상",
        panel: SymptomSearch,
        class:classes.tabCenter
      }, 
      {
        title: "제품정보",
        panel: ProductSearch,
        class:classes.tabLast
      }, 
      {
        title: "출장서비스 예약",
        panel: EvRequest,
        class:classes.tabLast
      }

    ];

    const handleChange = (_, value) => {
      setSelect(value);
    }

    return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
    >
      {value === index && (
        <div className={classes.root}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12} md={12} className={classes.rightLayout}>
                <AppBar position="static" classes={{root:classes.appBar}}>
                  <Tabs
                    value={select}
                    onChange={handleChange}
                    classes={{
                      root: classes.tabs,
                      indicator: classes.indicator,
                    }}>
                    {tabs.map((tab, index) => (
                      <Tab
                        key={`monitor_cs_tab_${tab.title}`}
                        label={tab.title}
                        index={index}
                        className={[tab.class, classes.tab].join(" ")}
                        classes={{selected:classes.tabSelected }} 
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                {tabs.map((tab, i) => (
                  // <Dashboard key={`monitor_cs_tabpanel_${i}`} index={i} value={select} />
                  <Panel key={`chat_tabpanel_${i}`} index={i} value={select} Component={tab.panel} data={data}/>
                ))}
              </Grid>
            </Grid>
        </div>
      )}
    </div>
  
    );
  
  
}
export default withStyles(styles)(Support);