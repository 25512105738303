import React, {useContext, createContext, useEffect, useState} from 'react';
import { Base64 } from 'js-base64';
import { withStyles, Typography, Divider, Grid } from '@material-ui/core';

import Carousel from "../../carousel/Carousel";
import SG from "../Message/SG"
import TX from "../Message/TX"
import CT from "../Message/CT"
import ProductCard from "../Message/ProductCard"
import {Card, Button, TextField, FormControl, Select, MenuItem, InputLabel} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { mutationSendMessage, getProductInfo, getSymptomCodeSubs, getSymptomCodes, getSymptomProductGroups, getSymptomProducts, getCustomer} from '../../data/Loader';
import {mkTLStr,mkProductCardStr, mkReserveStr} from '../../data/DataConvert'
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const productCompareURL = 'https://www.lge.co.kr/compare-products?chatbot=y&modelId=';

const styles = (theme) => ({
  root: {
    height: "-webkit-fill-available",
    fontFamily: 'LGSmHaT',
    color:'#8e8e8e',
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "5px",
  },
  div:{
    padding:"20px 5px",
    background: "#f7f7f7",
    margin: "10px 0px",
  },
  doSearchButton:{
    width:"25%",
    margin:"5px",
    boxShadow:"none",
    background:"#0a8297",
  },
  conditionAddButton:{
    width:"25%",
    margin:"5px",
    boxShadow:"none",
    border: "1px solid #0a8297",
    color: "#0A8297",
    backgroundColor: "#f5f5f5",
    fontWeight: 600,
    padding: "0px 19px",
    fontSize: "0.8rem",
    height: "31px",
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  }, 
  title:{
    fontWeight:'600',
    color:'#7b7a7a',
    paddingLeft:'15px',
    paddingBottom:'5px',
  },
  td:{
    background:'none',
    border:'none',
    padding:'3px 24px 3px 16px',
    color:'#7b7a7a'
  },
  searchInput:{
    display:'inline'
  },
  contentSearchTextField: {
    width: "75%",
    margin: "5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  },
  contentSearchTextField2: {
    width: "75%",
    margin: "5px",
    '& label':{
      fontSize:"0.8rem",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
      transform: "translate(15px, 10px) scale(1)",
    },
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.8rem"
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      "paddingTop":"8px",
      "paddingBottom":"8px",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },
  
  value:{

  },
  refreshIcon:{
    float:'right',
  },
  carousel:{
    color:'yellow'
  },
  carouselDiv:{
    height:'100px',
  },
  gscsBtn:{
    backgroundColor:'#bf134a',
    width:'100px',
  },
  inputSearchDiv:{
    minWidth:"100%",
    display:"flex",
  },
  selectDiv:{
    minWidth:"100%",
    display:"flex",
  },
  subTitle:{
    margin:"20px 10px 0px 0px",
    fontWeight:"600",
    color:"#5a5a5a",
    display:"flex",
    alignItems:"center",
    fontSize: "1.0rem"
  },
  subConditionDiv:{
    background: "#f5f5f5",
    margin: "10px 5px 10px 5px",
    // paddingTop: "10px"
  },
  subCondition:{
    // minWidth:"100%",
    display:"flex",
    // border:"1px solid #ececec",
    // margin:"5px"

  },
  selectFormControl:{
    padding:0,
    width: "50%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.84rem"
    }
  },
  selectFormControl2:{
    textAlign:"center",
    height:"33px",
    padding:0,
    width: "50%",
    margin:"5px",
    '& .MuiOutlinedInput-root': {
      "borderRadius": "0px",
    },
    '& .MuiInputBase-root' : {
      "fontSize":"0.8rem"
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      "padding":"8px 32px 8px 14px"
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
      transform: "translate(15px, 10px) scale(1)",
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },
  menuItem:{
    lineHeight:"1.1",
    fontSize: "0.84rem",
  },
  selectLabel2:{
    fontSize: "0.8rem"
  },
  submitBtn:{
    // display:'inline',
    alignSelf:'center',
    textAlign:'center',
    // margin:"15px",
  },
  submit:{
    backgroundColor:'#0a8297',
    // width:'100px',
    margin:"5px",
  },
  submitCancel:{
    backgroundColor:'#a9a8a8',
    width:'100px',
  },
  searchBtnDiv:{
    display:"flex",
    justifyContent:"flex-end"
  },
  carousels:{
    minHeight: "400px",
  },
  carouselMsg:{
    border: "1px solid #c4c4c4",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    height: "400px",
    margin: "5px",
  },
  alert:{
    margin:"-5px 5px 5px 5px",
  },
  alertBox:{
    marginTop:"10px"
  }
});

function EvRequest(props) {
  const { classes, data } = props;
  const counselorId = data && data.counselorId;
  const convId = data && data.convId;
  const customerId = data && data.customerId;
  const ticketId = data && data.ticketId

  const [listProductGroup, setListProductGroup] = React.useState([]);
  const [listProduct, setListProduct] = React.useState([]);
  
  const [productGroupSelect, setProductGroupSelect] = useState("");
  const [productGroupName, setProductGroupName] = useState("");
  
  const [productSelect, setProductSelect] = useState("");
  const [productName, setProductName] = useState("");

  const [listSymptom, setListSymptom] = React.useState([]);
  const [listSubSymptom, setListSubSymptom] = React.useState([]);

  const [symptom, setSymptom] = React.useState('');
  const [subSymptom, setSubSymptom] = React.useState('');

  const [placeTypeSelect, setPlaceTypeSelect] = useState("")
  const [installTypeSelect, setInstallTypeSelect] = useState("")

  const [customerInfo, setCustomerInfo] = useState({});

  const [searchError, setSearchError] = useState("");
  const [sendDisabled, setSendDisabled] = useState(true);


  const chatStr = data && data.chatInfo;
  const deviceStr = data && data.deviceInfo;
  let chatJson = {};
  let deviceJson = {};
  try{
   chatJson = chatStr ? JSON.parse(chatStr):{};
   deviceJson =deviceStr? JSON.parse(deviceStr):{};
  }catch(e){
     console.log("parse error ", e)
  }


  const placeTypeList = [
    {code:'residence',name:"주거공간"}, 
    {code:'business',name:"상업공간"}, 
  ] ;

  const installTypeList = [
    {code:"단독설치",name:"단독설치"}, 
    {code:"병렬(나란히)설치-1단",name:"병렬(나란히)설치-1단"}, 
    {code:"직렬(위아래)설치-2단",name:"직렬(위아래)설치-2단"}, 
  ]

  const returnValueProduct = [
    'productCode',
    'productName',
  ]
  
  const returnValueProductGroup = [
    'productGroupCode',
    'productGroupName',
  ]

  const returnValueSymptom = [
    'productCode',
    'productName',
    'symptomCode',
    'symptomName',
  ]

  const returnValueSymptomFull = [
    'productGroupCode',
    'productGroupName',
    'productCode',
    'productName',
    'symptomCode',
    'symptomName',
    'subSymptomCode',
    'subSymptomName',
  ]
  

  function encodeParam(paramObj){
    let paramStr = JSON.stringify(paramObj);
    const paramBase64 = Base64.encodeURI(paramStr);
    return paramBase64;
  }

  const hirun_product_list = ['PAH','CRA'];  //상업용에어컨, 시스템/천정형 에어컨 -> place_type필요 없음, is_hirun="yes"
  const install_type_visible_list = ['DRR', 'DRW']; //건조기, 드럼세탁기 ...워시타워?

  const handleSendReserve = () =>{

    if(!productGroupSelect.length){
      setSearchError("제품 그룹을 선택해 주세요.");
      return;
    }else if(!productSelect.length){
      setSearchError("제품을 선택해 주세요.");
      return;
    }else if(!symptom.length){
      setSearchError("고장 증상을 선택해 주세요.");
      return;
    }else if(!subSymptom.length){
      setSearchError("상세 고장 증상을 선택해 주세요.");
      return;
    }else if( !hirun_product_list.includes(productSelect) && !placeTypeSelect.length){
      setSearchError("사용 장소를 선택해 주세요.");
      return;
    }else if (install_type_visible_list.includes(productSelect) && !installTypeSelect.length){
      setSearchError("설치 형태를 선택해 주세요.")
      return;
    }else{
      setSearchError("");
    }


    const currentTime = Math.floor(Date.now()/1000);
    var data = {
      "product_group_code" : productGroupSelect,
      "product_group_name" : productGroupName,
      "product_code" : productSelect,
      "product_name" : productName,
      "rcpt_symptom_code": symptom,
      "sub_symptom_code" : subSymptom,
      "client_id": ticketId,
      "request_type":"engineer",
      "svc_type":"A",
      "premium":"N",
      "timestamp": currentTime,
      "device_type":"mobile",
      "privacy_consent":"Y",
      "terms_type":"A_SVC",
      "user_name": customerInfo && customerInfo.name,
      "phone_number":customerInfo && customerInfo.mobilephone,
      "model_name": deviceJson.sales_model,
    }

    if (hirun_product_list.includes(productSelect)) data.is_hirun = "yes" ;
    if (installTypeSelect.length) data.install_type=installTypeSelect;
    if (placeTypeSelect.length) data.place_type = placeTypeSelect;

    const reservationUrl = '/ext/reservation/services/request.html'
    const paramBase64 = encodeParam(data)
    const targetUrl = "http://chatbot-st.lgthinq.com" + reservationUrl+"?channel=thinq&channel_parameters="+paramBase64;
    var json = mkReserveStr(targetUrl);

    mutationSendMessage(json, convId, counselorId)
    .then(result => {
      console.log("[Send Reserve Link] Result", result);
    })
    .catch(error => {
      console.log("[Send Reserve Link] Error", error);
    })
  }
  const handleProductGroupChange = (event) => {
    const productGroupCode = event.target.value;
    setProductGroupSelect(productGroupCode);
    const sel = listProductGroup && listProductGroup.filter(p=>p.code===productGroupCode);
    setProductGroupName(sel && sel.length && sel[0].name);

    setProductSelect("");
    setListProduct([]);
    setSymptom("");
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomProducts(returnValueProduct, "", productGroupCode)
    .then(prodList=>{
      console.log(prodList);
      setListProduct(prodList.map(prodInfo=>{
        return {
          name:`${prodInfo.productName} (${prodInfo.productCode})`,
          code: prodInfo.productCode
        }
      }))
    })
  }

  const handleProductChange = (event) => {
    const productCode = event.target.value
    console.log(`productInfo : ${productCode}`)
    setProductSelect(productCode);

    const sel = listProduct && listProduct.filter(p=>p.code===productCode);
    setProductName(sel && sel.length && sel[0].name);

    setSymptom("");
    setListSymptom([]);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomCodes(returnValueSymptom, "", productGroupSelect, productCode)
    .then(symptomList=>{
      console.log("symptomList:", symptomList);
      setListSymptom(symptomList.map(sympInfo=>{
        return {
          name: `${sympInfo.symptomName}(${sympInfo.productName})`,
          code: `${sympInfo.symptomCode}`
        } 
      }))
    })
    .catch(error => {
      console.error(error)
    });

  }

  const handleSymptomChange = (event) => {
    const symptomCode = event.target.value;
    setSymptom(symptomCode);
    setSubSymptom("");
    setListSubSymptom([]);
    getSymptomCodeSubs(returnValueSymptomFull,"",productGroupSelect,productSelect,symptomCode)
    .then(subSympList=>{
      console.log(productGroupSelect , ">>", productSelect , ">>", symptomCode)
      console.log(subSympList);
      setListSubSymptom(subSympList.map(subSympInfo=>{
        return {
          name:`${subSympInfo.subSymptomName}(${subSympInfo.productName},${subSympInfo.symptomName})`,
          code:`${subSympInfo.subSymptomCode}`
        }
      }))
    })
    .catch(error => {
      console.error(error)
    });
  };
  const handleSubSymptomChange = (event) => {
    setSubSymptom(event.target.value);
  };

  const handlePlaceTypeChange = (event) => {
    setPlaceTypeSelect(event.target.value);
  }

  const handleInstallTypeChange = (event) => {
    setInstallTypeSelect(event.target.value);
  }


  const fnGetCustomerInfo = () => {
    getCustomer(customerId)
    .then((result) => {
      console.log("[get customer info] query result : ", result.data)
      setCustomerInfo(result.data.getCustomer)
    })
    .catch((e) => {
      console.log("[get customer info] query error: ", e);
    });
  }

  useEffect(()=>{
    getSymptomProductGroups(returnValueProductGroup)
    .then(prodGroupList=>{
      console.log(prodGroupList);
      setListProductGroup(prodGroupList.map(prodGroupInfo=>{
        return {
          name:`${prodGroupInfo.productGroupName}`,
          code:`${prodGroupInfo.productGroupCode}`
        }
      }))

      
      //////////// temp/////////////
      const tempProdGroup = "AC";
      const tempProd = "PAC";
      const tempSymp = chatJson.symptom_code;
      const tempSubSymp = chatJson.sub_symptom_code;

      setProductGroupSelect(tempProdGroup);
      setProductSelect(tempProd);
      setSymptom(tempSymp);
      setSubSymptom(tempSubSymp);

      setCleaningProductGroupCode(tempProdGroup)
      setCleaningProductCode(tempProd)

      //product select list
      getSymptomProducts(returnValueProduct, "", tempProdGroup)
      .then(prodList=>{
        console.log("productList:", prodList);
        setListProduct(prodList.map(prodInfo=>{
          return {
            name:`${prodInfo.productName} (${prodInfo.productCode})`,
            code: prodInfo.productCode
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });

      //symptom list
      getSymptomCodes(returnValueSymptom,"",tempProdGroup,tempProd)
      .then(symptomList=>{
        console.log(symptomList);
        setListSymptom(symptomList.map(sympInfo=>{
          return {
            name: `${sympInfo.symptomName}(${sympInfo.productName})`,
            code: `${sympInfo.symptomCode}`
          } 
        }))
      })
      .catch(error => {
        console.error(error)
      });
    
      //sub symptom list
      getSymptomCodeSubs(returnValueSymptomFull,"", tempProdGroup, tempProd, tempSymp)
      .then(subSympList=>{
        console.log(subSympList);
        setListSubSymptom(subSympList.map(subSympInfo=>{
          return {
            name:`${subSympInfo.subSymptomName}(${subSympInfo.productName},${subSympInfo.symptomName})`,
            code:`${subSympInfo.subSymptomCode}`
          }
        }))
      })
      .catch(error => {
        console.error(error)
      });
      //////////////////////////////////////////////////////////////////////////////////////////////////  


    })
    .catch(error => {
      console.error(error)
    });

    fnGetCustomerInfo()

  },[]);




  // cleaning service
  const cleaning_product_group_code_select_list=[
    {productGroupCode:"AC", productGroupName:"에어컨",},
    {productGroupCode:"WM", productGroupName:"세탁기",},
    {productGroupCode:"RF", productGroupName:"냉장고",},
  ];
  const cleaning_product_code_select_list=[
    // {productGroupCode:"AC", productCode: 'CRA', productName: '시스템/천정형_에어컨', rcptSympCode:"CRAST000749", rcptSympName:"세척서비스", rcptSubSympCode:"SE000001",rcptSubSympName:"가전세척서비스",   system:"hirun"}
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'CRA', productName: '시스템/천정형_에어컨', rcptSympCode:"CRAST000749", system:"hirun"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'CRB', productName: '가정용/천정형_에어컨', rcptSympCode:"CRBST000749", system:"hirun"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'PAH', productName: '업소용 _에어컨', rcptSympCode:"PAHST000749", system:"hirun"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'PAC', productName: '가정용/스탠드_에어컨', rcptSympCode:"PACST000458", system:"smile"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'PAT', productName: '가정용/스탠드2in1_에어컨', rcptSympCode:"PATST000572", system:"smile"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'SRA', productName: '벽걸이_에어컨', rcptSympCode:"SRAST000542", system:"smile"},
    {productGroupCode:"AC", productGroupName:"에어컨", productCode: 'WRA', productName: '창문형/포터블_에어컨', rcptSympCode:"WRAST000747", system:"smile"},
    {productGroupCode:"WM", productGroupName:"세탁기", productCode: 'DRW', productName: '드럼_세탁기', rcptSympCode:"DRWST000749", system:"smile"},
    {productGroupCode:"WM", productGroupName:"세탁기", productCode: 'W/M', productName: '일반_세탁기', rcptSympCode:"W/MST000749", system:"smile"},
    {productGroupCode:"RF", productGroupName:"냉장고", productCode: 'REF', productName: '일반_냉장고', rcptSympCode:"REFST000015", system:"smile"},
    {productGroupCode:"RF", productGroupName:"냉장고", productCode: 'SBS', productName: '양문_냉장고', rcptSympCode:"SBSST000016", system:"smile"},
    {productGroupCode:"RF", productGroupName:"냉장고", productCode: 'KRE', productName: '김치_냉장고', rcptSympCode:"KREST000016", system:"smile"},
  ];

  const [cleaningProductGroupCode, setCleaningProductGroupCode] = useState('');
  const [cleaningProductCode, setCleaningProductCode] = useState('');
  const [cleaningProductName, setCleaningProductName] = useState('');
  const [cleaningRcptSympCode, setCleaningRcptSympCode] = useState('');
  const [cleaningSelect, setCleaningSelect] = useState('');

  const [cleaningProductSelectList, setCleaningProductSelectList] = useState(cleaning_product_code_select_list);

  const [cleaningSearchError, setCleaningSearchError] = useState("");

  const handleChangeCleaningProductGroup = (event) => {
    const productGroupCode = event.target.value;
    setCleaningProductGroupCode(productGroupCode);
    setCleaningProductSelectList(cleaning_product_code_select_list.filter( el => el.productGroupCode===productGroupCode))
  }
  const handleChangeCleaningProduct = (event) => {
    const productCode = event.target.value;
    setCleaningProductCode(productCode);
    console.log("::::::",cleaningProductSelectList);
    console.log("::::::",cleaningProductSelectList.filter(el => el.productCode ===productCode));
    setCleaningSelect(cleaningProductSelectList.filter(el => el.productCode ===productCode)[0]);
  }

  const handleSendCleaning = () =>{
    console.log(cleaningSelect)
    if(!cleaningProductGroupCode.length){
      setCleaningSearchError("제품 그룹을 선택해 주세요.");
      return;
    }else if(!cleaningProductCode.length){
      setCleaningSearchError("제품을 선택해 주세요.");
      return;
    }

    const currentTime = Math.floor(Date.now()/1000);
    var data = {
      "product_group_code" : cleaningSelect.productGroupCode,
      "product_group_name" : cleaningSelect.productGroupName,
      "product_code" : cleaningSelect.productCode,
      "product_name" : cleaningSelect.productName,
      "rcpt_symptom_code": cleaningSelect.rcptSympCode,
      "sub_symptom_code" : "SE000001",
      "client_id": ticketId,
      "request_type":"cleaning",
      "svc_type":"A",
      "premium":"N",
      "timestamp": currentTime,
      "device_type":"mobile",
      "privacy_consent":"Y",
      "terms_type":"A_SVC",
      "user_name": customerInfo && customerInfo.name,
      "phone_number":customerInfo && customerInfo.mobilephone,
      "channel":"thinq"
    }

    console.log(data)
    if (hirun_product_list.includes(productSelect)) data.is_hirun = "yes" ;
    if (installTypeSelect.length) data.install_type=installTypeSelect;
    if (placeTypeSelect.length) data.place_type = placeTypeSelect;

    const reservationUrl = '/ext/reservation/services/request.html'
    const paramBase64 = encodeParam(data)
    const targetUrl = "http://chatbot-st.lgthinq.com" + reservationUrl+"?channel=thinq&channel_parameters="+paramBase64;
    var json = mkReserveStr(targetUrl);

    mutationSendMessage(json, convId, counselorId)
    .then(result => {
      console.log("[Send Reserve Link] Result", result);
    })
    .catch(error => {
      console.log("[Send Reserve Link] Error", error);
    })
  }


  return (
    <div className={classes.root}>
      <div className={classes.subTitle}> <ArrowRightIcon/>출장서비스예약</div>
      <div className={classes.div}>
        <div className={classes.selectDiv}>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
              <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품그룹</InputLabel>
              <Select
                size='small'
                labelId="product-group-select-label"
                id="product-group-select"
                value={productGroupSelect}
                onChange={handleProductGroupChange}
                label="제품그룹"
                classes={{root:classes.selectRoot}}
              >
              {
                listProductGroup.map(productGroupInfo=>{
                  return <MenuItem key={productGroupInfo.code} value={productGroupInfo.code} className={classes.menuItem}>{productGroupInfo.name}({productGroupInfo.code})</MenuItem>
                })
              }
              </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
            <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품</InputLabel>
            <Select
              size='small'
              labelId="product-select-label"
              id="product-select"
              value={productSelect}
              onChange={handleProductChange}
              label="제품"
            >
              {listProduct && listProduct.length 
                ?
                  listProduct.map(productInfo=>{
                  return <MenuItem key={productInfo.code} value={productInfo.code} className={classes.menuItem}>{productInfo.name}</MenuItem>
                  })
                : <MenuItem value="" className={classes.menuItem}><em>제품 그룹을 선택해 주세요.</em></MenuItem>
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="symptom-select-label">고장증상</InputLabel>
            <Select
                labelId="symptom-select-label"
                id="symptom-select"
                value={symptom}
                onChange={handleSymptomChange}
                label="고장증상"
              >
              { listSymptom && listSymptom.length
                ?
                  listSymptom.map(symptomInfo=>{
                    return <MenuItem key={symptomInfo.code} value={symptomInfo.code} className={classes.menuItem}>{symptomInfo.name}</MenuItem>
                  })
                : <MenuItem value="" className={classes.menuItem}><em>제품을 선택해 주세요.</em></MenuItem>
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="sub-symptom-select-label">상세 고장증상</InputLabel>
              <Select
                labelId="sub-symptom-select-label"
                id="sub-symptom-select"
                value={subSymptom}
                onChange={handleSubSymptomChange}
                label="상세 고장증상"
              >
              
              { listSubSymptom && listSubSymptom.length?
                listSubSymptom.map(subSymp=>{
                  return <MenuItem key={subSymp.code} value={subSymp.code} className={classes.menuItem}>{subSymp.name}</MenuItem>
                })
                :<MenuItem value="" className={classes.menuItem}><em>고장증상을 선택해 주세요.</em></MenuItem>
              }

              </Select>
          </FormControl>
        </div>
        <div className={classes.selectDiv}>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="place-type-label" classes={{root:classes.selectLabel}}>사용장소</InputLabel>
            <Select
              size='small'
              labelId="place-type-label"
              id="place-type-select"
              value={placeTypeSelect}
              onChange={handlePlaceTypeChange}
              label="사용장소 ">
              { placeTypeList&&placeTypeList.length &&
                  placeTypeList.map(p=>{
                    return <MenuItem key={p.code} value={p.code} className={classes.menuItem}>{p.name}({p.code})</MenuItem>
                  })
              }
            </Select>
          </FormControl>
          {install_type_visible_list.includes(productSelect) &&
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="install-type-label" classes={{root:classes.selectLabel}}>설치형태</InputLabel>
            <Select
              size='small'
              labelId="install-type-label"
              id="install-type-select"
              value={installTypeSelect}
              onChange={handleInstallTypeChange}
              label="설치형태 ">
              { installTypeList&&installTypeList.length &&
                  installTypeList.map(p=>{
                    return <MenuItem key={p.code} value={p.code} className={classes.menuItem}>{p.name}({p.code})</MenuItem>
                  })
              }
            </Select>
          </FormControl>
          }
        </div>
        <div className={classes.alertBox}>
          {searchError.length>0 && <Alert severity="error" className={classes.alert}>{searchError}</Alert>}
        </div>
        <div className={classes.submitBtn}>
          <Button onClick={handleSendReserve} variant="contained" size="small" color="primary" className={classes.submit}>출장서비스 예약 Link전송</Button>
        </div>
      </div>
      <div className={classes.subTitle}> <ArrowRightIcon/>세척서비스예약</div>
      <div className={classes.div}>
      <div className={classes.selectDiv}>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
              <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품그룹</InputLabel>
              <Select
                size='small'
                labelId="product-group-select-label"
                id="product-group-select-cleaning"
                value={cleaningProductGroupCode}
                onChange={handleChangeCleaningProductGroup}
                label="제품그룹"
                classes={{root:classes.selectRoot}}
              >
              {cleaning_product_group_code_select_list
                .map((el, index)=>(
                  <MenuItem key={`${el.productGroupCode}_${index}`} value={el.productGroupCode} className={classes.menuItem}>{el.productGroupName}({el.productGroupCode})</MenuItem>
                ))}
              </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense'>
            <InputLabel id="product-select-label" classes={{root:classes.selectLabel}}>제품</InputLabel>
            <Select
              size='small'
              labelId="product-select-label"
              id="product-select"
              value={cleaningProductCode}
              onChange={handleChangeCleaningProduct}
              label="제품"
            >
            {cleaningProductSelectList
              .map((el, index)=>(
                <MenuItem key={`${el.productCode}_${index}`} value={el.productCode} className={classes.menuItem}>{el.productName}({el.productCode})</MenuItem>
            ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.selectDiv}>
          {/* <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="place-type-label" classes={{root:classes.selectLabel}}>사용장소</InputLabel>
            <Select
              size='small'
              labelId="place-type-label"
              id="place-type-select"
              value={placeTypeSelect}
              onChange={handlePlaceTypeChange}
              label="사용장소 ">
              { placeTypeList&&placeTypeList.length &&
                  placeTypeList.map(p=>{
                    return <MenuItem key={p.code} value={p.code} className={classes.menuItem}>{p.name}({p.code})</MenuItem>
                  })
              }
            </Select>
          </FormControl> */}
          {install_type_visible_list.includes(productSelect) &&
          <FormControl variant="outlined" className={classes.selectFormControl} margin='dense' size="small">
            <InputLabel id="install-type-label" classes={{root:classes.selectLabel}}>설치형태</InputLabel>
            <Select
              size='small'
              labelId="install-type-label"
              id="install-type-select"
              value={installTypeSelect}
              onChange={handleInstallTypeChange}
              label="설치형태 ">
              { installTypeList&&installTypeList.length &&
                  installTypeList.map(p=>{
                    return <MenuItem key={p.code} value={p.code} className={classes.menuItem}>{p.name}({p.code})</MenuItem>
                  })
              }
            </Select>
          </FormControl>
          }
        </div>
        <div className={classes.alertBox}>
          {cleaningSearchError.length>0 && <Alert severity="error" className={classes.alert}>{cleaningSearchError}</Alert>}
        </div>
        <div className={classes.submitBtn}>
          <Button onClick={handleSendCleaning} variant="contained" size="small" color="primary" className={classes.submit}>세척 서비스 예약 Link전송</Button>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(EvRequest);