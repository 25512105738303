import React, { useCallback, useState } from "react";
import { withStyles } from "@material-ui/core";

import LO from "../Message/LO";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import {
  mutationSendMessage,
  extGetGeocode,
  extSearchCSCenterOrBestshop,
} from "../../data/Loader";
import { mkLOStr } from "../../data/DataConvert";

const styles = (theme) => ({
  root: {
    fontFamily: "LGSmHaT",
    // padding:'10px',
    color: "#8e8e8e",
    display: "flex",
    flexDirection: "column",
    // height: "calc(100% - 25px)",
    background: "#f9f7f7",
    border: "5px solid #fff",
    boxSizing: "border-box",
  },
  title: {
    fontWeight: "600",
    color: "#7b7a7a",
    paddingLeft: "15px",
    paddingBottom: "5px",
  },
  td: {
    background: "none",
    border: "none",
    padding: "3px 24px 3px 16px",
    color: "#7b7a7a",
  },
  searchInput: {
    display: "inline",
  },

  value: {},
  refreshIcon: {
    float: "right",
  },
  carousel: {
    color: "yellow",
  },
  carouselDiv: {
    height: "300px",
  },
  addressMenuDiv: {
    // height:'300px',
  },
  gscsBtn: {
    backgroundColor: "#bf134a",
    width: "100px",
  },
  inputSearchDiv: {
    minWidth: "100%",
    display: "flex",
  },
  contentSearchTextField: {
    width: "75%",
    margin: "5px",
  },
  contentSearchBtn: {
    width: "25%",
    margin: "5px",
    boxShadow: "none",
    background: "#0a8297",
  },
  selectDiv: {
    minWidth: "100%",
    display: "flex",
  },
  addressSelectFormControl: {
    padding: 0,
    width: "100%",
    margin: "5px",
  },
  selectFormControl: {
    padding: 0,
    width: "50%",
    margin: "5px",
  },
  selectLabel: {},
  submitBtn: {
    display: "inline",
    alignSelf: "center",
    textAlign: "center",
    marginBottom: "20px",
  },
  submit: {
    backgroundColor: "#0a8297",
    width: "100px",
    marginLeft: "4px",
  },
  submitCancel: {
    backgroundColor: "#a9a8a8",
    width: "100px",
  },
});

const CenterTypeSelect = withStyles(styles)((props) => {
  const { classes, onSelect } = props;

  const handleChange = (event) => {
    onSelect(event.target.value);
  };
  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.selectFormControl}
        margin="dense"
        size="small"
      >
        <InputLabel
          id="centertype-select-label"
          classes={{ root: classes.selectLabel }}
        >
          센터 타입
        </InputLabel>
        <Select
          size="small"
          labelId="centertype-select-label"
          id="centertype-select"
          // value={0}
          onChange={handleChange}
          label="센터 타입"
        >
          <MenuItem key={1} value={1}>ServiceCenter</MenuItem>
          <MenuItem key={2} value={2}>BestShop</MenuItem>
        </Select>
      </FormControl>
    </>
  );
});

const AddressSelect = withStyles(styles)((props) => {
  const { classes, addressList, onAddressChange } = props;
  const [address, setAddress] = React.useState("");

  const handleChange = (event) => {
    onAddressChange(event.target.value);
  };
  let addressMenu =
    addressList.length > 0 &&
    addressList.map((item, i) => {
      return <MenuItem key={i} value={i}>{item.formatted_address}</MenuItem>;
    });
  return (
    addressList.length > 0 && (
      <>
        <FormControl
          variant="outlined"
          className={classes.addressSelectFormControl}
          margin="dense"
          size="small"
        >
          <InputLabel
            id="address-select-label"
            classes={{ root: classes.selectLabel }}
          >
            중복된 지역명이 존재합니다. 지역을 선택해 주세요.
          </InputLabel>
          <Select
            size="small"
            labelId="product-select-label"
            id="product-select"
            // value={0}
            onChange={handleChange}
            label="원하는 지역을 선택해주세요"
          >
            {addressMenu}
            {/* <MenuItem value={1} >서울특별시 강남구 역삼동</MenuItem>
          <MenuItem value={2} >경기도 용인시 처인구 역삼동</MenuItem> */}
          </Select>
        </FormControl>
      </>
    )
  );
});

let convertCenterInfo = (centerInfoList) => {
  if (!centerInfoList || centerInfoList.length == 0) return [];

  return centerInfoList.map((centerInfo) => {
    let newInfo = {
      location: {
        latitude: centerInfo.latitude,
        longitude: centerInfo.longitude,
      },
      name: centerInfo.name,
      center_code: centerInfo.code,
      distance: `${centerInfo.distance.toFixed(1)}Km`,
      address: {
        depth_1: centerInfo.address1st,
        depth_2: centerInfo.address2nd,
      },
      opening_time: {
        weekday_hour: centerInfo.weekdayHour,
        saturday_hour: centerInfo.saturdayHour,
      },
      phone_number: centerInfo.phoneNumber,
      parking: centerInfo.parking,
      buttons: [
        {
          type: "action_frontend",
          parameters: {
            action: "get_direction",
            dest: centerInfo.name,
            latitude: centerInfo.latitude,
            longitude: centerInfo.longitude,
          },
          label: "길찾기",
        },
        {
          type: "action_frontend",
          parameters: {
            action: "send_lms",
            phone_number: "01028232175", //todo change phonenumber to customer's
          },
          label: "문자로받기",
        },
        {
          type: "action_frontend",
          parameters: {
            action: "external",
            url: centerInfo.webURL,
            mobile_url: centerInfo.mobileURL,
            enable: "Y",
          },
          label: "방문예약",
        },
      ],
    };
    return newInfo;
  });
};

const SearchHeader = withStyles(styles)((props) => {
  const { classes, onCenterData } = props;
  const [dongAddr, setDongAddr] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [centerType, setCenterType] = useState(0);

  const handleAddressList = async (addressIndex) => {
    let addressObj = addressList[addressIndex];
    let latitude = addressObj.latitude;
    let longitude = addressObj.longitude;

    let centerInfo = await extSearchCSCenterOrBestshop(
      "cs",
      "KR",
      latitude,
      longitude,
      10
    );
    let centerInfoObj = JSON.parse(centerInfo.data.extSearchCSCenterOrBestshop);
    console.log(centerInfoObj);
  };

  const handleSearch = async () => {
    if (!dongAddr) {
      console.log("dongAddr is empty");
      return;
    }
    let result = await extGetGeocode(dongAddr, "naver", "KR", false, "ko");
    console.log("[extGetGeocode] Result", result);
    let addressObj = JSON.parse(result.data.extGetGeocode);
    let addressList = addressObj.result.address;
    console.log(`addressObj len : ${addressList.length}`);
    if (addressObj.length > 1) {
      setAddressList(addressList);
    } else {
      let latitude = addressList[0].latitude;
      let longitude = addressList[0].longitude;

      let centerInfo = await extSearchCSCenterOrBestshop(
        centerType === 1 ? "cs" : "bestshop",
        "KR",
        latitude,
        longitude,
        10
      );
      let centerInfoObj = JSON.parse(
        centerInfo.data.extSearchCSCenterOrBestshop
      );
      console.log(centerInfoObj);
      let centerInfoList = convertCenterInfo(centerInfoObj);
      console.log("centerInfoList", centerInfoList);
      onCenterData({ items: centerInfoList });
    }
  };

  const handleChangeDongAddr = (event) => {
    // event.preventDefault();
    setDongAddr(event.target.value);
  };

  const handleCenterSelect = (center) => {
    console.log("center", center);
    setCenterType(center);
  };

  return (
    <>
      <form noValidate autoComplete="off">
        <div className={classes.inputSearchDiv}>
          <CenterTypeSelect onSelect={handleCenterSelect} />
          <TextField
            value={dongAddr}
            onChange={(event) => handleChangeDongAddr(event)}
            id="standard-basic"
            label="센터 검색"
            size="small"
            className={classes.contentSearchTextField}
            variant="outlined"
          />
          <Button
            onClick={handleSearch}
            variant="contained"
            size="small"
            color="primary"
            className={classes.contentSearchBtn}
          >
            검색
          </Button>
        </div>
      </form>
      <div className={classes.addressMenuDiv}>
        <AddressSelect
          addressList={addressList}
          onAddressChange={handleAddressList}
        />
      </div>
    </>
  );
});

function CenterSearch(props) {
  const { classes, data } = props;
  const counselorId = data && data.counselorId;
  const convId = data && data.convId;
  const [centerData, setCenterData] = useState({ items: [] });
  const [selected, setSelected] = useState([]);
  const [sendable, setSendable] = useState(true);

  const handleSendCard = useCallback(() => {
    if (centerData.items.length <= 0) return;

    console.log("test", centerData.items, selected)
    const centerSelected = centerData.items.filter((_, index) => selected[index]);

    // handleSelect가 LO에 전달되고 있지 않아 고객 Client가 죽는 문제로 - 임시로 전체전달
    // const centerSelected = centerData.items;

    mutationSendMessage(mkLOStr(centerSelected), convId, counselorId)
      .then((result) => {
        console.log("[Send Message Carousel] Result", result);
      })
      .catch((error) => {
        console.log("[Send Message Carousel] Error", error);
      });
  }, [selected, centerData]);

  const handleSelect = select => {
    setSelected(select);

    console.log("handleSelect", select);

    setSendable(select.filter(s => s === true).length > 0);
  }

  return (
    <div className={classes.root}>
      <SearchHeader onCenterData={setCenterData} />
      {centerData.items && centerData.items.length > 0 && (
        <LO data={centerData} selectable onSelect={handleSelect} />
      )}

      <div className={classes.submitBtn}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.submitCancel}
        >
          취소
        </Button>
        <Button
          onClick={handleSendCard}
          variant="contained"
          size="small"
          color="primary"
          className={[classes.submit, !sendable ? classes.submitCancel : "" ].join(" ")}
          disabled={!sendable}
        >
          Card전송
        </Button>
      </div>
    </div>
  );
}
export default withStyles(styles)(CenterSearch);
