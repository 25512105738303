import React, { useState } from 'react';
import { withStyles, Grid, AppBar, Tabs, Tab, Typography  } from "@material-ui/core";
import CustomerInfo from './CustomerInfo'
import ChatbotHistory from './ChatbotHistory'
import LivechatHistory from './LiveChatHistory'
import ChatSummary from './ChatSummary';
const styles = (theme) => ({
  root:{
    background:"#fff",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  tabPanel: {
    // width: "100%",
    height: "calc(100% - 20px)",
    marginTop:"-17px",
    marginLeft:"10px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    width: "100%"
  },
  panel: {
    width: "100%"
  },
  appBar:{
    margin:'25px 10px 10px',
    boxShadow:'none',
    backgroundColor:'transparent',
  },
  tabs:{
    minHeight:'25px',
    height:'25px',
  },
  tab:{
    padding:'5px 10px',
    minWidth:'90px',
    // border: '1px solid #827f80' ,
    backgroundColor: '#fff',
    fontSize: '0.75rem',
    color:'#000000',
    minHeight:'25px',
    height:'25px',
    boxShadow:'none',
  },
  // tabFirst:{
  //   borderRight:"none",
  //   borderRadius: '5px 0px 0px 5px',
  // },
  // tabCenter:{
  //   borderRight:"none",
  //   borderRadius: '0px 0px 0px 0px',
  // },
  // tabLast:{
  //   borderRadius: '0px 5px 5px 0px',
  // },

  tabSelected:{
    color: "#535353",
    fontWeight:"600",
    // background: "#827f80",
  },

  indicator:{
    background:"#0a8297",
  },
  padding:{
    padding:"10px"
  }

});

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

const Panel = withStyles(styles)((props) => {
  const { Component, classes, key, index, value, data, argusKey } = props;

  return (index === value &&
    <Component key={key} data={data} argusKey={argusKey}/>
  );
});


function ChatHistory(props) {
    const { classes, value, index, data, argusKey } = props;
    const [select, setSelect] = useState(0);
    const tabs = [{
        title: "고객정보",
        panel: CustomerInfo,
        class:classes.tabFirst
      }, {
      title: "챗봇상담",
      panel: ChatbotHistory,
      class:classes.tabFirst
    }, {
      title: "이전상담",
      panel: LivechatHistory,
      class:classes.tabLast
    },
    {
      title: "상담요약",
      panel: ChatSummary,
      class:classes.tabLast
    },
    //  {
    //   title: "comment",
    //   panel: LivechatHistory,
    //   class:classes.tabLast
    // }
    ];

    const handleChange = (_, value) => {
      setSelect(value);
    }

    return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
    >
      {value === index && (
        <div className={classes.root}>
          <AppBar position="static" classes={{root:classes.appBar}}>
            <Tabs
              value={select}
              onChange={handleChange}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
              }}>
              {tabs.map((tab, index) => (
                <Tab
                  key={`monitor_cs_tab_${tab.title}`}
                  label={tab.title}
                  index={index}
                  className={[tab.class, classes.tab,].join(" ")}
                  classes={{selected:classes.tabSelected }} 
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
          {tabs.map((tab, i) => (
            <Panel key={`chat_tabpanel_${i}`} index={i} value={select} Component={tab.panel} data={data} argusKey={argusKey}/>
          ))}
        </div>
      )}
    </div>
  
    );
  
  
}
export default withStyles(styles)(ChatHistory);