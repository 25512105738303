import React, { useState, useCallback, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import useEmblaCarousel from "embla-carousel-react";
import arrow from "../../asset/img/btn_arr_pc_2.png";

const styles = () => ({
  root: {
    position: "relative",
    maxWidth: "100%",
    // width: "100%",
    padding: "0px 10px"
  },
  list: {
    display: "flex",
    // width: "100%"
  },
  swipe: {
    width: "30px",
    height: "42px",
    position: "absolute",
    display: "block",
    // transform: "translateY(-50%)",
    top: "50%",
    padding: "0px",
    overflow: "auto",
    backgroundImage: `url(${arrow})`,
    background: "none",
    textIndent: "-9999em",
    border: "none",
    "-webkit-transform": "translate3d(0,-50%,0)",
    zIndex: "12"
  },
  prev: {
    left: "0px",
    backgroundPosition: "left top",
    "&:hover": {
      backgroundPosition: "left 42px",
    },
  },
  next: {
    right: "0px",
    backgroundPosition: "right top",
    "&:hover": {
      backgroundPosition: "right 42px",
    },
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0px"
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
    margin: "4px",
    transition: "all, .5s, ease-in-out",
  },
  dotActive: {
    background: "#00a0b6",
    transform: "scale(1)",
    // opacity: "1",
  },
  dotSide: {
    background: "#bbb",
    transform: "scale(.66)",
    // opacity: ".2",
  },
  dotSideSide: {
    background: "#bbb",
    transform: "scale(.33)",
    // opacity: ".2",
  },
  blur: {
    width: "34px",
    height: "42px",
    position: "absolute",
    display: "block",
    top: "50%",
    padding: "0px",
    overflow: "auto",
    textIndent: "-9999em",
    border: "none",
    background: "none",
    "-webkit-transform": "translate3d(0,-50%,0)",
    zIndex: "12"
  },
  blurPrev: {
    left: "0px",
    backgroundImage: "-webkit-linear-gradient(to left, rgba(255,255,255,0), rgba(245,245,245,1.0))",
    backgroundImage: "linear-gradient(to left, rgba(255,255,255,0), rgba(245,245,245,1.0))"
  },
  blurNext: {
    right: "0px",
    backgroundImage: "-webkit-linear-gradient(to right, rgba(255,255,255,0), rgba(245,245,245,1.0))",
    backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), rgba(245,245,245,1.0))"
  }
});

function Carousel({ component: Component, ...props }) {
  const { 
    classes, children, data, parentFunc, 
    indicator = true, arrows = false, blur = false, selectable = false, onSelect = null, 
    selectCardFunc = null, selectCardArray = null 
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start", dragFree: true, containScroll: true });

  // const [embla, setEmbla] = useState(null);
  const [slide, setSlide] = useState(0);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const onScrollPrev = useCallback(() => emblaApi.scrollPrev(), [emblaApi]);
  const onScrollNext = useCallback(() => emblaApi.scrollNext(), [emblaApi]);

  const setScrollStatus = () => {
    if (emblaApi) {
      setSlide(emblaApi.selectedScrollSnap());
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    }
  };

  useEffect(() => {
    if (emblaApi) {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());

      emblaApi.on("select", () => {
        setScrollStatus();
      });
      emblaApi.on("resize", () => {
        setScrollStatus();
      });
    }
    //remove below line due to https://github.com/davidcetinkaya/embla-carousel-react/issues/13
    //return () => embla && embla.destroy();
  }, [emblaApi]);

  const renderDots = useCallback(() => {
    if (emblaRef) {
      return (
        <div className={classes.dots}>
          {emblaApi.slideNodes().length === 4 ? emblaApi.scrollSnapList().map((snap, index) => (
            <p
              key={index}
              active={index === slide ? "true" : "false"}
              onClick={() => emblaApi.scrollTo(index)}
              hidden={index < slide - 3 || index > slide + 3}
              className={
                index === slide
                  ? [classes.dot, classes.dotActive].join(" ")
                  : index === slide + 1 || index === slide - 1
                  ? [classes.dot, classes.dotSide].join(" ")
                  : index === slide + 2 || index === slide - 2 || index === slide +3 || index === slide - 3
                  ? [classes.dot, classes.dotSideSide].join(" ")
                  : undefined
              }
            />
          )) : (
            emblaApi.scrollSnapList().map((snap, index) => (
              <p
                key={index}
                active={index === slide ? "true" : "false"}
                onClick={() => emblaApi.scrollTo(index)}
                hidden={index < slide - 2 || index > slide + 2}
                className={
                  index === slide
                    ? [classes.dot, classes.dotActive].join(" ")
                    : index === slide + 1 || index === slide - 1
                    ? [classes.dot, classes.dotSide].join(" ")
                    : index === slide + 2 || index === slide - 2
                    ? [classes.dot, classes.dotSideSide].join(" ")
                    : undefined
                }
              />
          )))}
        </div>
      );
    }
    return null;
  }, [emblaApi, slide]);

  let childComponent = null;
  if (Component) {
    childComponent = (
      <Component ref={emblaApi} data={data} parentFunc={parentFunc} selectable={selectable} onSelect={onSelect} selectCardFunc={selectCardFunc} selectCardArray={selectCardArray} />
    );
  } else {
    childComponent = children;
  }

  return (
    <div className={classes.root} onTouchMove={(e) => e.stopPropagation()}>
      <div
        ref={emblaRef}
        // options={{ align: "start", dragFree: true, containScroll: true }}
      >
        <div className={classes.list}>{childComponent}</div>
      </div>

      {/* Pagination 좌/우 버튼 */}
      {canScrollPrev ?
        arrows ? (
          <button
            className={[classes.swipe, classes.prev].join(" ")}
            onClick={onScrollPrev}
          >
            이전버튼
          </button>
        ) : blur && (
          <div className={[classes.blur, classes.blurPrev].join(" ")}/>
        )
      : null}
      {canScrollNext ?
        arrows ? (
          <button
            className={[classes.swipe, classes.next].join(" ")}
            onClick={onScrollNext}
          >
            다음버튼
          </button>
        ) : blur && (
          <div className={[classes.blur, classes.blurNext].join(" ")}/>
        )
      : null}

      {/* Pagination Dot 버튼 */}
      {indicator && (canScrollPrev || canScrollNext) && renderDots()}
    </div>
  );
}
export default withStyles(styles)(Carousel);
