import React, { useEffect, useState } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { Auth } from "aws-amplify";
import LoginForm from "../login/LoginForm";
import ChangePasswordForm from "../login/ChangePasswordForm";
import Loading from "../../asset/img/loading.svg";
import { useHistory } from "react-router-dom";
import { useUser } from "../data/User";

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "300px",
    height: "360px",
    background: "#f9f9f9",
    padding: theme.spacing(2),
    display: "flex",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    width: "100px",
    height: "100px",
  },
});

function Login(props) {
  const { classes } = props;
  const [errorLogin, setErrorLogin] = useState(null);
  const [errorChangePassword, setErrorChangePassword] = useState(null);
  const [needChangePassword, setNeedChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user, login, changePassword } = useUser();

  // 로그인 된 경우에 '상담하기' 페이지로 이동
  useEffect(() => {
    console.log("[Login]", user);
    if (user.username) {
      setLoading(true);
      history.push("/chat");
    }
  }, [user]);

  // 로그인
  const handleLogin = (id, password) => {
    setErrorLogin(false);
    setLoading(true);

    /**
     * 로그인 성공 시 함수
     * - 첫 로그인 성공 시 비밀번호 변경 필요
     * - 그 외의 성공 시 '상담하기' 페이지로 이동
     * @param {String} result
     */
    const success = (result) => {
      switch (result.toLowerCase()) {
        case "change_password":
          moveChangePassword();
          setErrorChangePassword("첫 로그인 시 비밀번호를 변경해야 합니다.");
          break;
        case "chat":
        default:
          history.push("/chat");
          break;
      }
      setLoadingDelayed(false, 1500);
    };

    // 로그인 실패 시 함수
    const failure = (error) => {
      if (
        error.code === "UserNotFoundException" ||
        error.code === "InvalidParameterException" ||
        error.code === "NotAuthorizedException"
      ) {
        setErrorLogin("정확한 아이디와 비밀번호를 입력해 주세요.");
      }
      setLoadingDelayed(false, 1500);
    };

    // 로그인 요청
    login(id, password, success, failure);
  };

  // 비밀번호 변경
  const handleChangePassword = (
    id,
    password,
    passwordNew,
    passwordNewConfirm
  ) => {
    // 빈 아이디 & 패스워드 입력 확인
    if (id === null || id === undefined || id === "") {
      setErrorChangePassword("아이디를 입력해 주세요.");
      return;
    }
    if (
      password === null ||
      password === undefined ||
      password === "" ||
      passwordNew === null ||
      passwordNew === undefined ||
      passwordNew === "" ||
      passwordNewConfirm === null ||
      passwordNewConfirm === undefined ||
      passwordNewConfirm === ""
    ) {
      setErrorChangePassword("비밀번호를 입력해 주세요.");
      return;
    }

    // Confirm 비밀번호 확인
    if (passwordNew !== passwordNewConfirm) {
      setErrorChangePassword("동일한 비밀번호를 입력해 주세요.");
      return;
    }

    // 로딩 화면 시작
    setLoading(true);

    // 비밀번호 변경 성공 함수
    const success = (result) => {
      switch (result.toLowerCase()) {
        case "chat":
          history.push(result);
          break;
        default:
          break;
      }
      setLoading(false);
    };

    // 비밀번호 변경 실패 함수
    const failure = () => {
      setLoading(false);
      setErrorChangePassword("오류가 발생했습니다. 다시 시도해 주세요.");
    };

    // 비밀번호 변경 요청
    changePassword(
      id,
      password,
      passwordNew,
      passwordNewConfirm,
      success,
      failure
    );
  };

  // 로그인 화면으로 전환
  const moveLogin = (e) => {
    if (e) e.preventDefault();
    setNeedChangePassword(false);
    setErrorLogin(null);
    setLoading(false);
  };
  // 비밀번호 변경 화면으로 전환
  const moveChangePassword = (e) => {
    if (e) e.preventDefault();
    setNeedChangePassword(true);
    setErrorChangePassword(null);
  };
  // 로딩 화면 지연시간 후 전환
  const setLoadingDelayed = (loading, delay) => {
    setTimeout(() => {
      setLoading(loading);
    }, delay);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <img className={classes.loading} src={Loading} alt={"Loading"} />
          </div>
        ) : needChangePassword ? (
          <ChangePasswordForm
            onLogin={moveLogin}
            onChangePassword={handleChangePassword}
            error={errorChangePassword}
          />
        ) : (
          <LoginForm
            onLogin={handleLogin}
            onChangePassword={moveChangePassword}
            error={errorLogin}
          />
        )}
      </Paper>
    </div>
  );
}
export default withStyles(styles)(Login);
