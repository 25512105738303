import { Base64 } from 'js-base64';

const makeInAppUrl = (url) => {
  url = '"' + url + '"'

  console.log("[encoding.before]", url);
  const urlBase64 = Base64.encodeURI(url); // in encoded string with js-base64, padding(=) character is truncated
  console.log("[encoding.after]", urlBase64);
  const internalLink = "thinqapp://inappbrowser?url=" + urlBase64 + "&target=new"
  return internalLink
}

const goStore = () => {
  window.NativeInterface.open("thinqapp://store?source=chatbot");
}

const goDial = (telNum) => {
  window.NativeInterface.open("tel:"+telNum);
}

const openLink = (context, linkType, url, log = null) => {
  const deviceType = context.userAgent.deviceType;
  console.log('>> openLink',url);
  linkType = linkType.toLowerCase();
  if (deviceType === 'mobile'){
    if (linkType === 'inapp') {
      url = makeInAppUrl(url);
      console.log('>> openLink',' encodedUrl : ',url);
      window.NativeInterface.open(url);
    }
    else if (linkType === 'external'){
      // external nothign to do 
      window.NativeInterface.open(url);
    }
    else if (linkType === 'native'){
      if (url === 'store'){
        window.NativeInterface.open("thinqapp://store?source=chatbot");
      }
      else if (url === 'qna'){
        window.NativeInterface.open("thinqapp://qna?source=chatbot");
      }
      else if (url.startsWith("device/")) {
        const id = url.split("/")[1];
        window.NativeInterface.goProductPage(id);
      }
      else if (url.startsWith("call/")) {
        const number = url.split("/")[1];
        window.NativeInterface.open(`tel:${number}`);
      }
    }
  }
  else {
    window.open(url);
  }
  
  if (log) {
    context.log(context, 'clicks', log);
  }
}


export { goStore, goDial, openLink };