import moment from 'moment';
// import base62 from 'uuid-base62';

/**
 * 서버에서 전달된 메시지를 UI에 맞도록 변경한다.
 * @param {Server Data Format} server 
 */
const makeTXMessage = (isMine, text, loading = false) => {
  return {
    // id: base62.v4(),
    type: 'tx',
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    loading: loading,
    online: true
  }
}


const makeTLMessage = (isMine, text, link) => {
  return {
    // id: base62.v4(),
    type: 'tl',
    mine: isMine,
    text: text,
    date: moment().format('LT'),
    link: link
  }
}


const convertMessage = (messages, isWelcome = false) => {
  return messages.map(message => {
    let tempMessage = {};

    console.log("type", message.template_type);

    switch (message.template_type.toUpperCase()) {
      case "TX":
        tempMessage = makeTXMessage(false, message.text);
        break;
      case "TL":
        tempMessage = {
          type: message.template_type,
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          link: message.link
        }
        break;
      case "CB":
        tempMessage = {
          type: message.template_type,
          subType: message.template_sub_type,
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons,
          listItems: message.list_items,
          title: message.title
        }
        break;
      case "GRID":
        tempMessage = {
          type: "Grid",
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons
        }
        break;
      case "SG": 
        tempMessage = {
          type: "SG",
          mine: false,
          text: message.text,
          date: moment().format('LT'),
          buttons: message.buttons.map(button => {
            return { label: button.speech }
          }),
          isWelcome: message.template_type.toUpperCase() === "GRID" ? true : isWelcome
        }
        break;
      case "SL":
        // tempMessage = makeSLMessage(false, message.text, message.list_items);
        break;
      case "BC":
        tempMessage = {
          type: message.template_type,
          mine: false,
          date: moment().format('LT'),
          title: message.title,
          text: message.text,
          image: {
            url: message.image.url,
            accessibility_text: message.image.accessibility_text
          },
          buttons: message.buttons
        }
        break;
      case "LO":
        tempMessage = {
          type: message.template_type,
          template_type: message.template_type,
          mine: false,
          date: moment().format("LT"),
          template_sub_type: message.template_sub_type,
          items: message.list_items,
        }
        break;
      case "CARD":
        tempMessage = {
          type: message.template_sub_type,
          mine: false,
          date: moment().format("LT"),
          template_sub_type: message.template_sub_type,
          items: message.items,
        }
        break;
      case "CAROUSEL":
        if (message.template_sub_type.toLowerCase() === 'helpcard'){
          tempMessage = {
            type: message.template_sub_type,
            list_items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'contentlist'){
          tempMessage = {
            type: 'CL',
            mine: false,
            date: moment().format('LT'),
            list_items: message.list_items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'productcard'){
          tempMessage = {
            type: 'ProductCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items,
            product_group_link_url: message.product_group_link_url,
            more_items: message.more_items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'eventcard') {
          tempMessage = {
            type: 'EventCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'carecard') {
          tempMessage = {
            type: 'CareCard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        else if (message.template_sub_type.toLowerCase() === 'ticard') {
          tempMessage = {
            type: 'TICard',
            mine: false,
            date: moment().format('LT'),
            items: message.items
          }
        }
        break;
      case "ACTION":
        tempMessage.type = 'ACTION';
        tempMessage.subType = message.action.code;
        tempMessage.action = message.action;
        tempMessage.reaction = message.action.type.toLowerCase() === 'request_response';
        tempMessage.mine = false;
        tempMessage.date = moment().format('LT');
        tempMessage.parameters = message.parameters;
        break;
      default: break;
    }
    // tempMessage.id = base62.v4();
    return tempMessage;
  });
}


// const convertMessage = (messages, isWelcome = false) => {
//   return messages.map(message => {
//     let tempMessage = {};
//     switch (message.template_type.toUpperCase()) {
//       case "TX":
//         tempMessage = makeTXMessage(false, message.text);
//         break;
//       case "TL":
//         tempMessage = {
//           type: message.template_type,
//           mine: false,
//           text: message.text,
//           date: moment().format('LT'),
//           link: message.link
//         }
//         break;
     
//       default: break;
//     }
//     // tempMessage.id = base62.v4();
//     return tempMessage;
//   });
// }

const convertDebugMessage = (messages) => {

  if (Array.isArray(messages)) {
    return messages.map(message => {
      let tempMessage = makeTXMessage(false, message);
      tempMessage.type = 'debug';
      tempMessage.text = message;
      return tempMessage;
    });
  } else {
    let tempMessage = makeTXMessage(false, messages);
    tempMessage.type = 'debug';
    tempMessage.text = messages;
    return [tempMessage];
  }
}


/**
 *
 * 
 * 
 * 
 *  
 */

const mkTXStr = (id, mine, text) =>(
  JSON.stringify({
    id: id, //base62.v4(),
    type: 'tx',
    template_type: "tx",
    mine: mine,
    text: text,
    date: moment().format('LT'),
    loading: false,
    online: true,
}));

const mkTLStr = (id, mine=false, text, label, speech, type, url ) => (
  JSON.stringify({
    id: id, //base62.v4(),
    type: 'tl',
    template_type: "tl",
    mine: mine,
    text: text,
    date: moment().format('LT'),
    link: [{
        label: label,
        speech: speech,
        type: type, //inapp, external, native
        url: url //url, qna, store
    }]
  }));

const mkCLStr = (cl_list)=>(JSON.stringify({
    type:"carousel",
    template_type: "Carousel",
    template_sub_type: "ContentList",
    list_items: cl_list,
    ellipsis: "Y"
}));

const mkProductCardStr = (productList)=>(JSON.stringify({
    type:"carousel",
    template_type: "Carousel",
    template_sub_type: "ProductCard",
    items: productList,
}));

const mkReserveStr = (url) => (JSON.stringify({
  type:"bc",
  template_type: "bc",
  mine: false,
  date: moment().format('LT'),
  // title: "출장서비스 예약",
  text: "아래 출장서비스 예약하기 링크를 눌러 예약을 진행해 주세요.",
  image: {
    url: "/image/ko_thinq_ev.jpg",
    accessibility_text: "출장서비스 예약"
  },
  buttons: [
    {title: "출장서비스 예약하기",
    // url:"http://chatbot-st.lgthinq.com/ext/reservation/services/request.html?channel=thinq&channel_parameters=eyJyZXF1ZXN0X3R5cGUiOiAiZW5naW5lZXIiLCAic3ZjX3R5cGUiOiAiQSIsICJwcmVtaXVtIjogIk4iLCAicHJvZHVjdF9jb2RlIjogIkRSVyIsICJwbGFjZV90eXBlIjogInJlc2lkZW5jZSIsICJpbnN0YWxsX3R5cGUiOiAiXHVjOWMxXHViODJjKFx1YzcwNFx1YzU0NFx1Yjc5OClcdWMxMjRcdWNlNTgtMlx1YjJlOCIsICJwcm9kdWN0X25hbWUiOiAiXHViNGRjXHViN2ZjIFx1YzEzOFx1ZDBjMVx1YWUzMCIsICJyY3B0X3N5bXB0b21fY29kZSI6ICJEUldTVDAwMDUwOCIsICJzdWJfc3ltcHRvbV9jb2RlIjogIlNFMDAwMDA1IiwgImNsaWVudF9pZCI6ICIwYVlGYTlSNXcwQ0M3RHk4S1ROWUljIiwgInRpbWVzdGFtcCI6IDE2NTI4NDEyNjQsICJkZXZpY2VfdHlwZSI6ICJtb2JpbGUiLCAib3MiOiAiYW5kcm9pZCIsICJwcm9kdWN0X2dyb3VwX2NvZGUiOiAiR1JfV00iLCAicHJvZHVjdF9ncm91cF9uYW1lIjogIlx1YzEzOFx1ZDBjMVx1YWUzMCIsICJwcml2YWN5X2NvbnNlbnQiOiAiWSIsICJ1c2VyX25hbWUiOiAiXHVjMTkwXHViM2Q5XHVjOTAwIiwgInBob25lX251bWJlciI6ICIwMTAyODIzMjE3NSIsICJhY2Nlc3NfdG9rZW4iOiAiYzBjMjI1N2NjNjM3Njc1MjAzMDU4ZjJhNzBiZjNmMmE3MDE3ODU2MDQwYzE5MTlkZWQ4MGVjOWI3NDViYmM0NjAxMTQzMjQzYWU2ZjQyNzhmMjI3ZTE4MWU2Yzg2MzU4In0=",
    url: url,
    type: "external"
    },
  ],
  loading:false,
}));

const mkTypingStr = ()=>(JSON.stringify({
  type:"tying",
  template_type: "typing",
}));

const mkLOStr = list => (JSON.stringify({
  type:"LO",
  template_type: "LO",
  template_sub_type: "LO",
  items: list,
}))

export  { makeTXMessage, makeTLMessage, convertMessage, convertDebugMessage,
        mkTXStr, mkTLStr, mkCLStr, mkTypingStr, mkLOStr, mkProductCardStr, mkReserveStr
};