import React, { useEffect, useState } from 'react';
import {
  Box, Container, Grid,TableBody, TableHead,
  Card, CardContent, Divider, CardActions, AppBar, Tab, Tabs, TabPanel, Switch,
  Typography, TableContainer, Table, TableRow, TableCell, withStyles, Paper,
  InputLabel, FilledInput, InputAdornment, FormControl, OutlinedInput,
  Input, Select, Button, Checkbox, MenuItem, Chip, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, TextField 
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { ArrowRight } from "@material-ui/icons";
import { Check } from "@material-ui/icons";
import { useUser } from '../data/User';
import { ButtonGroup } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import moment from "moment";
import { getMe, mutationUpdateCounselor } from '../data/Loader';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = (theme) => ({
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  container:{
  },
  // left:{
  //   margin:"10px"
  // },
  // right:{
  //   margin:"10px"
  // },
  root: {},
  subTitle: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {
    margin: "16px 0px",
    border: "1px solid #f6f6f6",
    borderRadius: theme.spacing(1)
  },
  require: {
    color: theme.palette.primary.main,
  },
  current:{
    background: theme.palette.primary.main,
    color: "#fff",
  },
  chip:{
    marginRight: "3px",
  }
});

const accountProfileStyles = (theme) =>({
  accountProfileBox:{
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar:{
    height: 100,
    width: 100,
    marginTop:"40px",
    color: '#0fb6d3',
    backgroundColor: "#b2e6ef",
    display:"flex",
    alignItems:"baseline",
    lineHeight:"normal",
    fontSize:"4rem",
  },
  avatarDefalut:{
    height: 100,
    width: 100,
    marginTop:"40px",
  },
  smallAvatar :{
    height: 50,
    width: 50,
    margin:"10px",
    display:"flex",
    alignItems:"center",
    lineHeight:"normal",
    fontSize:"4rem",
    color: '#0fb6d3',
    backgroundColor: "#b2e6ef",
    border:"1px solid #fff",
  },
  profileTitle:{
    fontSize: "1.5rem",
    fontWeight:600,
  },
  titleWrapper:{
    margin: "20px 0px 5px",
    display:"flex",
    alignItems:"center"
  },
  label:{
    marginLeft:"5px"
  },
  role:{
    borderRadius: "5px",
    borderColor: "#fff",
    fontWeight: "600",
    backgroundColor: "#f2eaff",
    color: "#804bdf",
  },
  counselorInfo:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"30px",

  },
  counselorInfoItem:{
    display:"flex",
    margin:"10px",
    alignItems:"center"
  },
  counselorInfoText:{
  },
  infoText1:{
    fontWeight:600,
    fontSize:"1.5em",
  },
  infoText2:{
    fontSize:"1.0em",
  },
  detailBox:{
    padding:"10px 0px",
  },
  detailTitle:{
    marginTop:"30px",
    marginBottom:"5px",
    fontSize: "1.0em",
    fontWeight: 600,
    display:"flex",
    alignItems:"center",
    color:"#787281"
  },
  detailItemBox:{
    padding:"10px 0px",
    color:"#787281",
    fontWeight:400,
    fontSize:"1em",
    borderBottom:"1px solid #ececec",
    "& span":{
      float:"right",
      fontWeight:400,
      marginRight:"5px"
    },
  },
  cardAction:{
    padding:"20px",
    justifyContent:"flex-end"
  },
  confirmBtn:{
    margin: "10px",
    padding: "8px 15px",
  },
  dialogTitle:{
    color:"#888",
    padding:"25px",
    fontWeight:600,
    fontSize: "1.0rem"
  }

});
//상담정보/설정/보안
const accountSettingStyles = (theme) =>({
  appbar:{
    color:"#888",
    boxShadow:"none",
    background:"none",
    "& .MuiTab-wrapper":{
      display:"flex",
      flexDirection:"row",
    },
    "& .MuiTabs-indicator":{
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root":{
      marginBottom:"0px !important",
    },
    "& .MuiTab-labelIcon":{
      minHeight:"45px",
    }
  },
  roleCard:{
    margin:"20px 0px",
    padding:" 20px"
  },
  accountSettingBox:{
    padding:"0px"
  },
  tabPanelBox:{
  },

  detailTitle:{
    marginTop:"10px",
    marginBottom:"5px",
    fontSize: "0.9em",
    fontWeight: 600,
    display:"flex",
    alignItems:"center",
    color:"#787281"
  },
  detailItemBox:{
    padding:"0px 10px 40px 10px",
    color:"#787281",
    fontWeight:400,
    fontSize:"1em",
    // borderBottom:"1px solid #ececec",
    "& span.row":{
      float:"right",
      fontWeight:400,
      marginRight:"5px"
    },
  },
  branchChipCurrent:{
    borderRadius: "3px",
    borderColor: theme.palette.primary.main,
    // fontWeight: "600",
    // backgroundColor: "#b2e6ef",
    color: theme.palette.primary.main,
    marginRight:"3px"
  },
  branchChip:{
    borderRadius: "3px",
    marginRight:"3px"
  },
  branchChipCurrentAvatar:{
    background:"none",
    width: "12px !important",
    height: "12px !important",
    color: theme.palette.primary.main,
  },
  th1:{
    width:"200px"
  },
  roleChip:{
    marginRight:"5px"
  },
  li:{fontSize:"1.0em"},
  branchDiv:{
    paddingLeft:"16px"
  },
  settingSelect:{
    marginRight:"5px",
    "& .MuiSelect-root":{
      padding: "10px 32px 10px 15px",
    },
    
  },
  settingTextField:{
    width:"100px",
    "& .MuiInputBase-input":{
      padding: "10px 32px 10px 15px",
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "4px 0px 0px 4px", 
      marginRight: "-1px", 
    }
  },
  TextFieldBtn:{
    color: theme.palette.primary.main,
    border: "1px solid",
    borderLeft:"none",
    borderColor: theme.palette.primary.main,
    backgroundColor:"#fff",
  },
  pwUpdate:{
    marginLeft:"10px"
  },

  //인사말/매크로 Textarea//
  commentTextarea:{
    borderRadius: "4px",
    marginRight: "-1px",
    width: "100%",
    "&:focus": {
      border: "1px solid #b6b6b6",
      outline: "none",
    },
    border: "1px solid #d7d7d7 !important",
    fontFamily: "inherit",
    padding: "5px",
    fontSize: "1.0em",
    minHeight: "1.5rem"
  },
  textareaBtn:{
    color: theme.palette.primary.main,
    border: "1px solid",
    // borderLeft:"none",
    borderColor: theme.palette.primary.main,
    backgroundColor:"#fff",
  },
  textareaGroup:{
    width:"100%"
  }


});
const RoleStyles = {

}
const RoleCard = withStyles(RoleStyles)((props) => {
  const {classes, me} = props;
  console.log(me.role);
  const roles = me.role;
  const branch_role = {};
  roles.forEach(
    (r) =>{
      if (r.branch in branch_role){
        branch_role[r.branch].push(r.roleName)
      }else{
        branch_role[r.branch] = [r.roleName]
      }
    }
  )
  console.log("[branch_Role::::]", branch_role)
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return(
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        {Object.keys(branch_role).map((key, i) => (
          <Tab label={key.toUpperCase()} {...a11yProps(i)} key={`role_table${i}`}/>
        ))}
        </Tabs>
      </AppBar>

        {Object.keys(branch_role).map((key, i) => (
           <TabPanel value={value} index={i} key={`role_tab${i}`}>
             {branch_role[key].map(
               r=> <Chip className={classes.role} variant="outlined" size="small" label= {r}/>
             )
            }
           </TabPanel>

        ))}
      
    </>
  )
})
const CategoryCard = withStyles(RoleStyles)((props) => {
  const {classes, me} = props;
  console.log(me.category);
  const category = me.category;
  const branch_category = {};
  category.forEach(
    (r) =>{
      if (r.branch in branch_category){
        branch_category[r.branch].push(r.categoryId)
      }else{
        branch_category[r.branch] = [r.categoryId]
      }
    }
  )
  console.log("[branch_category::::]", branch_category)
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return(
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        {Object.keys(branch_category).map((key, i) => (
          <Tab label={key.toUpperCase()} {...a11yProps(i)} key={`category_tab${i}`}/>
        ))}
        </Tabs>
      </AppBar>

        {Object.keys(branch_category).map((key, i) => (
           <TabPanel value={value} index={i} key={`category_panel${i}`}>
             {branch_category[key].map(
               r=> <Chip className={classes.role} variant="outlined" size="small" label= {r}/>
             )
            }
           </TabPanel>

        ))}
      
    </>
  )
})
const AccountSetting = withStyles(accountSettingStyles)((props) => {
  const {classes, me} = props;
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box className={classes.tabPanelBox}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const roles = me.role;
  const branch_role = {};
  roles.forEach(
    (r) =>{
      if (r.branch in branch_role){
        branch_role[r.branch].push(r.roleName)
      }else{
        branch_role[r.branch] = [r.roleName]
      }
    }
  )
  const category = me.category;
  const branch_category = {};
  category.forEach(
    (r) =>{
      if (r.branch in branch_category){
        branch_category[r.branch].push(r.categoryId)
      }else{
        branch_category[r.branch] = [r.categoryId]
      }
    }
  )
  const [maxCon, setMaxCon] = useState();
  useEffect(
    () =>{
      setMaxCon(me.maxCounselNum);
    },[me]
  );

  const handleMaxConChange = (e) =>{
    setMaxCon(e.target.value)
  }
  const handleMaxConUpdate = (e) =>{
    console.log("maxCon::", maxCon)
    mutationUpdateCounselor(me.counselorId,me.currentBranch, null, maxCon, null, null)
    .then((result) => {
      console.log("[updateCounselor] Query Result", result.data);
    })
    .catch((e) => {
      console.log("[updateCounselor] Query Error", e);
    })
    
  }


  //password//
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    confirmPassword:'',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChangePw = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMacro = () => {
    
  }

  
  

  return (
    <>
    <AppBar position="static" className={classes.appbar}>
      <Tabs value={value} onChange={handleChange} aria-label="personal setting">
        <Tab label="상담정보" {...a11yProps(0)} icon={<PersonOutlineIcon/>}/>
        <Tab label="설정" {...a11yProps(1)} icon={<SettingsIcon/>}/>
        <Tab label="보안" {...a11yProps(2)} icon={<LockOutlinedIcon/>}/>
        <Tab label="알림" {...a11yProps(3)} icon={<NotificationsNoneOutlinedIcon/>}/>
      </Tabs>
    </AppBar>
    <TabPanel value={value} index={0}>
      <Card className={classes.roleCard}>
          <Box className={classes.detailBox}>
            <Box className={classes.detailItemBox}>
              <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>상담 조직</Typography> 
              <div className={classes.branchDiv}>
                {me.branch.map(b=> 
                  <Chip variant="outlined" size="small" label= {b.toUpperCase()} className={b===me.currentBranch?classes.branchChipCurrent:classes.branchChip}
                  avatar={b==me.currentBranch?<Avatar className={classes.branchChipCurrentAvatar}><Check/></Avatar>:''}
                  ></Chip>)}
              </div>
            </Box>
            <Box className={classes.detailItemBox}>
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>권한</Typography> 
                <TableContainer compotnent={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th1}>상담 조직</TableCell>
                        <TableCell>권한</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(branch_role).map(b=> 
                        <TableRow>
                          <TableCell>
                            <Chip variant="outlined" size="small" label= {b.toUpperCase()} className={b===me.currentBranch?classes.branchChipCurrent:classes.branchChip}
                            avatar={b==me.currentBranch?<Avatar className={classes.branchChipCurrentAvatar}><Check/></Avatar>:''}
                            ></Chip>
                          </TableCell>
                          <TableCell>
                            {branch_role[b].map(r=> <Chip label={r} size="small" className={classes.roleChip}/>)}
                          </TableCell>
                        </TableRow>
                      )}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
            </Box>
            <Box className={classes.detailItemBox}>
                <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>카테고리</Typography> 
                <TableContainer compotnent={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th1}>상담 조직</TableCell>
                        <TableCell>카테고리</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(branch_category).map(b=> 
                        <TableRow>
                          <TableCell>
                            <Chip variant="outlined" size="small" label= {b.toUpperCase()} className={b===me.currentBranch?classes.branchChipCurrent:classes.branchChip}
                            avatar={b==me.currentBranch?<Avatar className={classes.branchChipCurrentAvatar}><Check/></Avatar>:''}
                            ></Chip>
                          </TableCell>
                          <TableCell>
                            {branch_category[b].map(r=> <Chip label={r} size="small"/>)}
                          </TableCell>
                        </TableRow>
                      )}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
            </Box>
          
            
          </Box>
        {/* <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            권한 정보
          </Typography>
          <RoleCard me={me}/>
        </CardContent> */}
      </Card>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <Card className={classes.roleCard}>
        <Box className={classes.detailBox}>
          {/* <Box className={classes.detailItemBox}>
            <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>사용자 그룹</Typography> 
            <div className={classes.branchDiv}>
             
            </div>
          </Box> */}
          <Box className={classes.detailItemBox}>
              <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>언어</Typography> 
              <Select value={0} variant="outlined" className={classes.settingSelect}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>한국어(한국)</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>영어</Typography></MenuItem>
              </Select>
          </Box>
          {/* <Box className={classes.detailItemBox}>
              <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>계정만료일 사용 여부</Typography> 
              <Switch
                // checked={state.checkedA}
                // onChange={handleChange}
                // name="checkedA"
                inputProps={{ 'aria-label': '계정만료일 사용 여부' }}
              />
          </Box> */}
          <Box className={classes.detailItemBox}>
              <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>최대 연결 수</Typography> 
              
              <ButtonGroup disableElevation variant="contained" color="primary">
                <TextField id="connectionNum"  variant="outlined"  className={classes.settingTextField} value={maxCon} onChange={handleMaxConChange}/>
                <Button className={classes.TextFieldBtn} onClick={handleMaxConUpdate}>Update</Button>
              </ButtonGroup>
          </Box>
          <Box className={classes.detailItemBox}>
              <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>로그인 후 시작 페이지</Typography> 
              <div>
              <Select value={0} variant="outlined" className={classes.settingSelect}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>상담관리</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>모니터링</Typography></MenuItem>
              </Select>
              <Select value={0} variant="outlined" className={classes.settingSelect}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>전체목록</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>서브목록</Typography></MenuItem>
              </Select>
              </div>
          </Box>
        
          
        </Box>
      </Card>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <Card className={classes.roleCard}>
        <Box className={classes.detailBox}>
          <Box className={classes.detailItemBox}>
            <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>패스워드 변경</Typography> 
            <Alert severity="warning">
              <AlertTitle>Ensure that these requirements are met.</AlertTitle>
              <Typography>Minimum 8 characters long, uppercase &amp; symbol</Typography>
            </Alert>
          </Box>
          <Box className={classes.detailItemBox}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                  autoFocus
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChangePw('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={120}
                />
              
              </FormControl>
          </Box>
          <Box className={classes.detailItemBox}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
                <OutlinedInput
                  autoFocus
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChangePw('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={170}
                />
              
              </FormControl>
          </Box>
          <Button variant="contained" color="primary" classes={{ root: classes.pwUpdate}}>
             변경
          </Button>
          
        </Box>
      </Card>
    </TabPanel>
    <TabPanel value={value} index={3}>
      <Card className={classes.roleCard}>
        <Box className={classes.detailItemBox}>
            <Typography className={classes.detailTitle}><ChevronRightIcon className={classes.li}/>상태표시</Typography> 
            <TableContainer compotnent={Paper}>
              <Table size="small">
                {/* <TableHead>
                  <TableRow>
                    <TableCell className={classes.th1}>상담 조직</TableCell>
                    <TableCell>권한</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {Object.keys(branch_role).map(b=> 
                    <TableRow>
                      {/* <TableCell>
                        <Chip variant="outlined" size="small" label= {b.toUpperCase()} className={b===me.currentBranch?classes.branchChipCurrent:classes.branchChip}
                        avatar={b==me.currentBranch?<Avatar className={classes.branchChipCurrentAvatar}><Check/></Avatar>:''}
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        {branch_role[b].map(r=> <Chip label={r} size="small" className={classes.roleChip}/>)}
                      </TableCell> */}
                    </TableRow>
                  )}
                  
                </TableBody>
              </Table>
            </TableContainer>
        </Box>
      </Card>
    </TabPanel>
    </>
  )
})
const AccountProfile = withStyles(accountProfileStyles)((props) => {
  const {classes, me} = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditClick = () => {
    setEditDialogOpen(true);
  }

  const [newPhone, setNewPhone] = useState();
  const [newMobile, setNewMobile] = useState();
  const [newDepartment, setNewDepartment] = useState();
  const handleChange = (event) => {
    console.log(event.target.id)
    const id = event.target.id;
    const value = event.target.value;
    id == "phone" && setNewPhone(value);
    id == "mobile" && setNewMobile(value);
    id == "department" && setNewDepartment(value);
    // setName(event.target.value);
  };
  const submitEdit = () => {
    // console.log(newPhone)
    // console.log(newMobile)
    // console.log(newDepartment)
    mutationUpdateCounselor(me.counselorId, me.currentBranch, newDepartment, null, newMobile, newPhone)
    .then((result) => {
      console.log("[updateCounselor] Query Result", result.data, ">>>me:", me);
      handleClose();
      // getMyInfo();
    })
    .catch((e) => {
      console.log("[updateCounselor] Query Error", e);
    })
  }
  const handleClose = () => {setEditDialogOpen(false)}

  // const getMyInfo = () => {
  //     getMe()
  //     .then(result => {
  //       console.log('[User.Provider] Me Result', result);
  //       const update = result.data.whoami;
  //       setNewPhone(update.phone);
  //       setNewMobile(update.mobilephone);
  //       setNewDepartment(update.department);
  //     })
  //     .catch(error => {
  //       console.log('[User.Provider] Me Error', error);
  //     });
  // }

  useEffect(
    () =>{
      setNewPhone(me.phone);
      setNewMobile(me.mobilephone);
      setNewDepartment(me.department);
    }
    ,[me]
  );
  return (
    <>
      <Card>
        <CardContent> 
          <Box className={classes.accountProfileBox}>
            {/* <Avatar src={me.avatar} className={classes.avatar} variant="rounded">{me.counselorId &&me.counselorId.length && me.counselorId.slice(0,1)}</Avatar> */}
            <Avatar className={classes.avatarDefalut}/>
            <div className={classes.titleWrapper}>
              <Typography className={classes.profileTitle} >{me.name} 상담사</Typography> 
            </div>
            <Chip className={classes.role} variant="outlined" size="small" label= {me.role.filter(r=>r.branch==me.currentBranch)[0].roleName}/>

            <div className={classes.counselorInfo}>
              <div className={classes.counselorInfoItem}>
                <Avatar variant="rounded" className={classes.smallAvatar}>
                  <AssignmentIcon/>
                </Avatar>
                <div className={classes.counselorInfoText}>
                  <Typography className={classes.infoText1}>{me.currentCounselNum}건</Typography>
                  <Typography className={classes.infoText2}>상담중</Typography>
                </div>
              </div>
              <div className={classes.counselorInfoItem}>
                <Avatar variant="rounded" className={classes.smallAvatar}>
                  <Check/>
                </Avatar>
                <div className={classes.counselorInfoText}>
                  <Typography className={classes.infoText1}>13건</Typography>
                  <Typography className={classes.infoText2}>상담완료</Typography>
                </div>
              </div>
            </div>
          </Box>
        </CardContent>
        <CardContent>
          <div className={classes.detailTitle}> <PersonIcon/>기본정보</div>
          <Box className={classes.detailBox}>
            <Box className={classes.detailItemBox}>
              상담사 ID: <span>{me.counselorId}</span>
            </Box>
            <Box className={classes.detailItemBox}>
              Email: <span>{me.email}</span>
            </Box>
            <Box className={classes.detailItemBox}>
              전화번호: <span>{me.phone}</span>
            </Box>
            <Box className={classes.detailItemBox}>
              휴대폰번호: <span>{me.mobilephone}</span>
            </Box>
            <Box className={classes.detailItemBox}>
              부서: <span>{me.department}</span>
            </Box>
            <Box className={classes.detailItemBox}>
              사번: <span>-</span>
            </Box>
            <Box className={classes.detailItemBox}>
              계정생성일: <span>{moment(me.created * 1000).format("YYYY-MM-DD HH:mm")}</span>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" classes={{ root: classes.confirmBtn}} onClick={handleEditClick}>
            수정
          </Button>
        </CardActions>
      </Card>
      <Dialog classes={{paper: classes.root}} open={editDialogOpen} onClose={handleClose}>
        <DialogTitle classes={{root:classes.dialogTitle}} disableTypography={true}>
          개인정보 수정
        </DialogTitle>
        <DialogContent classes={{root:classes.dialogContent}}>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} >
                <TextField id="email" label="Email" variant="outlined" fullWidth value={me.email} disabled />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField id="phone" label="전화번호" variant="outlined" fullWidth value={newPhone} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField id="mobile" label="휴대폰번호" variant="outlined" fullWidth value={newMobile} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12} sm={12} >
                <TextField id="department" label="부서" variant="outlined" fullWidth value={newDepartment} onChange={handleChange}/>
              </Grid>
            </Grid>

          </form>
          <Grid container className={classes.column} xs={6} sm={6} md={6} >
          {/* <div className={classes.folder}>
            <AppBar position="static" classes={{root:classes.appBar}}>
              <Tabs value={value} onChange={handleChange} aria-label="" classes={{root: classes.tabs,indicator: classes.indicator,}}>
                <Tab label="사용자 그룹" {...a11yProps(0)} 
                className={classes.tab}
                classes={{selected:classes.tabSelected }} 
                />
                <Tab label="사용자 권한" {...a11yProps(1)} 
                className={classes.tab}
                classes={{selected:classes.tabSelected }} 
                />
              </Tabs>
            </AppBar>
            <div role="tabpanel" value={value} index={0} hidden={value !== 0}>
                <TreeView
                  className={classes.treeViewRoot}
                  defaultExpanded={categoryExpanded} 
                  defaultCollapseIcon={<ArrowDropDown />}
                  defaultExpandIcon={<ArrowRight />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                >
                  {renderTree(categoryData)}
                </TreeView>
            </div>
            <div role="tabpanel" value={value} index={1} hidden={value !== 1}>
               
            </div>
          </div> */}
          </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={submitEdit} color="primary" autoFocus type="submit">
            수정
          </Button>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
})
function Setting_Personal(props) {
  const { classes } = props;

  const { me } = useUser();
  // const {me, setMe} = useState({
  //   counselorId: "",
  //   name: "",
  //   phone: "",
  //   mobilephone: "",
  //   department: "",
  //   email: "",
  //   created: 0,
  //   maxCounselNum: 0,
  //   availCounselNum: 0,
  //   currentCounselNum: 0,
  //   cognitoId: "",
  //   currentBranch: "",
  //   isPWInit: false
  // })
  // const getMyInfo = () => {
  //     getMe()
  //     .then(result => {
  //       console.log('[User.Provider] Me Result', result);
  //       const update = result.data.whoami;
  //       setMe(update);
  //     })
  //     .catch(error => {
  //       console.log('[User.Provider] Me Error', error);
  //     });
  // }
  // useEffect(
  //   ()=>{
  //     getMyInfo()
  //   },[]
  // )

  return (
    <>
    <Typography className={classes.title} color={"primary"}>
      개인정보 설정
    </Typography>
    <Box maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid className={classes.left}
          item
          lg={4}
          md={4}
          xs={12}
        >
          <AccountProfile me={me}/>
        </Grid>
        <Grid className={classes.right}
          item
          lg={8}
          md={8}
          xs={12}
        >
          <AccountSetting me={me}/>
        </Grid>
      </Grid>
    </Box>

{/* 
    <div className={classes.root}>
      <Typography className={classes.title} color={"primary"}>
        개인정보 설정
      </Typography>
      <div className={classes.subTitle}>
        <ArrowRight color="primary" />
        <Typography variant={"subtitle2"}>기본 정보</Typography>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
        >
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                이름{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input required value={me.name} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                아이디{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input disabled value={me.counselorId} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                상담조직{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
            <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                {me.branch.map( r=> (
                  r==me.currentBranch?
                   <Button startIcon={<Check/>} className={classes.current} color="primary">{r.toUpperCase()}</Button>
                    : <Button>{r.toUpperCase()}</Button>
                ))}
            </ButtonGroup>
  
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                사용자 권한{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              {me.role.map( r=> r.branch==me.currentBranch && <Chip className={classes.chip} size="small" label={r.roleName} avatar={<Avatar>{r.roleName.length>0 && r.roleName.slice(0,1).toUpperCase()}</Avatar>}/>)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                비밀번호{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Button variant={"outlined"} size={"small"}>비밀번호 변경</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                휴대폰번호{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input required value={me.mobilephone?me.mobilephone:""} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                최대 연결수{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input required value={me.maxCounselNum} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                현재 연결수{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input required value={me.currentCounselNum} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                계정 만료일{" "}
              </Typography>
            </TableCell>
            <TableCell>
              <Checkbox color="primary" />
              <Typography component={"span"} variant={"subtitle2"}>
                계정 만료일 사용여부
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
        >
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                사용자 그룹{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Input required disabled value="기본 그룹" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                언어{" "}
              </Typography>
              <Typography component={"span"} className={classes.require}>
                *
              </Typography>
            </TableCell>
            <TableCell>
              <Select value={0}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>한국어(한국)</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>영어</Typography></MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
        >
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                이메일
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                휴대폰 번호
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                사번
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                부서
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                전화번호
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                별칭
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                설명
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                입사년도
              </Typography>
            </TableCell>
            <TableCell>
              <Input value="" />
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>

      <div className={classes.subTitle}>
        <ArrowRight color="primary" />
        <Typography variant={"subtitle2"}>일반환경 설정</Typography>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
        >
          <TableRow>
            <TableCell variant={"head"}>
              <Typography component={"span"} variant={"subtitle2"}>
                로그인 후 시작페이지
              </Typography>
            </TableCell>
            <TableCell>
              <Select value={0}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>상담관리</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>모니터링</Typography></MenuItem>
              </Select>
              <Select value={0}>
                <MenuItem value={0}><Typography variant={"subtitle2"}>전체목록</Typography></MenuItem>
                <MenuItem value={1}><Typography variant={"subtitle2"}>서브목록</Typography></MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
    
   */}
  </>
    
  );
}
export default withStyles(styles)(Setting_Personal);
