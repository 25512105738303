import { createGlobalStyle } from 'styled-components';

import LGSmHaTL_woff2 from '../../asset/font/LGSmHaTL.woff2';
import LGSmHaTR_woff2 from '../../asset/font/LGSmHaTR.woff2';
import LGSmHaTSB_woff2 from '../../asset/font/LGSmHaTSB.woff2';

export default  createGlobalStyle`
  @font-face {
    font-family:'LGL' ; font-style:normal; font-weight:normal;
    src: local('LGSmHaTL'),
    url('${LGSmHaTL_woff2}') format('woff2'),
  }
  @font-face {
    font-family:'LG' ; font-style:normal; font-weight:normal;
    src: local('LGSmHaT'),
    url('${LGSmHaTR_woff2}') format('woff2'),
  }
  @font-face {
    font-family:'LGSB' ; font-style:normal; font-weight:normal;
    src: local('LGSmHaTSB'),
    url('${LGSmHaTSB_woff2}') format('woff2'),
  }
`;