import React from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Select,
  MenuItem,
  Input,
  Button
} from "@material-ui/core";

const styles = (theme) => ({
  root: {},
  title: {
    fontWeight: "bold",
    height: "45px",
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "#f9f9f9",
  },
  searchBarTitle: {
    margin: "0px 16px"
  },
  searchBarButton: {
    marginStart: "8px"
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    border: "1px solid #f9f9f9",
    borderRadius: theme.spacing(1),
  },
});

function Setting_Recommend(props) {
  const { classes } = props;

  const list = [
    {
      hotkey: "Alt + Number 0",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 1",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 3",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 4",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 5",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 6",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 7",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 8",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Alt + Number 0",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 1",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 2",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 3",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 4",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 5",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 6",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 7",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 8",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 9",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
    {
      hotkey: "Ctrl + Number 0",
      sentence:
        "추가 문의 사항 있으시면 연락 주시기 바라며, 올 한해에도 건강하시고 새해 복 많이 받으세요.",
    },
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color={"primary"}>
        톡 추천 메시지 관리
      </Typography>
      <div className={classes.searchBar}>
        <Typography
          component={"span"}
          variant={"subtitle2"}
          className={classes.searchBarTitle}
        >
          도메인
        </Typography>
        <Select value={0}>
          <MenuItem value={0}>
            <Typography variant={"subtitle2"}>LG전자</Typography>
          </MenuItem>
          <MenuItem value={1}>
            <Typography variant={"subtitle2"}>삼성전자</Typography>
          </MenuItem>
        </Select>
        <Typography
          component={"span"}
          variant={"subtitle2"}
          className={classes.searchBarTitle}
        >
          사용여부
        </Typography>
        <Select value={0}>
          <MenuItem value={0}>
            <Typography variant={"subtitle2"}>전체</Typography>
          </MenuItem>
          <MenuItem value={1}>
            <Typography variant={"subtitle2"}>부분</Typography>
          </MenuItem>
        </Select>
        <Typography
          component={"span"}
          variant={"subtitle2"}
          className={classes.searchBarTitle}
        >
          메시지
        </Typography>
        <Input />
        <Button size="small" variant={"contained"} color="primary" className={classes.searchBarButton}>검색</Button>
        <Button size="small" variant={"outlined"} color="primary" className={classes.searchBarButton}>초기화</Button>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          size={"small"}
          className={classes.table}
          aria-label="Service History"
        >
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.th }}>단축키</TableCell>
              <TableCell classes={{ root: classes.th }}>
                자주 사용하는 문장
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              <TableRow
                className={classes.tableButton}
                key={`setting_macro_table_index_${index}`}
              >
                <TableCell classes={{ root: classes.td }}>
                  {item.hotkey}
                </TableCell>
                <TableCell classes={{ root: classes.td }}>
                  {/* <Input value={item.sentence}/> */}
                  {item.sentence}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default withStyles(styles)(Setting_Recommend);
