import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%"
  }
});

const Column = (props) => {
  const { series, categories, width, height, legend, classes, limit } = props;

  let options = {
    chart: {
      type: "column",
      style: {
        fontFamily: 'LG'
      }
      // allowChartUpdate: true,
      // panning: true,
      // panKey: 'shift',
      // reflow: true,
      // margin: [0, 50, 0, 0]
    },
    credits: {
      enabled: false
    },
    // exporting: {
    //   buttons: {
    //     contextButton: {
    //       theme: {
    //         fill: "#00000000",
    //         states: {
    //           hover: {
    //             fill: "#FFFFFF",
    //             stroke: "#FF0000"
    //           },
    //           select: {
    //             fill: "#FFFFFF",
    //             stroke: "#FF0000"
    //           }
    //         }
    //       },
    //       onclick: this.onClick
    //     }
    //   }
    // },
    title: {
      text: "" //'Browser market shares. January, 2015 to May, 2015'
    },
    subtitle: {
      text: "" //'Click the columns to view versions. Source: <a href="http://netmarketshare.com">netmarketshare.com</a>.'
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      x: 0,
      y: -20,
      // margin: [0, 0, 0, 0]
      enabled: false
    },
    xAxis: {
      // categories: [],
      // type: "category",
      // visible: true,
      // labels: {
      //   enabled: true,
      //   align: "center",
      //   // autoRotation: [-30]
      //   // padding: 2,
      // },
      // gridLineWidth: 1,
      // gridLineDashStyle: "dash",
      // tickLength: 0,
      // minPadding: 0,
      // maxPadding: 0,
      // pointStart: 0,
      // max: 9,
      // scrollbar: {
      //   enabled: false
      // }
      crosshair: true
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      // x: -40,
      // y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    yAxis: {
      title: {
        text: "" // 'Total percent market share'
      },
      // visible: true,
      // labels: {
      //   enabled: false
      //   // align: "left",
      //   // x: 0,
      //   // y: -3
      // },
      // min: 0,
      // gridLineWidth: 1,
      // gridLineDashStyle: "dash",
      // type: this.state.type,
      // plotLines: [
      //   {
      //     enabled: true,
      //     color: "red",
      //     width: 1,
      //     value: 80,
      //     zIndex: 999,
      //     label: {
      //       x: 45,
      //       y: 5,
      //       text: "80",
      //       textAlign: "right",
      //       align: "right",
      //       style: {
      //         color: "#666",
      //         fontSize: "15px",
      //         fontWeight: 400
      //       }
      //     }
      //   }
      // ],
      // endOnTick: false
    },
    plotOptions: {
      column: {
        // stacking: "normal",
        // colorByPoint: true,
        pointPadding: 0.2,
        borderWidth: 0
      },
      series: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0.1
      }
    },
    colors: [
      "#0A8297", //"#e91e5f",
      "#003458"
      // "#ef9a9a",
      // "#80CBC4",
      // "#9FA8DA",
      // "#BCAAA4",
      // "#FFCC80",
      // "#B0BEC5",
      // "#F48FB1",
      // "#90CAF9",
      // "#C5E1A5",
      // "#FFAB91"
    ]

    // DATA
    // series: []
  };

  

  if (series !== undefined) {
    options.series = series;
    // options.plotOptions.column.colorByPoint = series.length <= 1;
  }
  if (categories !== undefined) {
    options.xAxis.categories = categories;
  }
  if (width !== undefined) options.chart.width = width;
  if (height !== undefined) options.chart.height = height;
  if (legend !== undefined) options.legend.enabled = legend;
  if (limit !== undefined) {
    options.yAxis.plotLines[0].value = limit;
    options.yAxis.plotLines[0].label.text = limit;
    options.yAxis.max = limit * 2;
  }

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
      />
    </div>
  );
}
export default withStyles(styles)(Column);
