import { Input, Button, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';

const styles = (theme) => ({
  root: {
    backgroundColor:"#f5f5f5",
    padding: "8px"
  },
  container: {
    display: "flex"
  },
  containerLeft: {
    justifyContent: "flex-start"
  },
  containerRight: {
    justifyContent: "flex-end"
  },
  message: {
    padding: "8px",
    maxWidth: "75%",
    width: "auto",
    backgroundColor: "white",
    borderRadius: "12px",
    border: "0 none",
    margin: "12px",
    padding: "8px",
    boxShadow: "0px 12px 12px rgba(194, 194, 194, 0.58)",
  },
  myMessage: {
    backgroundColor: "#7f7f7f",
    color: "#fff",
    margin: "12px",
    boxShadow: "none",
  },
  input: {
    width:"100%",
    flexGrow: 1
  },
  inputField: {
    width: "100%",
    display: "flex",
  },
  send: {
    // width: "100%"
    minWidth: "80px"
  }
});


const queryAllMessageConnection = `query queryAllMessageConnection($after: String, $conversationId: ID!, $first: Int) {
  allMessageConnection(after: $after, conversationId: $conversationId, first: $first) {
    nextToken
    messages {
      author {
        username
        id
      }
      content
      conversationId
      createdAt
      id
      isSent
      sender
    }
  }
}`;

const queryAllMessage = `query queryAllMessage($conversationId: ID!) {
  allMessage(conversationId: $conversationId) {
    author {
      username
      id
    }
    content
    conversationId
    createdAt
    id
    isSent
    sender
  }
}`;

const subscribeToNewMessage = `subscription subscribeToNewMessage($conversationId: ID!) {
  subscribeToNewMessage(conversationId: $conversationId) {
    content
    conversationId
    createdAt
    id
    isSent
    sender
  }
}`;

const createMessage = `mutation createMessage($content: String, $conversationId: ID!, $createdAt: String!, $id: ID!) {
  createMessage(content: $content, conversationId: $conversationId, createdAt: $createdAt, id: $id) {
    content
    conversationId
    createdAt
    id
    isSent
    sender
  }
}`;

const Conversation = withStyles(styles)((props) => {
  const { classes, sender, content, createdAt, myId } = props;

  // 상대방
  if (sender !== myId) {
    return (
      <div className={[classes.container, classes.containerLeft].join(" ")}>
        <div className={[classes.message].join(" ")}>
          <div>{content}</div>
          {/* <div>createdAt: {createdAt}</div> */}
        </div>
      </div>
    );
  }
  // 나
  else {
    return (
      <div className={[classes.container, classes.containerRight].join(" ")}>
        <div className={[classes.message, classes.myMessage, ].join(" ")}>
          <div>{content}</div>
          {/* <div>createdAt: {createdAt}</div> */}
        </div>
      </div>
    );
  }
});

const ConversationPanel = (props) => {
  const {classes, value, index, conversationId, myId } = props;
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const scrollRef = useRef(null);
  var subscription = null;

  console.log("conversationId", conversationId, "value", value);

  useEffect(() => {
    getAllMessageConnection(conversationId);
    subscribeMessage(conversationId);

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }, []);

  const getAllMessageConnection = async (conversationId, after = null, first = null) => {
    var nextToken = null;
    const result = await API.graphql(graphqlOperation(queryAllMessageConnection, {conversationId: conversationId, after: after, first: first}));
    console.log("[ ConversationPanel ] allMessageConnection:", result);

    nextToken = result.data.allMessageConnection.nextToken;
    setMessages(prev => [...prev, ...result.data.allMessageConnection.messages]);

    if (nextToken) {
      await getAllMessageConnection(conversationId, nextToken);
    }
  }

  const getAllMessage = async (conversationId) => {
    const result = await API.graphql(graphqlOperation(queryAllMessage, {conversationId: conversationId}));
    console.log("[ ConversationPanel ] allMessage:", result);
    setMessages(result.data.allMessage)
  }

  const subscribeMessage = async (conversationId) => {
    subscription = await API.graphql(graphqlOperation(subscribeToNewMessage, {conversationId: conversationId})).subscribe({
      next: result => {
        // setMessages(prev => [ ...prev, ])
        console.log("[ ConversationPanel ] subscribe:", result);
        const data = result.value.data.subscribeToNewMessage;
        console.log("data", data);
        setMessages(prev => {
          return [...prev, data];
        });
        scrollRef.current.scrollIntoView({ behavior: "auto" });
      },
      error: error => {
        console.log("[ ConversationPanel ] error:", error);
      }
    });
  }

  const sendMessage = async () => {
    const result = await API.graphql(graphqlOperation(createMessage, {content: input, conversationId: conversationId, createdAt: new Date().toISOString(), id: myId}));
    console.log("[ ConversationPanel ] sendMessage:", result);
    setInput("");
  }

  const handleInputChange = (e) => {
    setInput(e.target.value);
  }

  const handleEnter = (e) => {
    if (
      e.keyCode === 13 &&
      e.shiftKey === false
    ) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className={classes.root} role="tabpanel" hidden={value !== index} id={conversationId}>
      <div>
        {messages && (
          messages.map(message => (
            <Conversation key={message.createdAt} myId={myId} sender={message.sender} content={message.content} createdAt={message.createdAt}/>
          ))
        )}  
        <div ref={scrollRef}/>
      </div>
      <div className={classes.inputField}>
        <Input value={input} onChange={handleInputChange} onKeyDown={handleEnter} className={classes.input}/>
        <Button onClick={sendMessage} variant="contained" color="secondary" classes={{root: classes.send}}>SEND</Button>
      </div>
      
    </div>
  );
}
export default withStyles(styles)(ConversationPanel);