import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import Error from "./Error";
import NoPermission from "./NoPermission";
import Chat from "./Chat";
import NavBar from "../navbar/NavBar";
import Monitor_CS from "./Monitor_CS";
import Monitor_Allocate from "./Monitor_Allocate";
import History from "./History";
import Graph from "./Graph";
import Knowledge from "./Knowledge";
import Setting_Macro from "./Setting_Macro";
import Setting_Personal from "./Setting_Personal";
import Setting_Recommend from "./Setting_Recommend";
import Management_Category from "./Management_Category";
import Management_Counselor from "./Management_Counselor";
import Management_Work from "./Management_Work";
import Management_Holiday from "./Management_Holiday";
import Management_Others from "./Management_Others";
import Management_Survey from "./Management_Survey";
import Management_Template from "./Management_Template";
import Management_Notice from "./Management_Notice";
import Management_Favorite from "./Management_Favorite";
import Management_Prohibit from "./Management_Prohibit";
import System_Settings from "./System_Settings";
import Login from "./Login";
import { RBACIsAccessbile } from '../data/Loader';

import { useUser } from '../data/User'

function RouteMap() {
  const { me } = useUser();
  const [accInfo, setAccInfo] = useState({});
  useEffect(() => {
    if (me && me.counselorId) {
      let retvalue = ['viewId', 'viewName'];
      let viewIdArray = [
        "menu_counsel",
        "menu_account_setting",
        "menu_branch_setting",
        "menu_monitoring",
        "menu_system_setting"
      ]
      let branchArray = [
        me.currentBranch
      ]
      RBACIsAccessbile(retvalue, branchArray, viewIdArray)
        .then(RBACViewList => {
          let accObj = RBACViewList.reduce((newObj, viewInfo) => {
            newObj[viewInfo.viewId] = true
            return newObj;
          }, {})
          console.log(accObj);
          setAccInfo(accObj);
          // menus.map(curMenu=>{
          //   curMenu.accessible = accObj[curMenu.viewId];
          // })
        })
        .catch(error => {
          console.error(error)
        })
    
    }
    else {
      setAccInfo({});
    }
  }, [me]);
  if (Object.keys(accInfo).length > 0) {
    return (
      <Router>
        <NavBar>
          <Switch>
            {accInfo['menu_counsel']===true?<Route exact path="/chat" component={Chat}/>:<Route component={NoPermission} />}
            <Route exact path="/monitor/cs" component={Monitor_CS} />
            <Route exact path="/monitor/allocate" component={Monitor_Allocate} />
            <Route exact path="/history" component={History} />
            <Route exact path="/history" component={History} />
            <Route exact path="/graph" component={Graph} />
            <Route exact path="/knowledge" component={Knowledge} />
            <Route exact path="/management/category" component={Management_Category} />
            <Route exact path="/management/counselor" component={Management_Counselor} />
            <Route exact path="/management/work" component={Management_Work} />
            <Route exact path="/management/holiday" component={Management_Holiday} />
            <Route exact path="/management/others" component={Management_Others} />
            <Route exact path="/management/survey" component={Management_Survey} />
            <Route exact path="/management/template" component={Management_Template} />
            <Route exact path="/management/notice" component={Management_Notice} />
            <Route exact path="/management/notice/:id" component={Management_Notice} /> 
            <Route exact path="/management/favorite" component={Management_Favorite} />
            <Route exact path="/management/prohibit" component={Management_Prohibit} />
            <Route exact path="/setting/macro" component={Setting_Macro} />
            <Route exact path="/setting/personal" component={Setting_Personal} />
            <Route exact path="/setting/recommend" component={Setting_Recommend} />
            <Route exact path="/system/settings" component={System_Settings} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route component={Error} />
          </Switch>
        </NavBar>
      </Router>
    );
  }
  else {
    return (
      <Router>
        <NavBar>
          <Switch>
            <Route component={Login} />
          </Switch>
        </NavBar>
      </Router>
    );
  }
}

export default RouteMap;
