import React, { useRef, useState } from "react";
import { Typography, Button, withStyles, TextField } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: "600",
  },
  form: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonOK: {
    marginStart: "10px",
  },
});

function LoginForm(props) {
  const { classes, onLogin, onChangePassword, error } = props;
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const inputRef = useRef(null);

  // 아이디
  const handleChangeID = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };

  // 비밀번호
  const handleChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  // 다음 Input 이동
  const handleNext = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      inputRef.current.focus();
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary" className={classes.title}>
        LOG IN
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete="true"
        onSubmit={() => onLogin(id, password)}
      >
        <div>
          <TextField
            value={id}
            onChange={handleChangeID}
            label={"ID"}
            error={error && typeof error === "string" && error.length > 0}
            required
            margin={"dense"}
            fullWidth
            autoFocus
            InputLabelProps={{ shrink: true }}
            onKeyPress={handleNext}
          />
          <TextField
            type={"password"}
            value={password}
            onChange={handleChangePassword}
            label={"PASSWORD"}
            error={error && typeof error === "string" && error.length > 0}
            helperText={error}
            required
            margin={"dense"}
            fullWidth
            inputRef={inputRef}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onChangePassword}
          >
            CHANGE PASSWORD
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonOK}
            type={"submit"}
          >
            OK
          </Button>
        </div>
      </form>
    </div>
  );
}
export default withStyles(styles)(LoginForm);
