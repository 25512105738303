import React, { useContext, useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
// import DataContext from "../../data/DataContext";
import MsgRetry from '../../../asset/img/btn_refresh_nor.png';
import MsgRetryPressed from '../../../asset/img/btn_refresh_pre.png';
import MsgDelete from '../../../asset/img/btn_remove_nor.png';
import MsgDeletePressed from '../../../asset/img/btn_remove_pre.png';
import TypingData from "../../../asset/img/img_text_loading.json";
import Lottie from "lottie-react";
import LoveIcon from "../../../asset/img/sentiment_love.svg";
import AngryIcon from "../../../asset/img/sentiment_angry.svg";
import NormalIcon from "../../../asset/img/sentiment_normal.svg";

const styles = theme => ({  
  root: {
    display: "flex",
    letterSpacing: "-0.5px",
    whiteSpace: "pre-wrap",
    zIndex: 10
  },
  leftContainer: {
    justifyContent: "flex-start"
  },
  container: {
    justifyContent: "center",
    margin: "10px",
    backgroundColor: "#eee",
    borderRadius:"9px",
  },
  message: {
    borderRadius: "12px",
    flexWrap: "wrap",
    color: "#949494",
    padding: "9px 20px",
    maxWidth: "70%",
    zIndex: 10,
    // wordWrap: 'break-word',
    wordBreak: "break-all"
  },
  date: {
    color: "#7f7f7f",
    fontSize: theme.font.size12,
    fontFamily: "LGSmHaTL",
    alignSelf: "flex-end",
    margin: "5px 0 6px 0"
  },
  rightDate: {
    margin: "5px 0 22px 0"
  },
  typing: {
    width: '30px',
    height: '6px'
  },

  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
    
  },
  messageLoading: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  messageStatusImg: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    //margin: "10px 0 0 0",
    border: "none",
    outline: "0 none",
    backgroundColor: "transparent",
    backgroundSize: "32px",
    backgroundImage: `url(${MsgRetry})`,
    "&:active": {
      backgroundImage: `url(${MsgRetryPressed})`,
    }
  },
  rightBox: {
    position: "relative"
  },
  btnDelete: {
    position: "absolute",
    left: "-25px",
    top: "12px",
    width: "32px",
    height: "32px",
    zIndex: "20",
    cursor: "pointer",
    border: "none",
    outline: "0 none",
    backgroundColor: "transparent",
    backgroundSize: "32px",
    backgroundImage: `url(${MsgDelete})`,
    "&:active": {
      backgroundImage: `url(${MsgDeletePressed})`,
    }
  },
  sentiment: {

  }
});

const lottieTypingOptions = {
  loop: true,
  autoplay: true,
  animationData: TypingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function TXInfoR(props) {
  const { classes, data } = props;
  // const context = useContext(DataContext);
  const [loading, setLoading] = useState(data.loading);
  const [online, setOnline] = useState(data.online);
  const handleRetry = async () => {
    setLoading(true);
    setOnline(true);
  
  }
  const handleDelete = () => {
    // context.deleteMessage(data.id);
  }
  
  return data.text && <div
    className={[
      classes.root,
      classes.container
    ].join(" ")}
  >
    <div className={classes.leftBox}>
      {loading==="true" && <Lottie
        {...lottieTypingOptions}
        className={classes.messageLoading}
        width={32}
        height={14}
      />}
      {/* {!online && <button
        className={classes.messageStatusImg}
        onClick={handleRetry} />} */}
      {/* <p className={[classes.date, classes.rightDate].join(' ')}>{data.date}</p> */}
    </div>
    <p
      className={[
        classes.message,
      ].join(" ")}
    >
      {data.text.split("\n").map((content, index) => (
        <Fragment key={index}>
          <span>{content}</span>
          <br />
        </Fragment>
      ))}
    </p>
    {!online&&(
      <div className={classes.rightBox}>
        <button className={classes.btnDelete} onClick={handleDelete}/>
      </div>
    )}
  </div>
}

const TXInfo = withStyles(styles)(TXInfoR)

function Info(props) {
  const { data } = props;
  return <TXInfo data={data}></TXInfo>
}
export default withStyles(styles)(Info);
