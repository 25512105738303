/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:840c47a5-714a-4817-bd97-e150696ba7d7",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_gPTIqVw3k",
    "aws_user_pools_web_client_id": "3p2e8o4e7npkaqbou3sukbr0im",
    "oauth": {},
    "aws_mobile_analytics_app_id": "4028233315064f36aa75abb9695d771e",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://v7bhi7safjbkpn25jwj2d4cb6e.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "awsmobileappsyncchat-20210215161051-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-2",
    "aws_content_delivery_url": "http://awsmobileappsyncchat-20210215161051-hostingbucket-dev.s3-website.ap-northeast-2.amazonaws.com"
};


export default awsmobile;
